import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import config from '@root/src/config';
import { mediaBreakpoint, ResponsiveWrapper } from '@services/responsiveProvider';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';
import { textH2 } from '@features/ui/styles/textStyles';

const Organizer = () => {
  const { t } = useTranslation();
  return (
    <StyledResponsiveWrapper>
      <Content>
        {t('footer_organizerTitle.message')}
        <DiscoverButton
          variant={BUTTON_VARIANT.linkSecondary}
          text={t('footer_organizer_discoverText.message')}
          leftIcon={ICON_NAME.arrowForward}
          url={{ pathname: config.urls.organiser }}
        />
      </Content>
    </StyledResponsiveWrapper>
  );
};

export default Organizer;

const StyledResponsiveWrapper = styled(ResponsiveWrapper)`
  background-color: var(--color-dark);
  background-image: url('/images/mso-topography-map.svg');
  background-size: cover;
`;
const DiscoverButton = styled(Button)<OwnProps>`
  margin-top: 12px;
`;
const Content = styled.div`
  ${textH2};
  display: flex;
  justify-content: space-between;
  color: var(--color-white);
  padding: 40px 9%;
  align-items: center;
  text-align: center;

  .button_discover_service{
    margin-top: 12px;
  }

  ${mediaBreakpoint.tablet} {
    flex-direction: column;
    padding: 20px 3%;
  }
`;
