/* eslint-disable no-console */
import { GetServerSidePropsContext } from 'next';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import { Store } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { DEFAULT_PRE_SERVICES, RANKINGS_LIST_TYPE, SERVICE_GROUP_TYPE } from '@root/src/types';
import config from '@root/src/config';
import { TAGS } from '@root/src/hooks/useEventInfo';
import useSubscriptionInfo from '@root/src/hooks/useSubscriptionInfo';
import { QUERY_API_DATE_FORMAT_LUXON } from '@utils/dates';
import { formatStringToNumberOrNull } from '@utils/gobal';
import { QUERY_ENUM } from '@services/routeList';
import { getAuthCookies, isAuthenticated } from '@services/cookies';
import { DataHandlerConstructorGeneric, isClientSideConstructor } from '@services/dataHandler/InitDataHandler';
import { useRequestHandler } from '@services/api/handlers';
import { EventsListApiParameters, EVENT_LIST_MODELS } from '@services/api';
import CartDataHandler from '@features/carts/CartDataHandler';
import GetEventData from '@features/eventDetail/GetEventData';
import GetUserData from '@features/users/GetUserData';
import { MODAL_NAME } from '@features/ui-layout/templates/ModalContainer';
import GetSerieData from '@features/serieDetail/GetSerieData';
import { getQuerySerieId } from '@features/serieDetail/utils';
import GetEventsData from '@features/eventList/GetEventsData';
import GetSubscriptionData from '@features/subscriptions/GetSubscriptionData';
import { getQueryEventId } from '@features/eventDetail/utils';
import OrderDataHandler from '@features/orders/OrdersDataHandler';
import ResultsDataHandler from '@features/results/ResultsDataHandler';
import {
  ServicesValues,
  setSubscriptionDefaultPreServices,
  setServicesForTeamMate,
  setSubscriptionPreServices,
  setSubscription,
} from '@features/subscriptions/slices/formSubscriptionSlice';

export enum GET_DATA_FOR {
  AUTH = 'AUTH',
  HOME = 'HOME',
  SERIE_DESCRIPTION = 'SERIE_DESCRIPTION',
  SERIE_EVENTS = 'SERIE_EVENTS',
  SERIE_SUBSCRIPTIONS = 'SERIE_SUBSCRIPTIONS',
  SERIE_RESULTS = 'SERIE_RESULTS',
  EVENT_LIST = 'EVENT_LIST',
  EVENT_DESCRIPTION = 'EVENT_DESCRIPTION',
  EVENT_SUBSCRIPTION = 'EVENT_SUBSCRIPTION',
  EVENT_ENGAGED_LIST = 'EVENT_ENGAGED_LIST',
  EVENT_RESULTS = 'EVENT_RESULTS',
  EVENT_LIVE_RESULTS = 'EVENT_LIVE_RESULTS',
  EVENT_RESULTS_SERIES = 'EVENT_RESULTS_SERIES',
  CART_PAYMENT_SUCCESS = 'CART_PAYMENT_SUCCESS',
  CART_PAYMENT_SELECTION = 'CART_PAYMENT_SELECTION',
  ACCOUNT_RECEIPTS = 'ACCOUNT_RECEIPTS',
  ACCOUNT_ACCESS_LIST = 'ACCOUNT_ACCESS_LIST',
  ACCOUNT_MOBILE_NUMBERS = 'ACCOUNT_MOBILE_NUMBERS',
  ACCOUNT_SUBSCRIPTIONS = 'ACCOUNT_SUBSCRIPTIONS',
  ACCOUNT_SUBSCRIPTION_DETAIL = 'ACCOUNT_SUBSCRIPTION_DETAIL',
  MODAL_ACCOUNT_SUBSCRIPTION_DETAIL = 'MODAL_ACCOUNT_SUBSCRIPTION_DETAIL',
  MODAL_CART_SUBSCRIPTION_UPDATE = 'MODAL_CART_SUBSCRIPTION_UPDATE',
  ACCOUNT_PREFERENCES = 'ACCOUNT_PREFERENCES',
}

const getData: (
  type: GET_DATA_FOR,
  dataHandlerConstructor: DataHandlerConstructorGeneric,
  isAuth: boolean,
  userId?: number,
) => Promise<void> = async (
  type,
  dataHandlerConstructor,
  isAuth,
  userId,
) => {
  const isClientSide = isClientSideConstructor(dataHandlerConstructor);
  switch (type) {
    case GET_DATA_FOR.AUTH: {
      const getUserData = new GetUserData(dataHandlerConstructor);
      const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
      const cartDataHandler = new CartDataHandler(dataHandlerConstructor);
      await getSubscriptionData.getNewSubscriptions();
      await cartDataHandler.getCurrentCart();
      await getUserData.getProfile();
      getUserData.setIsAuthenticated();
      break;
    }

    case GET_DATA_FOR.HOME: {
      const getEventData = new GetEventsData(dataHandlerConstructor);
      await getEventData.getNumberOfEventsToExplore();
      const today = DateTime.now();
      const data = {
        pageSize: config.lists.currentEventsSize,
        q: '*',
      };
      await getEventData.getEvents({
        filteredRange: [
          {
            id: 'date',
            type: 'date',
            valueLte: today.toFormat(QUERY_API_DATE_FORMAT_LUXON),
          },
        ],
        sorted: [
          {
            id: 'date',
            desc: true,
          },
        ],
        models: [EVENT_LIST_MODELS.baseRace],
        ...data,
      });
      await getEventData.getEvents({
        filteredRange: [
          {
            id: 'date',
            type: 'date',
            valueGte: today.plus({ days: 1 }).toFormat(QUERY_API_DATE_FORMAT_LUXON),
          },
        ],
        sorted: [
          {
            id: 'date',
            desc: false,
          },
        ],
        models: [EVENT_LIST_MODELS.baseRace],
        ...data,
      }, true);
      await getEventData.getSponsoredEvents();
      break;
    }

    case GET_DATA_FOR.SERIE_DESCRIPTION: {
      const getSerieData = new GetSerieData(dataHandlerConstructor);
      const serieId = getQuerySerieId(getSerieData.query, getSerieData.serverSideContext?.req?.url);
      await Promise.all(
        [getSerieData.getSerie(serieId), getSerieData.getSerieEvents(serieId), getSerieData.getSeriePacks(serieId)]
      );
      break;
    }

    case GET_DATA_FOR.SERIE_EVENTS: {
      const getSerieData = new GetSerieData(dataHandlerConstructor);
      const serieId = getQuerySerieId(getSerieData.query, getSerieData.serverSideContext?.req?.url);
      await Promise.all(
        [getSerieData.getSerie(serieId), getSerieData.getSerieEvents(serieId), getSerieData.getSeriePacks(serieId)]
      );
      break;
    }

    case GET_DATA_FOR.SERIE_SUBSCRIPTIONS: {
      const getSerieData = new GetSerieData(dataHandlerConstructor);
      const getEventData = new GetEventData(dataHandlerConstructor);
      const serieId = getQuerySerieId(getSerieData.query, getSerieData.serverSideContext?.req?.url);

      if (!isAuth && !isClientSide) {
        const [, events] = await Promise.all(
          [getSerieData.getSerie(serieId), getSerieData.getSeriePacks(serieId), getSerieData.getSerieEvents(serieId)]
        );
        await Promise.all(events.map(e => getEventData.getEvent(e._idMso)));
        await Promise.all(events.map(e => getEventData.getEventCategories(e._idMso)));
      }

      if (isClientSide && isAuth) {
        await Promise.all(
          [getSerieData.getSerie(serieId), getSerieData.getSeriePacks(serieId), getSerieData.getSerieEvents(serieId)]
        );
        const getUserData = new GetUserData(dataHandlerConstructor);
        await getUserData.getAccessesOnMyFriends();

        const modalName = getEventData.query[QUERY_ENUM.modal];
        const modalId = getEventData.query[QUERY_ENUM.modalId];
        const eventId = getEventData.query[QUERY_ENUM.modalParamOne];
        if (
          modalName != null
          && (modalName === MODAL_NAME.subscriptionCartCreate || modalName === MODAL_NAME.subscriptionCartUpdate)
          && modalId != null
          && eventId != null
        ) {
          const categoryId = parseInt(modalId as string, 10);
          const eventIdInt = parseInt(eventId as string, 10);
          await getEventData.getEvent(eventIdInt);
          await getEventData.getEventCategoryService(eventIdInt, categoryId, SERVICE_GROUP_TYPE.SERVICES);
        }
      }
      break;
    }

    case GET_DATA_FOR.SERIE_RESULTS: {
      const getSerieData = new GetSerieData(dataHandlerConstructor);
      const serieId = getQuerySerieId(getSerieData.query, getSerieData.serverSideContext?.req?.url);
      await Promise.all(
        [getSerieData.getSerie(serieId), getSerieData.getSerieEvents(serieId), getSerieData.getSeriePacks(serieId)]
      );

      const resultsDataHandler = new ResultsDataHandler(dataHandlerConstructor);
      if (serieId == null) return;

      const seriesList = await resultsDataHandler.getTopSeriesList(serieId);
      const firstSerie = seriesList[0];
      if (firstSerie == null) return;

      let serieType = getSerieData.query[QUERY_ENUM.type] as RANKINGS_LIST_TYPE;
      let serieResultsId = getSerieData.query[QUERY_ENUM.serieId] as string;
      if (serieType == null) serieType = firstSerie.serieResultsType;
      if (serieResultsId == null) serieResultsId = firstSerie._idMso?.toString();

      const resultTypeId = getSerieData.query[QUERY_ENUM.typeId] as string;

      await resultsDataHandler.getSeriesResultsList(serieType, serieId, serieResultsId, resultTypeId);
      break;
    }

    case GET_DATA_FOR.EVENT_LIST: {
      const getEventData = new GetEventsData(dataHandlerConstructor);
      const { query } = getEventData;
      const data: EventsListApiParameters = { models: [EVENT_LIST_MODELS.baseRace, EVENT_LIST_MODELS.baseEvent] };
      if (query[QUERY_ENUM.page] != null) {
        const pageNumber = Number(query[QUERY_ENUM.page]);
        if (Number.isInteger(pageNumber)) {
          data.page = pageNumber;
        } else {
          console.error(`GET_DATA_FOR.EVENT_LIST : pageNumber is not an integer pageNumber=${pageNumber}`);
        }
      }
      if (query[QUERY_ENUM.filterSearch]) {
        data.q = `${query[QUERY_ENUM.filterSearch] as string}*`;
      }
      data.filtered = [
        query[QUERY_ENUM.filterSportCategory] && {
          id: 'sportCategory',
          value: query[QUERY_ENUM.filterSportCategory],
        },
        query[QUERY_ENUM.filterSite] && {
          id: 'site',
          value: query[QUERY_ENUM.filterSite],
        },
        query[QUERY_ENUM.filterEventType] && {
          id: 'eventType',
          value: query[QUERY_ENUM.filterEventType],
        },
        {
          id: 'hasEventResults',
          value: query[QUERY_ENUM.filterTag] === TAGS.RESULTS,
        },
        {
          id: 'hasSerieResults',
          value: query[QUERY_ENUM.filterTag] === TAGS.RANKED_SERIES,
        },
        {
          id: 'hasLive',
          value: query[QUERY_ENUM.filterTag] === TAGS.LIVE_TIMING,
        },
        {
          id: 'hasEngagedList',
          value: query[QUERY_ENUM.filterTag] === TAGS.ENGAGED_LIST,
        },
        {
          id: 'hasOpenRegistrations',
          value: query[QUERY_ENUM.filterTag] === TAGS.REGISTRATION_OPEN,
        },
      ].filter(filter => filter?.value);

      if (query[QUERY_ENUM.filterStartDate] || query[QUERY_ENUM.filterEndDate]) {
        const filteredRange: any = {
          id: 'date',
          type: 'date',
        };
        if (query[QUERY_ENUM.filterStartDate]) {
          filteredRange.valueGte = query[QUERY_ENUM.filterStartDate];
        }
        if (query[QUERY_ENUM.filterEndDate]) {
          filteredRange.valueLte = query[QUERY_ENUM.filterEndDate];
        }
        data.filteredRange = [filteredRange];
      }
      await getEventData.getSites();
      await getEventData.getSports();
      await getEventData.getEvents(data);
      break;
    }

    case GET_DATA_FOR.EVENT_DESCRIPTION: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);
      await getEventData.getEvent(eventId);
      if (isAuth) {
        await getEventData.getEventQuotas(eventId);
      }
      break;
    }
    case GET_DATA_FOR.EVENT_SUBSCRIPTION: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);

      if (!isAuth && !isClientSide) {
        await getEventData.getEvent(eventId);
        await getEventData.getEventCategories(eventId);
      }

      if (isClientSide && isAuth) {
        await getEventData.getEvent(eventId);
        const modalName = getEventData.query[QUERY_ENUM.modal];
        const modalId = getEventData.query[QUERY_ENUM.modalId];
        if (
          modalName != null
          && (modalName === MODAL_NAME.subscriptionCartCreate || modalName === MODAL_NAME.subscriptionCartUpdate)
          && modalId != null
        ) {
          const categoryId = parseInt(modalId as string, 10);
          await getEventData.getEventCategoryService(eventId, categoryId, SERVICE_GROUP_TYPE.SERVICES);
        } else {
          const getUserData = new GetUserData(dataHandlerConstructor);
          await getEventData.getEventCategories(eventId, userId);
          await getEventData.getHasDiscountVoucher(eventId);
          await getEventData.getEventQuotas(eventId);
          await getUserData.getAccessesOnMyFriends();
        }
      }
      break;
    }
    case GET_DATA_FOR.EVENT_ENGAGED_LIST: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);

      await getEventData.getEvent(eventId);

      await getEventData.getEventCategories(eventId);
      if (isAuth) {
        const getUserData = new GetUserData(dataHandlerConstructor);
        await getUserData.getAccessesOnMyFriends();
        await getUserData.getAccessesOnMyProfile();
      }

      // Handle old events with eventId > euroCategoriesIds
      if (eventId < config.customHandlings.euroCategoriesIds) {
        await Promise.allSettled([
          getEventData.getEngagedListCount(eventId),
          getEventData.getEngagedListCantons(eventId),
          getEventData.getEngagedListNationalities(eventId),
          getEventData.getEngagedPaginatedList(eventId),
        ]);
      } else {
        const categoriesJson = await getEventData.getEventCategories(eventId);
        await Promise.allSettled(
          categoriesJson.map(category => (getEventData.getEngagedList(eventId, category._idMso))),
        );
      }
      break;
    }
    case GET_DATA_FOR.EVENT_RESULTS: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const resultsDataHandler = new ResultsDataHandler(dataHandlerConstructor);
      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);
      const event = await getEventData.getEvent(eventId);

      const eventResultsAvailability = await resultsDataHandler.getEventResultsAvailability(eventId);
      const resultTypeParam = getEventData.query[QUERY_ENUM.type] as RANKINGS_LIST_TYPE;
      const resultType = eventResultsAvailability[resultTypeParam]
        ? resultTypeParam
        : Object.keys(eventResultsAvailability).filter(key => eventResultsAvailability[key])[0] as RANKINGS_LIST_TYPE;

      const resultTypeId = formatStringToNumberOrNull(getEventData.query[QUERY_ENUM.typeId] as string);

      await Promise.allSettled([
        resultsDataHandler.getEventResultsList(
          resultType, eventId, resultTypeId
        ), getEventData.getEngagedListCantons(eventId), getEventData.getEngagedListNationalities(eventId),
      ]);

      if (isAuth) {
        const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
        await getSubscriptionData.getSubscriptions({ eventID: event._id });
      }
      break;
    }

    case GET_DATA_FOR.EVENT_LIVE_RESULTS: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const resultsDataHandler = new ResultsDataHandler(dataHandlerConstructor);

      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);
      const event = await getEventData.getEvent(eventId);
      const categories = await getEventData.getEventCategories(eventId);

      const liveResultsContext = await resultsDataHandler.getEventLiveResultsContext(eventId, categories);
      console.log('liveResultsContext', liveResultsContext);

      const resultsListType = getEventData.query[QUERY_ENUM.type] as RANKINGS_LIST_TYPE
        ?? RANKINGS_LIST_TYPE.SCRATCH;
      resultsDataHandler.setEventResultsListType(resultsListType);

      await Promise.allSettled(
        [getEventData.getEngagedListCantons(eventId), getEventData.getEngagedListNationalities(eventId)]
      );

      const typeId = formatStringToNumberOrNull(getEventData.query[QUERY_ENUM.typeId] as string);
      const currentOption = typeId
        ? liveResultsContext.options[resultsListType][typeId]
        : Object.values(liveResultsContext.options[resultsListType])[0];

      await resultsDataHandler.getEventLiveResultsRankings(
        eventId,
        currentOption,
        categories,
      );

      if (isAuth) {
        const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
        await getSubscriptionData.getSubscriptions({ eventID: event._id });
      }
      break;
    }

    case GET_DATA_FOR.EVENT_RESULTS_SERIES: {
      const getEventData = new GetEventData(dataHandlerConstructor);
      const resultsDataHandler = new ResultsDataHandler(dataHandlerConstructor);
      const getSerieData = new GetSerieData(dataHandlerConstructor);

      const eventId = getQueryEventId(getEventData.query, getEventData.serverSideContext?.req?.url);
      const event = await getEventData.getEvent(eventId);
      const serieId = event?.serie?._idMso;
      if (serieId == null) return;

      await getSerieData.getSerie(serieId);

      const seriesList = await resultsDataHandler.getTopSeriesList(serieId);
      const firstSerie = seriesList[0];
      if (firstSerie == null) return;

      let serieType = getEventData.query[QUERY_ENUM.type] as RANKINGS_LIST_TYPE;
      let serieResultsId = getEventData.query[QUERY_ENUM.serieId] as string;
      if (serieType == null) serieType = firstSerie.serieResultsType;
      if (serieResultsId == null) serieResultsId = firstSerie._idMso?.toString();

      const resultTypeId = getEventData.query[QUERY_ENUM.typeId] as string;

      await resultsDataHandler.getSeriesResultsList(serieType, serieId, serieResultsId, resultTypeId);
      break;
    }

    case GET_DATA_FOR.CART_PAYMENT_SELECTION: {
      const cartDataHandler = new CartDataHandler(dataHandlerConstructor);
      await cartDataHandler.getPaymentMethodsInfo();
      break;
    }
    case GET_DATA_FOR.CART_PAYMENT_SUCCESS: {
      const cartDataHandler = new CartDataHandler(dataHandlerConstructor);
      await cartDataHandler.getCarts({ pageSize: 1 });
      break;
    }

    case GET_DATA_FOR.ACCOUNT_SUBSCRIPTIONS: {
      const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
      await getSubscriptionData.getSubscriptions();
      break;
    }
    case GET_DATA_FOR.ACCOUNT_SUBSCRIPTION_DETAIL: {
      const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
      const subscriptionId = getSubscriptionData.query[QUERY_ENUM.subscriptionId] as string;
      if (subscriptionId == null) {
        console.error('getServerSideData ACCOUNT_SUBSCRIPTION_DETAIL : try to call the subscription without the QUERY_ENUM.subscriptionId ');
        break;
      }
      const subscription = await getSubscriptionData.getSubscription({ subscriptionId });
      if (subscription === null) {
        console.error('getServerSideData ACCOUNT_SUBSCRIPTION_DETAIL : getSubscription failed');
        break;
      }

      try {
        await getSubscriptionData.getSubscriptionServicesGroups({ subscriptionId });
      } catch (err) {
        console.error('incomplete data for subscription', err);
      }
      break;
    }
    case GET_DATA_FOR.MODAL_ACCOUNT_SUBSCRIPTION_DETAIL: {
      const getSubscriptionData = new GetSubscriptionData(dataHandlerConstructor);
      const subscriptionId = getSubscriptionData.query[QUERY_ENUM.subscriptionId] as string;
      const subscription = await getSubscriptionData.getSubscription({ subscriptionId });

      const getEventData = new GetEventData(dataHandlerConstructor);
      const { category, athlete, team } = subscription;
      await getEventData.getEvent(category.event._idMso);
      await getEventData.getEventCategoryService(category.event._idMso, category._idMso, SERVICE_GROUP_TYPE.SERVICES);

      const serviceGroups = await getSubscriptionData.getSubscriptionServicesGroups({ subscriptionId });

      const { teamMemberIDinServiceGroup } = useSubscriptionInfo(subscription);
      const athletesIds = [athlete._idMso, ...(team ? team.map(athlete => athlete.teamMember._idMso) : [])];
      const getUserData = new GetUserData(dataHandlerConstructor);
      const athletes = await Promise.all(athletesIds.map(athleteId => getUserData.getAthleteProfile(athleteId)));

      athletes.forEach((athlete, index) => {
        const teamMateServices = serviceGroups
          .filter(serviceGroup => serviceGroup.groupType === SERVICE_GROUP_TYPE.SERVICES)
          .filter(serviceGroup => serviceGroup.teamMember === teamMemberIDinServiceGroup(athlete));

        const servicesFormatted = teamMateServices.map(serviceGroup => ({
          id: `group_${serviceGroup._idMso}`,
          value: serviceGroup.services.reduce((acc, current) => {
            if ('value' in current) {
              acc[`service_${current._idMso}`] = current.value;
            }
            return acc;
          }, {}),
        }))
          .reduce((acc, current) => {
            acc[current.id] = {
              ...acc[current.id]?.value,
              ...current.value,
            };
            return acc;
          }, {}) as ServicesValues;

        getEventData.dispatch(setServicesForTeamMate({
          categoryId: category._idMso,
          profile: athlete,
          values: servicesFormatted,
          index,
        }));
      });
      break;
    }
    case GET_DATA_FOR.MODAL_CART_SUBSCRIPTION_UPDATE: {
      // TODO: Check if all of these call are necessary and clean code
      const getCartData = new CartDataHandler(dataHandlerConstructor);
      const currentCart = await getCartData.getCurrentCart();
      const itemId = getCartData.query[QUERY_ENUM.modalId];
      const order = currentCart.items.find(item => item._id === itemId);
      const { team } = order;

      const getEventData = new GetEventData(dataHandlerConstructor);
      const eventId = order.event._idMso;
      const categoryId = order.category._idMso;
      await getEventData.getEventCategoryService(eventId, categoryId, SERVICE_GROUP_TYPE.SERVICES);
      const isTeamSubscription = team.length > 0;

      const event = await getEventData.getEvent(eventId);
      const category = await getEventData._getEventCategory(categoryId);
      getEventData.dispatch(setSubscription({
        event,
        category,
      }));

      const athletesIds = [
        ...(isTeamSubscription
          ? team.map(athlete => athlete.teamMember._idMso)
          : [order.athlete._idMso]),
      ];
      const getUserData = new GetUserData(dataHandlerConstructor);
      const athletes = await Promise.all(athletesIds.map(athleteId => getUserData.getAthleteProfile(athleteId)));

      athletes.forEach((athlete, index) => {
        const servicesFormatted = order.serviceGroupsOrders
          .filter(serviceGroup => serviceGroup.groupType === SERVICE_GROUP_TYPE.SERVICES)
          .filter(serviceGroup => (isTeamSubscription
            ? serviceGroup.teamMember._idMso === athlete._idMso
            : serviceGroup.teamMember === null))
          .map(serviceGroup => ({
            id: `group_${serviceGroup.group._idMso}`,
            value: serviceGroup.serviceOrders.reduce((acc, current) => {
              acc[`service_${current.service._idMso}`] = current.value;
              return acc;
            }, {}),
          }))
          .reduce((acc, current) => {
            acc[current.id] = {
              ...acc[current.id]?.value,
              ...current.value,
            };
            return acc;
          }, {});

        getEventData.dispatch(setServicesForTeamMate({
          categoryId,
          profile: athlete,
          values: servicesFormatted,
          index,
        }));
      });

      const preServicesValues = order
        .serviceGroupsOrders
        .filter(serviceGroupOrder => serviceGroupOrder.groupType === SERVICE_GROUP_TYPE.PRE_SERVICES)
        .map(serviceGroupOrder => ({
          id: `group_${serviceGroupOrder.group._idMso}`,
          value: serviceGroupOrder.serviceOrders.reduce((acc, current) => {
            acc[`service_${current.service._idMso}`] = current.value;
            return acc;
          }, {}),
        }))
        .reduce((acc, current) => {
          acc[current.id] = {
            ...acc[current.id]?.value,
            ...current.value,
          };
          return acc;
        }, {});

      getEventData.dispatch(setSubscriptionPreServices({
        categoryId,
        preServicesValues,
      }));

      const defaultPreServices = Object
        .values(DEFAULT_PRE_SERVICES)
        .filter(defaultPreService => order[defaultPreService] !== undefined)
        .reduce((acc, defaultPreService) => {
          acc[defaultPreService] = order[defaultPreService];
          return acc;
        }, {});

      getEventData.dispatch(setSubscriptionDefaultPreServices({
        categoryId,
        defaultPreServices,
      }));
      break;
    }
    case GET_DATA_FOR.ACCOUNT_RECEIPTS: {
      const orderDataHandler = new OrderDataHandler(dataHandlerConstructor);
      await orderDataHandler.getOrders();
      break;
    }
    case GET_DATA_FOR.ACCOUNT_ACCESS_LIST: {
      const getUserData = new GetUserData(dataHandlerConstructor);
      await getUserData.getAccessesOnMyProfile();
      await getUserData.getAccessesOnMyFriends();
      break;
    }
    case GET_DATA_FOR.ACCOUNT_MOBILE_NUMBERS: {
      const getUserData = new GetUserData(dataHandlerConstructor);
      await getUserData.getMobileNumbers();
      break;
    }
    case GET_DATA_FOR.ACCOUNT_PREFERENCES: {
      const getUserData = new GetUserData(dataHandlerConstructor);
      await getUserData.getPreferences();
      break;
    }

    default: {
      throw Error('getData : type : GET_DATA_FOR is not implemented');
    }
  }
};

export const getServerSideData: (
  store: Store,
  serverSideContext: GetServerSidePropsContext,
  type: GET_DATA_FOR,
) => Promise<void> = async (
  store,
  serverSideContext,
  type,
) => {
  const serverSideConstructor = {
    store,
    serverSideContext,
  };

  const isAuth = isAuthenticated(serverSideContext);
  const { userId } = getAuthCookies(serverSideContext);
  return getData(type, serverSideConstructor, isAuth, userId);
};

export const useGetClientSideData: (type: GET_DATA_FOR, fetchDataCondition?: Array<boolean>) => boolean = (
  type, fetchDataCondition = [true],
) => {
  const requestHandler = useRequestHandler();
  const store = useStore();
  const router = useRouter();
  const didMount = useRef(false);

  const [isLoading, setIsLoading] = useState(true);

  const isAuth = isAuthenticated();
  const { userId } = getAuthCookies();

  const clientSideConstructor = {
    requestHandler,
    store,
    router,
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await getData(type, clientSideConstructor, isAuth, userId);
      setIsLoading(false);
    };

    if (didMount.current) {
      // If it's a re-render
      if (fetchDataCondition.some(Boolean)) {
        loadData();
      }
    } else {
      // If it's the first render always load the data
      didMount.current = true;
      loadData();
    }
  }, fetchDataCondition);

  return isLoading;
};
