import { useEffect } from 'react';

const beforeClosing = event => {
  // Cancel the event
  event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  event.returnValue = true;
};

const disarmConfirm = () => {
  window.removeEventListener('beforeunload', beforeClosing);
};

const useConfirmBeforeClosing = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', beforeClosing);
    return disarmConfirm;
  }, []);
  return disarmConfirm;
};

export default useConfirmBeforeClosing;
