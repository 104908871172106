import { FormikContextType, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

/**
 * Use GetFormik if you need to access Formik data outside of its Form render tree.
 * @constructor
 */
const GetFormik = ({ getFormikContext }: { getFormikContext: (formik: FormikContextType<any>) => void }) => {
  const formik = useFormikContext();
  useEffect(() => {
    getFormikContext(formik);
  }, [
    formik.values,
    formik.errors,
    formik.isValid,
    formik.isSubmitting,
  ]);

  return (
    <></>
  );
};

export default GetFormik;
