import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  AccessesOnMyFriends,
  AccessesOnMyProfile,
  MobileNumber,
  Preferences,
  Profile,
  ACCESS_LEVEL,
} from '@root/src/types';
import { hydrateStateIfDataNotNull, RootState } from '@services/store';
import {
  accessesOnMyFriendsFromApiToReducer,
  accessesOnMyProfileFromApiToReducer,
  mobileNumbersFromApiToReducer,
  preferencesFromApiToReducer,
  profileFromApiToReducer,
} from '@features/users/formatters';

export interface UserSliceState {
  isAuthenticated: boolean;
  detail: Profile;
  accessesOnMyProfile: Array<AccessesOnMyProfile>;
  accessesOnMyFriends: AccessesOnMyFriends;
  mobileNumbers: Array<MobileNumber>;
  preferences: Preferences;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    detail: null,
    accessesOnMyProfile: null,
    accessesOnMyFriends: null,
    mobileNumbers: null,
    preferences: null,
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      // COMMENT 152 (search the comment)
      const { user } = action.payload;
      return hydrateStateIfDataNotNull(state, user);
    },
  },
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.detail = profileFromApiToReducer(action.payload);
    },
    setAccessesOnMyProfile: (state, action) => {
      state.accessesOnMyProfile = accessesOnMyProfileFromApiToReducer(action.payload);
    },
    setAccessesOnMyFriends: (state, action) => {
      state.accessesOnMyFriends = accessesOnMyFriendsFromApiToReducer(action.payload);
    },
    setMobileNumbers: (state, action) => {
      state.mobileNumbers = mobileNumbersFromApiToReducer(action.payload);
    },
    setPreferences: (state, action) => {
      state.preferences = preferencesFromApiToReducer(action.payload);
    },
  },
});
export default userSlice.reducer;

export const {
  setIsAuthenticated,
  setUser,
  setAccessesOnMyProfile,
  setAccessesOnMyFriends,
  setMobileNumbers,
  setPreferences,
} = userSlice.actions;

export const selectCurrentUserDetail = (state: RootState) => state.user.detail;
export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;

export const selectAccessesOnMyProfile = (state: RootState) => state.user.accessesOnMyProfile;
export const selectAccessesOnMyProfileIDsWithoutMeSorted = (state: RootState) => state.user.accessesOnMyProfile
  .filter(access => access.level !== ACCESS_LEVEL.OWNER)
  .sort((a, b) => {
    const result = a.athlete.lastName.localeCompare(b.athlete.lastName);
    return result !== 0 ? result : a.athlete.firstName.localeCompare(b.athlete.firstName);
  })
  .map(access => access.athlete._id);
export const selectAccessOnMyProfileByID = (id: string) => createSelector(
  selectAccessesOnMyProfile,
  access => access.find(item => item.athlete._id === id),
);
export const selectAccessesOnMyFriends = (state: RootState) => state.user.accessesOnMyFriends?.items || [];
export const selectAccessesOnMyFriendsIDs = createSelector(
  selectAccessesOnMyFriends,
  (accessesOnMyFriendsList) => accessesOnMyFriendsList.map(athlete => athlete._id),
);
export const selectAccessesOnMyFriendsIDsWithoutMe = createSelector(
  selectAccessesOnMyFriends,
  (accessesOnMyFriendsList) => accessesOnMyFriendsList
    .filter(athlete => athlete.accessLevel !== ACCESS_LEVEL.OWNER)
    .map(athlete => athlete._id),
);
export const selectAccessOnMyFriendsByID = (id: string) => createSelector(
  selectAccessesOnMyFriends,
  athletes => athletes.find(athlete => athlete._id === id),
);

export const selectMobileNumbers = (state: RootState) => state.user.mobileNumbers;
export const selectMobileNumbersIDs = (state: RootState) => (state.user.mobileNumbers
  ? state.user.mobileNumbers.map(mobileNumber => mobileNumber._id)
  : []);
export const selectMobileNumberByID = (id: string) => createSelector(
  selectMobileNumbers,
  mobileNumbers => mobileNumbers.find(mobileNumber => mobileNumber._id === id),
);

export const selectPreferences = (state: RootState) => state.user.preferences;
