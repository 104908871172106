import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useConfirmBeforeClosing from '@root/src/hooks/useConfirmBeforeClosing';
import { randomInt } from '@utils/gobal';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import getRoute from '@services/routeList';
import apiGenericNotification from '@services/api/apiGenericNotification';
import {
  pushCompletedCat,
  selectCompletedCats,
  selectCurrentTeamMate,
  selectEarliestNextSubscription,
  selectSubscriptionCategory,
  selectSubscriptionDefaultPreServices,
  selectSubscriptionEvent,
  selectSubscriptionFor,
  selectSubscriptionPack,
  selectSubscriptionPreServices,
  selectSubscriptionsDetails,
  selectSubscriptionServices,
  setServicesForTeamMate,
} from '@features/subscriptions/slices/formSubscriptionSlice';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { VIP_ERROR } from '@features/ui-input/components/InputVIP';
import {
  createApiServicesGroups,
  createApiSubscriptionPack,
  createApiSubscriptionSolo,
  createApiSubscriptionTeam,
} from '@features/subscriptions/formatters';
import { QUOTAS_ERROS } from '@features/carts/PaymentHandler';
import { selectSerieDetail } from '@features/serieDetail/slices/serieDetailServerSlice';

interface useRegistrationOptions {
  categoryId: number,
  orderItemId?: string,
  teamMateIndexToEdit?: number,
  ready?: boolean,
}

const useRegistration = ({
  categoryId,
  orderItemId,
  teamMateIndexToEdit,
  ready = true,
}: useRegistrationOptions) => {
  const requestHandler = useRequestHandler();
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const disarmConfirm = useConfirmBeforeClosing();
  const uniqueRandomId = randomInt().toString();

  const [teamMateIndex, setTeamMateIndex] = useState(teamMateIndexToEdit ?? 0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const category = useSelector(selectSubscriptionCategory(categoryId));
  const event = useSelector(selectSubscriptionEvent(categoryId));
  const defaultPreServices = useSelector(selectSubscriptionDefaultPreServices(categoryId)) || {};
  const preServices = useSelector(selectSubscriptionPreServices(categoryId)) || {};
  const services = useSelector(selectSubscriptionServices(categoryId));
  const storedCurrentTeamMate = useSelector(selectCurrentTeamMate(categoryId, teamMateIndex));
  const completedCategories = useSelector(selectCompletedCats);
  const earliestNextSubscription = useSelector(selectEarliestNextSubscription);
  const serie = useSelector(selectSerieDetail);
  const pack = useSelector(selectSubscriptionPack);
  const athlete = useSelector(selectSubscriptionFor);
  const subscriptions = useSelector(selectSubscriptionsDetails);

  const inEditMode = teamMateIndexToEdit != null;
  const nbTeamMates = category?.team?.nbMembers ?? 1;
  const isSolo = nbTeamMates === 1;

  const changeTeamMate = inc => {
    if ((teamMateIndex + inc) >= 0 && (teamMateIndex + inc) < nbTeamMates && !teamMateIndexToEdit) {
      setIsLoading(true);
      setTeamMateIndex(teamMateIndex + inc);
    }
  };

  const addServiceToCurrentTeamMate = (values) => {
    const payload = {
      categoryId,
      values,
      profile: storedCurrentTeamMate,
      index: teamMateIndex,
    };
    dispatch(setServicesForTeamMate(payload));
    dispatch(pushCompletedCat({ categoryId }));
  };

  const handleSubmitSuccess = async () => {
    disarmConfirm();
    await router.replace(getRoute.cart.index(), null, { scroll: false });
  };

  const handleSubmitError = async (response: Response) => {
    const responseContent = await response.json();

    switch (responseContent.code) {
      case VIP_ERROR.ALREADY_USED:
        toast(<Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('InputVIP_vipCodeAlreadyUsed.message')}
          text=""
        />);
        break;
      case QUOTAS_ERROS.CATEGORY_FULL:
      case QUOTAS_ERROS.EVENT_FULL:
        toast(<Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('notification_genericTitle.message')}
          text={t('orderPayment_errorQuotaExceededMessageContent.message')}
        />);
        break;
      case 'error.casLicenseRequired':
        toast(<Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('notification_genericTitle.message')}
          text={t('orderPayment_errorTeamLeadNeedsCas.message')}
        />);
        break;
      case 'error.shoppingCartIsFull':
        toast(<Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('notification_genericTitle.message')}
          text={t('orderPayment_errorShoppingCartIsFull.message')}
        />);
        break;
      case 'error.duplicatePackAthleteInCart':
        toast(<Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('notification_genericTitle.message')}
          text={t('orderPayment_errorDuplicatePackAthleteInCart.message', { athleteName: athlete.fullName })}
        />);
        break;
      default:
        apiGenericNotification(t);
        break;
    }
    setIsLoading(false);
  };

  const createOrder = async (body) => {
    const request = pack
      ? api.subscription.carts.packs.create({
        body,
        locale: router.locale,
      })
      : api.subscription.carts.create({
        body,
        locale: router.locale,
      });

    const { response } = await requestHandler({
      request,
      overwrite: {
        [STATUS_RANGE.CLIENT_ERROR]: (async (handlerResponse) => {
          const { response } = handlerResponse;
          if (response) {
            await handleSubmitError(response);
          }
          return handlerResponse;
        }),
      },
    });

    if (response && response?.ok) {
      await handleSubmitSuccess();
    }
  };

  const updateOrder = async (body) => {
    if (orderItemId) {
      const { response } = await requestHandler({
        request: api.subscription.carts.update({
          body,
          orderId: orderItemId,
          locale: router.locale,
        }),
        overwrite: {
          [STATUS_RANGE.CLIENT_ERROR]: (async (handlerResponse) => {
            const { response } = handlerResponse;
            if (response) {
              await handleSubmitError(response);
            }
            return handlerResponse;
          }),
        },
      });
      if (response && response?.ok) {
        await handleSubmitSuccess();
      }
    }
  };

  const makeSoloSubscription = async () => {
    const preServicesBody = createApiServicesGroups(services[0].servicesValues, null);
    const preServicesApi = createApiServicesGroups(preServices, null);

    const body = createApiSubscriptionSolo(
      [...preServicesApi.flat(), ...preServicesBody],
      services[0].profile._idMso,
      categoryId,
      event._idMso,
      defaultPreServices.club,
      defaultPreServices.insurance,
      defaultPreServices.vip,
      defaultPreServices.emergencyNumber,
      defaultPreServices.license
    );
    if (inEditMode) {
      await updateOrder(body);
    } else {
      await createOrder({
        ...body,
        idempotentToken: uniqueRandomId,
      });
    }
  };

  const makeTeamSubscription = async () => {
    const servicesGroupsPerTeamMate = Object.values(services)
      .map((servicePerTeamMate) => createApiServicesGroups(
        { ...servicePerTeamMate.servicesValues },
        servicePerTeamMate.profile._idMso,
      ));

    const preServicesApi = createApiServicesGroups(
      preServices,
      null,
    );

    const body = createApiSubscriptionTeam(
      [...preServicesApi.flat(), ...servicesGroupsPerTeamMate.flat()],
      categoryId,
      event._idMso,
      defaultPreServices.club as string,
      Object.values(services)
        .map(servicePerTeamMate => servicePerTeamMate.profile._idMso),
      defaultPreServices.insurance,
      defaultPreServices.vip,
      defaultPreServices.emergencyNumber,
      defaultPreServices.license
    );
    if (inEditMode) {
      await updateOrder(body);
    } else {
      await createOrder({
        ...body,
        idempotentToken: uniqueRandomId,
      });
    }
  };

  const makePackSubscription = async () => {
    const items = Object.values(subscriptions).map(({
      category,
      event,
      defaultPreServices,
      services,
      preServices,
    }) => {
      const preServicesBody = createApiServicesGroups(services[0].servicesValues, null);
      const preServicesApi = createApiServicesGroups(preServices, null);

      const soloSub = createApiSubscriptionSolo(
        [...preServicesApi.flat(), ...preServicesBody],
        services[0].profile._idMso,
        category._idMso,
        event._idMso,
        defaultPreServices.club,
        defaultPreServices.insurance,
        defaultPreServices.vip,
        defaultPreServices.emergencyNumber,
        defaultPreServices.license
      );

      return omit(soloSub, ['athlete']);
    });

    const body = createApiSubscriptionPack(
      serie._idMso,
      pack._idMso,
      athlete._idMso,
      items,
    );

    await createOrder({
      ...body,
      idempotentToken: uniqueRandomId,
    });
  };

  const addToCartIfComplete = async () => {
    const areAllMembersRegistered = services
      .filter(service => service.profile && service.servicesValues).length === nbTeamMates;
    if (areAllMembersRegistered) {
      setIsLoading(true);
      if (pack) {
        await makePackSubscription();
      } else if (isSolo) {
        await makeSoloSubscription();
      } else {
        await makeTeamSubscription();
      }
    }
  };

  const onServicesUpdated = async () => {
    if (earliestNextSubscription && !inEditMode) {
      router.replace(
        getRoute.openModal(router)
          .categoryServices(
            earliestNextSubscription.category._idMso,
            earliestNextSubscription.event._idMso,
          ),
        null,
        { scroll: false },
      );
    } else {
      await addToCartIfComplete();
    }
  };

  useEffect(() => {
    // Skip the initial load / not ready
    if (ready && completedCategories.length > 0) {
      (async () => onServicesUpdated())();
    }
  }, [completedCategories]);

  useEffect(() => {
    setIsLoading(false);
  }, [teamMateIndex]);

  return ({
    nbTeamMates,
    teamMateIndex,
    changeTeamMate,
    addServiceToCurrentTeamMate,
    isLoading,
  });
};

export default useRegistration;
