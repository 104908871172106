import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import config from '@root/src/config';
import { ApiType } from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import Loader from '@features/ui/components/Loader';

enum FETCH_MODE {
  LOAD_NEW = 'LOAD_NEW',
  LOAD_NEW_AND_REFRESH = 'LOAD_NEW_AND_REFRESH',
  REFRESH = 'REFRESH',
  RESET = 'RESET',
}

interface UseLazyLoadingProps {
  endpoint: ApiType<any> | null;
  params?: any;
  processData: (json: any, overrideCurrent?: boolean) => void;
  checkIfHasNoMoreData: (json: any, pageSize?: number) => boolean;
  resetListDependencies?: Array<any>;
  fetchIsValid: boolean;
  disableFetchMoreOnScroll?: boolean;
  autoRefresh?: boolean;
}

const useLazyLoading = ({
  endpoint,
  params = {},
  processData,
  checkIfHasNoMoreData,
  resetListDependencies = [],
  fetchIsValid,
  disableFetchMoreOnScroll = false,
  autoRefresh = false,
}: UseLazyLoadingProps) => {
  const requestHandler = useRequestHandler();
  const router = useRouter();

  const [hasMore, setHasMore] = useState(true);
  const hasMoreRef = useRef(true);
  hasMoreRef.current = hasMore;

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  isLoadingRef.current = isLoading;

  const endPointRef = useRef(endpoint);
  endPointRef.current = endpoint;

  const paramsRef = useRef(params);
  paramsRef.current = params;

  const observerLoadMoreRef = useRef(null);
  const currentPage = useRef(1);

  const fetchMore = async (fetchMode: FETCH_MODE) => {
    if (!fetchIsValid || !endPointRef.current) {
      setHasMore(false);
      return;
    }

    // Default config for fetchMode === LOAD_NEW
    let page = currentPage.current;
    let pageSize = config.lists.defaultPageSize;
    let incrementPageCount = true;
    let overrideCurrent = false;

    if (fetchMode === FETCH_MODE.REFRESH) {
      page = config.lists.defaultPage;
      pageSize = currentPage.current * config.lists.defaultPageSize;
      incrementPageCount = false;
      overrideCurrent = true;
    } else if (fetchMode === FETCH_MODE.LOAD_NEW_AND_REFRESH) {
      page = config.lists.defaultPage;
      pageSize = currentPage.current * config.lists.defaultPageSize;
      overrideCurrent = true;
    } else if (fetchMode === FETCH_MODE.RESET) {
      page = config.lists.defaultPage;
      overrideCurrent = true;
    }

    setIsLoading(true);
    const { response } = await requestHandler({
      request: endPointRef.current({
        locale: router.locale,
        ...paramsRef.current,
        page,
        pageSize,
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      if (checkIfHasNoMoreData(json, pageSize)) {
        setHasMore(false);
      } else if (incrementPageCount) {
        currentPage.current += 1;
      }

      processData(json, overrideCurrent);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (disableFetchMoreOnScroll) {
      setHasMore(false);
      return null;
    }

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (hasMoreRef.current && !isLoadingRef.current) {
          fetchMore(autoRefresh ? FETCH_MODE.LOAD_NEW_AND_REFRESH : FETCH_MODE.LOAD_NEW);
        }
      }
    }, { threshold: 1 });

    if (observerLoadMoreRef.current) {
      observer.observe(observerLoadMoreRef.current);
    }

    return () => {
      if (observerLoadMoreRef.current) {
        observer.unobserve(observerLoadMoreRef.current);
      }
    };
  }, [observerLoadMoreRef, disableFetchMoreOnScroll]);

  useEffect(() => {
    if (!autoRefresh) {
      return () => null;
    }
    const interval = setInterval(() => {
      fetchMore(FETCH_MODE.REFRESH);
    }, config.lists.lazyLoadAutoRefreshInterval);

    return () => clearInterval(interval);
  }, [autoRefresh]);

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      currentPage.current = 0;
      setHasMore(!disableFetchMoreOnScroll);
      fetchMore(FETCH_MODE.RESET);
    }
    didMountRef.current = true;
  }, resetListDependencies);

  return {
    isLoading,
    hasMore,
    observerLoadMoreRef,
  };
};

export default useLazyLoading;

interface LazyLoaderProps {
  isLoading: boolean;
  hasMore: boolean;
}

export const LazyLoader = forwardRef((
  { isLoading, hasMore }: LazyLoaderProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation();
  return (
    <LazyLoaderContainer ref={ref}>
      {isLoading && <Loader size={50} />}
      {(!hasMore && !isLoading) && <span>{t('lazyLoading_textNoMoreItems.message')}</span>}
    </LazyLoaderContainer>
  );
});

const LazyLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  height: 50px;
`;
