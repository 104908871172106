import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectResponsive, setResponsive } from '@features/ui-layout/slices/uiLayoutSlice';

export const MOBILE_DEVICES_REGEX = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/;
export const TABLET_DEVICES_REGEX = /iPad/;
export enum RESPONSIVE {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP'
}
export const breakpoint = {
  desktop: 1600,
  tablet: 1080,
  mobile: 800,
};
export const mediaBreakpoint = {
  tablet: `@media (max-width: ${breakpoint.tablet}px)`,
  mobile: `@media (max-width: ${breakpoint.mobile}px)`,
};
export const getViewportWidth = (): number => {
  const { innerWidth = 0 } = window;
  const { documentElement: { clientWidth } } = document;
  return Math.max(clientWidth, innerWidth);
  // Fixed from min to max for List cpt (exe: RankingList)
  // The opposite fix was done before but I not sure why, probably for cross browser
  // If you want to change this, you may check the browser and call JBA
};

export const useResize = (onResize) => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
};

export const useSetResponsive = () => {
  const dispatch = useDispatch();
  const onResize = () => {
    const width = getViewportWidth();
    let responsiveValue = RESPONSIVE.DESKTOP;
    if (width <= breakpoint.tablet && width > breakpoint.mobile) {
      responsiveValue = RESPONSIVE.TABLET;
    } else if (width <= breakpoint.mobile) {
      responsiveValue = RESPONSIVE.MOBILE;
    }
    dispatch(setResponsive(responsiveValue));
  };
  useResize(onResize);
};

export const useResponsive = () => useSelector(selectResponsive);

export const ResponsiveWrapper = (
  { children, className } :
  { children?: React.ReactNode, className?: string }
) => (
  <ResponsiveParent className={className}>
    <ResponsiveContent>
      {children}
    </ResponsiveContent>
  </ResponsiveParent>
);
const ResponsiveParent = styled.div`
  width: 100%;
  padding-right: var(--jumpHackScroll);
  height: 100%;
  display: flex;
  flex: 1;

  ${mediaBreakpoint.mobile} {
    padding-right: 0;
  }
`;
const ResponsiveContent = styled.div`
  width: 100%;
  min-width: var(--page-min-width);
  max-width: var(--page-max-width);
  margin: auto;
  padding: 0 20px;
`;
