import React from 'react';
import styled, { css } from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'next-i18next';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';

type Option = {
  value: string | number,
  label: React.ReactNode,
}

export interface InputSelectProps {
  /** List of options to display */
  options: Array<Option>,
  placeholder?: string,
  className?: string,
  /** Other props are passed to the inner <input> element */
  [etc: string]: any;
  dataQa?: string;
}

// least a defaultValue. Having a Select where it's ok for it to be empty but no means to select an empty value means
// its starting state could never be attained again, which is otherwise doable by the user by - for example - refreshing
// the page, so it's definitely not what we want. So, don't place a Select without a default value.

/**
 * An input that contains text. Can be used both controlled (with value) and uncontrolled (with defaultValue).
 * @constructor
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputSelect = ({
  options,
  defaultValue,
  value,
  disabled,
  placeholder,
  customPlaceholderText,
  onChange,
  onBlur,
  name,
  className,
  dataQa,
}: InputSelectProps) => {
  const { t } = useTranslation();
  return (
    <StyledInputContainer disabled={disabled} className={className}>
      <Select
        defaultValue={defaultValue == null && value == null ? '' : defaultValue}
        value={value}
        IconComponent={ArrowIcon}
        disabled={disabled}
        {...((placeholder && value === '') && ({
          displayEmpty: true,
          renderValue: () => <div className="inputSelect_placeholder">{customPlaceholderText ?? t('inputSelect_placeholder.message')}</div>,
        }))}
        placeholder="test"
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        inputProps={{ 'data-qa': dataQa }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} data-qa={`${dataQa}-${option.value}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </StyledInputContainer>
  );
};
export default InputSelect;

const ArrowIcon = () => <StyledIcon name={ICON_NAME.arrowDropDown} color="var(--color-grey3)" size="20px" />;

export { MenuItem };

const StyledIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  right: 2px;
`;

const StyledInputContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  & .MuiInputBase-root {
    width: 100%;
    position: relative;
    border: 2px solid transparent;
    :hover {
      border: 2px solid var(--color-orange-light);
    }
    transition: border 0.25s;
  }

  & .MuiInput-underline:before, & .MuiInput-underline:after {
    display: none;
  }

  & .MuiSelect-select:focus {
    background: var(--color-orange-light);
    border: 2px solid var(--color-orange-light);
  }

  & .inputSelect_placeholder {
    color: var(--color-grey3);
  }

  ${({ disabled }) => disabled && css`
    & .MuiInputBase-root.Mui-disabled {
      background: var(--color-white) !important;
      color: var(--color-grey3);
    }

    & .MuiSelect-select {
      border: 1px solid var(--color-grey1);
      border-radius: 4px;
    }
  `}
`;
