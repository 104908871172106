import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { uniq, uniqBy } from 'lodash';
import {
  Category,
  EngagedUser,
  Event,
  EVENT_LIST_SEARCH_FILTER,
  SERVICE_GROUP_TYPE,
  ServiceGroup,
  REGISTRATION_STATUS,
  EngagedUserListRaw,
  ListFilter,
  EngagedStatsFromAPI,
  EngagedStats,
} from '@root/src/types';
import { hydrateStateIfDataNotNull, RootState } from '@services/store';
import {
  engagedDenormalizedFromApiToReducer,
  engagedFromApiToReducer,
  eventCategoryFromApiToReducer,
  eventCategoryQuotaFromApiToReducer,
  eventCategoryServiceGroupFromApiToReducer,
  eventFromApiToReducer,
  eventQuotaFromApiToReducer,
} from '@features/eventDetail/formatters';

export interface EventDetailServerSliceState {
  events: {
    [eventId: number]: {
      event?: Event;
      categories: Array<Category>;
      services: Array<ServiceGroup>;
      engaged: {
        list: Array<EngagedUser>;
        stats: EngagedStats;
        cantons: Array<string>;
        nationalities: Array<string>;
        searchFilter: ListFilter;
      }
      preServices: { // TODO delete this because it's useless OR merge this file with the clientSideSlice
        [categoryId: number]: Array<ServiceGroup>;
      };
    }
  },
}

const eventDefaultState = {
  event: null,
  categories: null,
  services: [], // TODO replace all default value by null to make the check during hydration
  preServices: {},
  engaged: {
    list: [],
    searchFilter: {
      query: null,
      filterBy: EVENT_LIST_SEARCH_FILTER.ATHLETE,
    },
  },

};

const eventDetailServerSlice = createSlice({
  name: 'eventDetailServer',
  initialState: { events: null },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      // COMMENT 152
      // When the modal is open, the server side data are called. So the categories are not call
      // during the SS if your are logged because it's done on the front side. It's result
      // here with a null categories value, so you don't want to overwrite the value already
      // call by the client side.
      // --
      // If we don't do that, when the modal is open, the categories will disappear on the backside
      // But when the modal is closed, the client side data will be re-call (du to this case : the user
      // open the login modal, so you want to reload the categories on the client side because the are
      // filtered for logged user. So it's just a problem of the display but not fo the logic.
      // --
      // This is the same problem for all the call done on the client side only.
      // TODO Removed the quota and the hasDiscount from the vent, because the check is not
      // possible like that.
      const { eventDetailServer } = action.payload;
      return hydrateStateIfDataNotNull(state, eventDetailServer);
    },
  },
  reducers: {
    setEvents: (state, action: PayloadAction<{
      apiEvents: {[eventId: number]: {event: Event, categories: Array<Category>}}
    }>) => {
      state.events = action.payload.apiEvents;
    },
    setEvent: (state, action: PayloadAction<{ apiEvent: any, eventId: number }>) => {
      if (!state.events) {
        state.events = {};
      }

      if (state.events[action.payload.eventId]) {
        state.events[action.payload.eventId].event = eventFromApiToReducer(action.payload.apiEvent);
      } else {
        state.events[action.payload.eventId] = {
          ...eventDefaultState,
          event: eventFromApiToReducer(action.payload.apiEvent),
        };
      }
    },
    setEventQuotas: (state, action: PayloadAction<{ quotaApi: any, eventId: number }>) => {
      state.events[action.payload.eventId].event.quotas = eventQuotaFromApiToReducer(action.payload.quotaApi);
    },
    setCategories: (state, action: PayloadAction<{ apiCategories: any, eventId: number }>) => {
      state.events[action.payload.eventId].categories = action.payload.apiCategories
        .map(eventCategoryFromApiToReducer).sort((catA: Category, catB: Category) => {
          if (catA.name > catB.name) { return 1; }
          if (catA.name < catB.name) { return -1; }
          return 0;
        });
    },
    setServices: (state, action: PayloadAction<{ apiServices: any, eventId: number }>) => {
      if (!state.events) {
        state.events = {};
      }
      if (!state.events[action.payload.eventId]) {
        state.events[action.payload.eventId] = {};
      }

      state.events[action.payload.eventId].services = action.payload.apiServices
        .map(eventCategoryServiceGroupFromApiToReducer)
        .sort((a: ServiceGroup, b: ServiceGroup) => a.sortIndex - b.sortIndex)
        .map((group: ServiceGroup) => {
          group.services = group.services.sort((a, b) => a.sortIndex - b.sortIndex);
          return group;
        })
        .filter(group => group.groupType === SERVICE_GROUP_TYPE.SERVICES);
    },
    setCategoryQuotas: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ categoryId: number, quotaApi: any, eventId: number }>
    ) => {
      const category = state
        .events[action.payload.eventId]
        ?.categories
        ?.find(category => category._idMso === action.payload.categoryId);

      if (category) {
        category.quotas = eventCategoryQuotaFromApiToReducer(action.payload.quotaApi);
      }
    },
    setHasEventDiscount: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ reductionsApi: boolean, eventId: number }>
    ) => {
      state.events[action.payload.eventId].event.hasDiscountVoucher = action.payload.reductionsApi;
    },
    pushEngaged: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ engagedApi: any, eventId: number }>
    ) => {
      // TODO rename this function by updateEngaged + split with setEngaged
      state.events[action.payload.eventId].engaged.list
        .push(...action.payload.engagedApi.map(engagedFromApiToReducer));
    },
    pushEngagedPaginated: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ engagedApi: EngagedUserListRaw, overrideCurrent?: boolean, eventId: number }>
    ) => {
      const engaged = Object.values(action.payload.engagedApi)
        .flat()
        .map(engagedDenormalizedFromApiToReducer);
      const engagedConsolidated = action.payload.overrideCurrent
        ? engaged
        : [...state.events[action.payload.eventId].engaged.list, ...engaged];
      state.events[action.payload.eventId].engaged.list = uniqBy(engagedConsolidated, 'id');
    },
    setEngagedStats: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ engagedApi: EngagedStatsFromAPI, eventId: number }>
    ) => {
      const engagedStats = action.payload.engagedApi
        .reduce((acc, categoryStats) => ({
          ...acc,
          [categoryStats._idMso]: {
            susbcriptionsCount: categoryStats.count,
            athletesCount: categoryStats.athletesCount,
          },
        }), {});
      state.events[action.payload.eventId].engaged.stats = engagedStats;
    },
    setEngagedCantons: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ engagedApi: Array<string>, eventId: number }>
    ) => {
      state.events[action.payload.eventId].engaged.cantons = action.payload.engagedApi;
    },
    setEngagednationalities: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ engagedApi: Array<string>, eventId: number }>
    ) => {
      state.events[action.payload.eventId].engaged.nationalities = action.payload.engagedApi;
    },
    setEngagedFilter: (
      state: EventDetailServerSliceState,
      action: PayloadAction<{ query: string; filterBy: EVENT_LIST_SEARCH_FILTER, eventId: number }>
    ) => {
      state.events[action.payload.eventId].engaged.searchFilter = {
        query: action.payload.query,
        filterBy: action.payload.filterBy,
      };
    },
  },
});
export default eventDetailServerSlice.reducer;

export const {
  setEvents,
  setEvent,
  setEventQuotas,
  setCategories,
  setServices,
  setCategoryQuotas,
  setHasEventDiscount,
  pushEngaged,
  pushEngagedPaginated,
  setEngagedStats,
  setEngagedCantons,
  setEngagednationalities,
  setEngagedFilter,
} = eventDetailServerSlice.actions;

// Event detail
export const selectEventsDetail = (state: RootState) => state.eventDetailServer.events ?? {};
export const selectEventsDetailList = createSelector(
  selectEventsDetail,
  (events) => Object.values(events)
    .map(e => e.event)
    .sort((eventA: Event, eventB: Event) => {
      if (eventA.dateFrom > eventB.dateFrom) { return 1; }
      if (eventA.dateFrom < eventB.dateFrom) { return -1; }
      return 0;
    })
);
export const selectEventDetail = (eventId: number) => createSelector(
  selectEventsDetail,
  (events) => events[eventId]?.event ?? null,
);
export const selectEventCurrency = (eventId: number) => createSelector(
  selectEventDetail(eventId),
  (event) => event?.currency
);
export const selectEventSlug = (eventId: number) => createSelector(
  selectEventDetail(eventId),
  (event) => event?.slug
);
export const selectEventSerieId = (eventId: number) => createSelector(
  selectEventDetail(eventId),
  (event) => event?.serie?._idMso
);
export const selectEventQuota = (eventId: number) => createSelector(
  selectEventDetail(eventId),
  (event) => event?.quotas
);
export const selectEventHasDiscountVoucher = (eventId: number) => (
  state: RootState
) => state.eventDetailServer.events[eventId]?.event?.hasDiscountVoucher;

// Event engaged
export const selectEventEngaged = (eventId: number) => (
  state: RootState
) => state.eventDetailServer.events[eventId]?.engaged?.list.filter(
  engaged => engaged.bib != null
) ?? [];
export const selectEventEngagedByCategory = (eventId: number, categoryId: number) => createSelector(
  selectEventEngaged(eventId),
  engagedList => engagedList.filter(engaged => engaged.category === categoryId),
);
export const selectEventEngagedFilter = (eventId: number) => (
  state: RootState
) => state.eventDetailServer.events[eventId]?.engaged.searchFilter;
export const selectEventEngagedCategoriesLoaded = (eventId: number) => createSelector(
  selectEventEngaged(eventId),
  engagedList => uniq(engagedList.map(engaged => engaged.category))
);
export const selectEventEngagedCantons = (eventId: number) => (
  state: RootState
) => state
  .eventDetailServer
  .events[eventId]
  ?.engaged
  .cantons ?? [];
export const selectEventEngagedCantonsOptions = (eventId: number) => createSelector(
  selectEventEngagedCantons(eventId),
  cantons => cantons.map(canton => ({
    value: canton,
    label: canton,
  }))
);
export const selectEventEngagedNationalities = (eventId: number) => (
  state: RootState
) => state
  .eventDetailServer
  .events[eventId]
  ?.engaged
  .nationalities ?? [];
export const selectEventEngagedNationalitiesOptions = (eventId: number) => createSelector(
  selectEventEngagedNationalities(eventId),
  nationalities => nationalities.map(nationality => ({
    value: nationality,
    label: nationality,
  }))
);

// Engaged count
export const selectEventEngagedStats = (eventId: number) => (
  state: RootState
) => state
  .eventDetailServer
  .events[eventId]
  ?.engaged
  .stats ?? {};
export const selectEventAthletesCountForCategory = (eventId: number, categoryId: number) => createSelector(
  selectEventEngagedStats(eventId),
  engagedCount => engagedCount[categoryId]?.athletesCount ?? null,
);
export const selectEventAthletesCountForEvent = (eventId: number) => createSelector(
  selectEventEngagedStats(eventId),
  engagedCount => Object.values(engagedCount).reduce((acc, { athletesCount }) => acc + athletesCount, 0),
);

// Event Categories
export const selectEventCategories = (eventId) => (
  state: RootState
) => state.eventDetailServer.events[eventId]?.categories ?? [];
export const selectEventCategoriesIds = (eventId: number) => createSelector(
  selectEventCategories(eventId),
  categories => categories.map(category => category._idMso)
);

export const selectEventCategory = (eventId: number, id: number) => createSelector(
  selectEventCategories(eventId),
  categories => categories.find(category => category._idMso === id)
);

// Event Category Service
export const selectEventCategoryServicesGroups = (eventId: number) => (
  state: RootState
) => state.eventDetailServer.events?.[eventId]?.services ?? [];
export const selectEventCategoryServicesGroupsIds = (eventId: number) => createSelector(
  selectEventCategoryServicesGroups(eventId),
  servicesGroups => servicesGroups.map(group => group._idMso)
);

export const selectEventCategoryServiceGroup = (eventId: number, id: number) => createSelector(
  selectEventCategoryServicesGroups(eventId),
  services => services.find(service => service._idMso === id)
);

export const selectEventCategoryServices = (eventId: number) => createSelector(
  selectEventCategoryServicesGroups(eventId),
  services => services.flatMap(group => group.services)
);

export const selectEventCategoryService = (eventId: number, id: number) => createSelector(
  selectEventCategoryServices(eventId),
  services => services.find(service => service._idMso === id)
);

export const selectEventsWithOpenRegCategoriesCount = createSelector(
  selectEventsDetail,
  (events) => Object.values(events)
    .filter(({ event: { status } }) => status?.registration === REGISTRATION_STATUS.OPEN)
    .map(({ categories }) => categories)
    .flat()
    .length
);

export const selectEventFromCategoryId = (categoryId: number) => createSelector(
  selectEventsDetail,
  events => Object.values(events)
    .find(({ categories }) => categories.find(c => c._idMso === categoryId))
    ?.event
);
