import { isPossiblePhoneNumber } from 'react-phone-number-input';
import yup from '@services/translation/translatedYup';

export const addressFieldValidation = (t) => ({
  address: yup.string()
    .required()
    .label(t('userAddressForm_addressLabel.message')),
});

export const postalCodeFieldValidation = (t) => ({
  postalCode: yup.string()
    .required()
    .label(t('userAddressForm_postalCodeErrorLabel.message')),
});

export const localityFieldValidation = (t) => ({
  locality: yup.string()
    .required()
    .label(t('userAddressForm_LocalityErrorLabel.message')),
});

export const countryFieldValidation = (t) => ({
  country: yup.string()
    .required()
    .label(t('userAddressForm_countryLabel.message')),
});

export const languageFieldValidation = (t) => ({
  language: yup.string()
    .required()
    .label(t('userAddressForm_languageLabel.message')),
});

export const phoneNumberFieldValidation = (t, required ?: boolean) => {
  const phoneValidation = yup
    .string()
    .test('phone', t('userAddressForm_phoneNumberError.message'), (value) => {
      const phoneNumber = value?.toString() || '';
      if (phoneNumber.trim().length === 0) {
        // This allow to have empty number if not required;
        // (Because isPossiblePhoneNumber will fail with empty string)
        // required attribute (from yup) catch this error if necessary
        return true;
      }
      return isPossiblePhoneNumber(value.toString());
    });
  return { phoneNumber: required ? phoneValidation.required().label(t('servicesValidationSchemaFactory_labelThis.message')) : phoneValidation };
};

const UserAddressFormValidationSchema = (t) => yup.object()
  .shape({
    ...addressFieldValidation(t),
    ...postalCodeFieldValidation(t),
    ...localityFieldValidation(t),
    ...countryFieldValidation(t),
    ...languageFieldValidation(t),
    ...phoneNumberFieldValidation(t),
  });

export default UserAddressFormValidationSchema;
