import { Dispatch, Store } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';
import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { RequestHandlerTypeClientSide, RequestHandlerTypeCommon, ssrRequestHandler } from '@services/api/handlers';
import { getAuthCookies } from '@services/cookies';

interface DataHandlerConstructorCommon {
  store: Store,
}
interface DataHandlerConstructorServerSide extends DataHandlerConstructorCommon{
  serverSideContext: GetServerSidePropsContext,
}
interface DataHandlerConstructorClientSide extends DataHandlerConstructorCommon{
  router: NextRouter,
  requestHandler: RequestHandlerTypeClientSide,
}
export type DataHandlerConstructorGeneric = DataHandlerConstructorServerSide | DataHandlerConstructorClientSide

function isServerSideConstructor(params: DataHandlerConstructorGeneric): params is DataHandlerConstructorServerSide {
  return (params as DataHandlerConstructorServerSide).serverSideContext != null;
}
export function isClientSideConstructor(
  params: DataHandlerConstructorGeneric
): params is DataHandlerConstructorClientSide {
  return (params as DataHandlerConstructorClientSide).requestHandler != null;
}

class InitDataHandler {
  userId?: number;

  serverSideContext?: GetServerSidePropsContext;

  store: Store;

  requestHandler: RequestHandlerTypeCommon;

  dispatch: Dispatch;

  query: ParsedUrlQuery;

  locale: string;

  constructor(params: DataHandlerConstructorGeneric) {
    this.store = params.store;
    this.dispatch = this.store.dispatch;

    if (isServerSideConstructor(params)) {
      this.serverSideContext = params.serverSideContext;
      this.query = this.serverSideContext.query;
      this.locale = this.serverSideContext.locale;
      this.requestHandler = ssrRequestHandler;
    } else if (isClientSideConstructor(params)) {
      this.requestHandler = params.requestHandler;
      this.query = params.router.query;
      this.locale = params.router.locale;
    } else {
      throw Error('You should set a router, a requestHandler and a dispatch OR a context and a store at least');
    }
    const { userId } = getAuthCookies(this.serverSideContext);
    this.userId = userId;
  }
}

export default InitDataHandler;
