import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import i18nConfig from '@services/translation/i18n';
import ButtonDropdown from '@features/ui/components/ButtonDropdown';
import { ICON_NAME } from '@features/ui/components/Icon';

interface OwnProps {
  className?: string;
}

const LanguagesDropdown = ({ className } : OwnProps) => {
  const router = useRouter();
  const { i18n: { language } } = useTranslation();

  const data = i18nConfig.otherLanguages.map(lang => ({
    text: lang.toUpperCase(),
    onClick: () => router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      { locale: lang }
    ),
  }));

  return (
    <ButtonDropdown
      className={className}
      placeholder={language?.toUpperCase()}
      data={data}
      icon={ICON_NAME.language}
    />
  );
};
export default LanguagesDropdown;
