import { Cart, OrderItem, PaymentMethodInfo } from '@root/src/types';
import { removeUndefs } from '@utils/gobal';
import { eventCategoryServiceFromApiToReducer } from '@features/eventDetail/formatters';

export const currentCartFromApiToReducer = (
  currentCart: any,
): Cart => removeUndefs<Cart>({
  _id: currentCart._id,
  _idMso: parseInt(currentCart._idMso, 10),
  orderid: currentCart.orderid,
  orderMadeBy: currentCart.orderMadeBy
    ? {
      _idMso: parseInt(currentCart.orderMadeBy._idMso, 10),
      fullName: currentCart.orderMadeBy.fullName,
    }
    : null,
  cartStatus: currentCart.orderStatus,
  club: currentCart.club,
  items: currentCart.items.map(orderItemFromApiToReducer),
  createdBy: currentCart.createdBy ? { athleteId: currentCart.createdBy.athleteId } : null,
  price: {
    totalItems: currentCart.price.totalItems,
    totalFee: currentCart.price.totalFee,
    totalOrder: currentCart.price.totalOrder,
    totalReduction: currentCart.price.totalReduction,
    totalInsurance: currentCart.price.totalInsurance,
    fee: currentCart.price.fee ? {
      amount: currentCart.price.fee.amount,
      amountFixed: currentCart.price.fee.amountFixed,
      amountVariable: currentCart.price.fee.amountVariable,
      percent: currentCart.price.fee.percent,
    } : null,
    currency: currentCart.price.currency,
  },
  transaction: currentCart.transaction ? {
    type: currentCart.transaction.type,
    status: currentCart.transaction.status,
    currency: currentCart.transaction.currency,
    paymentMethod: currentCart.transaction.paymentMethod,
    detail: currentCart.transaction.detail ? {
      fail: currentCart.transaction.detail.fail ? {
        reason: currentCart.transaction.detail.fail.reason,
        message: currentCart.transaction.detail.fail.message,
      } : null,
    } : null,
  } : null,
  refused: currentCart.refused ? {
    reason: currentCart.refused.reason,
    code: currentCart.refused.code,
  } : null,
  datatrans: currentCart.datatrans ? { transactionId: currentCart.datatrans.transactionId } : null,
  paymentMethodsAvailable: currentCart.paymentMethodsAvailable?.map(i => i) ?? [],
  paymentMethod: currentCart.paymentMethod,
  createdAt: currentCart.createdAt,
  created: currentCart.created,
  uniqid: currentCart.uniqid,
  currency: currentCart.currency,
});

const orderItemFromApiToReducer: (item: any) => OrderItem = item => ({
  athlete: {
    _idMso: parseInt(item.athlete._idMso, 10),
    gender: item.athlete.gender,
    birthDateObj: item.athlete.birthDateObj ? {
      year: item.athlete.birthDateObj.year,
      month: item.athlete.birthDateObj.month,
      day: item.athlete.birthDateObj.day,
    } : null,
    fullName: item.athlete.fullName,
  },
  event: {
    // TODO Uncomment this for series
    //  It'll be probably rename in 'series' for the future
    //  event: {
    //   _idMso: number;
    // }
    _idMso: parseInt(item.event._idMso, 10),
    name: item.event.name,
    date: item.event.date,
    dateFrom: item.event.dateFrom,
    dateTo: item.event.dateTo,
    location: item.event.location,
    urlOrganiser: item.event.urlorganiser,
    slug: item.event.slug,
  },
  category: {
    _idMso: parseInt(item.category._idMso, 10),
    name: item.category.name,
  },
  _id: item._id,
  team: item.team.map(member => ({
    teamMember: {
      fullName: member.teamMember.fullName,
      _idMso: parseInt(member.teamMember._idMso, 10),
    },
    role: member.role,
  })),
  insurance: item.insurance,
  club: item.club,
  emergencyNumber: item.emergencyNumber,
  license: item.license,
  price: {
    registration: item.price.registration,
    services: item.price.services,
    insurance: item.price.insurance,
    discount: item.price.discount,
    total: item.price.total,
  },
  reduction: {
    _id: item.reduction?._id,
    _idMso: item.reduction?._idMso,
    code: item.reduction?.code,
    amount: item.reduction?.amount,
    percent: item.reduction?.percent,
    redutype: item.reduction?.redutype,
  },
  hasDiscountVoucher: item.hasDiscountVoucher,
  serviceGroupsOrders: serviceGroupsOrderItemFromApiToReducer(item.serviceGroupsOrders),
  partOfPack: item.partOfPack,
  packReduction: item.packReduction && {
    redutype: item.packReduction.redutype,
    percent: item.packReduction.percent,
    amount: item.packReduction.amount,
  },
});

export const serviceGroupsOrderItemFromApiToReducer = (serviceGroupsOrderItem) => serviceGroupsOrderItem
  .map(serviceGroupOrderItem => ({
    group: { _idMso: parseInt(serviceGroupOrderItem.group._idMso, 10) },
    groupType: serviceGroupOrderItem.groupType,
    teamMember: serviceGroupOrderItem.teamMember
      ? { _idMso: parseInt(serviceGroupOrderItem.teamMember._idMso, 10) }
      : null,
    serviceOrders: serviceGroupOrderItem.serviceOrders.map(serviceOrder => ({
      service: { _idMso: parseInt(serviceOrder.service._idMso, 10) },
      value: serviceOrder.value,
      unitPrice: serviceOrder.unitprice,
      ...eventCategoryServiceFromApiToReducer(serviceOrder),
    })),
  }));

const paymentMethodInfoFromApiToReducer = (paymentMethodInfo): PaymentMethodInfo => ({
  code: paymentMethodInfo.code,
  value: paymentMethodInfo.value,
});

export const paymentMethodsInfoFromApiToReducer = (paymentMethodsInfo) => paymentMethodsInfo
  .map(paymentMethodInfo => paymentMethodInfoFromApiToReducer(paymentMethodInfo));
