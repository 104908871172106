import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ACCESS_LEVEL, ProfileVerification } from '@root/src/types';
import { mediaBreakpoint } from '@services/responsiveProvider';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import UseRegisterStepper, { REGISTER_CONTEXT } from '@features/users/components/UseRegisterStepper';
import ModalRegisterFooter from '@features/users/components/ModalRegisterFooter';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1 } from '@features/ui/styles/textStyles';
import { selectAccessesOnMyFriendsIDs } from '@features/users/slices/userSlice';
import Loader from '@features/ui/components/Loader';

const ModalAddFriend = ({ closeModal }) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();
  const friendsIDsUnselectable = useSelector(selectAccessesOnMyFriendsIDs);

  const addAsFriend = async (user: ProfileVerification) => {
    await requestHandler({
      request: api.profile.access.create({
        body: {
          athlete: user._id,
          birthDateObj: user.birthDateObj,
          level: ACCESS_LEVEL.NOT_ALLOWED,
        },
      }),
    });
  };

  const onUserVerificationSelected = async (user: ProfileVerification) => {
    await addAsFriend(user);
    closeModal();
  };

  const onRegisterCallback = () => {
    closeModal();
  };

  const {
    registerView,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    currentStep,
    isFirst,
    isLast,
    handleSubmit,
    hasDuplicates,
    steps,
    isSubmitting,
  } = UseRegisterStepper(
    REGISTER_CONTEXT.FRIEND,
    onUserVerificationSelected,
    friendsIDsUnselectable,
    null,
    onRegisterCallback,
  );

  return (
    <ModalTemplate
      footer={isSubmitting ? <></> : (
        <ModalRegisterFooter
          onNext={goToNextStep}
          onPrevious={goToPreviousStep}
          goToStep={goToStep}
          currentStep={currentStep}
          isFirstStep={isFirst}
          isLastStep={isLast}
          closeModal={closeModal}
          onSubmit={handleSubmit}
          hasDuplicates={hasDuplicates}
          nextButtonDisabled={!steps[currentStep - 1].valid}
        />
      )}
      closeModal={closeModal}
    >
      <ModalTitle>{t('modalAddFriend_title.message')}</ModalTitle>
      <FormWrapper>
        {isSubmitting
          ? <Loader />
          : registerView}
      </FormWrapper>
    </ModalTemplate>
  );
};

export default ModalAddFriend;

const ModalTitle = styled.div`
  ${textH1}
`;

const FormWrapper = styled.div`
  margin: 32px 0 40px;
  max-width: 682px;
  width: 100%;

  ${mediaBreakpoint.tablet} {
    max-width: unset;
  }
`;
