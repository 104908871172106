import { Profile } from '@root/src/types';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import api from '@services/api';
import { STATUS_RANGE } from '@services/api/handlers';
import {
  setAccessesOnMyFriends,
  setAccessesOnMyProfile,
  setIsAuthenticated,
  setMobileNumbers,
  setPreferences,
  setUser,
} from '@features/users/slices/userSlice';
import { profileFromApiToReducer } from '@features/users/formatters';

// TODO check if we need the result or not in response of each methods
class GetUserData extends InitDataHandler {
  setIsAuthenticated: () => void = () => {
    this.dispatch(setIsAuthenticated(true));
  };

  getAthleteProfile: (athleteId: number) => Promise<Profile|null> = async (athleteId) => {
    const { response } = await this.requestHandler({ request: api.users.read({ athleteId: `mso:${athleteId}` }) });
    if (response && response?.ok) {
      const json = await response.json();
      return profileFromApiToReducer(json);
    }
    return null;
  }

  getProfile: () => Promise<true | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.profile.read({ ctx: this.serverSideContext }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setUser(json));
      return true;
    }
    return null;
  };

  getPreferences: () => Promise<true | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.profile.preferences.get({ ctx: this.serverSideContext }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setPreferences(json));
      return true;
    }
    return null;
  };

  getAccessesOnMyProfile: () => Promise<true | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.profile.access.list({
        ctx: this.serverSideContext,
        locale: this.locale,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setAccessesOnMyProfile(json));
      return true;
    }
    return null;
  };

  getAccessesOnMyFriends: () => Promise<true | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.profile.athletes({
        ctx: this.serverSideContext,
        locale: this.locale,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setAccessesOnMyFriends(json));
      return true;
    }
    return null;
  };

  getMobileNumbers: () => Promise<true | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.profile.mobileNumbers.list({
        ctx: this.serverSideContext,
        locale: this.locale,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setMobileNumbers(json));
      return true;
    }
    return null;
  };
}

export default GetUserData;
