/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useRollbar } from '@rollbar/react';
import { Cart, CART_STATUS, PAYMENT_METHOD, TRANSACTION_STATUS } from '@root/src/types';
import getRoute from '@root/src/services/routeList';
import poll, { removeTimeoutInstances } from '@utils/poll';
import { CART_POLLING_STOP_REASON, cartPollingStatus, useCartDataHandler } from '@features/carts/CartDataHandler';
import { selectCurrentCartDetail } from '@features/carts/slices/cartsSlice';
import {
  paymentAmountMismatchNotification,
  paymentNotification,
  paymentQuotaExceedNotification,
} from '@features/carts/paymentNotification';

export enum PAYMENT_STATE {
  IDLE = 'IDLE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PAY = 'PAY',
  RENDER_LIGHTBOX = 'RENDER_LIGHTBOX',
  IN_POLLING = 'IN_POLLING',
}

export enum QUOTAS_ERROS {
  EVENT_FULL = 'error.eventQuotaExceeded',
  CATEGORY_FULL = 'error.categoryQuotaExceeded',
  AMOUNT_MISMATCH = 'error.transactionAmountMismatch'
}

export const paymentErrorsQuota = [QUOTAS_ERROS.CATEGORY_FULL, QUOTAS_ERROS.EVENT_FULL];

export const usePaymentHandler = () => {
  const router = useRouter();
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(null);
  const [paymentState, setPaymentState] = useState<PAYMENT_STATE>(PAYMENT_STATE.IDLE);
  const cartDataHandler = useCartDataHandler();
  const currentCart = useSelector(selectCurrentCartDetail);
  const { t } = useTranslation();
  const rollbar = useRollbar();

  useEffect(() => () => {
    removeTimeoutInstances(); // Kill polling when leave the page
  }, []);

  useEffect(() => {
    const callback = async () => {
      const {
        cartStatus,
        transaction,
        paymentMethodsAvailable,
        paymentMethod,
      } = currentCart;
      const transactionStatus = transaction?.status;

      if (
        paymentMethodsAvailable.includes(PAYMENT_METHOD.FREE)
        && cartStatus === CART_STATUS.OPEN
      ) {
        if (paymentMethod !== PAYMENT_METHOD.FREE) {
          const response = await registerPaymentMethod(PAYMENT_METHOD.FREE);
          if (!response) return false;
        } else {
          setPaymentMethod(PAYMENT_METHOD.FREE);
          setPaymentState(PAYMENT_STATE.PAYMENT_METHOD);
        }
      }

      if (transactionStatus != null && paymentMethod !== PAYMENT_METHOD.FREE) {
        // Voluntary ignore other transactionStatus, there should be manage by the backend.
        if (transactionStatus === TRANSACTION_STATUS.AUTHORIZED && cartStatus === CART_STATUS.OPEN) {
          await doPay();
        } else if (transactionStatus === TRANSACTION_STATUS.FAILED) {
          const message = `Datatrans failure : ${JSON.stringify(currentCart.transaction?.detail?.fail)}`;
          console.log(message);
          rollbar.info(message);
          await cartDataHandler.cancelPayment();
          paymentNotification(t);
          setPaymentState(PAYMENT_STATE.IDLE);
        }
      }
      return false;
    };
    callback();
  }, [currentCart]);

  const doPay = async () => {
    const cart = await cartDataHandler.setPay();
    if (!cart) {
      console.error('doPay error : cart is null');
      rollbar.error('doPay error : cart is null');
      await cartDataHandler.cancelPayment();
      setPaymentState(PAYMENT_STATE.IDLE);
      return false;
    }

    if (cart.cartStatus !== CART_STATUS.PENDING) {
      if (cart.refused?.code === QUOTAS_ERROS.AMOUNT_MISMATCH) {
        paymentAmountMismatchNotification(t);
      }
      console.error('doPay error : cartStatus should be PENDING but it is ', cart.cartStatus);
      rollbar.error(`doPay error : cartStatus should be PENDING but it is ${cart.cartStatus}`);
      await cartDataHandler.cancelPayment();
      setPaymentState(PAYMENT_STATE.IDLE);
      return false;
    }
    setPaymentState(PAYMENT_STATE.PAY);
    await pollCurrentCart();
    return true;
  };

  const pollCurrentCart = async () => {
    setPaymentState(PAYMENT_STATE.IN_POLLING);
    return poll({
      fn: cartDataHandler.getCurrentCart,
      validate: (currentCart?: Cart) => {
        if (currentCart == null) {
          return false; //
        }
        return cartPollingStatus(currentCart).success;
      },
      onValidate: async (currentCart: Cart) => {
        const pollingStatus = cartPollingStatus(currentCart);

        if (pollingStatus.success === CART_POLLING_STOP_REASON.SUCCESS) {
          return router.push(getRoute.cart.paymentSuccess());
        }

        if (paymentErrorsQuota.includes(pollingStatus?.code as QUOTAS_ERROS)) {
          paymentQuotaExceedNotification(t);
        } else if (pollingStatus?.code === QUOTAS_ERROS.AMOUNT_MISMATCH) {
          paymentAmountMismatchNotification(t);
        } else {
          paymentNotification(t);
        }

        await cartDataHandler.cancelPayment();
        return setPaymentState(PAYMENT_STATE.IDLE);
      },
    });
  };

  const registerPaymentMethod = async (paymentMethodSend: PAYMENT_METHOD) => {
    if (!paymentState && paymentState !== PAYMENT_STATE.IDLE && paymentState !== PAYMENT_STATE.PAYMENT_METHOD) {
      console.error('setPaymentMethod error : paymentState is not IDLE : state=', paymentState);
      rollbar.error(`setPaymentMethod error : paymentState is not IDLE : state=${paymentState}`);
      return false;
    }

    const response = await cartDataHandler.setPaymentMethod(paymentMethodSend);
    if (!response) return false;

    setPaymentMethod(paymentMethodSend);
    setPaymentState(PAYMENT_STATE.PAYMENT_METHOD);
    return true;
  };

  return {
    currentCart,
    paymentState,
    setPaymentState,
    paymentMethod,

    registerPaymentMethod,

    validatePayment: async () => {
      if (paymentState !== PAYMENT_STATE.PAYMENT_METHOD) {
        rollbar.error(`validatePayment error : paymentState is not PAYMENT_METHOD : paymentState=${paymentState}`);
        return false;
      }

      switch (paymentMethod) {
        case PAYMENT_METHOD.BANK_TRANSFER:
        case PAYMENT_METHOD.INVOICE:
        case PAYMENT_METHOD.FREE: {
          return doPay();
        }
        case PAYMENT_METHOD.DATATRANS: {
          const success = await cartDataHandler.setInitDatatrans();
          if (!success) return false;
          setPaymentState(PAYMENT_STATE.RENDER_LIGHTBOX);
          break;
        }
        default:
          rollbar.error(`validatePayment error : paymentMethod is not set : paymentMethod==${paymentState}`);
          break;
      }
      return false;
    },
  };
};
