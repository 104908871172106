import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Profile, ACCESS_LEVEL, ProfileVerification } from '@root/src/types';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import Card from '@features/ui/components/Card';
import UseRegisterStepper, { REGISTER_CONTEXT } from '@features/users/components/UseRegisterStepper';
import { selectSubscriptionServices, setServicesProfile } from '@features/subscriptions/slices/formSubscriptionSlice';
import Footer from './Footer';

interface ServiceUserSubscriptionProps {
  className?: string;
  teamMateIndex: number;
  categoryId: number;
}

const ServiceUserSubscription = ({
  className,
  teamMateIndex,
  categoryId,
}: ServiceUserSubscriptionProps) => {
  const requestHandler = useRequestHandler();
  const dispatch = useDispatch();

  const services = useSelector(selectSubscriptionServices(categoryId));

  const addUserAsFriend = async (user: ProfileVerification) => {
    await requestHandler({
      request: api.profile.access.create({
        body: {
          athlete: user._id,
          birthDateObj: user.birthDateObj,
          level: ACCESS_LEVEL.NOT_ALLOWED,
        },
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });
  };

  const onUserVerificationSelected = async (user: ProfileVerification) => {
    await addUserAsFriend(user);
    dispatch(setServicesProfile({
      categoryId,
      profile: user,
      index: teamMateIndex,
    }));
  };

  const onUserCreated = async (user: Profile) => {
    await addUserAsFriend(user);
    dispatch(setServicesProfile({
      categoryId,
      profile: user,
      index: teamMateIndex,
    }));
  };

  const usersIDsAlreadyInTeam = services?.map(service => service.profile?._id) ?? [];

  const {
    registerView,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    currentStep,
    isFirst,
    isLast,
    handleSubmit,
    hasDuplicates,
    steps,
  } = UseRegisterStepper(
    REGISTER_CONTEXT.ADD_TEAMMATE,
    onUserVerificationSelected,
    usersIDsAlreadyInTeam,
    onUserCreated,
  );

  return (
    <Container className={className}>
      <FormWrapper>
        {registerView}
        <Footer
          onNext={goToNextStep}
          onPrevious={goToPreviousStep}
          goToStep={goToStep}
          currentStep={currentStep}
          isFirstStep={isFirst}
          isLastStep={isLast}
          hasDuplicates={hasDuplicates}
          onSubmit={handleSubmit}
          nextButtonDisabled={!steps[currentStep - 1].valid}
        />
      </FormWrapper>
    </Container>
  );
};

export default ServiceUserSubscription;

const FormWrapper = styled.div`
  margin: 32px 0 40px;
  max-width: 682px;
  width: 100%;
  ${mediaBreakpoint.tablet} {
    max-width: unset;
  }
`;

const Container = styled(Card)`
  display: flex;
  justify-content: center;
  padding: 0 20px;
`;
