import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';
import config from '@root/src/config';
import { ListFilter, SelectOptions } from '@root/src/types';
import { mediaBreakpoint } from '@root/src/services/responsiveProvider';
import { ICON_NAME } from '@features/ui/components/Icon';
import InputSelect from '@features/ui-input/components/InputSelect';
import InputText from '@features/ui-input/components/InputText';
import Button, { OwnProps } from './Button';

interface SearchInputProps {
  groupOptions?: Array<SelectOptions>;
  onGroupChange?: (group: number) => void;
  groupOptionsValue?: number | string;
  groupOptionsDefaultValue?: number | string;

  searchFilterBy: Array<SelectOptions & {options?: Array<SelectOptions>}>;
  onSearchSubmit: (query: string, filterBy: string | number) => void;

  className?: string;
  disableSearch?: boolean;
  disableFilter?: boolean;

  isLoading?: boolean;
  filter: ListFilter;
}

const submitForm = (onSearchSubmit, query, filterBy) => onSearchSubmit(query, filterBy);
const debounceSubmitForm = debounce(submitForm, 500);
const SearchInput = ({
  groupOptions,
  onGroupChange,
  groupOptionsValue,
  groupOptionsDefaultValue = -1,
  searchFilterBy,
  onSearchSubmit,
  className,
  disableSearch = false,
  disableFilter = false,
  isLoading = false,
  filter,
}: SearchInputProps) => {
  const [query, setQuery] = useState('');
  const [filterBy, setFilterBy] = useState(searchFilterBy[0]?.value);

  const filterByQueryOptions = searchFilterBy
    .find(filterByOption => filterByOption.value === filterBy)
    .options;

  useEffect(() => {
    if (filter.query === null) {
      setQuery('');
    }
  }, [filter.query]);

  return (
    <Container className={className}>
      <form onSubmit={(e) => {
        /* prevent the enter key to reload the page */
        e.preventDefault();
      }}
      >
        {groupOptions && (
          <StyledInputSelectGroup
            defaultValue={groupOptionsValue ? null : groupOptionsDefaultValue}
            options={groupOptions}
            onChange={e => onGroupChange(e.target.value)}
            value={groupOptionsValue}
            disabled={disableFilter}
          />
        )}

        <StyledInputSelectFilterBy
          value={filterBy}
          options={searchFilterBy}
          onChange={e => {
            setFilterBy(e.target.value);
            setQuery('');
            submitForm(onSearchSubmit, '', e.target.value);
          }}
          disabled={disableSearch}
        />
        <QueryContainer>
          {filterByQueryOptions ? (
            <StyledInputSelectFilterByOptions
              value={query}
              options={filterByQueryOptions}
              onChange={e => {
                setQuery(e.target.value);
                submitForm(onSearchSubmit, e.target.value, filterBy);
              }}
              disabled={disableSearch}
            />
          ) : (
            <StyledInputText
              value={query}
              onChange={e => {
                setQuery(e.target.value);
                debounceSubmitForm(onSearchSubmit, e.target.value, filterBy);
              }}
              disabled={disableSearch}
            />
          )}
          <StyledButton
            type="submit"
            leftIcon={isLoading ? ICON_NAME.loading : ICON_NAME.search}
            iconSize={isLoading ? '5px' : '20px'}
            disabled={disableSearch || isLoading}
          />
        </QueryContainer>
      </form>
    </Container>
  );
};
export default SearchInput;

export enum SEARCH_TYPES {
  TEXT = 'TEXT',
  BIRTH_YEAR = 'BIRTH_YEAR',
  SELECT = 'SELECT',
}

export const searchIsValid = (type, query?) => {
  if (query?.length === 0) {
    return true;
  }

  if (type === SEARCH_TYPES.TEXT
    && query?.length < config.lists.searchMinimumCharactersText
  ) {
    return false;
  }

  if (type === SEARCH_TYPES.BIRTH_YEAR
    && !(parseInt(query, 10) >= config.lists.searchMinimumBirthYear)
  ) {
    return false;
  }
  return true;
};

const QueryContainer = styled.div`
  display: flex;
  width: 100%;
`;
const Container = styled.div`
  > form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mediaBreakpoint.mobile} {
      flex-wrap: wrap;
      > *:not(:first-child) {
        margin-top: 5px;
      }
      width: 100%;
    }
  }
`;

const selectStyle = css`
  & .MuiInputBase-root {
    height: 42px !important;
  }

  width: 100%;
  ${mediaBreakpoint.mobile} {
    margin-right: 0;
  }
`;
const StyledInputSelectGroup = styled(InputSelect)`
  ${selectStyle};
  margin-right: 20px;
`;
const StyledInputSelectFilterBy = styled(InputSelect)`
  ${selectStyle};
  min-width: 160px;
  margin-right: 5px;
`;
const StyledInputSelectFilterByOptions = styled(InputSelect)`
  ${selectStyle};
  width: 300px;
  ${mediaBreakpoint.tablet} {
    width: 100%;
  }
`;
const StyledInputText = styled(InputText)`
  width: 300px;
  ${mediaBreakpoint.tablet} {
    width: 100%;
  }
`;
const StyledButton = styled(Button)<OwnProps>`
  margin-left: 5px;
`;
