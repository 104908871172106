import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textH1, textP } from '@features/ui/styles/textStyles';
import { selectMobileNumberByID } from '@features/users/slices/userSlice';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

interface ModalDeleteMobileNumberProps {
  closeModal: () => void,
  mobileNumberID: string,
}

const ModalDeleteMobileNumber = ({
  closeModal,
  mobileNumberID,
}: ModalDeleteMobileNumberProps) => {
  const { t } = useTranslation();
  const mobileNumber = useSelector(selectMobileNumberByID(mobileNumberID));
  const requestHandler = useRequestHandler();

  const deleteMobileNumber = async () => {
    const { response } = await requestHandler({ request: api.profile.mobileNumbers.delete({ mobileNumberID }) });
    if (response && response?.ok) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('modalDeleteMobileNumber_deleteSuccess.message')}
        text=""
      />);
      closeModal();
    }
  };

  const FooterForm = () => (
    <Footer>
      <Button
        text={t('cancel.message')}
        leftIcon={ICON_NAME.keyboardReturn}
        variant={BUTTON_VARIANT.secondary}
        onClick={closeModal}
      />
      <Button
        text={t('modalDeleteMobileNumber_submitButton.message')}
        leftIcon={ICON_NAME.check}
        onClick={deleteMobileNumber}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<FooterForm />} closeModal={closeModal}>
      <Container>
        <Title>
          {t('modalDeleteMobileNumber_title.message')}
        </Title>
        <Description>
          {t('modalDeleteMobileNumber_description.message', { number: mobileNumber.num })}
        </Description>
      </Container>
    </ModalTemplate>
  );
};

export default ModalDeleteMobileNumber;

const Footer = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`;

const Container = styled.div`
  max-width: 682px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
 ${textH1}
 margin-bottom: 28px;
`;

const Description = styled.p`
  ${textP}
  margin-bottom: 40px;
`;
