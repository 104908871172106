import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import useStepper from '@root/src/hooks/useStepper';
import { Profile, ProfileVerification } from '@root/src/types';
import { removeEmpty } from '@utils/gobal';
import poll from '@utils/poll';
import api from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import { createApiRegister } from '@features/subscriptions/formatters';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import UserProfileForm, { UserProfileFormInputs } from '@features/users/components/UserProfileForm';
import UserVerification from '@features/users/components/UserVerification';
import UserAddressForm, { UserAddressFormInputs } from '@features/users/components/UserAddressForm';
import UserAccountForm, {
  USER_ACCOUNT_FORM_CONTEXT,
  UserAccountFormInputs,
} from '@features/users/components/UserAccountForm';

export enum REGISTER_CONTEXT {
  NEW_USER,
  FRIEND,
  ADD_TEAMMATE
}

export interface RegisterCallBack {
  userProfileContext: FormikProps<UserProfileFormInputs>,
  userAddressContext: FormikProps<UserAddressFormInputs>,
  userAccountContext: FormikProps<UserAccountFormInputs>
}

const UseRegisterStepper = (
  context: REGISTER_CONTEXT,
  onUserVerificationSelected: (user: ProfileVerification) => void,
  usersIDsUnselectable: Array<string>,
  onUserCreated?: (newUser: Profile) => void,
  onRegisterCallback?: ({
    userProfileContext,
    userAddressContext,
    userAccountContext,
  }: RegisterCallBack) => void,
) => {
  const errorCode = 'error.athleteNotSynchronized';

  const [formikContextProfile, setFormikContextProfile] = useState<FormikProps<UserProfileFormInputs>>();
  const [formikContextAddress, setFormikContextAddress] = useState<FormikProps<UserAddressFormInputs>>();
  const [formikContextAccount, setFormikContextAccount] = useState<FormikProps<UserAccountFormInputs>>();
  const [hasDuplicates, setHasDuplicates] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const requestHandler = useRequestHandler();

  const profileFormDescription = {
    [REGISTER_CONTEXT.NEW_USER]: t('modalRegister_userProfileFormDescription.message'),
    [REGISTER_CONTEXT.ADD_TEAMMATE]: t('serviceUserSubscription_userProfileFormDescription.message'),
    [REGISTER_CONTEXT.FRIEND]: t('modalAddFriend_userProfileFormDescription.message'),
  };

  const verificationFormDescriptionKey = {
    [REGISTER_CONTEXT.NEW_USER]: 'modalRegister_userVerificationFormDescription.message',
    [REGISTER_CONTEXT.ADD_TEAMMATE]: 'serviceUserSubscription_userVerificationDescription.message',
    [REGISTER_CONTEXT.FRIEND]: 'modalAddFriend_userVerificationDescription.message',
  };

  const userAccountFormContext = {
    [REGISTER_CONTEXT.NEW_USER]: USER_ACCOUNT_FORM_CONTEXT.REGISTER,
    [REGISTER_CONTEXT.FRIEND]: USER_ACCOUNT_FORM_CONTEXT.REGISTER_FRIEND,
    [REGISTER_CONTEXT.ADD_TEAMMATE]: USER_ACCOUNT_FORM_CONTEXT.REGISTER_FRIEND,
  };

  const submitUrl = {
    [REGISTER_CONTEXT.NEW_USER]: api.auth.register,
    [REGISTER_CONTEXT.FRIEND]: api.auth.create,
    [REGISTER_CONTEXT.ADD_TEAMMATE]: api.auth.create,
  };

  const handleUserNotSync = async (response: Response) => {
    const json = await response.json();
    if (json.code !== errorCode) {
      toast(
        <Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('notification_genericTitle.message')}
          text=""
        />,
      );
    }
  };

  const readUserLoggedSync = async () => {
    const {
      username,
      password,
    } = formikContextAccount.values;
    const { response } = await requestHandler({
      request: api.auth.login({
        body: {
          login: username,
          password,
        },
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response && response.ok) {
      const json = await response.json();
      return json.user;
    }
    await handleUserNotSync(response);
    return null;
  };

  const readUserSync = async (athleteId: string) => {
    const { response } = await requestHandler({
      request: api.users.read({ athleteId }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });
    if (response && response.ok) {
      return response.json();
    }
    await handleUserNotSync(response);
    return null;
  };

  const checkIfUserHasMSOId = async (newUser: Profile) => {
    const fn = context === REGISTER_CONTEXT.NEW_USER ? readUserLoggedSync : readUserSync;

    // TODO 1531
    await poll({
      fn: async () => fn(newUser._id),
      validate: (user?: Profile) => !!user,
      onValidate: async (userSync) => {
        await onSuccess(userSync);
      },
    });
  };

  const onSuccess = async (newUser: Profile) => {
    await onUserCreated?.(newUser);
    await onRegisterCallback?.({
      userAccountContext: formikContextAccount,
      userAddressContext: formikContextAddress,
      userProfileContext: formikContextProfile,
    });
    // TODO 1531: Put this on handleSubmit once poll can be await
    setIsSubmitting(false);
  };

  const mapErrorToTitle = { 'error.accountAlreadyExists': t('userAccountForm_usernameErrorLabel.message') };

  const mapErrorToText = { 'error.accountAlreadyExists': '' };

  const onError = async (response: Response) => {
    const result = await response.json();
    const errorCode = result.code;

    toast(
      <Alert
        variant={ALERT_VARIANTS.ERROR}
        title={mapErrorToTitle[errorCode] ?? t('notification_genericTitle.message')}
        text={mapErrorToText[errorCode] ?? result.message}
      />,
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = createApiRegister(
      formikContextProfile.values,
      formikContextAddress.values,
      formikContextAccount.values,
    );

    const { response } = await requestHandler({
      request: submitUrl[context]({ body: removeEmpty(body) }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response) {
      if (response?.ok) {
        const newUser: Profile = await response.json();
        await checkIfUserHasMSOId(newUser);
      } else {
        await onError(response);
        setIsSubmitting(false);
      }
    }
  };
  const steps = [
    {
      title: t('modalRegister_stepProfileTitle.message'),
      component: () => {
        setHasDuplicates(true);
        return (
          <UserProfileForm
            initialValuesUserProfile={formikContextProfile?.values as UserProfileFormInputs}
            initialErrors={formikContextProfile ? {} : { global: 'formikContextProfile not init' }}
            setFormikContextProfile={setFormikContextProfile}
            description={profileFormDescription[context]}
          />
        );
      },
      valid: formikContextProfile?.isValid,
    },
    {
      title: t('modalRegister_stepProfileVerification.message'),
      component: ({ goToNextStep }) => {
        if (!formikContextProfile) {
          return <div />;
        }

        const { birthdateYear } = formikContextProfile.values;
        const { birthdateMonth } = formikContextProfile.values;
        const { birthdateDay } = formikContextProfile.values;

        return (
          <UserVerification
            context={context}
            emptyVerificationCallback={() => {
              setHasDuplicates(false);
              goToNextStep();
            }}
            onUserSelectedClicked={onUserVerificationSelected}
            user={{
              firstName: formikContextProfile.values.firstName,
              lastName: formikContextProfile.values.lastName,
              birthDate: `${birthdateYear}-${birthdateMonth}-${birthdateDay}`,
              gender: formikContextProfile.values.gender,
            }}
            usersIDsUnselectable={usersIDsUnselectable}
            description={verificationFormDescriptionKey[context]}
          />
        );
      },
      valid: true,
    },
    {
      title: t('modalRegister_stepProfileAddress.message'),
      component: () => (
        <UserAddressForm
          initialValuesUserAddress={formikContextAddress?.values as UserAddressFormInputs}
          setFormikContextProfile={setFormikContextAddress}
        />
      ),
      valid: formikContextAddress?.isValid,
    },
    {
      title: t('modalRegister_stepProfileAccount.message'),
      component: () => (
        <UserAccountForm
          initialValueUserAccount={formikContextAccount?.values as UserAccountFormInputs}
          setFormikContextAccount={setFormikContextAccount}
          context={userAccountFormContext[context]}
        />
      ),
      valid: formikContextAccount?.isValid,
    },
  ];

  const {
    goToNextStep,
    goToPreviousStep,
    goToStep,
    isFirst,
    isLast,
    stepperView,
    currentStep,
  } = useStepper(steps);

  const registerView = useMemo(() => (
    stepperView
  ), [currentStep, isSubmitting]);

  return ({
    registerView,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    currentStep,
    isFirst,
    isLast,
    handleSubmit,
    isSubmitting,
    hasDuplicates,
    steps,
  });
};

export default UseRegisterStepper;
