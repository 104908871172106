import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { textH4, textSmall } from '../styles/textStyles';

interface RawSerieLabelProps {
  className?: string;
}

const RawSerieLabel = ({ className }: RawSerieLabelProps) => {
  const { t } = useTranslation();

  return (
    <span className={className}>
      {t('serieLabel_text.message')}
    </span>
  );
};

const SerieLabel = styled(RawSerieLabel)`
  padding: 5px 15px;

  color: var(--color-white);
  background: var(--color-orange);
  border-radius: 20px 0 10px 0;
  text-transform: uppercase;
  ${textH4}
`;

export const SerieLabelTopRight = styled(SerieLabel)`
  border-radius: 0 10px 0 20px;
`;

export const SerieLabelSmall = styled(SerieLabel)`
  && {
    border-radius: 12px 0 8px 0;
    padding: 1px 6px;
    ${textSmall}
  }
`;

export default SerieLabel;
