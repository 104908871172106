import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';
import { textP, textPBold } from '@features/ui/styles/textStyles';

export enum ALERT_VARIANTS {
  ERROR,
  SUCCESS,
  WARNING,
}

const VARIANT_ICONS = {
  [ALERT_VARIANTS.ERROR]: ICON_NAME.error,
  [ALERT_VARIANTS.SUCCESS]: ICON_NAME.iconSuccess,
  [ALERT_VARIANTS.WARNING]: ICON_NAME.error,
};

const VARIANT_COLORS = {
  [ALERT_VARIANTS.ERROR]: 'var(--color-red)',
  [ALERT_VARIANTS.SUCCESS]: 'var(--color-blue)',
  [ALERT_VARIANTS.WARNING]: 'var(--color-orange)',
};

interface AlertProps {
  closeToast?: () => void;
  title: string;
  text: string;
  withRetry?: boolean;
  onRetry?: () => void;
  variant: ALERT_VARIANTS;
}

const Alert = ({ closeToast, title, text, withRetry, onRetry, variant }: AlertProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ColorLine variant={variant} />
      <AlertHeader>
        <StyledIcon name={VARIANT_ICONS[variant]} size="44px" />
        <div>
          {withRetry && (
            <Button
              text={t('notification_retry.message')}
              leftIcon={ICON_NAME.refresh}
              onClick={() => {
                if (onRetry) {
                  return onRetry();
                }
                return window.location.reload();
              }}
              variant={BUTTON_VARIANT.secondary}
            />
          )}
          <ClosedButton
            text={withRetry ? '' : t('notification_close.message')}
            leftIcon={ICON_NAME.clear}
            onClick={closeToast}
          />
        </div>
      </AlertHeader>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  );
};
export default Alert;

const Wrapper = styled.div`
  display: block;
  box-shadow: var(--alert-shadow);
  background: var(--color-white);
  padding: 20px;
  position: relative;
  width: var(--page-min-width);
  border-radius: 4px;
  overflow: hidden;
`;
const ColorLine = styled.div<{variant: ALERT_VARIANTS}>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: ${({ variant }) => VARIANT_COLORS[variant]};
`;
const StyledIcon = styled(Icon)`
  margin-right: 30px;
`;
const AlertHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
`;
const Title = styled.p`
  ${textPBold};
`;
const Text = styled.p`
  ${textP};
`;
const ClosedButton = styled(Button)<OwnProps>`
  margin-left: 12px;
`;
