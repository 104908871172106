import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { routeRegex } from '@services/routeList';
import { setNavigationHistoryProp } from '@features/ui-layout/slices/uiLayoutSlice';

export enum NAVIGATION_PROP {
  lastNonEventPage = 'lastNonEventPage',
  lastNonSeriePage = 'lastNonSeriePage',
  lastRegistrationPage = 'lastRegistrationPage',
}

export const navigationHistoryConfigs: Array<{
  navigationProp: NAVIGATION_PROP,
  match: (asPath: string) => boolean,
}> = [
  {
    navigationProp: NAVIGATION_PROP.lastNonEventPage,
    match: (asPath) => !routeRegex.event.test(asPath),
  }, {
    navigationProp: NAVIGATION_PROP.lastNonSeriePage,
    match: (asPath) => !routeRegex.serie.test(asPath),
  }, {
    navigationProp: NAVIGATION_PROP.lastRegistrationPage,
    match: (asPath) => routeRegex.subscriptions.test(asPath),
  },
];

const useNavigationHistory = () => {
  const dispatch = useDispatch();
  const { pathname, query, asPath } = useRouter();

  useEffect(() => {
    navigationHistoryConfigs.forEach(({ navigationProp, match }) => {
      if (match(asPath)) {
        const queryWithoutModalParams = Object.entries(query)
          .filter(([key]) => !key.includes('modal'))
          .reduce((acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }), {});

        dispatch(setNavigationHistoryProp({
          navigationProp,
          urlObject: {
            pathname,
            query: queryWithoutModalParams,
          },
        }));
      }
    });
  }, [pathname, query, asPath]);
};

export default useNavigationHistory;
