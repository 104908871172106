import yup from '@services/translation/translatedYup';
import {
  birthdateDayFieldValidation, birthdateMonthFieldValidation, birthdateYearFieldValidation,
  firstNameFieldValidation, genderFieldValidation,
  lastNameFieldValidation, nationalityFieldValidation,
} from '@features/users/components/UserProfileFormSchema';
import { emailFieldValidation } from '@features/users/components/UserAccountFormSchema';
import {
  addressFieldValidation, countryFieldValidation, languageFieldValidation,
  localityFieldValidation,
  postalCodeFieldValidation,
} from '@features/users/components/UserAddressFormSchema';

const ProfileFormSchema = (setValidationDateError, t) => yup.object().shape({
  ...firstNameFieldValidation(t),
  ...lastNameFieldValidation(t),
  ...emailFieldValidation(t),
  ...addressFieldValidation(t),
  ...postalCodeFieldValidation(t),
  ...localityFieldValidation(t),
  ...countryFieldValidation(t),
  ...birthdateDayFieldValidation(t, setValidationDateError),
  ...birthdateMonthFieldValidation(t, setValidationDateError),
  ...birthdateYearFieldValidation(t, setValidationDateError),
  ...genderFieldValidation(t),
  ...languageFieldValidation(t),
  ...nationalityFieldValidation(t),
});

export default ProfileFormSchema;
