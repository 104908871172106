import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { mediaBreakpoint, RESPONSIVE, useResponsive } from '@root/src/services/responsiveProvider';
import getRoute, { QUERY_ENUM } from '@services/routeList';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';
import { RoundChip } from '@features/ui-layout/components/Menu';
import { selectSubscriptions } from '@features/subscriptions/slices/subscriptionDetailServerSlice';
import { textLink } from '@features/ui/styles/textStyles';

const AccountMenu = () => {
  const router = useRouter();
  const activeTab = router.route;
  const { t } = useTranslation();
  const responsive = useResponsive();
  const userSubscriptions = useSelector(selectSubscriptions);
  const numberEvents = userSubscriptions?.total;

  const menuItems = [
    {
      url: getRoute.account.profile(),
      name: t('accountMenu_profile.message'),
    },
    {
      url: getRoute.account.mobiles(),
      name: t('accountMenu_mobiles.message'),
    },
    {
      url: getRoute.account.subscriptions(),
      otherActiveUrls: [getRoute.account.subscription(router.query[QUERY_ENUM.subscriptionId]).pathname],
      name: t('accountMenu_events.message'),
      rightComponent: numberEvents ? <RoundChip>{numberEvents}</RoundChip> : undefined,
    },
    {
      url: getRoute.account.receipts(),
      name: t('accountMenu_receipts.message'),
    },
    {
      url: getRoute.account.friends(),
      name: t('accountMenu_friends.message'),
    },
    {
      url: getRoute.account.password(),
      name: t('accountMenu_password.message'),
    },
    {
      url: getRoute.account.preferences(),
      name: t('accountMenu_preferences.message'),
    },
  ];

  return (
    <Container>
      {menuItems.map(menuItem => (responsive === RESPONSIVE.DESKTOP ? (
        <LinkWrapper key={menuItem.url.pathname}>
          <Link href={menuItem.url}>
            <a>
              <DesktopMenuItem
                active={activeTab === menuItem.url.pathname || menuItem.otherActiveUrls?.includes(activeTab)}
              >
                {menuItem.name}
              </DesktopMenuItem>
            </a>
          </Link>
        </LinkWrapper>
      ) : (
        <TabletMenuItem
          key={menuItem.url.pathname}
          variant={BUTTON_VARIANT.menu}
          text={menuItem.name}
          url={menuItem.url}
          rightComponent={menuItem.rightComponent}
        />
      )))}
    </Container>
  );
};

export default AccountMenu;

const Container = styled.div`
  width: 331px;
  box-sizing: border-box;
  border: 1px solid var(--color-grey1);
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(128,122,122,0.2);
  ${mediaBreakpoint.tablet} {
    width: 100%;
    box-shadow: inherit;
    border: inherit;
    a {
     display: block;
    }
    a + a {
      margin-top: 12px;
    }
  }

  a:hover {
    text-decoration: none;
  }
`;

const LinkWrapper = styled.div`
  ${textLink};
  text-decoration: none;
  color: var(--color-dark);
  & + & {
    border-top: 2px solid var(--color-grey1);
  }
`;

const DesktopMenuItem = styled.span<{ active: boolean }>`
  padding: 20px 19px;
  display: block;
  border-right: ${({ active }) => (active ? 'solid 4px var(--color-blue)' : 'inherit')};
  background-color: ${({ active }) => (active ? 'var(--color-blue-light)' : 'var(--color-white)')};
  cursor: pointer;
  &:hover{
    border-right: solid 4px var(--color-blue-hover);
  }
`;

const TabletMenuItem = styled(Button)<OwnProps>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;
