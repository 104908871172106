import styled from 'styled-components';
import Icon from '@features/ui/components/Icon';
import { FLAG_NAME } from './utils';

const flagWidth = 20;
const flagHeight = 13;
interface RankProps {
  nationality: string | Array<string>;
}

const CountryFlag = ({ nationality }: RankProps) => {
  const nationalityClean = Array.isArray(nationality)
    ? nationality[0]
    : nationality;

  return (
    <CountryFlagContainer>
      {FLAG_NAME[`flag${nationalityClean}`] ? (
        <Icon
          size={`${flagWidth}px`}
          name={FLAG_NAME[`flag${nationalityClean}`]}
        />
      ) : (
        <EmptyFlag />
      )}
      {nationalityClean}
    </CountryFlagContainer>
  );
};

export default CountryFlag;

const CountryFlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const EmptyFlag = styled.div`
  width: ${flagWidth}px;
  height: ${flagHeight}px;
  border-radius: 1px;
  background-color: var(--color-grey2);
`;
