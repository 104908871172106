import styled from 'styled-components';

const RequiredChip = styled.div`
  height: 6px;
  width: 6px;
  background-color: var(--color-red);
  border-radius: 6px;
`;

export default RequiredChip;
