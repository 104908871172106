import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FormikContextType } from 'formik';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';

interface ModalRequestPasswordResetFooterProps {
  formikContext: FormikContextType<{username: string}>
}

const FooterSubmitForm = ({ formikContext }: ModalRequestPasswordResetFooterProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Footer>
      <Button
        text={t('cancel.message')}
        leftIcon={ICON_NAME.keyboardReturn}
        variant={BUTTON_VARIANT.secondary}
        onClick={router.back}
      />
      <Button
        text={t('modalRequestPasswordResetFooter_sendEmailButton.message')}
        leftIcon={ICON_NAME.mail}
        onClick={formikContext?.submitForm}
      />
    </Footer>
  );
};

export default FooterSubmitForm;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
