import { DateTime } from 'luxon';

export const QUERY_API_DATE_FORMAT_MOMENT = 'YYYY-MM-DD';
export const QUERY_API_DATE_FORMAT_LUXON = 'yyyy-MM-dd';

export enum DATE_FORMAT {
  default = 'dd.MM.yyyy',
  year = 'yyyy',
  dateWithWeekday = 'cccc dd.MM.yyyy',
  dateWithHours = 'dd.MM.yyyy (HH:mm)',
  dayMonth = 'd LLLL',
  day = 'd',
}

export const formatEventDate = (date, dateFrom?: string, dateTo?: string, separator = ' - ') => {
  if (dateTo && dateFrom) {
    const dateFromFormatted = formatDate(dateFrom);
    const dateToFormatted = formatDate(dateTo);

    return dateFromFormatted === dateToFormatted
      ? dateFromFormatted
      : `${dateFromFormatted}${separator}${dateToFormatted}`;
  }
  return formatDate(date);
};

export const formatEventRangeFull = (
  date,
  dateFrom?: string,
  dateTo?: string,
  locale = 'en'
) => {
  if (dateTo && dateFrom) {
    const isSameDay = DateTime.fromISO(dateFrom).day === DateTime.fromISO(dateTo).day;
    const isSameMonth = DateTime.fromISO(dateFrom).month === DateTime.fromISO(dateTo).month;

    if (isSameDay && isSameMonth) {
      return formatDate(dateFrom, DATE_FORMAT.dayMonth, locale);
    }

    return `${
      isSameMonth ? formatDate(dateFrom, DATE_FORMAT.day, locale) : formatDate(dateFrom, DATE_FORMAT.dayMonth, locale)
    }${' - '}${
      formatDate(dateTo, DATE_FORMAT.dayMonth, locale)
    }`;
  }
  return formatDate(date, DATE_FORMAT.dayMonth, locale);
};

export const formatDate = (
  date: string,
  format = DATE_FORMAT.default,
  locale = 'en',
) => DateTime
  .fromISO(date)
  .setLocale(locale)
  .toFormat(format);

export const formatDateAsYear = (date: string) => formatDate(date, DATE_FORMAT.year);
export const formatDateWithHours = (date: string) => formatDate(date, DATE_FORMAT.dateWithHours);
export const formatDateWithWeekday = (date: string, locale: string = 'en') => DateTime
  .fromISO(date)
  .setLocale(locale)
  .toFormat(DATE_FORMAT.dateWithWeekday);
