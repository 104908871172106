import { createSelector, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { uniqBy } from 'lodash';
import {
  Cart,
  PAYMENT_METHOD,
  PAYMENT_METHOD_INFO_CODE,
  PaymentMethodInfo,
  RegistrationTeamMate,
  TEAM_MEMBER_ROLE,
} from '@root/src/types';
import { hydrateStateIfDataNotNull, RootState } from '@services/store';
import { currentCartFromApiToReducer, paymentMethodsInfoFromApiToReducer } from '@features/carts/formatter';

export interface CartsSliceState {
  current: Cart
  list: Cart[],
  paymentMethodsInfo: Array<PaymentMethodInfo>
}

export const cartsSlice = createSlice({
  name: 'carts',
  initialState: {
    current: null,
    list: null,
    paymentMethodsInfo: null,
  },
  extraReducers: {
    [HYDRATE]: (
      state,
      action
    ) => hydrateStateIfDataNotNull(state, action.payload.carts),
  },
  reducers: {
    setCurrentCart: (state, action) => {
      state.current = currentCartFromApiToReducer(action.payload);
    },
    setCarts: (state, action) => {
      state.list = action.payload.items.map(currentCartFromApiToReducer);
    },
    setPaymentMethodsInfo: (state, action) => {
      state.paymentMethodsInfo = paymentMethodsInfoFromApiToReducer(action.payload);
    },
  },
});

export const {
  setCurrentCart,
  setCarts,
  setPaymentMethodsInfo,
} = cartsSlice.actions;

const mapPaymentMethodToPaymentMethodInfoFixedFees = {
  [PAYMENT_METHOD.BANK_TRANSFER]: PAYMENT_METHOD_INFO_CODE.BANK_TRANSFER_FIXED_FEES,
  [PAYMENT_METHOD.INVOICE]: PAYMENT_METHOD_INFO_CODE.INVOICE_FIXED_FEES,
  [PAYMENT_METHOD.DATATRANS]: PAYMENT_METHOD_INFO_CODE.DATATRANS_FIXED_FEES,
};

const mapPaymentMethodToPaymentMethodInfoVariableFees = {
  [PAYMENT_METHOD.BANK_TRANSFER]: PAYMENT_METHOD_INFO_CODE.BANK_TRANSFER_VARIABLE_FEES,
  [PAYMENT_METHOD.INVOICE]: PAYMENT_METHOD_INFO_CODE.INVOICE_VARIABLE_FEES,
  [PAYMENT_METHOD.DATATRANS]: PAYMENT_METHOD_INFO_CODE.DATATRANS_VARIABLE_FEES,
};

export const selectCurrentCartDetail = (state: RootState) => state.carts.current;
export const selectCarts = (state: RootState) => state.carts.list || [];

export const selectLastCart = () => createSelector(
  selectCarts,
  carts => carts[0],
);
export default cartsSlice.reducer;

export const selectAllAthletes = createSelector(
  selectCurrentCartDetail,
  currentCart => {
    const athletesSolo = currentCart.items.map(item => ({
      teamMember: {
        fullName: item.athlete.fullName,
        _idMso: item.athlete._idMso,
      },
      role: TEAM_MEMBER_ROLE.LEAD,
    }));
    return uniqBy<RegistrationTeamMate>(
      [...currentCart.items.flatMap(item => item.team), ...athletesSolo],
      'teamMember._idMso',
    );
  },
);

export const selectCartItem = itemId => createSelector(
  selectCurrentCartDetail,
  cart => cart.items.find(item => item._id === itemId),
);

export const selectPaymentMethodsInfo = (state: RootState) => state.carts.paymentMethodsInfo;

export const selectFixedFeesByPaymentMethod = (paymentMethod: PAYMENT_METHOD) => createSelector(
  selectPaymentMethodsInfo,
  paymentsMethodsInfo => paymentsMethodsInfo
    .find(
      paymentMethodInfo => mapPaymentMethodToPaymentMethodInfoFixedFees[paymentMethod] === paymentMethodInfo.code
    )
    ?.value,
);

export const selectVariableFeesByPaymentMethod = (paymentMethod: PAYMENT_METHOD) => createSelector(
  selectPaymentMethodsInfo,
  paymentsMethodsInfo => paymentsMethodsInfo
    .find(
      paymentMethodInfo => mapPaymentMethodToPaymentMethodInfoVariableFees[paymentMethod] === paymentMethodInfo.code
    )
    ?.value
);
