import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { Serie, Event, Pack, PackDetails } from '@root/src/types';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import api, { EVENT_LIST_MODELS } from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import {
  selectSerieDetail,
  selectSerieEvents,
  selectSeriePacks,
  selectSeriePackDetails,
  setSerie,
  setSerieEvents,
  setSeriePacks,
  setSeriePackDetails,
} from './slices/serieDetailServerSlice';

class GetSerieData extends InitDataHandler {
  getSerie: (serieId: number) => Promise<Serie | null> = async (serieId) => {
    const data = {
      serieId,
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.series.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSerie({ apiSerie: json }));
      return selectSerieDetail(this.store.getState());
    }

    return null;
  }

  getSerieEvents: (serieId: number) => Promise<Array<Event>> = async (serieId) => {
    const data = {
      pageSize: 50, // TODO: is this a good number? no serie with more than 50 events? should we paginate instead, to be safe?
      q: '*',
      filtered: [
        {
          id: 'serie',
          value: String(serieId),
        },
      ],
      locale: this.locale,
      models: [EVENT_LIST_MODELS.baseRace],
    };

    const { response } = await this.requestHandler({
      request: api.events.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const { items } = await response.json();
      this.dispatch(setSerieEvents({ apiEvents: items }));
      return selectSerieEvents(this.store.getState());
    }

    return null;
  }

  getSeriePacks: (serieId: number) => Promise<Array<Pack>> = async (serieId) => {
    const data = {
      serieId,
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.series.packs.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSeriePacks({ apiPacks: json }));
      return selectSeriePacks(this.store.getState());
    }

    return null;
  }

  getSeriePack: (
    serieId: number,
    packId: number,
    athleteId?: number
  ) => Promise<PackDetails> =
  async (serieId, packId, athleteId) => {
    const data = {
      serieId,
      packId,
      ...(athleteId && { athleteId }),
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.series.packs.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSeriePackDetails({ apiPack: json }));
      return selectSeriePackDetails(this.store.getState());
    }

    return null;
  }
}

export default GetSerieData;

export const useGetSerieData = () => {
  const store = useStore();
  const requestHandler = useRequestHandler();
  const router = useRouter();

  return new GetSerieData({
    store,
    requestHandler,
    router,
  });
};
