import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import config from '@root/src/config';
import { mediaBreakpoint, ResponsiveWrapper } from '@services/responsiveProvider';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import LanguagesDropdown from '@features/ui/components/LanguagesDropdown';
import { textLink, textP, textSmall } from '@features/ui/styles/textStyles';

interface IconInterface {
  iconName: ICON_NAME;
}

interface UrlInterface {
  url: string;
  blank?: boolean;
}

interface LinkInterface extends UrlInterface {
  text: string;
}

interface SocialItem extends IconInterface, UrlInterface {
}

const socialLinksData: SocialItem[] = [
  {
    iconName: ICON_NAME.socialInsta,
    url: 'https://www.instagram.com/mso.swiss/',
  },
  {
    iconName: ICON_NAME.socialFacebook,
    url: 'https://www.facebook.com/mso.swiss',
  },
  {
    iconName: ICON_NAME.socialLinkedin,
    url: 'https://www.linkedin.com/company/msoswiss',
  },
  {
    iconName: ICON_NAME.socialYoutube,
    url: 'https://www.youtube.com/channel/UCS8x1fqMKt_ohhboTlFH5TA',
  },
];

const internalLinksData: (locale: string) => LinkInterface[] = (locale) => ([

  {
    text: 'footer_generalConditions',
    url: config.documentsPath.termOfUse,
  }, {
    text: 'footer_insurance',
    url: config.documentsPath.cga[locale],
  }, {
    text: 'footer_privacyPolicy',
    url: config.documentsPath.privacyPolicy,
  },
]);

const outerUserLinksData: (locale: string) => LinkInterface = (locale) => (
  {
    text: 'footer_faq',
    url: config.helpUrl.faq[locale],
  }
);

const innerUserLinksData: (locale: string) => LinkInterface[] = (locale) => ([
  {
    text: 'footer_registration',
    url: config.helpUrl.registration[locale],
  }, {
    text: 'footer_password',
    url: config.helpUrl.password[locale],
  }, {
    text: 'footer_transfer',
    url: config.helpUrl.transfer[locale],
  },
]);

const aboutLinksData: LinkInterface[] = [
  {
    text: 'footer_aboutMso',
    url: config.urls.aboutMSO,
    blank: true,
  }, {
    text: 'footer_organiserSpace',
    url: config.urls.organiser,
    blank: true,
  }, {
    text: 'footer_contactBusiness',
    url: config.urls.contact,
    blank: false,
  },
];

const paymentsMethodData: IconInterface[] = [
  { iconName: ICON_NAME.paymentPost },
  { iconName: ICON_NAME.paymentVisa },
  { iconName: ICON_NAME.paymentMaster },
  { iconName: ICON_NAME.paymentTwint },
  { iconName: ICON_NAME.paymentApple },
  { iconName: ICON_NAME.paymentGoogle },
  { iconName: ICON_NAME.paymentSamsung },
  { iconName: ICON_NAME.paymentSofort },
  { iconName: ICON_NAME.paymentTransfer },
  { iconName: ICON_NAME.paymentBill },
];

const FooterInfos = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <StyledResponsiveWrapper>
        <FooterBlock>
          <IdentityContainer>
            <Logo src="/images/mso-logo-white.png" />
            <div>{`${currentYear} © ${t('global_projectName.message')}`}</div>
            <div>{t('footer_identityLocation.message')}</div>
            <SocialLinksContainer>
              {socialLinksData.map(socialLink => (
                <SocialIcon rel="external" target="_blank" href={socialLink.url} key={socialLink.iconName}>
                  <Icon name={socialLink.iconName} color="var(--color-white)" />
                </SocialIcon>
              ))}
            </SocialLinksContainer>
          </IdentityContainer>
          <AboutContainer>
            {aboutLinksData.map(aboutLink => (
              <Link key={aboutLink.text} target={aboutLink.blank ? '_blank' : ''} href={aboutLink.url}>
                {t(`${aboutLink.text}.message`)}
              </Link>
            ))}
          </AboutContainer>
          <InternalLinksContainer>
            <Link key={outerUserLinksData(router.locale).text} target={outerUserLinksData(router.locale).blank ? '_blank' : ''} href={outerUserLinksData(router.locale).url}>
              {t(`${outerUserLinksData(router.locale).text}.message`)}
            </Link>
            {innerUserLinksData(router.locale).map(userLink => (
              <Link key={userLink.text} target={userLink.blank ? '_blank' : ''} href={userLink.url}>
                <pre>
                  {t('•   ').concat(t(`${userLink.text}.message`))}
                </pre>
              </Link>
            ))}
          </InternalLinksContainer>
          <LanguageDropdownContainer>
            <div>{t('footer_selectLanguage.message')}</div>
            <LanguageDropdownWrapper>
              <LanguagesDropdown />
            </LanguageDropdownWrapper>
          </LanguageDropdownContainer>
          <PaymentsMethodContainer>
            <div>{t('footer_paymentsMethods.message')}</div>
            <PaymentsMethodIconsContainer>
              {paymentsMethodData.map(paymentMethod => (
                <PaymentIcon key={paymentMethod.iconName}>
                  <Icon name={paymentMethod.iconName} />
                </PaymentIcon>
              ))}
            </PaymentsMethodIconsContainer>
          </PaymentsMethodContainer>
        </FooterBlock>
      </StyledResponsiveWrapper>

      <StyledResponsiveWrapper>
        <BorderedFooterBlock>
          {internalLinksData(router.locale).map(msoLink => (
            <OffSetLink target="_blank" key={msoLink.text} href={msoLink.url}>{t(`${msoLink.text}.message`)}</OffSetLink>
          ))}
        </BorderedFooterBlock>
      </StyledResponsiveWrapper>
    </>
  );
};
export default FooterInfos;

const StyledResponsiveWrapper = styled(ResponsiveWrapper)`
  background-color: var(--color-grey4);
`;

const FooterBlock = styled.div`
  display: grid;
  padding: 64px 0 30px;
  color: var(--color-white);
  grid-template-areas:
      "identity about links language" 
      "identity social links payments";

  ${mediaBreakpoint.tablet} {
    grid-template-areas:
        "identity about" 
        "identity about" 
        "identity links" 
        "language links" 
        "payments social";
  }

  ${mediaBreakpoint.mobile} {
    padding: 40px 5%;
    grid-template-areas:
        "identity"
        "language"
        "payments"
        "about" 
        "links"
        "social";
  }
`;

const BorderedFooterBlock = styled.div`
  border-top: 1px solid var(--color-white);
  color: var(--color-white);
  display: flex;
  flex-direction: row;
  padding: 15px 0 25px;

  ${mediaBreakpoint.mobile} {
    padding: 10px  5% 20px;
    flex-direction: column;
  }
`;

const Link = styled.a`
  ${textLink};
  text-decoration: none;
  color: var(--color-white);

  &:hover {
    color: var(--color-orange-hover);
  }

  cursor: pointer;

  & + & {
    margin-top: 16px;
  }

  transition: 0.3s;
`;

const OffSetLink = styled(Link)`
  & + & {
    margin-top: 0;
    margin-left: 16px;
  }

  ${mediaBreakpoint.mobile} {
    & + & {
      word-break: break-all;
      margin-top: 7px !important;
      margin-left: 0 !important;
    }
  }
`;

const Logo = styled.img`
  max-height: 60px;
  width: 154px;
  margin-bottom: 18px;
`;

const IdentityContainer = styled.div`
  ${textSmall};
  grid-area: identity;
  display: flex;
  flex-direction: column;

  & * {
    color: var(--white);
  }
`;

const AboutContainer = styled.div`
  grid-area: about;
  display: flex;
  flex-direction: column;

  ${mediaBreakpoint.tablet} {
    margin-top: 20px;
  }
`;

const SocialLinksContainer = styled.div`
  grid-area: social;
  display: flex;
  flex-direction: row;
  align-self: start;

  ${mediaBreakpoint.tablet} {
    margin-top: 20px;
  }
`;

const SocialIcon = styled.a`
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: 1px solid var(--color-white);
  margin-top: 15px;

  & + & {
    margin-left: 8px;
    margin-top: 15px;
  }

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    transition: all 0.3s;
    border: 1px solid var(--color-orange-hover);
  }

  :hover * {
    transition: all 0.3s;
    fill: var(--color-orange-hover);
  }
`;

const InternalLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: links;

  ${mediaBreakpoint.tablet} {
    margin-top: 16px;
  }
`;

const LanguageDropdownContainer = styled.div`
  ${textP};
  grid-area: language;
  display: flex;
  flex-direction: column;

  ${mediaBreakpoint.mobile} {
    margin-top: 26px;
  }
`;

const LanguageDropdownWrapper = styled.div`
  margin-top: 8px;

  & > * {
    display: inline-block;
  }
`;

const PaymentsMethodContainer = styled.div`
  ${textP};
  grid-area: payments;

  ${mediaBreakpoint.tablet} {
    margin-top: 8px;
  }
`;

const PaymentsMethodIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  flex-wrap: wrap;
`;

const PaymentIcon = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;
`;
