import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'next-i18next';
import { DateTime } from 'luxon';
import { FormikContextType } from 'formik';
import { toast } from 'react-toastify';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { selectMobileNumberByID } from '@features/users/slices/userSlice';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1, textP, textPBold, textSmall } from '@features/ui/styles/textStyles';
import MobileNumberValidationForm, { MobileNumberValidationFormInputs } from '@features/account/components/MobileNumberValidationForm';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

interface ModalMobileValidationProps {
  mobileNumberID: string;
  closeModal: () => void,
}

const ModalMobileValidation = ({
  mobileNumberID,
  closeModal,
}: ModalMobileValidationProps) => {
  const { t } = useTranslation();
  const mobileNumber = useSelector(selectMobileNumberByID(mobileNumberID));
  const requestHandler = useRequestHandler();
  const [formikContext, setFormikContext] = useState<FormikContextType<MobileNumberValidationFormInputs>>(null);
  const secondsToWaitBetweenEachSMS = 60;

  const [sendingValidationCode, setSendingValidationCode] = useState(false);
  const [timeSmsSend, setTimeSmsSend] = useState(null);
  const [countDown, setCountDown] = useState(secondsToWaitBetweenEachSMS);

  const updateCountDown = () => {
    if (countDown <= 1) {
      setSendingValidationCode(false);
      setCountDown(secondsToWaitBetweenEachSMS);
    } else {
      const time = DateTime.now().diff(timeSmsSend, ['seconds']).seconds;
      setCountDown(Math.round(secondsToWaitBetweenEachSMS - time));
    }
  };

  useEffect(() => {
    if (sendingValidationCode) {
      const timer = setTimeout(() => {
        updateCountDown();
      }, 1000);
      return () => clearTimeout(timer);
    }
    return () => null;
  });

  const errorsCode = {
    'error.incorrectPhoneValidationCode': t('modalMobileValidation_submitErrorIncorrectPhoneValidationCode.message'),
    'error.maximumNumberOfValidationCodesSentReached': t('modalMobileValidation_submitErrorMaximumNumberOfValidationCodesSentReached.message'),
    'error.anErrorOccurrendWithTwilioAPI': t('modalMobileValidation_submitError.message'),
    'error.noRequestHasBeenMadeToValidateThisNumber': t('modalMobileValidation_noRequestHasBeenMadeToValidateThisNumber.message'),
  };

  const handleError = async (response: Response) => {
    const result = await response.json();
    const errorCode = result.code;
    toast(<Alert
      variant={ALERT_VARIANTS.ERROR}
      title={errorsCode[errorCode]}
      text=""
    />);
  };

  const sendValidationCode = async () => {
    const { response } = await requestHandler({
      request: api.profile.mobileNumbers.requestValidation({ mobileNumberID }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response) {
      if (response.ok) {
        setSendingValidationCode(true);
        setTimeSmsSend(DateTime.now());
      } else {
        await handleError(response);
      }
    }
  };

  const validateNumber = async () => {
    const { code } = formikContext.values;
    const { response } = await requestHandler({
      request: api.profile.mobileNumbers.validate({
        mobileNumberID,
        body: { code: code.toUpperCase() },
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response) {
      if (response.ok) {
        toast(<Alert
          variant={ALERT_VARIANTS.SUCCESS}
          title={t('modalMobileValidation_submitSuccess.message')}
          text=""
        />);
        closeModal();
      } else {
        await handleError(response);
      }
    }
  };

  const FooterForm = () => (
    <Footer>
      <Button
        text={t('cancel.message')}
        leftIcon={ICON_NAME.keyboardReturn}
        variant={BUTTON_VARIANT.secondary}
        onClick={closeModal}
      />
      <Button
        text={t('modalMobileValidation_submitButton.message')}
        leftIcon={ICON_NAME.check}
        onClick={() => formikContext.submitForm()}
        disabled={formikContext?.isSubmitting}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<FooterForm />} closeModal={closeModal}>
      <Container>
        <Title>{t('modalMobileValidation_title.message')}</Title>
        <Trans i18nKey="modalMobileValidation_description.message">
          <DescriptionBold />
          <Description />
        </Trans>
        <SendSMSWrapper>
          <Button
            text={t('modalMobileValidation_sendCode.message')}
            leftIcon={ICON_NAME.mobilePhone}
            onClick={sendValidationCode}
            disabled={sendingValidationCode}
          />
          {sendingValidationCode && (
            <WaitingTime>
              {t('modalMobileValidation_waitingTime.message', { seconds: countDown })}
            </WaitingTime>
          )}
        </SendSMSWrapper>
        <DescriptionBold>
          {t('modalMobileValidation_typeCode.message', { phoneNumber: mobileNumber.num })}
        </DescriptionBold>
        <StyledForm
          onSubmit={validateNumber}
          getFormikContext={setFormikContext}
        />
      </Container>
    </ModalTemplate>
  );
};

export default ModalMobileValidation;

const Title = styled.h1`
 ${textH1}
 margin-bottom: 28px;
`;

const Container = styled.div`
  max-width: 682px;
  display: flex;
  flex-direction: column;
  ${mediaBreakpoint.tablet} {
    max-width: unset;
  }
`;

const Footer = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
`;

const Description = styled.div`
  ${textP}
`;

const DescriptionBold = styled.div`
  ${textPBold}
`;

const SendSMSWrapper = styled.div`
  display: flex;
  margin: 20px 0 40px;
  flex-direction: column;
  align-items: center;
`;

const WaitingTime = styled.div`
  ${textSmall}
`;

const StyledForm = styled(MobileNumberValidationForm)`
  margin: 20px 0 40px;
`;
