import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import {
  Category,
  RANKINGS_LIST_TYPE,
  ResultAvailability,
  RESULTS_TYPE,
  SeriesList,
  TEventDakineResultsList,
  TEventResultsList,
  TSeriesResultsList,
  LiveResultsRankingsFromApi,
  LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS,
  LiveResultsContextFromApi,
  LiveResultsContext,
  LiveResultsOption,
} from '@root/src/types';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import api, { ListParameters } from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import {
  pushLiveResultsRankings,
  selectEventResultsAvailability,
  selectLiveResultsContext,
  selectResultsList,
  selectResultsListType,
  selectSeriesList,
  setEventResultsAvailability,
  setLiveResultsContext,
  setResultsCount,
  setResultsList,
  setResultsListOptions,
  setResultsListType,
  setResultType,
  setSeriesList,
} from '@features/results/slices/resultsSlice';
import {
  eventResultsCategoryFromApiToRankingListReducer,
  eventResultsDakineFromApiToRankingListReducer,
  eventResultsScratchFromApiToRankingListReducer,
  rankingsFromApiToReducer,
  seriesListFromApiToReducer,
  seriesResultsFromApiToRankingListReducer,
} from '@features/results/formatters';

class ResultsDataHandler extends InitDataHandler {
  getTopSeriesList: (serieId: number) => Promise<SeriesList | null> = async (serieId) => {
    const data = {
      locale: this.locale,
      serieId,
    };
    const { response } = await this.requestHandler({
      request: api.results.series.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      const seriesList = json.map(seriesListFromApiToReducer);

      // We move Dakine results at the end of the list
      // (so that they are not selected by default in the list)
      const seriesListWithoutDakine = seriesList
        .filter(({ serieResultsType }) => serieResultsType !== RANKINGS_LIST_TYPE.DAKINE);
      const seriesListOnlyDakine = seriesList
        .filter(({ serieResultsType }) => serieResultsType === RANKINGS_LIST_TYPE.DAKINE);
      const constSeriesListDakineAtEnd = seriesListWithoutDakine.concat(seriesListOnlyDakine);

      this.dispatch(setSeriesList(constSeriesListDakineAtEnd));
      return selectSeriesList(this.store.getState());
    }
    return null;
  };

  getEventResultsAvailability: (eventId: number) => Promise<ResultAvailability | null> = async (eventId) => {
    const data = {
      locale: this.locale,
      eventId,
    };
    const { response } = await this.requestHandler({
      request: api.results.event.rankings.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEventResultsAvailability(rankingsFromApiToReducer(json)));
      return selectEventResultsAvailability(this.store.getState());
    }
    return null;
  };

  setEventResultsListType: (
    resultsListType: RANKINGS_LIST_TYPE,
  ) => RANKINGS_LIST_TYPE = (resultsListType) => {
    this.dispatch(setResultsListType(resultsListType));
    return selectResultsListType(this.store.getState()) as RANKINGS_LIST_TYPE;
  };

  getEventLiveResultsContext: (eventId: number, categories: Category[])
  => Promise<LiveResultsContext> = async (eventId, categories) => {
    const data = {
      locale: this.locale,
      eventId,
    };
    const { response } = await this.requestHandler({
      request: api.results.live.events.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json: LiveResultsContextFromApi = await response.json();
      this.dispatch(setLiveResultsContext({
        liveResultsContextApi: json,
        categories,
      }));
      return selectLiveResultsContext(this.store.getState());
    }

    return null;
  };

  getEventLiveResultsRankings: (
    eventId: number,
    currentOption: LiveResultsOption,
    categories: Array<Category>,
  )
  => Promise<TEventResultsList> = async (eventId, currentOption, categories) => {
    const categoryFilter = currentOption.categoryId
      ? { filterBy: { [LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS.category]: currentOption.categoryId.toString() } }
      : {};

    const data = {
      locale: this.locale,
      eventId,
      rankingId: currentOption.wiclaxRankingId,
      ...categoryFilter,
    };

    const { response } = await this.requestHandler({
      request: api.results.live.events.rankings.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json: LiveResultsRankingsFromApi = await response.json();
      this.dispatch(pushLiveResultsRankings({
        liveResultsFromApi: json,
        currentOption,
        overrideCurrent: true,
        categories,
      }));
      return selectResultsList(this.store.getState()) as TEventResultsList;
    }

    return null;
  };

  getEventResultsList: (type: RANKINGS_LIST_TYPE, eventId: number, detailId?: number)
    => Promise<TEventResultsList | null> = async (type, eventId, detailId) => {
      let resultsList = null;
      let resultsListOptions = null;

      if (type === RANKINGS_LIST_TYPE.CATEGORIES) {
        resultsList = await this._getEventResultsCategoriesList(eventId);
        resultsListOptions = resultsList.map(rankingList => ({
          label: rankingList.name,
          value: rankingList._idMso,
        }));
        if (detailId != null) {
          resultsList = await this._getEventResultsCategoryAll(eventId, detailId);
        }
      } else if (type === RANKINGS_LIST_TYPE.SCRATCH) {
        resultsList = await this._getEventResultsScratchsList(eventId);
        resultsListOptions = resultsList.map(rankingList => ({
          label: rankingList.name,
          value: rankingList._idMso,
        }));
        if (detailId != null) {
          resultsList = await this._getEventResultsScratchDetail(eventId, detailId);
        }
      } else if (type === RANKINGS_LIST_TYPE.DAKINE) {
        const rankingLists = await this._getEventResultsDakineList(eventId);
        resultsListOptions = rankingLists.map(rankingList => ({
          label: rankingList.name,
          value: rankingList._idMso,
        }));
        if (detailId != null) {
          resultsList = await this._getEventResultsDakineDetail(eventId, detailId);
        } else {
          resultsList = await this._getEventResultsDakineDetail(eventId, rankingLists[0]._idMso);
        }
      }

      if (resultsList != null && resultsListOptions != null && type != null) {
        this.dispatch(setResultType(RESULTS_TYPE.EVENT));
        this.dispatch(setResultsListOptions(resultsListOptions));
        this.dispatch(setResultsList(resultsList));
        this.dispatch(setResultsListType(type));
        this.dispatch(setResultsCount(resultsList));
        return selectResultsList(this.store.getState()) as TEventResultsList;
      }
      return null;
    };

  getSeriesResultsList: (type: RANKINGS_LIST_TYPE, serieId: number, serieResultsId: string, detailId?: string)
    => Promise<TSeriesResultsList | null> = async (type, serieId, serieResultsId, detailId) => {
      if (type == null || serieId == null || serieResultsId == null) {
      // eslint-disable-next-line no-console
        console.error(`ResultsDataHandler error in getSeriesResultsList : one of the required keys are missing :
           type=${type} serieId=${serieId} serieResultsId=${serieResultsId}`);
        return null;
      }
      let resultsList = null;
      let resultsListOptions = null;

      if (type === RANKINGS_LIST_TYPE.CATEGORIES) {
        resultsList = await this._getSeriesResultsCategoriesList(serieId, serieResultsId);
        resultsListOptions = resultsList.map(rankingList => ({
          label: rankingList.name,
          value: rankingList._idMso,
        }));
        if (detailId != null) {
          resultsList = await this._getSeriesResultsCategoryDetail(serieId, serieResultsId, detailId);
        }
      } else if (type === RANKINGS_LIST_TYPE.SCRATCH) {
        resultsList = await this._getSeriesResultsScratchList(serieId, serieResultsId);
      } else if (type === RANKINGS_LIST_TYPE.DAKINE) {
        resultsList = await this._getSeriesResultsDakineList(serieId, serieResultsId);
        this.dispatch(setResultsListType(type));
      }

      if (resultsList != null) {
        this.dispatch(setResultType(RESULTS_TYPE.SERIES));
        if (resultsListOptions) {
          this.dispatch(setResultsListOptions(resultsListOptions));
        }
        this.dispatch(setResultsList(resultsList));
        this.dispatch(setResultsListType(type));
        return selectResultsList(this.store.getState()) as TSeriesResultsList;
      }
      return null;
    };

  _getEventResultsCategoriesList: (eventId: number) => Promise<TEventResultsList | null> = async (eventId) => {
    const data = {
      locale: this.locale,
      eventId,
    };
    const { response } = await this.requestHandler({
      request: api.results.event.categories.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      return json.map(eventResultsCategoryFromApiToRankingListReducer);
    }
    return null;
  }

  _getEventResultsCategoryAll: (eventId: number, categoryId: number)
    => Promise<TEventResultsList | null> = async (eventId, categoryId) => {
      const data = {
        locale: this.locale,
        eventId,
        categoryId,
      };
      const { response } = await this.requestHandler({
        request: api.results.event.categories.all({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return json.map(eventResultsCategoryFromApiToRankingListReducer);
      }
      return null;
    };

  _getEventResultsScratchsList: (
    eventId: number,
    parameters?: ListParameters,
  ) => Promise<TEventResultsList | null> = async (eventId, parameters = {}) => {
    const data = {
      locale: this.locale,
      eventId,
      ...parameters,
    };
    const { response } = await this.requestHandler({
      request: api.results.event.scratch.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      return json.map(eventResultsScratchFromApiToRankingListReducer);
    }
    return null;
  };

  _getEventResultsScratchDetail: (
    eventId: number,
    scratchId: number,
    parameters?: ListParameters,
  )
    => Promise<TEventResultsList | null> = async (eventId, scratchId, parameters = {}) => {
      const data = {
        locale: this.locale,
        eventId,
        scratchId,
        ...parameters,
      };
      const { response } = await this.requestHandler({
        request: api.results.event.scratch.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return [eventResultsScratchFromApiToRankingListReducer(json)];
      }
      return null;
    };

    _getEventResultsDakineList: (eventId: number) => Promise<TEventResultsList | null> = async (eventId) => {
      const data = {
        locale: this.locale,
        eventId,
      };
      const { response } = await this.requestHandler({
        request: api.results.event.rankings.list({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        // TODO: assess if this overwrite is really needed
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return json
          .filter(ranking => !ranking.resultsType) // TODO-DAKINE: replace this once DAKINE imports have their own resultsType
          .map(eventResultsDakineFromApiToRankingListReducer);
      }
      return null;
    };

  _getEventResultsDakineDetail: (eventId: number, importId: number)
    => Promise<TEventDakineResultsList | null> = async (eventId, importId) => {
      const data = {
        locale: this.locale,
        eventId,
        importId,
      };
      const { response } = await this.requestHandler({
        request: api.results.event.rankings.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        // TODO: assess if this overwrite is really needed
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return [eventResultsDakineFromApiToRankingListReducer(json)];
      }
      return null;
    };

  _getSeriesResultsCategoriesList: (serieId: number, serieResultsId: string) => Promise<TSeriesResultsList | null>
    = async (serieId, serieResultsId) => {
      const data = {
        locale: this.locale,
        serieId,
        serieResultsId,
      };
      const { response } = await this.requestHandler({
        request: api.results.series.categories.list({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return json.map(e => seriesResultsFromApiToRankingListReducer(RANKINGS_LIST_TYPE.CATEGORIES, e));
      }
      return null;
    }

  _getSeriesResultsCategoryDetail: (serieId: number, serieResultsId: string, categoryId: string)
    => Promise<TSeriesResultsList | null> = async (serieId, serieResultsId, categoryId) => {
      const data = {
        locale: this.locale,
        serieId,
        serieResultsId,
        categoryId,
      };
      const { response } = await this.requestHandler({
        request: api.results.series.categories.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return [seriesResultsFromApiToRankingListReducer(RANKINGS_LIST_TYPE.CATEGORIES, json)];
      }
      return null;
    };

  _getSeriesResultsScratchList: (serieId: number, serieResultsId: string) => Promise<TSeriesResultsList | null>
    = async (serieId, serieResultsId) => {
      const data = {
        locale: this.locale,
        serieId,
        serieResultsId,
      };
      const { response } = await this.requestHandler({
        request: api.results.series.scratch.list({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return [seriesResultsFromApiToRankingListReducer(RANKINGS_LIST_TYPE.SCRATCH, json)];
      }
      return null;
    }

  _getSeriesResultsDakineList: (serieId: number, serieResultsId: string) => Promise<TSeriesResultsList | null>
    = async (serieId, serieResultsId) => {
      const data = {
        locale: this.locale,
        serieId,
        serieResultsId,
      };

      const { response } = await this.requestHandler({
        request: api.results.series.dakine.list({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
        overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
      });

      if (response && response?.ok) {
        const json = await response.json();
        return [seriesResultsFromApiToRankingListReducer(RANKINGS_LIST_TYPE.DAKINE, json)];
      }
      return null;
    }
}

export default ResultsDataHandler;

export const useResultDataHandler = () => {
  const store = useStore();
  const requestHandler = useRequestHandler();
  const router = useRouter();

  return new ResultsDataHandler({
    store,
    requestHandler,
    router,
  });
};
