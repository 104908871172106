import styled from 'styled-components';
import { textSmall } from '@features/ui/styles/textStyles';

const Tag = styled.div`
  background: var(--color-blue-light);
  border-radius: 8px;
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${textSmall};
  padding: 6px 12px;
  color: var(--color-grey4);
`;

export default Tag;
