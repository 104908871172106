import { Subscription, Subscriptions, TEAM_MEMBER_ROLE } from '@root/src/types';
import { formatPhoneNumberFormToApi, removeUndefs } from '@utils/gobal';
import { eventCategoryFromApiToReducer } from '@features/eventDetail/formatters';
import { profileFromApiToReducer } from '@features/users/formatters';
import { resultFromApiToReducer } from '@features/results/formatters';
import { ServicesValues } from './slices/formSubscriptionSlice';

export const createApiSubscriptionSolo = (
  servicesGroupsWithTeamMate,
  athlete: number,
  categoryId: number,
  eventId: number,
  club: string,
  insurance?: boolean,
  vip?: string,
  emergencyNumber?: string,
  license?: string,
) => ({
  athlete: `mso:${athlete}`,
  event: `mso:${eventId}`,
  category: `mso:${categoryId}`,
  ...(vip ? { vipCode: vip } : {}),
  serviceGroups: servicesGroupsWithTeamMate,
  ...(club ? { club } : {}),
  ...(insurance != null && { insurance }),
  ...(emergencyNumber ? { emergencyNumber } : {}),
  ...(license ? { license } : {}),
});

export const createApiSubscriptionTeam = (
  servicesGroupsWithTeamMate,
  categoryId: number,
  eventId: number,
  club: string,
  teamMatesIds: number[],
  insurance?: boolean,
  vip?: string,
  emergencyNumber?: string,
  license?: string,
) => {
  const leaderId = teamMatesIds[0];
  const base = createApiSubscriptionSolo(
    servicesGroupsWithTeamMate,
    leaderId,
    categoryId,
    eventId,
    club,
    insurance,
    vip,
    emergencyNumber,
    license,
  );
  const team = {
    [TEAM_MEMBER_ROLE.LEAD]: `mso:${leaderId}`,
    ...teamMatesIds.slice(1, teamMatesIds.length)
      .map((id, index) => ({ [TEAM_MEMBER_ROLE[`MEMBER_${index + 2}`]]: `mso:${id}` }))
      .reduce((a, b) => ({
        ...a,
        ...b,
      }), {}),
  };
  return {
    ...base,
    team,
  };
};

export const createApiSubscriptionPack = (
  serieId: number,
  packId: number,
  athleteId: number,
  items: any,
) => ({
  serie: `mso:${serieId}`,
  pack: `mso:${packId}`,
  athlete: `mso:${athleteId}`,
  items,
});

export const createApiServicesGroups = (servicesValues?: ServicesValues, teamMateId?: number) => Object
  .entries(servicesValues || {}).map(([groupId, services]) => ({
    group: `mso:${groupId.replace('group_', '')}`,
    ...(teamMateId && ({ teamMember: `mso:${teamMateId}` })),
    services: Object
      .entries(services)
      .filter(([, service]) => service != null && service !== '')
      .map(([serviceId, service]) => ({
        service: `mso:${serviceId.replace('service_', '')}`,
        value: service,
      })),
  }));

export const createApiRegister = (profileValues, addressValues, accountValues) => ({
  firstName: profileValues.firstName,
  lastName: profileValues.lastName,
  birthDateObj: {
    day: profileValues.birthdateDay,
    month: profileValues.birthdateMonth,
    year: profileValues.birthdateYear,
  },
  gender: profileValues.gender,
  nationality: profileValues.nationality,
  legAddress: {
    address: addressValues.address,
    country: addressValues.country,
    postalcode: addressValues.postalCode,
    locality: addressValues.locality,
    department: addressValues.canton,
  },
  language: addressValues.language,
  club: addressValues.club,
  legPhone: formatPhoneNumberFormToApi(addressValues.phoneNumber),
  login: accountValues.username.trim(),
  email: accountValues.email,
  password: accountValues.password,
  acceptcg: accountValues.termOfUse,
  acceptPrivacyPolicy: accountValues.privacyPolicy,
  newsletter: accountValues.newsletter,
});

export const subscriptionFromApiToReducer = (registration: any): Subscription => removeUndefs({
  _idMso: parseInt(registration._idMso, 10),
  accessLevel: registration.accessLevel,
  registerin: registration.registerin,
  athlete: registration.athlete || typeof registration.athlete !== 'string'
    ? profileFromApiToReducer(registration.athlete)
    : null,
  category: eventCategoryFromApiToReducer(registration.category),
  club: registration.club,
  emergencyNumber: registration.emergencyNumber,
  license: registration.license,
  teamname: registration.teamname,
  _id: registration._id,
  registrationStatus: registration.registrationStatus,
  fidOrder: registration.fid_order,
  insurance: registration.insurance,
  registrationMadeBy: registration.registrationMadeBy ? {
    _idMso: parseInt(registration.registrationMadeBy._idMso, 10),
    fullName: registration.registrationMadeBy.fullName,
  } : null,
  team: registration.team?.map(teamMate => ({
    teamMember: {
      _idMso: parseInt(teamMate.teamMember?._idMso, 10),
      fullName: `${teamMate.teamMember?.firstName} ${teamMate.teamMember?.lastName}`,
    },
    role: teamMate.role,
  })),
  order: registration.order,
  bib: registration.bib,
  chip: registration.chip,
  result: subscriptionResultsFromApiToReducer(registration.results),
});

const subscriptionResultsFromApiToReducer = (results: any) => {
  if (
    results != null
    && results[0] != null
    && results[0].results != null
    && results[0].results[0] != null
  ) {
    const resultValues = results[0].results;
    return resultFromApiToReducer(resultValues);
  }
  return null;
};

export const subscriptionsFromApiToReducer = (registrations: any): Subscriptions => removeUndefs({
  items: registrations.items.map(registration => subscriptionFromApiToReducer(registration)),
  pages: registrations.pages,
  total: registrations.total,
});
