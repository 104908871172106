import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { GENDER } from '@root/src/types';
import { countryList } from '@services/enum/countries';
import { setYupLocale } from '@services/translation/translatedYup';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import MultiInputLine from '@features/ui-input/formik/MultiInputLine';
import InputSelect from '@features/ui-input/components/InputSelect';
import { textP } from '@features/ui/styles/textStyles';
import UserProfileFormValidationSchema from '@features/users/components/UserProfileFormSchema';
import GetFormik from '@features/ui-input/formik/GetFormik';

export interface UserProfileFormInputs {
  firstName: string,
  lastName: string,
  birthdateDay: number,
  birthdateMonth: number,
  birthdateYear: number,
  gender: GENDER,
  nationality: string,
}

export const getGenderChoice = (t) => [
  {
    label: t('userProfileForm_genderLabelMale.message'),
    value: GENDER.MALE,
  }, {
    label: t('userProfileForm_genderLabelFemale.message'),
    value: GENDER.FEMALE,
  },
];

const defaultCountry = 'SUI';

interface UserProfileFormProps {
  initialValuesUserProfile: UserProfileFormInputs,
  initialErrors?: { [key: string]: string },
  setFormikContextProfile?: (context: FormikProps<UserProfileFormInputs>) => void,
  handleSubmit?: () => void,
  description: string,
}

const UserProfileForm = ({
  initialValuesUserProfile,
  initialErrors,
  setFormikContextProfile,
  handleSubmit,
  description,
}: UserProfileFormProps) => {
  const [validationDateError, setValidationDateError] = useState('');
  const { t } = useTranslation();
  const router = useRouter();
  setYupLocale(router.locale);

  useEffect(() => {
    const firstInput = document.getElementById('mso_UserProfileForm_firstInput');
    if (firstInput) firstInput.focus();
  }, []);

  const genderChoice = getGenderChoice(t);
  const initialValues = initialValuesUserProfile ?? {
    firstName: '',
    lastName: '',
    birthdateDay: '',
    birthdateMonth: '',
    birthdateYear: '',
    gender: genderChoice[0].value,
    nationality: countryList(t)
      .find(country => country.value === defaultCountry).value,
  };

  const validationSchema = UserProfileFormValidationSchema(setValidationDateError, t);

  return (
    <UserProfileContainer>
      <UserProfileDescription>{description}</UserProfileDescription>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount
        initialErrors={initialErrors}
      >
        {(context) => {
          const {
            birthdateDay,
            birthdateMonth,
            birthdateYear,
          } = context.errors;
          const hasSpecificDateError = `${birthdateDay}${birthdateMonth}${birthdateYear}`.trim() !== '';
          return (
            <Form>
              <GetFormik getFormikContext={setFormikContextProfile} />
              <InputLine
                label={t('userProfileForm_firstnameLabel.message')}
                name="firstName"
                as={InputText}
                showRequiredChip
                tooltipSpace
                labelMinWidth="202px"
                id="mso_UserProfileForm_firstInput"
              />
              <InputLine
                label={t('userProfileForm_lastnameLabel.message')}
                name="lastName"
                as={InputText}
                showRequiredChip
                tooltipSpace
                labelMinWidth="202px"

              />
              <MultiInputLine
                tooltipSpace
                label={t('userProfileForm_birthdateLabel.message')}
                tooltip={t('userProfileForm_birthdateTooltip.message')}
                labelMinWidth="202px"
                spaceInterInput="4px"
                globalError={!hasSpecificDateError && validationDateError}
                inputs={[
                  {
                    as: InputText,
                    showRequiredChip: true,
                    name: 'birthdateDay',
                    type: 'number',
                  }, {
                    as: InputText,
                    showRequiredChip: true,
                    name: 'birthdateMonth',
                    type: 'number',

                  }, {
                    as: InputText,
                    showRequiredChip: true,
                    name: 'birthdateYear',
                    type: 'number',
                    inputWrapperClassName: 'inputBirthdateYear',
                  },
                ]}
              />
              <InputLine
                label={t('userProfileForm_genderLabel.message')}
                as={InputSelect}
                showRequiredChip
                name="gender"
                options={genderChoice}
                tooltip={t('userProfileForm_genderTooltip.message')}
                labelMinWidth="202px"
              />
              <InputLine
                label={t('userProfileForm_nationalityLabel.message')}
                as={InputSelect}
                showRequiredChip
                name="nationality"
                options={countryList(t)}
                tooltipSpace
                labelMinWidth="202px"
              />
            </Form>
          );
        }}
      </Formik>
    </UserProfileContainer>
  );
};

export default UserProfileForm;

const UserProfileDescription = styled.div`
  ${textP};
  margin-bottom: 20px;
`;
const UserProfileContainer = styled.div`
  .inputBirthdateYear{
    flex: 2;
  }
`;
