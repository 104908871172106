import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { mediaBreakpoint, RESPONSIVE, ResponsiveWrapper, useResponsive } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textP } from '@features/ui/styles/textStyles';
import Menu from '@features/ui-layout/components/Menu';

const Header = () => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  const router = useRouter();

  const [show, setShow] = useState(true);
  const [showShadow, setShowShadow] = useState(false);
  let previousScrollTop = 0;
  const onScroll = (e) => {
    const scroll = window.pageYOffset || e?.target?.documentElement?.scrollTop;
    if (scroll < 1) {
      setShowShadow(false);
    } else {
      setShowShadow(true);
    }
    if (scroll < 20 || scroll < previousScrollTop) {
      setShow(true);
    } else {
      setShow(false);
    }
    previousScrollTop = scroll;
  };
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const isInHomePage = router.route === getRoute.home().pathname;
  const showContrastColor = isInHomePage && !showShadow;

  return (
    <Wrapper show={show} showShadow={showShadow}>
      <ResponsiveWrapper>
        <Content>
          <Center>
            <Link href={getRoute.home()}>
              <a>
                {showContrastColor
                  ? <Logo src="/images/mso-logo-white.png" />
                  : <Logo src="/images/mso-logo-black.png" />}
              </a>
            </Link>
            {responsive === RESPONSIVE.DESKTOP
            && <Description showContrastColor={showContrastColor}>{t('header_description.message')}</Description>}
          </Center>
          <Center>
            {responsive === RESPONSIVE.DESKTOP ? (<Menu showContrastColor={showContrastColor} />) : (
              <Button
                variant={showContrastColor ? BUTTON_VARIANT.menuContrast : BUTTON_VARIANT.menu}
                text={t('header_menu.message')}
                leftIcon={ICON_NAME.menu}
                url={getRoute.openModal(router).menu()}
              />
            )}
          </Center>
        </Content>
      </ResponsiveWrapper>
    </Wrapper>
  );
};
export default Header;

const Wrapper = styled.header<{ show: boolean; showShadow: boolean; }>`
  width: 100%;
  position: fixed;
  padding-top: 16px;
  padding-bottom: 16px;
  top: 0;
  background: ${({ showShadow }) => (showShadow ? 'var(--color-white)' : 'none')};
  box-shadow: ${({ showShadow }) => (showShadow ? 'var(--menu-shadow)' : 'none')};
  transform: translateY(${({ show }) => (show ? '0' : '-120%')});
  transition: var(--base-transition);
  z-index: var(--z-index-header);
  height: var(--menu-height);
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Center = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled.img`
  height: 69px;

  ${mediaBreakpoint.tablet} {
    height: 44px;
  }

  ${mediaBreakpoint.mobile} {
    height: 32px;
  }
`;
const Description = styled.span<{ showContrastColor: boolean }>`
  ${textP};
  margin-left: 54px;
  white-space: break-spaces;
  color: ${({ showContrastColor }) => (showContrastColor ? 'var(--color-white)' : 'var(--color-grey4)')};
`;
