import React from 'react';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Subscription } from '@root/src/types';
import { getResultsRoute } from '@root/src/hooks/useEventInfo';
import downloadPDF from '@utils/downloadPDF';
import { mediaBreakpoint } from '@services/responsiveProvider';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import ResultChip, { RESULT_CHIP_VARIANTS } from '@features/ui/components/ResultChip';
import { textH3 } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { selectCurrentSubscription } from '@features/subscriptions/slices/subscriptionDetailServerSlice';
import Card from '@features/ui/components/Card';

interface SubscriptionResultProps {
  className?: string;
  subscription?: Subscription
}

const SubscriptionResult = ({ className, subscription }: SubscriptionResultProps) => {
  const { t } = useTranslation();
  // TODO: Make a raw and pass an event ID on the specific one.
  const currentSubscription = subscription ?? useSelector(selectCurrentSubscription);
  const requestHandler = useRequestHandler();
  const router = useRouter();

  if (currentSubscription.athlete?.fullName == null) {
    return (<></>);
  }

  const { diploma } = currentSubscription.category;

  return (
    <StyledStackableCard className={className}>
      <Content>
        <Title>{t('subscriptionResult_title.message', { name: currentSubscription.athlete?.fullName })}</Title>
        <ButtonWrapper>
          {/* TODO: Add missing urls in buttons when feature available */}
          {diploma && diploma > 0 && (
            <Button
              text={t('subscriptionResult_buttonDiploma.message')}
              leftIcon={ICON_NAME.assignmentInd}
              variant={BUTTON_VARIANT.secondary}
              onClick={() => {
                const request = requestHandler({
                  request: api.subscription.registrations.diplomaPdf({
                    subscriptionId: currentSubscription._id,
                    locale: router.locale,
                  }),
                });
                const fileName = `${t('subscriptionResult_buttonDiploma.message')}.pdf`;
                downloadPDF(request, fileName);
              }}
            />
          )}
          {/* <Button */}
          {/*  text={t('subscriptionResult_buttonLivePhoto.message')} */}
          {/*  leftIcon={ICON_NAME.photoCamera} */}
          {/*  variant={BUTTON_VARIANT.secondary} */}
          {/*  disabled */}
          {/* /> */}
          <Button
            text={t('subscriptionResult_buttonResults.message')}
            leftIcon={ICON_NAME.militaryTech}
            variant={BUTTON_VARIANT.secondary}
            url={getResultsRoute(currentSubscription.category.event)}
          />
          {/* <Button */}
          {/*  text={t('subscriptionResult_buttonSeriesRanking.message')} */}
          {/*  leftIcon={ICON_NAME.emojiEvent} */}
          {/*  variant={BUTTON_VARIANT.secondary} */}
          {/*  disabled */}
          {/* /> */}
        </ButtonWrapper>
      </Content>
      <Footer>
        <StyledResultChip variant={RESULT_CHIP_VARIANTS.left} result={currentSubscription.result?.position} />
        <TimerWrapper>
          <Icon name={ICON_NAME.timer} color="var(--color-grey3)" />
          <div>{currentSubscription.result?.timings ? currentSubscription.result?.timings[0] : ''}</div>
        </TimerWrapper>
        <CategoryWrapper>
          <Icon name={ICON_NAME.playlistAddCheck} color="var(--color-grey3)" />
          <div>{`${currentSubscription.category.name} (${currentSubscription.category.distance})`}</div>
        </CategoryWrapper>
        {/*  TODO: Social share component */}
      </Footer>
    </StyledStackableCard>
  );
};

export default SubscriptionResult;

const StyledStackableCard = styled(Card)`
  --card-padding: 20px;
  padding: var(--card-padding) var(--card-padding) 0;
  position: relative;
  border-width: 0;

  &:hover {
    border-width: 0;
  }
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 12px;

  ${mediaBreakpoint.mobile} {
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const Title = styled.h3`
  ${textH3};
  flex: 1;
  margin-right: 12px;
  min-width: 200px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-bottom: 12px;
  }

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const StyledResultChip = styled(ResultChip)`
  position: relative;
  left: calc(var(--card-padding) * (-1));
  grid-area: result;
`;

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: time;

  > :first-child {
    margin-right: 8px;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: category;

  > :first-child {
    margin-right: 8px;
  }

  ${mediaBreakpoint.mobile} {
    margin-bottom: 8px;
  }
`;

const Footer = styled.div`
  display: grid;
  grid-template-areas: "result time category social";
  grid-template-columns: auto minmax(auto, 1fr) minmax(1fr, auto) auto;
  grid-column-gap: 20px;
  grid-row-gap: 12px;

  ${mediaBreakpoint.mobile} {
    grid-template-columns: auto 1fr;
    grid-template-areas: 
       "time time"
       "category category"
       "result social";

  }
`;
