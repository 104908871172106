import { DateTime } from 'luxon';
import { Event, Serie, Sport } from '@root/src/types';
import { QUERY_API_DATE_FORMAT_LUXON } from '@utils/dates';
import api, { EventsListApiParameters, EVENT_LIST_MODELS } from '@services/api';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import {
  selectEventsList,
  selectSponsoredEvents,
  selectNumberOfEventsToExplore,
  selectSites,
  selectSports,
  setEvents,
  setSponsoredEvents,
  setNumberOfEventsToExplore,
  setSites,
  setSports,
} from './slices/eventListServerSliceState';

class GetEventsData extends InitDataHandler {
  getEvents: (parameters?: EventsListApiParameters, forceMerge?: boolean) => Promise<Array<Event | Serie> | null>
  = async (
    parameters, forceMerge = false,
  ) => {
    let data: any = { locale: this.locale };
    let page = 0;
    if (parameters != null) {
      data = {
        ...data,
        ...parameters,
      };
      if (parameters.page) page = parameters.page;
    }
    if (data.q == null) {
      data.q = '*';
    }
    const { response } = await this.requestHandler({
      request: api.events.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEvents({
        apiEvents: json,
        hydrateWithMerge: parameters?.page != null,
        page,
        forceMerge,
      }));
      return selectEventsList(this.store.getState());
    }
    return null;
  };

  getSponsoredEvents: () => Promise<Array<Event> | null> = async () => {
    const data = {
      locale: this.locale,
      q: '*',
      filtered: [
        {
          id: 'isSponsoredNow',
          value: true,
        },
      ],
      sorted: [
        {
          id: 'date',
          desc: false,
        },
      ],
      models: [EVENT_LIST_MODELS.baseRace],
    };
    const { response } = await this.requestHandler({
      request: api.events.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSponsoredEvents(json.items));
      return selectSponsoredEvents(this.store.getState());
    }
    return null;
  };

  getNumberOfEventsToExplore: () => Promise<Number | null> = async () => {
    const today = DateTime.now();

    const { response } = await this.requestHandler({
      request: api.events.list({
        ctx: this.serverSideContext,
        locale: this.locale,
        q: '*',
        pageSize: 0,
        filteredRange: [
          {
            id: 'date',
            type: 'date',
            valueGte: today.minus({ years: 1 }).toFormat(QUERY_API_DATE_FORMAT_LUXON),
            valueLte: today.plus({ years: 1 }).toFormat(QUERY_API_DATE_FORMAT_LUXON),
          },
        ],
        models: [EVENT_LIST_MODELS.baseRace],
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      const { total } = json;
      this.dispatch(setNumberOfEventsToExplore(total));
      return selectNumberOfEventsToExplore(this.store.getState());
    }
    return null;
  };

  getSites: () => Promise<Array<string>> = async () => {
    const { response } = await this.requestHandler({
      request: api.sites.list({
        ctx: this.serverSideContext,
        locale: this.locale,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSites(json));
      return selectSites(this.store.getState());
    }

    return [];
  };

  getSports: () => Promise<Array<Sport>> = async () => {
    const { response } = await this.requestHandler({
      request: api.sports.list({
        ctx: this.serverSideContext,
        locale: this.locale,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setSports(json));
      return selectSports(this.store.getState());
    }

    return [];
  };
}

export default GetEventsData;
