import styled from 'styled-components';
import React from 'react';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';

interface ModalTemplateProps {
  children: React.ReactNode;
  footer: React.ReactNode;
  closeModal: () => void;
}

const ModalTemplate = ({
  children,
  footer,
  closeModal,
}: ModalTemplateProps) => (
  <Wrapper>
    <Content>
      <Button
        leftIcon={ICON_NAME.clear}
        variant={BUTTON_VARIANT.link}
        onClick={() => closeModal()}
        className="modalTemplate_close-button"
      />
      {children}
    </Content>
    <Footer>
      {footer}
    </Footer>
  </Wrapper>
);
export default ModalTemplate;

const Wrapper = styled.div`
  background: var(--page-background-color);
`;

const Content = styled.div`
  --template-modal-footer-height: 84px;
  padding: 60px 117px 0;
  position: relative;
  z-index: var(--z-index-modal-content-template-body);
  .modalTemplate_close-button {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  ${mediaBreakpoint.tablet} {
    padding-bottom: var(--template-modal-footer-height);
  }
  ${mediaBreakpoint.mobile} {
    padding: 20px 21px var(--template-modal-footer-height);
    .modalTemplate_close-button {
      top: 20px;
      right: 20px;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  padding: 0 117px 60px;
  background: var(--color-white);
  z-index: var(--z-index-modal-content-template-footer);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mediaBreakpoint.tablet} {
    position: fixed;
    left: 0;
    bottom: 0;
    box-shadow: 0 0 5px rgba(118, 141, 152, 0.25);
    padding: 20px 117px;
  }
  ${mediaBreakpoint.mobile} {
    padding: 20px 21px;
  }
`;
