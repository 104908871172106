import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { RESPONSIVE, useResize, useResponsive } from '@services/responsiveProvider';

interface OwnProps {
  component: ReactElement;
  closeModal: () => void;
  fullScreen?: boolean
  showConfirm?: boolean
}
const Modal = ({ component, closeModal, fullScreen = false, showConfirm = false } : OwnProps) => {
  useResize(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  const responsive = useResponsive();
  const isFull = responsive !== RESPONSIVE.DESKTOP || fullScreen;
  return (
    <Overlay>
      <ScrollableView
        id="mso_modalContent"
        onClick={(e) => onClickOverlay(e, closeModal, showConfirm)}
      >
        <Content full={isFull}>
          {component}
        </Content>
      </ScrollableView>
    </Overlay>
  );
};
export default React.memo(Modal);

function onClickOverlay(e, closeModal, showConfirm) {
  if (e.target !== e.currentTarget) {
    return;
  }
  closeModal(showConfirm);
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  // Hack for iPhone when the bottom bar is show
  // The calc is equal to 100vh but the 100vh is wrong when the bar is show
  // The value of window.innerHeight is always right so we re-calc 
  // what is 1vh relative to the viewport
  --height-100vh-hack: calc(var(--vh, 1vh) * 100);
  
  width: 100%;
  height: var(--height-100vh-hack); 
  overflow: hidden;
  
  background: var(--modal-background);
  z-index: var(--z-index-modal-overlay);
`;
const ScrollableView = styled.div`
  overflow: auto;
  height: 100%;
  width: 100vw;
  cursor: pointer;
`;
const Content = styled.div<{ full: boolean; }>`
  cursor: initial;
  position: relative;
  margin: ${({ full }) => (full ? '0' : '75px auto 30px')};
  
  width: ${({ full }) => (full ? '100%' : 'fit-content')};
  min-width: 200px;
  max-width: ${({ full }) => (full ? '100%' : '80%')};
  height: auto;
  min-height: ${({ full }) => (full ? 'var(--height-100vh-hack)' : '200px')};

  overflow: hidden;
  
  border-radius: ${({ full }) => (full ? '0' : '20px')};;
  background: var(--color-white);
  z-index: var(--z-index-modal-content);
`;
