import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikContextType } from 'formik';
import { useTranslation } from 'next-i18next';
import { toast } from 'react-toastify';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1 } from '@features/ui/styles/textStyles';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import FormAskUsername, { FormAskUsernameInputs } from '@features/account/components/RequestPasswordReset/FormAskUsername';
import SubmitConfirmed from '@features/account/components/RequestPasswordReset/SubmitConfirmed';
import FooterSubmitConfirmed from '@features/account/components/RequestPasswordReset/FooterSubmitConfirmed';
import FooterSubmitForm from '@features/account/components/RequestPasswordReset/FooterSubmitForm';

interface ModalPasswordResetTemplateProps {
  closeModal: () => void;
}

const ModalRequestResetPassword = ({ closeModal }: ModalPasswordResetTemplateProps) => {
  const [isPasswordRequestSend, setIsPasswordRequestSend] = useState<boolean>(false);
  const [formikContext, setFormikContext] = useState<FormikContextType<FormAskUsernameInputs>>(null);
  const [requestResponse, setRequestResponse] = useState(null);
  const requestHandler = useRequestHandler();
  const { t } = useTranslation();

  const onSubmit = async () => {
    const { response } = await requestHandler({
      request: api.auth.password.requestReset(
        { body: { login: formikContext.values.username } },
      ),
      overwrite: {
        [STATUS_RANGE.CLIENT_ERROR]: async (response) => {
          toast(<Alert
            variant={ALERT_VARIANTS.ERROR}
            title={t('modalRequestPasswordResetTemplate_submitFormError.message')}
            text=""
          />);
          return response;
        },
      },
    });

    if (response && response?.ok) {
      setRequestResponse(await response.json());
      setIsPasswordRequestSend(true);
    }
  };

  const content = isPasswordRequestSend
    ? <SubmitConfirmed email={requestResponse.email} />
    : <FormAskUsername setFormikContext={setFormikContext} onSubmit={onSubmit} />;

  const footer = isPasswordRequestSend
    ? <FooterSubmitConfirmed />
    : <FooterSubmitForm formikContext={formikContext} />;

  return (
    <ModalTemplate footer={footer} closeModal={closeModal}>
      <Wrapper>
        <Title>{t('modalRequestPasswordResetTemplate_title.message')}</Title>
        {content}
      </Wrapper>
    </ModalTemplate>
  );
};

export default ModalRequestResetPassword;

const Title = styled.h1`
  ${textH1}
  margin-bottom: 32px;
`;

const Wrapper = styled.div`
  max-width: 682px;
  margin-bottom: 40px;
`;
