import { TFunction } from 'next-i18next';
import BaseSchema from 'yup/lib/schema';
import { FieldType, Service, ServiceGroup } from '@root/src/types';
import yup from '@services/translation/translatedYup';
import { phoneNumberFieldValidation } from '@features/users/components/UserAddressFormSchema';

export const servicesValidationSchemaFactory = (service: Service, t: TFunction): BaseSchema => {
  switch (service.fieldType) {
    case FieldType.Checkbox: {
      let schema = yup.boolean();
      if (service.mandatory) {
        schema = schema.oneOf([true], t('servicesValidationSchemaFactory_requiredCheckboxErrorMessage.message'));
      } else {
        schema = schema.nullable();
      }
      return schema.label(t('servicesValidationSchemaFactory_labelThis.message'));
    }
    case FieldType.Int: {
      let schema = yup.number()
        .integer()
        .moreThan(-1)
        .nullable()
        .typeError(t('servicesValidationSchemaFactory_quantityErrorMessage.message'))
        .label(t('servicesValidationSchemaFactory_labelThis.message'));
      if (service.mandatory) {
        schema = schema.required();
      }
      return schema;
    }
    case FieldType.Select: {
      let schema = yup.string().label(t('servicesValidationSchemaFactory_labelThis.message'));
      if (service.mandatory) {
        schema = schema.required();
      }
      return schema;
    }
    case FieldType.Input: {
      let schema = yup.string().label(t('servicesValidationSchemaFactory_labelThis.message'));
      if (service.mandatory) {
        schema = schema.required();
      }
      if (service.validation) {
        schema = schema.matches(
          RegExp(service.validation.slice(1, -1)),
          { message: t('servicesValidationSchemaFactory_validationError.message', { example: service.tooltip }) }
        );
      }
      return schema;
    }
    case FieldType.Phone: {
      return phoneNumberFieldValidation(t, service.mandatory).phoneNumber;
    }
    default:
      return yup.mixed().label(t('servicesValidationSchemaFactory_labelThis.message'));
  }
};

export default servicesValidationSchemaFactory;

// Create a valid yup schema from the services
export const generateSchema = (servicesGroups: ServiceGroup[], t: TFunction) => {
  const object = servicesGroups.map(group => {
    const services = group
      .services
      .map(service => ({ [`service_${service._idMso}`]: servicesValidationSchemaFactory(service, t) }))
      .reduce((a, b) => ({
        ...a,
        ...b,
      }), {});
    return ({ [`group_${group._idMso}`]: yup.object().shape(services) });
  }).reduce((a, b) => ({
    ...a,
    ...b,
  }), {});

  return object;
};
