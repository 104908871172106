import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { mapTagToTranslationKey, TAGS } from '@root/src/hooks/useEventInfo';
import { QUERY_ENUM } from '@services/routeList';
import TagSelectable from '@features/ui/components/TagSelectable';
import { pushNewEventList } from '@features/eventList/utils';

interface TagFiltersProps {
  className?: string;
}

const TagFilters = ({ className }: TagFiltersProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const tagSelected = router.query[QUERY_ENUM.filterTag] ?? Object.keys(TAGS)[0];

  return (
    <Container className={className}>
      {Object.keys(TAGS).map(tag => (
        <TagSelectable
          key={tag}
          selected={tagSelected === tag}
          onClick={() => pushNewEventList(router, { filterTag: tag })}
        >
          {t(mapTagToTranslationKey[tag])}
        </TagSelectable>
      ))}
    </Container>
  );
};
export default TagFilters;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-top: 4px;
  }
`;
