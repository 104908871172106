import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik, FormikContextType } from 'formik';
import { toast } from 'react-toastify';
import { ACCESS_LEVEL } from '@root/src/types';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import GetFormik from '@features/ui-input/formik/GetFormik';
import InputLine from '@features/ui-input/formik/InputLine';
import InputCheckbox from '@features/ui-input/components/InputCheckbox';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

export interface EditAccessInputs {
  accessId: string,
  accessAuthorization: boolean
}

interface EditAccessFormProps {
  initialValues: EditAccessInputs,
  getFormikContext?: (formik: FormikContextType<EditAccessInputs>) => void
}

const EditAccessForm = ({
  initialValues,
  getFormikContext,
}: EditAccessFormProps) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();

  const updateAccess = async (values: EditAccessInputs) => {
    const { response } = await requestHandler({
      request: api.profile.access.update(
        {
          accessId: values.accessId,
          body: { level: values.accessAuthorization ? ACCESS_LEVEL.ALLOWED : ACCESS_LEVEL.NOT_ALLOWED },
        },
      ),
    });
    if (response && response?.ok) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('profileForm_updateSuccess.message')}
        text=""
      />);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateAccess}
    >
      <Form>
        {getFormikContext && <GetFormik getFormikContext={getFormikContext} />}
        <InputLine
          name="accessAuthorization"
          as={InputCheckbox}
          label={t('editAccessForm_accessAuthorizationLabel.message')}
          checkboxLabel={t('editAccessForm_accessAuthorizationCheckboxAccepted.message')}
          labelMinWidth="311px"
        />
      </Form>
    </Formik>
  );
};

export default EditAccessForm;
