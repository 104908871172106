import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { textP } from '@features/ui/styles/textStyles';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';

export interface InputTextProps {
  type?: 'text' | 'number' | 'password' | 'textarea';
  onChange?: (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void;
  disabled?: boolean;
  leftIcon?: React.ReactElement;
  leftText?: string;
  rightText?: String;
  clearable?: boolean;
  rightIcon?: React.ReactElement;
  alignRight?: boolean;
  onClear?: (e: MouseEvent) => void;
  onRightIconCallback?: (value: string | number | readonly string[]) => void;
  meta?: InputTextMeta;
  helpers?: InputTextHelpers;
  inputWrapperClassName ?: string;
}

interface InputTextMeta {
  touched: boolean,
  error: boolean
}

interface InputTextHelpers {
  setValue ?: (value)=> void
}

/**
 * An input that contains text. Can be used both controlled (with value) and uncontrolled (with defaultValue).
 * @constructor
 */
const InputText = ({
  type = 'text',
  onClear,
  value,
  defaultValue,
  onChange,
  leftIcon,
  leftText,
  rightText,
  clearable,
  rightIcon,
  alignRight,
  name,
  onRightIconCallback,
  meta,
  helpers,
  ...etc
}: InputTextProps & InputHTMLAttributes<HTMLInputElement>) => {
  const leftIconEl = useRef(null);
  const leftTextEl = useRef(null);
  const rightTextEl = useRef(null);
  const rightIconEl = useRef(null);

  const [rightPadding, updateRightPadding] = useState(0);
  const [leftPadding, updateLeftPadding] = useState(0);
  const [showErase, setShowErase] = useState(!!defaultValue?.toString().length || !!value?.toString().length);

  const isInputInError = meta && meta.touched && meta.error;
  const ElementToRender = (type === 'textarea' ? StyledTextarea : StyledInput) as React.ElementType;

  /**
   * This useEffect is here to update internal input padding -> since text/icons are in an overlay.
   * Furthermore, "left text" can change! For example: an input with phone number prefix may
   * change depending on the country chosen. This way, instead of doing complicated calculations,
   *  we take the actual size when it's rendered.
   */
  useEffect(() => {
    updateLeftPadding((leftIconEl.current?.clientWidth ?? 0) + (leftTextEl.current?.clientWidth ?? 0));
  }, [leftIconEl, leftTextEl, leftText]);

  useEffect(() => {
    updateRightPadding((rightIconEl.current?.clientWidth ?? 0) + (rightTextEl.current?.clientWidth ?? 0));
  }, [rightTextEl, rightIconEl, rightText]);

  const clearText = (e) => {
    helpers?.setValue?.('');
    onClear?.(e);
    setShowErase(false);
  };

  return (
    <StyledInputContainer>
      <ElementToRender
        type={type}
        onChange={(event) => {
          if (clearable) {
            setShowErase(event.target.value.length > 0);
          }
          if (onChange) {
            onChange(event);
          }
        }}
        leftPadding={leftPadding}
        rightPadding={rightPadding}
        alignRight={alignRight}
        leftIcon={leftIcon}
        hasError={isInputInError}
        clearable={clearable}
        name={name}
        value={value}
        defaultValue={defaultValue}
        {...etc}
      />
      <StyledUIOverlay>
        {leftIcon && (<LeftIcon ref={leftIconEl}>{leftIcon}</LeftIcon>)}
        {leftText && (<LeftText ref={leftTextEl}>{leftText}</LeftText>)}
        <Spacer />
        {rightText && (<RightText ref={rightTextEl}>{rightText}</RightText>)}
        {clearable && showErase && (
          <ClearWrapper onClick={clearText}>
            <Icon name={ICON_NAME.clear} color="var(--color-grey3)" />
          </ClearWrapper>
        )}
        {rightIcon && (
          <RightIcon
            ref={rightIconEl}
            hasRightIconClickable={!!onRightIconCallback}
            onClick={() => onRightIconCallback?.(value)}
          >
            {rightIcon}
          </RightIcon>
        )}
      </StyledUIOverlay>
    </StyledInputContainer>
  );
};
export default InputText;

interface IStyledInput {
  warning?: boolean;
  disabled?: boolean;
  alignRight?: boolean;
  leftPadding?: number;
  rightPadding?: number;
  maxlength?: string;
  hasError: boolean;
  clearable: boolean;
}

const colorStyles = ({ disabled, hasError }) => {
  if (disabled) {
    return css`
      background-color: transparent;
      color: var(--color-grey3);
      -webkit-text-fill-color: var(--color-grey3);
      cursor: not-allowed;
    `;
  }
  return css`
    background-color: var(--color-grey1);
    color: var(--color-grey4);
    &:hover {
      background-color: var(--color-grey1);
      border-top-color: var(--color-orange-light);
      border-right-color: var(--color-orange-light);
      border-bottom-color: var(--color-orange-light);
      ${!hasError && `
        border-left-color: var(--color-orange-light);
      `}
    }
    &:focus {
      background-color: var(--color-orange-light);
      border-top-color: var(--color-orange-light);
      border-right-color: var(--color-orange-light);
      border-bottom-color: var(--color-orange-light);
      ${!hasError && `
        border-left-color: var(--color-orange-light);
      `}
    }
  `;
};

const paddingLeft = ({ leftPadding, hasError }) => {
  const leftErrorMargin = hasError ? 4 : 0;
  const result = 12 - leftErrorMargin + leftPadding;
  return `${result}px`;
};

const paddingRight = ({ rightPadding, clearable }) => {
  const clearableWidth = clearable ? 28 : 0;
  const result = 12 + rightPadding + clearableWidth;
  return `${result}px`;
};

const elementStyle = css<IStyledInput>`
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid var(--color-grey1);
  ${({ hasError }) => hasError && `
    border-left: solid 5px var(--color-red);
  `}
  outline: none;
  width: 100%;

  padding-left: ${({ leftPadding, hasError }) => paddingLeft({
    leftPadding,
    hasError,
  })};
  padding-right: ${({ rightPadding, clearable }) => paddingRight({
    rightPadding,
    clearable,
  })};
  text-align: ${({ alignRight }) => ((alignRight) ? 'right' : 'inherit')};

  ${textP};

  ${({ disabled, hasError }) => colorStyles({
    disabled,
    hasError,
  })}

  max-width: auto;

  &::placeholder {
    color: var(--color-grey3);
  }

  /*
    Because Webkit thinks he knows better than us 🙄 https://stackoverflow.com/a/60987373/1841827
  */
  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${textP};
    max-width: auto;
  }
`;

export const StyledInput = styled.input<IStyledInput>`
  ${elementStyle}
  ${({ maxlength }) => `max-width: ${maxlength}`};
`;

const StyledTextarea = styled.textarea<IStyledInput>`
  resize: vertical;
  min-height: 44px;
  max-height: 800px;
  ${elementStyle}
`;

const StyledUIOverlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  pointer-events: none;
`;

const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const LeftIcon = styled.div`
  padding-right: 6px;
`;
const LeftText = styled.div`
  padding-right: 6px;
`;

const RightIcon = styled.div<{hasRightIconClickable: boolean;}>`
  padding-left: 6px;
  pointer-events: ${({ hasRightIconClickable }) => (hasRightIconClickable ? 'auto' : 'none')};
  cursor: ${({ hasRightIconClickable }) => (hasRightIconClickable ? 'pointer' : 'inherit')};
`;
const RightText = styled.div`
  padding-left: 6px;
`;

const ClearWrapper = styled.div`
 padding-left: 8px;
 cursor: pointer;
 pointer-events: auto;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;
