import styled from 'styled-components';
import Card from '@features/ui/components/Card';

export const StackableCardSection = ({ children, className = '' }) => <div className={`stackableCard_stackableCardSection ${className}`}>{children}</div>;

const StackableCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  & > .stackableCard_stackableCardSection {
    width: 100%;
    padding: 20px;
    height: 100%;
  }
  & > .stackableCard_stackableCardSection:not(:last-child) {
    border-bottom: 2px solid var(--color-grey1);
  }
`;

export default StackableCard;
