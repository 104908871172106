import { ParsedUrlQuery } from 'querystring';
import { useSelector } from 'react-redux';
import { formatStringToNumberOrNull } from '@utils/gobal';
import { QUERY_ENUM } from '@services/routeList';
import rollbarClientSide from '@services/rollbar';
import { selectAccessesOnMyFriends, selectCurrentUserDetail } from '@features/users/slices/userSlice';

export const getQueryEventId = (query: ParsedUrlQuery, url?: string) => {
  const eventSlug = query[QUERY_ENUM.eventSlug] as string;
  if (eventSlug == null) {
    const errorMessage = `getQueryEventId : eventSlug doesn't exist: eventSlug:${eventSlug} url:${url}`;
    if (!url) rollbarClientSide.error(errorMessage);
    throw Error(errorMessage);
  }
  const eventSplit = eventSlug.split('-');
  if (eventSplit.length >= 1) {
    const numberId = formatStringToNumberOrNull(eventSplit[0] as string);
    if (numberId !== null) {
      return numberId;
    }
    // eslint-disable-next-line max-len
    const errorMessage = `getQueryEventId : eventId from slug is not a number : eventSlug:${eventSlug} eventIdParse:${numberId} url:${url}`;
    if (!url) rollbarClientSide.error(errorMessage);
    throw Error(errorMessage);
  }
  const errorMessage = 'The slug in URL should be [id]-[slug], if you get this error you probably forget to add the slug part.';
  if (!url) rollbarClientSide.error(errorMessage);
  throw Error(errorMessage);
};

export const useShouldHighlight = () => {
  const currentUser = useSelector(selectCurrentUserDetail);
  const friends = useSelector(selectAccessesOnMyFriends);

  return (currentAthleteId) => {
    if (currentUser) {
      const athleteIsTheCurrentUser = currentAthleteId === currentUser._idMso;
      const athleteIsFriendOfTheCurrentUser = friends.map(friend => friend._idMso).includes(currentAthleteId);
      return athleteIsTheCurrentUser || athleteIsFriendOfTheCurrentUser;
    }
    return false;
  };
};

export const formatFullLocation = (location: string, site?: string) => `${location}${site ? ` (${site})` : ''}`;
