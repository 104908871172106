/* eslint-disable no-console */
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import { Cart, CART_STATUS, PAYMENT_METHOD, PaymentMethodInfo, TRANSACTION_STATUS } from '@root/src/types';
import config from '@root/src/config';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api, { listParameters } from '@services/api';
import getRoute from '@services/routeList';
import {
  selectCarts,
  selectCurrentCartDetail,
  selectPaymentMethodsInfo,
  setCarts,
  setCurrentCart,
  setPaymentMethodsInfo,
} from '@features/carts/slices/cartsSlice';

class CartDataHandler extends InitDataHandler {
  getCurrentCart: () => Promise<Cart | null> = async () => {
    const data = { locale: this.locale };
    const { response } = await this.requestHandler({
      request: api.subscription.carts.current({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      await Promise.all(json.items.map(async item => {
        item.hasDiscountVoucher = await this.appendDiscountVoucherToOrderItem(item.event._idMso);
        return item;
      }));
      this.dispatch(setCurrentCart(json));
      return selectCurrentCartDetail(this.store.getState());
    }
    return null;
  };

  setPaymentMethod: (paymentMethod: PAYMENT_METHOD) => Promise<Cart | null> = async (paymentMethod) => {
    const data = { paymentMethod };
    const { response } = await this.requestHandler({
      request: api.subscription.carts.payment.paymentMethod({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setCurrentCart(json));
      return selectCurrentCartDetail(this.store.getState());
    }
    return null;
  };

  setPay: () => Promise<Cart | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.subscription.carts.payment.pay({ ctx: this.serverSideContext }),
      serverSideContext: this.serverSideContext,
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setCurrentCart(json));
      return selectCurrentCartDetail(this.store.getState());
    }
    return null;
  };

  setInitDatatrans: () => Promise<Cart | null> = async () => {
    const basePathname = getRoute.cart.payment().pathname;
    const returnUrl = `${config.urlBase}${this.locale}${basePathname}`;
    const data = { returnUrl };
    const { response } = await this.requestHandler({
      request: api.subscription.carts.payment.init({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setCurrentCart(json));
      return selectCurrentCartDetail(this.store.getState());
    }
    return null;
  };

  deleteCart: (itemId: string) => Promise<Cart | null> = async (itemId) => {
    const { response } = await this.requestHandler({
      request: api.subscription.carts.delete({
        ctx: this.serverSideContext,
        orderId: itemId,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (!response || !response?.ok) return null;

    return this.getCurrentCart();
  };

  deletePack: (packId: string) => Promise<Cart | null> = async (packId) => {
    const { response } = await this.requestHandler({
      request: api.subscription.carts.packs.delete({
        ctx: this.serverSideContext,
        packId,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (!response || !response?.ok) return null;

    return this.getCurrentCart();
  };

  cancelPayment: () => Promise<Cart | null> = async () => {
    const { response } = await this.requestHandler({
      request: api.subscription.carts.payment.cancel({ ctx: this.serverSideContext }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setCurrentCart(json));
      return selectCurrentCartDetail(this.store.getState());
    }
    return null;
  };

  getPaymentMethodsInfo: () => Promise<PaymentMethodInfo[] | null> = async () => {
    const data = { locale: this.locale };
    const { response } = await this.requestHandler({
      request: api.subscription.paymentMethods.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setPaymentMethodsInfo(json));
      return selectPaymentMethodsInfo(this.store.getState());
    }
    return null;
  };

  getCarts: (parameters?: listParameters) => Promise<Cart[] | null> = async (parameters) => {
    const data = {
      locale: this.locale,
      pageSize: parameters ? parameters.pageSize : null,
    };
    const { response } = await this.requestHandler({
      request: api.subscription.carts.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setCarts(json));
      return selectCarts(this.store.getState());
    }
    return null;
  };

  private appendDiscountVoucherToOrderItem: (eventId: number) => Promise<boolean | null> = async (eventId) => {
    const data = {
      locale: this.locale,
      eventId: `mso:${eventId}`, // TODO 357 mso: is normally set in api service here it is not the case...
    };

    const { response } = await this.requestHandler({
      request: api.subscription.reductions.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json(); // TODO Forget to type the response...
      return json.reductions;
    }
    return null;
  };
}

export default CartDataHandler;

export const useCartDataHandler = () => {
  const store = useStore();
  const requestHandler = useRequestHandler();
  const router = useRouter();

  return new CartDataHandler({
    store,
    requestHandler,
    router,
  });
};

export enum CART_POLLING_STOP_REASON {
  SUCCESS = 'SUCCESS',
  FAIL_BACKEND = 'FAIL_BACKEND',
  FAIL_TRANSACTION = 'FAIL_TRANSACTION',
}

export const cartPollingStatus = (currentCart: Cart) => {
  const {
    cartStatus,
    refused,
    transaction,
  } = currentCart;

  let success: false | CART_POLLING_STOP_REASON = false;
  if (cartStatus === CART_STATUS.EMPTY) {
    success = CART_POLLING_STOP_REASON.SUCCESS;
  } else if (refused) {
    success = CART_POLLING_STOP_REASON.FAIL_BACKEND;
  } else if (transaction?.detail?.fail) {
    success = CART_POLLING_STOP_REASON.FAIL_TRANSACTION;
  }
  return {
    success,
    currentCart,
    code: refused?.code,
  };
};

export const transactionIsAuthorised = (currentCart: Cart) => {
  const transactionStatus = currentCart?.transaction?.status;
  return transactionStatus === TRANSACTION_STATUS.AUTHORIZED;
};
