import styled from 'styled-components';
import { shadowStyle } from '@features/ui/styles/shadowStyles';

const Card = styled.div<{hightlight?: boolean, isSelected?: boolean}>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  ${shadowStyle};
  border: 1px solid ${({ hightlight }) => (hightlight ? 'var(--color-orange-light)' : 'var(--color-white)')};
  border-right: ${({ isSelected }) => (isSelected ? '5px solid var(--color-orange)' : '1px solid var(--color-white)')};

  :hover {
    background: var(--color-white);
    border: 1px solid var(--color-grey1);
    border-right: ${({ isSelected }) => (isSelected ? '5px solid var(--color-orange)' : '1px solid var(--color-grey1)')};
    box-shadow: 0px 0px 16px 0px rgba(128,122,122,0.3);
  }
  transition: all 0.3s;
`;

export default Card;
