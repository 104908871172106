import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import api from '@root/src/services/api';
import useLazyLoading, { LazyLoader } from '@root/src/hooks/useLazyLoading';
import { EVENT_LIST_SEARCH_FILTER, RANKINGS_LIST_TYPE, RESULTS_LIST_SEARCH_FILTER_KEYS, TEventResultsList } from '@root/src/types';
import routeList, { QUERY_ENUM } from '@services/routeList';
import { selectEventEngagedCantonsOptions, selectEventEngagedNationalitiesOptions, selectEventSlug } from '@features/eventDetail/slices/eventDetailServerSlice';
import EventListPageTemplate from '@features/eventDetail/templates/EventListPageTemplate';
import {
  pushResultsPaginated,
  selectEventResultsAvailability,
  selectResultsList,
  selectResultsListFilter,
  selectResultsListOptions,
  selectResultsListType,
  setResultsListFilter,
} from '@features/results/slices/resultsSlice';
import EventRankingList from '@features/results/components/EventRankingList';
import { getQueryEventId } from '@features/eventDetail/utils';
import { SEARCH_TYPES, searchIsValid } from '@features/ui/components/SearchInput';

const EventResultsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const eventId = getQueryEventId(router.query);
  const eventSlug = useSelector(selectEventSlug(eventId));
  const resultsList = useSelector(selectResultsList) as TEventResultsList;
  const resultsListOptions = useSelector(selectResultsListOptions);
  const resultsListType = useSelector(selectResultsListType);
  const eventResultsAvailability = useSelector(selectEventResultsAvailability);
  const engagedCantonsOptions = useSelector(selectEventEngagedCantonsOptions(eventId));
  const engagedNationalitiesOptions = useSelector(selectEventEngagedNationalitiesOptions(eventId));
  const resultsFilter = useSelector(selectResultsListFilter);

  const searchOptions = getSearchOptions(t, engagedCantonsOptions, engagedNationalitiesOptions);
  const queryTypeId = router.query[QUERY_ENUM.typeId] as string;

  const isTopXList = !queryTypeId
  && (resultsListType === RANKINGS_LIST_TYPE.SCRATCH || resultsListType === RANKINGS_LIST_TYPE.CATEGORIES);

  const onFiltersChange = (query) => router.push(routeList.events(eventSlug).results(query), null, { scroll: false });

  const tagList = Object.keys(eventResultsAvailability)
    .filter(key => eventResultsAvailability[key])
    .map(key => ({
      label: t(`eventResultsList_tag${key}.message`),
      value: key,
    }));

  let groupOptions = null;
  if (resultsListOptions != null && resultsListOptions?.length > 0) {
    groupOptions = [...resultsListOptions];

    if (resultsListType === RANKINGS_LIST_TYPE.SCRATCH) {
      groupOptions.unshift({
        label: t('eventResultsList_selectAllScratchsOptionsLabel.message'),
        value: -1,
      });
    } else if (resultsListType === RANKINGS_LIST_TYPE.CATEGORIES) {
      groupOptions.unshift({
        label: t('eventResultsList_selectAllCategoriesOptionsLabel.message'),
        value: -1,
      });
    }
  }

  const endpoint = getResultsEndpoint(resultsListType, queryTypeId);
  const processData = (json: any, overrideCurrent?: boolean) => {
    dispatch(pushResultsPaginated({
      resultsApi: json,
      overrideCurrent,
      resultsListType,
    }));
  };
  const params = {
    eventId,
    scratchId: queryTypeId ?? null,
    categoryId: queryTypeId ?? null,
    importId: queryTypeId ?? resultsListOptions[0].value,
    filterBy: resultsFilter.query === '' ? {} : getResultsFilterForAPI(resultsFilter),
  };
  const fetchIsValid = searchIsValid(
    searchOptions.find(option => option.value === resultsFilter.filterBy)?.type,
    resultsFilter.query,
  );
  const { observerLoadMoreRef, isLoading, hasMore } = useLazyLoading({
    endpoint,
    params,
    processData,
    checkIfHasNoMoreData,
    resetListDependencies: useMemo(() => ([resultsFilter]), [resultsFilter]),
    fetchIsValid,
    disableFetchMoreOnScroll: isTopXList,
  });

  return (
    <EventListPageTemplate
      pageTitle={t('eventResultsList_pageHeaderTitle.message')}
      groupOptions={groupOptions}
      groupOptionsValue={queryTypeId || groupOptions[0].value}
      onGroupChange={(group: number) => onFiltersChange({
        type: resultsListType,
        typeId: group > -1 ? group : null,
      })}
      searchOptions={searchOptions}
      onSearchSubmit={(query, filterBy) => dispatch(setResultsListFilter({
        query,
        filterBy,
      }))}
      tagList={tagList}
      tagActiveValue={resultsListType}
      onTagChange={(tagValue) => onFiltersChange({ type: tagValue })}
      withResultSubscriptions
      disableSearch={resultsListType === RANKINGS_LIST_TYPE.DAKINE}
      filter={resultsFilter}
    >
      {resultsList.map(rankingList => (
        <EventRankingList rankingId={rankingList._idMso} key={rankingList._idMso} />
      ))}

      <LazyLoader isLoading={isLoading} hasMore={hasMore} ref={observerLoadMoreRef} />
    </EventListPageTemplate>
  );
};

export default EventResultsList;

const getSearchOptions = (t, engagedCantonsOptions, engagedNationalitiesOptions) => [
  {
    label: t('eventList_searchOptionAthlete.message'),
    value: EVENT_LIST_SEARCH_FILTER.ATHLETE,
    type: SEARCH_TYPES.TEXT,
  },
  {
    label: t('eventList_searchOptionState.message'),
    value: EVENT_LIST_SEARCH_FILTER.DEPARTEMENT,
    type: SEARCH_TYPES.SELECT,
    options: engagedCantonsOptions,
  },
  {
    label: t('eventList_searchOptionYear.message'),
    value: EVENT_LIST_SEARCH_FILTER.YEAR,
    type: SEARCH_TYPES.BIRTH_YEAR,
  },
  {
    label: t('eventList_searchOptionLocality.message'),
    value: EVENT_LIST_SEARCH_FILTER.LOCALITY,
    type: SEARCH_TYPES.TEXT,
  },
  {
    label: t('eventList_searchOptionNationality.message'),
    value: EVENT_LIST_SEARCH_FILTER.NATIONALITY,
    type: SEARCH_TYPES.SELECT,
    options: engagedNationalitiesOptions,
  },
  {
    label: t('eventList_searchOptionTeam.message'),
    value: EVENT_LIST_SEARCH_FILTER.TEAM,
    type: SEARCH_TYPES.TEXT,
  },
];

export const eventResultsListOptionsToKeys = {
  [EVENT_LIST_SEARCH_FILTER.ATHLETE]: [
    'athlete.firstName',
    'athlete.lastName',
    'athlete.fullName',
    'teamMembers.firstName',
    'teamMembers.lastName',
    'teamMembers.fullName',
  ],
  [EVENT_LIST_SEARCH_FILTER.DEPARTEMENT]: ['athlete.legAddress.department', 'teamMembers.legAddress.department'],
  [EVENT_LIST_SEARCH_FILTER.YEAR]: ['athlete.birthDateObj.year', 'teamMembers.birthDateObj.year'],
  [EVENT_LIST_SEARCH_FILTER.LOCALITY]: ['athlete.legAddress.locality', 'teamMembers.legAddress.locality'],
  [EVENT_LIST_SEARCH_FILTER.NATIONALITY]: ['athlete.nationality', 'teamMembers.nationality'],
};

const searchFilterOptionToAPIKey = {
  [EVENT_LIST_SEARCH_FILTER.ATHLETE]: RESULTS_LIST_SEARCH_FILTER_KEYS.athlete,
  [EVENT_LIST_SEARCH_FILTER.DEPARTEMENT]: RESULTS_LIST_SEARCH_FILTER_KEYS.canton,
  [EVENT_LIST_SEARCH_FILTER.YEAR]: RESULTS_LIST_SEARCH_FILTER_KEYS.year,
  [EVENT_LIST_SEARCH_FILTER.LOCALITY]: RESULTS_LIST_SEARCH_FILTER_KEYS.location,
  [EVENT_LIST_SEARCH_FILTER.NATIONALITY]: RESULTS_LIST_SEARCH_FILTER_KEYS.nationality,
  [EVENT_LIST_SEARCH_FILTER.TEAM]: RESULTS_LIST_SEARCH_FILTER_KEYS.teamname,
};

const getResultsFilterForAPI = (engagedFilter) => {
  const filterByAPI = searchFilterOptionToAPIKey[engagedFilter.filterBy];
  return { [filterByAPI]: engagedFilter.query };
};

const getResultsEndpoint = (type: RANKINGS_LIST_TYPE, queryTypeId?: string) => {
  if (type === RANKINGS_LIST_TYPE.SCRATCH) {
    return queryTypeId ? api.results.event.scratch.read : api.results.event.scratch.list;
  }

  if (type === RANKINGS_LIST_TYPE.CATEGORIES) {
    return queryTypeId ? api.results.event.categories.all : api.results.event.categories.list;
  }

  if (type === RANKINGS_LIST_TYPE.DAKINE) {
    return api.results.event.rankings.read;
  }
  return null;
};

const checkIfHasNoMoreData = (json: any) => {
  if (Array.isArray(json) && isEmpty(json)) {
    return true;
  }
  if (Array.isArray(json)) {
    return json.every(ranking => isEmpty(ranking.ranking));
  }
  return isEmpty(json.ranking);
};
