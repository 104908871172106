import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import InputText from '@features/ui-input/components/InputText';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';

interface InputIntProps {
  name: string;
  className?: string;
  disabled?: boolean;
}

const InputInt = ({
  name,
  className,
  disabled,
  ...etc
}: InputIntProps) => {
  const [field, , helpers] = useField(name);

  const onChange = (e) => {
    const newValue = Number(e.target.value);
    if (Number.isInteger(newValue)) {
      helpers.setValue(newValue);
    } else {
      helpers.setValue(e.target.value);
    }
  };

  const increment = (e) => {
    e.preventDefault();
    const newValue = Number.isInteger(field.value) ? Number(field.value + 1) : 1;
    helpers.setTouched(true);
    helpers.setValue(newValue);
  };

  const decrease = (e) => {
    e.preventDefault();
    const newValue = Number.isInteger(field.value) ? Number(field.value - 1) : 0;
    helpers.setTouched(true);
    helpers.setValue(newValue);
  };

  return (
    <Container className={className}>
      <StyledButton
        leftIcon={ICON_NAME.remove}
        variant={BUTTON_VARIANT.link}
        onClick={decrease}
        disabled={disabled || field.value <= 0}
      />
      <InputText
        type="text"
        name={name}
        value={field.value}
        {...etc}
        disabled={disabled}
        onChange={onChange}
      />
      <StyledButton
        leftIcon={ICON_NAME.add}
        variant={BUTTON_VARIANT.link}
        onClick={increment}
        disabled={disabled}
      />
    </Container>
  );
};

export default InputInt;

const StyledButton = styled(Button)<OwnProps>`
  margin: 0 4px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 185px;

  input {
    text-align: center;
  }

  fill: var(--color-orange);
`;
