import React from 'react';
import styled from 'styled-components';
import config, { SERVER_ENV_ENUM } from '@root/src/config';
import { mediaBreakpoint } from '@services/responsiveProvider';
import Reassurance from '@features/ui-layout/components/Footer/Reassurance';
import Organizer from '@features/ui-layout/components/Footer/Organizer';
import Versioning from '@features/ui-layout/components/Footer/Versioning';
import FooterInfos from '@features/ui-layout/components/Footer/Infos';

const Footer = () => {
  const showApiVersioning = config.environment !== SERVER_ENV_ENUM.prd;
  return (
    <Wrapper>
      <Reassurance />
      <Organizer />
      <FooterInfos />
      {showApiVersioning && (<Versioning />)}
    </Wrapper>
  );
};
export default Footer;

const Wrapper = styled.footer`
  margin-top: 60px;
  ${mediaBreakpoint.tablet} {
    margin-top: 40px;
  }
`;
