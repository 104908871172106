import { handleBirthdateValidation } from '@root/src/utils/gobal';
import yup from '@services/translation/translatedYup';

const minYear = 1900;

export const firstNameFieldValidation = (t) => ({
  firstName: yup.string()
    .required()
    .label(t('userProfileForm_firstnameLabel.message'))
    .test('notSameNames', t('userProfileForm_errorNamesCantBeSame.message'), (value, context) => context.parent.lastName !== value || value == null),
});

export const lastNameFieldValidation = (t) => ({
  lastName: yup.string()
    .required()
    .label(t('userProfileForm_lastnameLabel.message'))
    .test('notSameNames', t('userProfileForm_errorNamesCantBeSame.message'), (value, context) => context.parent.firstName !== value || value == null),
});

export const birthdateDayFieldValidation = (t, setValidationDateError) => ({
  birthdateDay: yup.number()
    .min(1)
    .max(31)
    .required()
    .label(t('userProfileForm_birthdateDayErrorLabel.message'))
    .test('birthdateDay', ' ', (value, context) => handleBirthdateValidation({
      day: value,
      month: context.parent.birthdateMonth,
      year: context.parent.birthdateYear,
    }, setValidationDateError, t, minYear)),
});

export const birthdateMonthFieldValidation = (t, setValidationDateError) => ({
  birthdateMonth: yup.number()
    .min(1)
    .max(12)
    .required()
    .label(t('userProfileForm_birthdateMonthErrorLabel.message'))
    .test('birthdateMonth', ' ', (value, context) => handleBirthdateValidation({
      day: context.parent.birthdateDay,
      month: value,
      year: context.parent.birthdateYear,
    }, setValidationDateError, t, minYear)),
});

export const birthdateYearFieldValidation = (t, setValidationDateError) => ({
  birthdateYear: yup.number()
    .min(minYear)
    .max(new Date().getFullYear())
    .required()
    .label(t('userProfileForm_birthdateYearErrorLabel.message'))
    .test('birthdateYear', ' ', (value, context) => handleBirthdateValidation({
      day: context.parent.birthdateDay,
      month: context.parent.birthdateMonth,
      year: value,
    }, setValidationDateError, t, minYear)),
});

export const genderFieldValidation = (t) => ({ gender: yup.string().required().label(t('userProfileForm_genderLabel.message')) });
export const nationalityFieldValidation = (t) => ({ nationality: yup.string().required().label(t('userProfileForm_nationalityLabel.message')) });

const UserProfileFormValidationSchema = (setValidationDateError, t) => yup.object()
  .shape({
    ...firstNameFieldValidation(t),
    ...lastNameFieldValidation(t),
    ...birthdateDayFieldValidation(t, setValidationDateError),
    ...birthdateMonthFieldValidation(t, setValidationDateError),
    ...birthdateYearFieldValidation(t, setValidationDateError),
    ...genderFieldValidation(t),
    ...nationalityFieldValidation(t),
  });

export default UserProfileFormValidationSchema;
