import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { chain, merge } from 'lodash';
import { DateTime } from 'luxon';
import { Event, EVENT_LIST_ITEM_TYPES, Serie, Sport } from '@root/src/types';
import { RootState } from '@services/store';
import { eventOrSerieFromApiToReducer } from '../formatters';

export interface EventListServerSliceState {
  events: {
    items: { [pageIndex: number]: Array<Event | Serie> },
    total: number,
    pages: number,
    currentPage: number,
    hydrateWithMerge: boolean,
  };
  sponsoredEvents: Array<Event>,
  numberOfEventsToExplore: number,
  sites: Array<string>,
  sports: Array<Sport>,
}

const eventListServerSlice = createSlice({
  name: 'eventListServer',
  initialState: {
    events: null,
    sponsoredEvents: null,
    numberOfEventsToExplore: null,
    sites: [],
    sports: [],
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const { events, sponsoredEvents, numberOfEventsToExplore, sites, sports } = action.payload.eventListServer;

      if (events != null) {
        if (state.events == null) state.events = {};
        state.events.total = events.total;
        state.events.pages = events.pages;
        state.events.hydrateWithMerge = events.hydrateWithMerge;
        state.events.currentPage = events.currentPage;

        if (events.hydrateWithMerge && state.events.items) {
          state.events.items = merge(state.events.items, events.items);
        } else {
          state.events.items = events.items;
        }
      }
      if (numberOfEventsToExplore != null) {
        // TODO: Think about the Immer problematic -> new value *or* modify the draft
        state.numberOfEventsToExplore = numberOfEventsToExplore;
      }
      if (sites != null) {
        state.sites = sites;
      }
      if (sports != null) {
        state.sports = sports;
      }
      if (sponsoredEvents !== null) {
        state.sponsoredEvents = sponsoredEvents;
      }

      return state;
    },
  },
  reducers: {
    setEvents: (state, action: PayloadAction<{
      apiEvents: any;
      hydrateWithMerge?: boolean;
      page: number;
      forceMerge?: boolean;
    }>) => {
      const { apiEvents, hydrateWithMerge, page, forceMerge } = action.payload;
      const { items, total, pages } = apiEvents;
      const newItem = state.events?.items || {};
      if (forceMerge && newItem[page] && newItem[page].length > 0) {
        newItem[page] = [...newItem[page], ...items.map(eventOrSerieFromApiToReducer)];
      } else {
        newItem[page] = items.map(eventOrSerieFromApiToReducer);
      }
      state.events = {
        items: newItem,
        total,
        pages,
        currentPage: page,
        hydrateWithMerge: hydrateWithMerge ?? false,
      };
    },
    setSponsoredEvents: (state, action: PayloadAction<Array<Event>>) => {
      state.sponsoredEvents = action.payload.map(e => eventOrSerieFromApiToReducer(e));
    },
    setNumberOfEventsToExplore: (state, action: PayloadAction<number>) => {
      state.numberOfEventsToExplore = action.payload;
    },
    setSites: (state, action: PayloadAction<Array<string>>) => {
      state.sites = action.payload;
    },
    setSports: (state, action: PayloadAction<Array<Sport>>) => {
      state.sports = action.payload;
    },
  },
});
export default eventListServerSlice.reducer;

export const {
  setEvents,
  setSponsoredEvents,
  setNumberOfEventsToExplore,
  setSites,
  setSports,
} = eventListServerSlice.actions;

export const selectEvents = (state: RootState) => state.eventListServer.events;
export const selectEventsList = createSelector(
  selectEvents,
  events => {
    if (events == null) return [];
    // TODO I don't know why lodash don't understand the flatMap, the return type is Event[][] but it's wrong
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return chain(events.items).toPairs().sortBy(0).fromPairs().flatMap().value() as Array<Event | Serie>;
  },
);
export const getNextAndPreviousPage = createSelector(
  selectEvents,
  events => {
    if (events == null) {
      return {
        previousPage: null,
        nextPage: null,
        previousLink: null,
        nextLink: null,
      };
    }
    const arrayOfIndex = chain(events.items)
      .toPairs()
      .sortBy(index => Number(index[0]))
      .map(i => Number(i[0]))
      .value();

    const firstIndex = arrayOfIndex[0];
    const lastIndex = arrayOfIndex[arrayOfIndex.length - 1];
    const lastPossibleIndex = events.pages - 1;
    const { currentPage } = events;

    return {
      previousPage: firstIndex === 0 ? null : firstIndex - 1,
      nextPage: lastIndex >= lastPossibleIndex ? null : lastIndex + 1,
      previousLink: currentPage === 0 ? null : currentPage - 1,
      nextLink: currentPage >= lastPossibleIndex ? null : currentPage + 1,
    };
  },
);
export const selectEventsTotal = createSelector(
  selectEvents,
  events => events?.total ?? null,
);
export const selectEventsPages = createSelector(
  selectEvents,
  events => events?.pages ?? null,
);

export const selectEventsIDsList = (sorted?: boolean) => createSelector(
  selectEventsList,
  eventsItems => {
    let list = [...eventsItems];
    if (sorted) {
      list = list.sort((a, b) => {
        const dateA = a._type === EVENT_LIST_ITEM_TYPES.event ? a.date : a.serieDates.earliestEventDate;
        const dateB = b._type === EVENT_LIST_ITEM_TYPES.event ? b.date : b.serieDates.earliestEventDate;
        return DateTime.fromISO(dateA).toMillis() - DateTime.fromISO(dateB).toMillis();
      });
    }
    return list.map(event => ({
      id: event._idMso,
      type: event._type,
    }));
  },
);

export const selectEventById = (id: number) => createSelector(
  selectEventsList,
  eventsItems => eventsItems.find(event => event._idMso === id),
);

export const selectNumberOfEventsToExplore = (state: RootState) => state.eventListServer.numberOfEventsToExplore;

export const selectSponsoredEvents = (state: RootState) => state.eventListServer.sponsoredEvents;
export const selectSponsoredEventById = (eventId: number) => createSelector(
  selectSponsoredEvents,
  events => events?.find(e => e._idMso === eventId)
);

export const selectSites = (state: RootState) => state.eventListServer.sites;
export const selectSitesForSearch = createSelector(
  selectSites,
  sites => sites.map(s => ({
    label: s,
    value: s,
  })),
);

export const selectSports = (state: RootState) => state.eventListServer.sports;
export const selectSportsForSearch = (sportCategory?: string) => createSelector(
  selectSports,
  sports => sports
    .filter(s => s.name
      && s.name !== ''
      && (!sportCategory || s.sportCategory === sportCategory))
    .map(s => ({
      label: s.name,
      value: s._idMso,
    }))
);
