import { css } from 'styled-components';

const fontCss = css`
 @font-face {
      font-family: 'barlow';
      src: url('/fonts/barlow-bold-webfont.woff2') format('woff2'),
           url('/fonts/barlow-bold-webfont.woff') format('woff'),
           url('/fonts/barlow-bold-webfont.ttf') format('truetype'),
           url('/fonts/barlow-bold-webfont.svg#barlowbold') format('svg');
      font-weight: 700;
      font-style: normal;
      font-display: optional;
  }

  @font-face {
      font-family: 'barlow';
      src: url('/fonts/barlow-bolditalic-webfont.woff2') format('woff2'),
           url('/fonts/barlow-bolditalic-webfont.woff') format('woff'),
           url('/fonts/barlow-bolditalic-webfont.ttf') format('truetype'),
           url('/fonts/barlow-bolditalic-webfont.svg#barlowbold_italic') format('svg');
      font-weight: 700;
      font-style: italic;
      font-display: optional;
  }

  @font-face {
      font-family: 'barlow';
      src: url('/fonts/barlow-medium-webfont.woff2') format('woff2'),
           url('/fonts/barlow-medium-webfont.woff') format('woff'),
           url('/fonts/barlow-medium-webfont.ttf') format('truetype'),
           url('/fonts/barlow-medium-webfont.svg#barlowmedium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: optional;
  }

  @font-face {
      font-family: 'barlow';
      src: url('/fonts/barlow-mediumitalic-webfont.woff2') format('woff2'),
           url('/fonts/barlow-mediumitalic-webfont.woff') format('woff'),
           url('/fonts/barlow-mediumitalic-webfont.ttf') format('truetype'),
           url('/fonts/barlow-mediumitalic-webfont.svg#barlowmedium_italic') format('svg');
      font-weight: 500;
      font-style: italic;
      font-display: optional;
  }

  @font-face {
      font-family: 'barlow';
      src: url('/fonts/barlow-semibold-webfont.woff2') format('woff2'),
           url('/fonts/barlow-semibold-webfont.woff') format('woff'),
           url('/fonts/barlow-semibold-webfont.ttf') format('truetype'),
           url('/fonts/barlow-semibold-webfont.svg#barlowsemibold') format('svg');
      font-weight: 600;
      font-style: normal;
      font-display: optional;
  }

  @font-face {
    font-family: 'barlow';
    src: url('/fonts/barlow-semibolditalic-webfont.woff2') format('woff2'),
         url('/fonts/barlow-semibolditalic-webfont.woff') format('woff'),
         url('/fonts/barlow-semibolditalic-webfont.ttf') format('truetype'),
         url('/fonts/barlow-semibolditalic-webfont.svg#barlowsemibold_italic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: optional;
  }

  @font-face {
    font-family: 'jeko-black';
    src: url('/fonts/jeko-black-webfont.woff2') format('woff2'),
         url('/fonts/jeko-black-webfont.woff') format('woff'),
         url('/fonts/jeko-black-webfont.ttf') format('truetype'),
         url('/fonts/jeko-black-webfont.svg#jeko-blackregular') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: optional;
  }
`;
export default fontCss;
