import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Tooltip = ({ placement = 'top', children, title, ...props }: TooltipProps) => {
  const classes = useStyles();
  return (
    <MUITooltip
      placement={placement}
      title={title}
      classes={classes}
      {...props}
    >
      <span>
        { children }
      </span>
    </MUITooltip>
  );
};
export default Tooltip;

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: 'var(--color-grey3)',
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '378px',
    padding: '12px 20px',
  },
  tooltipPlacementTop: { margin: '4px 0' },
}));
