import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { FormikContextType } from 'formik';
import { useSelector } from 'react-redux';
import { ACCESS_LEVEL } from '@root/src/types';
import { mediaBreakpoint } from '@services/responsiveProvider';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1, textPBold } from '@features/ui/styles/textStyles';
import EditAccessForm, { EditAccessInputs } from '@features/account/components/EditAccessForm';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { selectAccessOnMyProfileByID } from '@features/users/slices/userSlice';

interface ModalEditAccessProps {
  closeModal: () => void;
  accessId: string;
}

const ModalEditAccess = ({
  closeModal,
  accessId,
}: ModalEditAccessProps) => {
  const { t } = useTranslation();
  const [formikContext, setFormikContext] = useState<FormikContextType<EditAccessInputs>>(null);
  const access = useSelector(selectAccessOnMyProfileByID(accessId));

  const initialValues: EditAccessInputs = {
    accessAuthorization: access.level === ACCESS_LEVEL.ALLOWED,
    accessId,
  };

  const updateAccess = async () => {
    await formikContext.submitForm();
    closeModal();
  };

  const ModalFooter = () => (
    <Footer>
      <Button
        onClick={closeModal}
        leftIcon={ICON_NAME.arrowBack}
        variant={BUTTON_VARIANT.secondary}
        text={t('modalEditAccess_cancelButton.message')}
      />
      <Button
        onClick={updateAccess}
        leftIcon={ICON_NAME.check}
        text={t('modalEditAccess_submitButton.message')}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<ModalFooter />} closeModal={closeModal}>
      <Container>
        <Title>{t('modalEditAccess_title.message')}</Title>
        <Description>{t('modalEditAccess_description.message', { fullName: access.athlete.fullName })}</Description>
        <EditAccessForm initialValues={initialValues} getFormikContext={setFormikContext} />
      </Container>
    </ModalTemplate>
  );
};

export default ModalEditAccess;

const Title = styled.h1`
 ${textH1}
`;

const Description = styled.p`
  ${textPBold}
  margin: 28px 0;
`;

const Container = styled.div`
  max-width: 682px;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${mediaBreakpoint.tablet} {
    margin-top: unset;
  }
`;
