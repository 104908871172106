import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { destroyAuthCookies } from '@services/cookies';
import getRoute from '@services/routeList';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';

const Logout = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const logout = async () => {
    destroyAuthCookies();
    await router.push(getRoute.home());
  };

  return (
    <Button
      variant={BUTTON_VARIANT.link}
      text={t('myAccountLayout_logout.message')}
      leftIcon={ICON_NAME.logout}
      onClick={logout}
    />
  );
};

export default Logout;
