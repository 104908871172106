import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import InputSelect from '.';

interface InputSelectPriceProps {
  name: string,
  price: string;
  currency: string;
  options: any;
  showRequiredChip: boolean;
  selectNoneLabel: string;
}

const InputSelectPrice = ({
  name,
  price,
  currency,
  options,
  showRequiredChip,
  selectNoneLabel,
  ...etc
}: InputSelectPriceProps) => {
  const [field] = useField(name);

  let computedOptions = options;
  if (!showRequiredChip) {
    computedOptions = [
      {
        label: selectNoneLabel,
        value: '',
      }, ...options,
    ];
  }

  return (
    <Container>
      <InputSelect
        name={name}
        options={computedOptions}
        showRequiredChip={showRequiredChip}
        {...etc}
      />
      <Spacer />
      <Quantity>
        {field.value === '' ? '0' : '1'}
      </Quantity>
      <Multiplication>×</Multiplication>
      <Price>{`${price} ${currency}`}</Price>
    </Container>
  );
};

export default InputSelectPrice;

const Container = styled.div`
   display: flex; 
`;

const Spacer = styled.div`
  flex: 1;
`;

const Multiplication = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0 4px 0 7px;
`;
const Price = styled.div`
  min-width: 93px;
  text-align: right;
  display:flex;
  justify-content: flex-end;
  align-items: center;
`;

const Quantity = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
