import React, { useEffect, useRef, useState } from 'react';
import { useResize } from '@services/responsiveProvider';
import { ICON_NAME } from '@features/ui/components/Icon';
import Tag from '@features/ui/components/Tag';
import {
  ArrowIcon,
  Body,
  BodyWrapper,
  CardContainer,
  Chip,
  Header,
  HeaderFirstLine,
  HeaderSecondLine,
  TagsWrapper,
  StyledInputCheckbox,
} from '@features/ui/components/ToggleCard/styles';

export enum TOGGLE_CARD_SIZES {
  SMALL,
  MEDIUM,
  BIG
}

const heightPerSize = {
  [TOGGLE_CARD_SIZES.SMALL]: 44,
  [TOGGLE_CARD_SIZES.MEDIUM]: 84,
  [TOGGLE_CARD_SIZES.BIG]: 114,
};

type ToggleCardProps = {
  header: React.ReactNode;
  highlight?: boolean;
  size?: TOGGLE_CARD_SIZES;
  alignArrowOnTopOnMobile?: boolean;
  tags?: Array<string>;
  body: React.ReactNode;
  chip?: string;
  className?:string;
  onSetOpen?: (boolean) => void;
  disabled?: boolean;
  onSelectClick?: () => void;
  isSelected?: boolean;
  dataQa?: string;
}

const ToggleCard = ({
  header,
  body,
  className,
  highlight,
  onSetOpen,
  chip,
  tags,
  alignArrowOnTopOnMobile,
  disabled,
  size = TOGGLE_CARD_SIZES.SMALL,
  onSelectClick,
  isSelected,
  dataQa,
}: ToggleCardProps) => {
  const [open, setOpen] = useState(false);
  const bodyRef = useRef(null);
  const [bodyWrapperHeight, setBodyWrapperHeight] = useState(0);

  const headerHeight = tags || chip ? heightPerSize[TOGGLE_CARD_SIZES.BIG] : heightPerSize[size];

  const setBodyWrapperHeightIfExist = () => {
    const offsetHeight = bodyRef?.current?.offsetHeight;
    if (offsetHeight != null) {
      setBodyWrapperHeight(offsetHeight);
    }
  };

  useResize(() => setBodyWrapperHeightIfExist());
  useEffect(() => {
    // TODO: Find the right solution
    setTimeout(() => {
      setBodyWrapperHeightIfExist();
    }, 50);
  }, [bodyRef, open, body]);
  useEffect(() => {
    if (disabled && open) {
      setOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (onSetOpen) {
      onSetOpen(!open);
    }
  }, [open]);

  const isSelectedRef = useRef(isSelected);
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  const handleCheckboxClick = () => {
    if (!isSelectedRef.current) {
      setOpen(true);
    }
    onSelectClick();
  };

  const handleRowClick = () => {
    if (disabled) return;
    setOpen(open => !open);
  };

  return (
    <CardContainer
      className={className}
      hightlight={highlight}
      isSelected={isSelected}
      data-qa={dataQa}
    >
      <Header
        hightlight={highlight}
        open={open}
        height={headerHeight}
        onClick={handleRowClick}
        disabled={disabled}
      >
        <HeaderFirstLine>
          {onSelectClick && (
            <StyledInputCheckbox
              defaultValue={false}
              value={isSelected}
              onClick={handleCheckboxClick}
              disabled={disabled}
              stopPropagation
            />
          )}
          {!disabled && (
            <ArrowIcon
              open={open}
              alignArrowOnTopOnMobile={alignArrowOnTopOnMobile}
              size="30px"
              name={ICON_NAME.chevronLeft}
              color={disabled ? 'var(--color-grey3)' : 'var(--color-orange)'}
            />
          )}
          {header}
        </HeaderFirstLine>
        <HeaderSecondLine>
          {tags && (
            <TagsWrapper marginBottom={!chip}>
              {tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
            </TagsWrapper>
          )}
          {chip && <Chip>{chip}</Chip>}
        </HeaderSecondLine>
      </Header>
      <BodyWrapper
        open={open}
        maxHeight={bodyWrapperHeight}
      >
        <Body ref={bodyRef}>
          {body}
        </Body>
      </BodyWrapper>
    </CardContainer>
  );
};

export default ToggleCard;
