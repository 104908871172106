import { de, fr } from 'yup-locales';
import yupEnLocale from 'yup/lib/locale';
import * as yup from 'yup';
import { LOCALES } from '@root/src/types';

export const setYupLocale = (locale) => {
  switch (locale) {
    case LOCALES.FRENCH:
      yup.setLocale(fr);
      break;
    case LOCALES.GERMAN:
      yup.setLocale(de);
      break;
    case LOCALES.ENGLISH:
    default:
      yup.setLocale(yupEnLocale);
  }
};

export default yup;
