import yup from '@services/translation/translatedYup';
import { phoneNumberFieldValidation } from '@features/users/components/UserAddressFormSchema';

const CreateUpdateMobileNumberSchema = (t) => (
  yup.object()
    .shape({
      ...phoneNumberFieldValidation(t, true),
      description: yup.string()
        .max(50),
    })
);

export default CreateUpdateMobileNumberSchema;
