import {
  AccessesOnMyFriends,
  AccessesOnMyProfile,
  AthleteShortProfile,
  MobileNumber,
  Preferences,
  Profile,
} from '@root/src/types';
import { formatPhoneNumberFormToApi, removeEmpty, removeUndefs } from '@utils/gobal';
import { ProfileFormInputs } from '@features/account/components/ProfileForm';
import { CreateUpdateMobileNumberFormInputs } from '@features/account/components/CreateUpdateMobileNumberForm';

export const profileFromApiToReducer = (
  profileApi: any,
): Profile => removeUndefs<Profile>({
  _idMso: parseInt(profileApi._idMso, 10),
  _id: profileApi._id,
  accessLevel: profileApi.accessLevel,
  gender: profileApi.gender,
  language: profileApi.language,
  firstName: profileApi.firstName,
  lastName: profileApi.lastName,
  legAddress: profileApi.legAddress ? {
    address: profileApi.legAddress?.address,
    postalCode: profileApi.legAddress?.postalcode,
    locality: profileApi.legAddress?.locality,
    department: profileApi.legAddress?.department,
    country: profileApi.legAddress?.country,
  } : null,
  nationality: profileApi.nationality,
  club: profileApi.club,
  licence: profileApi.licence,
  birthDateObj: profileApi.birthDateObj ? {
    year: profileApi.birthDateObj.year,
    month: profileApi.birthDateObj.month,
    day: profileApi.birthDateObj.day,
  } : null,
  login: profileApi.login,
  email: {
    validated: profileApi?.email?.validated,
    email: profileApi?.email?.email,
  },
  legPhone: profileApi.legPhone,
  mPhones: profileApi.mPhones,
  status: profileApi.status,
  updatedAt: profileApi.updatedAt,
  createdAt: profileApi.createdAt,
  age: profileApi.age,
  fullName: profileApi.fullName,
  newsletter: profileApi.newsletter,
  licenseCAS: profileApi.licenseCAS ? {
    uniqid: profileApi.licenseCAS.uniqid,
    articleName: profileApi.licenseCAS.articlename,
    articleIdentity: profileApi.licenseCAS.articleidentity,
    articleDescription: profileApi.licenseCAS.articledescription,
    created: profileApi.licenseCAS.created,
    payed: profileApi.licenseCAS.payed,
    _id: profileApi.licenseCAS._id,
    validUntil: profileApi.licenseCAS.validUntil,
    _idMso: parseInt(profileApi.licenseCAS._idMso, 10),
  } : null,
});

export const profileFromReducerToApi = (
  profileReducer: Profile,
): any => ({
  _idMso: profileReducer._idMso,
  accessLevel: profileReducer.accessLevel,
  gender: profileReducer.gender,
  language: profileReducer.language,
  firstName: profileReducer.firstName,
  lastName: profileReducer.lastName,
  legAddress: profileReducer.legAddress ? {
    address: profileReducer.legAddress?.address,
    postalcode: profileReducer.legAddress?.postalCode,
    locality: profileReducer.legAddress?.locality,
    department: profileReducer.legAddress?.department,
    country: profileReducer.legAddress?.country,
  } : null,
  nationality: profileReducer.nationality,
  club: profileReducer.club,
  licence: profileReducer.licence,
  birthDateObj: profileReducer.birthDateObj ? {
    year: profileReducer.birthDateObj.year,
    month: profileReducer.birthDateObj.month,
    day: profileReducer.birthDateObj.day,
  } : null,
  login: profileReducer.login,
  email: {
    validated: profileReducer.email.validated,
    email: profileReducer.email.email,
  },
  legPhone: profileReducer.legPhone,
  mPhones: profileReducer.mPhones,
  status: profileReducer.status,
  updatedAt: profileReducer.updatedAt,
  createdAt: profileReducer.createdAt,
  age: profileReducer.age,
});

export const athleteShortProfileFromApiToReducer = (athlete): AthleteShortProfile => ({
  _idMso: parseInt(athlete?._idMso, 10),
  _id: athlete?._id,
  firstName: athlete?.firstName,
  lastName: athlete?.lastName,
  nationality: athlete?.nationality,
  legAddress: athlete?.legAddress && ({
    locality: athlete?.legAddress.locality,
    department: athlete?.legAddress.department,
  }),
  birthDateObj: { year: athlete?.birthDateObj?.year },
});

export const profileFromAccountFormToApi = (user: ProfileFormInputs) => (removeEmpty({
  firstName: user.firstName,
  lastName: user.lastName,
  birthDateObj: {
    day: user.birthdateDay,
    month: user.birthdateMonth,
    year: user.birthdateYear,
  },
  legAddress: {
    address: user.address,
    postalcode: user.postalCode,
    locality: user.locality,
    country: user.country,
    department: user.canton,
  },
  language: user.language,
  nationality: user.nationality,
  gender: user.gender,
  club: user.club,
  licence: user.licenseNumber,
}));

export const preferencesFromPreferencesFormToApi = (preferences: Preferences) => (removeEmpty({
  optIn: preferences.optIn,
  bike: preferences.bike,
  running: preferences.running,
  triathlon: preferences.triathlon,
  ski: preferences.ski,
  motorSports: preferences.motorSports,
}));

export const preferencesFromApiToReducer = (preferencesApi: any): Preferences => removeUndefs({
  optIn: preferencesApi.optIn == null ? false : preferencesApi.optIn,
  bike: preferencesApi.newsletterOptions?.bike == null ? false : preferencesApi.newsletterOptions?.bike,
  running: preferencesApi.newsletterOptions?.running == null ? false : preferencesApi.newsletterOptions?.running,
  triathlon: preferencesApi.newsletterOptions?.triathlon == null ? false : preferencesApi.newsletterOptions?.triathlon,
  ski: preferencesApi.newsletterOptions?.ski == null ? false : preferencesApi.newsletterOptions?.ski,
  motorSports: preferencesApi.newsletterOptions?.motorSports == null
    ? false
    : preferencesApi.newsletterOptions?.motorSports,
});

export const accessesOnMyProfileFromApiToReducer = (access: Array<AccessesOnMyProfile>)
  : Array<AccessesOnMyProfile> => (
  access.map(
    item => (removeUndefs({
      level: item.level,
      athlete: profileFromApiToReducer(item.athlete),
    })),
  ));

export const accessesOnMyFriendsFromApiToReducer = (athletes: AccessesOnMyFriends)
  : AccessesOnMyFriends => ({
  items: athletes.items.map(athlete => profileFromApiToReducer(athlete)),
  total: athletes.total,
  pages: athletes.pages,
});

export const mobileNumberFromApiToReducer = (mobileNumberApi: any): MobileNumber => removeUndefs(
  {
    _id: mobileNumberApi._id,
    _idMso: mobileNumberApi._idMso,
    num: `+${mobileNumberApi.num}`,
    description: mobileNumberApi.description,
    created: mobileNumberApi.created,
    validated: mobileNumberApi.validated,
    code: mobileNumberApi.code,
    coderequestnum: mobileNumberApi.coderequestnum,
    requested: mobileNumberApi.requested,
    def: mobileNumberApi.def,
  },
);

export const mobileNumbersFromApiToReducer = (mobileNumbersApi: any): Array<MobileNumber> => (
  mobileNumbersApi.mobiNumbers.map(mobileNumberApi => mobileNumberFromApiToReducer(mobileNumberApi))
);

export const mobileNumberFromMobileNumberFormToApi = (mobileNumber: CreateUpdateMobileNumberFormInputs) => removeEmpty({
  num: formatPhoneNumberFormToApi(mobileNumber.phoneNumber),
  description: mobileNumber.description,
  def: mobileNumber.def,
});
