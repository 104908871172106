import Rollbar from 'rollbar';
import { useRollbar } from '@rollbar/react';
import { useEffect } from 'react';
import config from '@root/src/config';

// This should be used ONLY if you can use hook
// If you can use hook, use the hook useRollbar ('@rollbar/react')
// It will inject much more infos, like the user or the context
const rollbarClientSide = new Rollbar(config.rollbar);
export default rollbarClientSide;

export const rollbarServerSide = new Rollbar({
  ...config.rollbar,
  accessToken: config.rollbar.serverAccessToken,
});

// This hook already exist in '@rollbar/react' but it don't work (PR already on the repo)
export const useRollbarContext = (ctx: string) => {
  const rollbar = useRollbar();
  useEffect(() => {
    const origCtx = rollbar.options.payload.context;
    rollbar.configure({ payload: { context: ctx } });
    return () => {
      rollbar.configure({ payload: { context: origCtx } });
    };
  }, [ctx]);
};
