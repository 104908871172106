import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { selectEventCategoryService, selectEventCurrency } from '@features/eventDetail/slices/eventDetailServerSlice';
import servicesInputFactory from '@features/subscriptions/factories/servicesInputFactory';
import ServiceLineRaw from '@features/subscriptions/components/ServiceLine/ServiceLineRaw';
import { TextAlignment } from '@features/ui-input/formik/MultiInputLine';
import { InputLineProps } from '@features/ui-input/formik/InputLine';
import { selectCurrentCartDetail } from '@features/carts/slices/cartsSlice';

interface ServiceLineEventSubscriptionProps {
  name: string;
  serviceId: number;
  eventId: number;
  useCard?: boolean,
}

const ServiceLineTypeService = ({
  serviceId,
  eventId,
  useCard,
  ...etc
}: ServiceLineEventSubscriptionProps & Partial<InputLineProps>) => {
  const service = useSelector(selectEventCategoryService(eventId, serviceId));
  const { t } = useTranslation();
  const inputsPropByFactory = useMemo(() => servicesInputFactory(service, t, false), [serviceId]);
  const currency = useSelector(selectEventCurrency(eventId));
  const cart = useSelector(selectCurrentCartDetail);

  const inputProps = {
    price: service?.price?.toFixed(2),
    currency: currency ?? cart?.price?.currency,
    labelWidthAuto: false,
    textAlignment: 'flex-start' as TextAlignment,
    ...etc,
    ...inputsPropByFactory,
  };

  return (
    <ServiceLineRaw
      useCard={useCard}
      labelText={service.label}
      labelUrl={service.url}
      {...inputProps}
    />
  );
};

export default ServiceLineTypeService;
