import { NextRouter, useRouter } from 'next/router';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import getRoute, { QUERY_ENUM } from '@services/routeList';
import { useRollbarContext } from '@services/rollbar';
import Modal from '@features/ui-layout/components/Modal';
import ModalExample from '@features/ui/templates/ModalExample';
import ModalMenu from '@features/ui-layout/components/ModalMenu';
import ModalLogin from '@features/users/templates/ModalLogin';
import ModalRegister from '@features/users/templates/ModalRegister';
import ModalCartDeleteItemTemplate from '@features/carts/templates/ModalCartDeleteItemTemplate';
import ModalSubscriptionAccountUpdate from '@features/subscriptions/templates/ModalSubscriptionAccountUpdate';
import ModalRequestResetPassword from '@features/users/templates/ModalRequestResetPassword';
import ModalEventFilter from '@features/eventList/templates/ModalEventFilter';
import ModalEditAccess from '@features/account/templates/ModalEditAccess';
import { ModalRemoveAccessFriend, ModalRemoveAccessProfile } from '@features/account/templates/ModalRemoveAccess';
import ModalAccountEditFriendTemplate from '@features/account/templates/ModalAccountEditFriendTemplate';
import ModalCreateUpdateMobileNumber from '@features/account/templates/ModalCreateUpdateMobileNumber';
import ModalDeleteMobileNumber from '@features/account/templates/ModalDeleteMobileNumber';
import ModalAddFriend from '@features/users/templates/ModalAddFriend';
import ModalSubscriptionCartCreate from '@features/subscriptions/templates/ModalSubscriptionCartCreate';
import ModalMobileValidation from '@features/account/templates/ModalMobileValidation';
import ModalSubscriptionCartUpdate from '@features/subscriptions/templates/ModalSubscriptionCartUpdate';
import { setLastModalOpen } from '@features/ui-layout/slices/uiLayoutSlice';

const transformStringToValidNumber = (value) => {
  const number = Number(value);
  return Number.isNaN(number) ? value : number;
};

export enum MODAL_NAME {
  example = 'example',
  menu = 'menu',
  login = 'login',
  resetPassword = 'resetPassword',
  register = 'register',
  deleteCartItem = 'deleteCartItem',
  subscriptionCartCreate = 'subscriptionCartCreate',
  subscriptionCartUpdate = 'subscriptionCartUpdate',
  subscriptionAccountUpdate = 'subscriptionAccountUpdate',
  eventFilterMobile = 'eventFilterMobile',
  editAccessOnMyProfile = 'editAccessOnMyProfile',
  removeAccessOnMyProfile = 'removeAccessOnMyProfile',
  removeAccessOnMyFriends = 'removeAccessOnMyFriends',
  accountEditFriend = 'accountEditFriend',
  createUpdateMobileNumber = 'createUpdateMobileNumber',
  deleteMobileNumber = 'deleteMobileNumber',
  addFriend = 'addFriend',
  accountValidateNumber = 'accountValidateNumber',
}

const ModalComponents: {
  [key in MODAL_NAME]: {
    getComponent: (obj: {
      closeModal: () => void;
      modalIdQuery: string;
      modalIdNotTransformed?: any;
      modalParamOneQuery: string;
    }) => ReactElement;
    closeWithHistory?: boolean;
    fullScreen?: boolean;
    showConfirm?: boolean;
  }
} = {
  [MODAL_NAME.example]: {
    getComponent: ({ closeModal }) => <ModalExample closeModal={closeModal} />,
    closeWithHistory: true,
  },
  [MODAL_NAME.menu]: {
    getComponent: ({ closeModal }) => <ModalMenu closeModal={closeModal} />,
    closeWithHistory: true,
    fullScreen: true,
  },
  [MODAL_NAME.subscriptionCartCreate]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalSubscriptionCartCreate
        closeModal={closeModal}
        categoryId={parseInt(modalIdQuery as string, 10)}
      />
    ),
    showConfirm: true,
  },
  [MODAL_NAME.login]: {
    getComponent: ({
      closeModal,
      modalParamOneQuery,
    }) => (
      <ModalLogin
        closeModal={closeModal}
        redirectUrl={modalParamOneQuery}
      />
    ),
  },
  [MODAL_NAME.register]: {
    getComponent: ({ closeModal }) => (
      <ModalRegister
        closeModal={closeModal}
      />
    ),
  },
  [MODAL_NAME.deleteCartItem]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
      modalParamOneQuery,
    }) => (
      <ModalCartDeleteItemTemplate
        closeModal={closeModal}
        itemId={modalIdQuery}
        categoryName={modalParamOneQuery}
      />
    ),
  },
  [MODAL_NAME.subscriptionCartUpdate]: {
    getComponent: ({
      closeModal,
      modalIdNotTransformed,
      modalParamOneQuery,
    }) => (
      <ModalSubscriptionCartUpdate
        closeModal={closeModal}
        orderItemId={modalIdNotTransformed}
        userId={modalParamOneQuery}
      />
    ),
    showConfirm: true,
  },
  [MODAL_NAME.subscriptionAccountUpdate]: {
    getComponent: ({
      closeModal,
      modalIdNotTransformed,
    }) => (
      <ModalSubscriptionAccountUpdate
        closeModal={closeModal}
        userId={modalIdNotTransformed}
      />
    ),
    showConfirm: true,
  },
  [MODAL_NAME.resetPassword]: {
    getComponent: ({ closeModal }) => (
      <ModalRequestResetPassword closeModal={closeModal} />
    ),
  },
  [MODAL_NAME.eventFilterMobile]: {
    getComponent: ({ closeModal }) => (
      <ModalEventFilter closeModal={closeModal} />
    ),
  },
  [MODAL_NAME.editAccessOnMyProfile]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalEditAccess closeModal={closeModal} accessId={modalIdQuery} />
    ),
  },
  [MODAL_NAME.removeAccessOnMyProfile]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalRemoveAccessProfile closeModal={closeModal} accessId={modalIdQuery} />
    ),
  },
  [MODAL_NAME.removeAccessOnMyFriends]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalRemoveAccessFriend closeModal={closeModal} athleteId={modalIdQuery} />
    ),
  },
  [MODAL_NAME.accountEditFriend]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalAccountEditFriendTemplate closeModal={closeModal} athleteId={modalIdQuery} />
    ),
  },
  [MODAL_NAME.addFriend]: {
    getComponent: ({ closeModal }) => (
      <ModalAddFriend
        closeModal={closeModal}
      />
    ),
  },
  [MODAL_NAME.createUpdateMobileNumber]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalCreateUpdateMobileNumber closeModal={closeModal} mobileNumberID={modalIdQuery} />
    ),
  },
  [MODAL_NAME.deleteMobileNumber]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalDeleteMobileNumber closeModal={closeModal} mobileNumberID={modalIdQuery} />
    ),
  },
  [MODAL_NAME.accountValidateNumber]: {
    getComponent: ({
      closeModal,
      modalIdQuery,
    }) => (
      <ModalMobileValidation closeModal={closeModal} mobileNumberID={modalIdQuery} />
    ),
  },
};

const ModalContainer = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const modalQuery = router.query[QUERY_ENUM.modal] as MODAL_NAME;
  const { t } = useTranslation();

  useRollbarContext(`Modal : ${modalQuery}`);

  useEffect(() => {
    if (modalQuery != null) {
      dispatch(setLastModalOpen({ modalName: modalQuery }));
    }
  }, [router.query]);

  if (modalQuery == null) return (<></>);

  const data = ModalComponents[modalQuery];
  if (data == null) return (<></>);

  const modalIdQuery = router.query[QUERY_ENUM.modalId] as string;
  const modalParamOneQuery = router.query[QUERY_ENUM.modalParamOne] as string;
  const closeWithHistory = data.closeWithHistory != null ? data.closeWithHistory : false;

  const componentRender = data.getComponent({
    closeModal: (showConfirm = false) => closeModal(router, closeWithHistory, showConfirm, t),
    modalIdQuery: transformStringToValidNumber(modalIdQuery),
    modalIdNotTransformed: modalIdQuery, // we need this because of mongo db ids may look like numbers sometimes but they aren't
    modalParamOneQuery,
  });

  if (componentRender == null) return (<></>);

  return (
    <Modal
      showConfirm={data.showConfirm ?? false}
      component={componentRender}
      closeModal={(showConfirm = false) => closeModal(router, closeWithHistory, showConfirm, t)}
      fullScreen={data?.fullScreen}
    />
  );
};
export default ModalContainer;

const closeModal = (router: NextRouter, withHistory = true, showConfirm: boolean, t) => {
  // checking the type because if closeModal is sent to a button like onClick={closeModal}, showConfirm will be an avent
  if (typeof showConfirm === 'boolean' && showConfirm) {
    // eslint-disable-next-line no-alert
    const answer = window.confirm(t('modalContainer_closeConfimationText.message'));

    if (!answer) {
      return;
    }
  }

  router[withHistory ? 'push' : 'replace'](getRoute.closeModal(router), null, { scroll: false });
};
