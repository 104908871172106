import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useField } from 'formik';
import InputRadio from '@features/ui-input/components/InputRadio';

const InputYesNo = props => {
  const { isInitialized } = props;
  const { t } = useTranslation();
  const [input, , helpers] = useField(props.name);
  const { setValue } = helpers;
  return (
    <Container>
      <InputRadio
        {...props}
        value
        label={t('InputYesNo_yes.message')}
        checked={isInitialized ? input.value : undefined}
        onChange={v => {
          // Forces a boolean to the values instead of a string
          setValue(v.target.value === 'true');
        }}
      />
      <InputRadio
        {...props}
        checked={isInitialized ? input.value === false : undefined}
        value={false}
        label={t('InputYesNo_no.message')}
        onChange={v => {
          setValue(v.target.value === 'true');
        }}
      />
    </Container>
  );
};

export default InputYesNo;

const Container = styled.div`
  display: flex;
  width: 150px;
`;
