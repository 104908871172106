import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { LOCALES } from '@root/src/types';
import { getQueryAsValidNumber, QUERY_ENUM } from '@services/routeList';
import { selectSeriesList } from '@features/results/slices/resultsSlice';

export const useSeriesListInfo = () => {
  const seriesList = useSelector(selectSeriesList);
  const router = useRouter();
  const currentId = getQueryAsValidNumber(router.query, QUERY_ENUM.serieId) ?? seriesList[0]._idMso;
  const currentSerie = seriesList.filter(serie => serie._idMso === currentId)[0];
  return {
    seriesList,
    currentSerie,
  };
};

const numberToOrdinalEnSuffix = (number) => {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }

  if (j === 2 && k !== 12) {
    return 'nd';
  }

  if (j === 3 && k !== 13) {
    return 'rd';
  }

  return 'th';
};

const numberToOrdinalFrSuffix = (number) => (number === 1 ? 'er' : 'ème');

export const numberToOrdinalSuffix = (number: number, locale?: LOCALES): string => {
  switch (locale) {
    case LOCALES.FRENCH: {
      return numberToOrdinalFrSuffix(number);
    }
    case LOCALES.GERMAN: {
      return '.';
    }
    case LOCALES.ENGLISH: {
      return numberToOrdinalEnSuffix(number);
    }
    default:
      return '';
  }
};
