import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';

interface ModalServicesGroupFooterProps {
  onNext: () => Promise<void>,
  onPrevious: () => void,
  previousButtonAsCancel: boolean,
  nextButtonAsValidate: boolean,
  nextButtonDisabled: boolean,
  hideFooter: boolean,
}

const ServicesGroupModalFooter = ({
  onNext,
  onPrevious,
  previousButtonAsCancel,
  nextButtonAsValidate,
  nextButtonDisabled,
  hideFooter,
}: ModalServicesGroupFooterProps) => {
  const { t } = useTranslation();

  return (
    <FooterContainer isVisible={hideFooter}>
      <Button
        onClick={onPrevious}
        leftIcon={previousButtonAsCancel ? ICON_NAME.keyboardReturn : ICON_NAME.arrowBack}
        variant={BUTTON_VARIANT.secondary}
        text={t(previousButtonAsCancel ? 'modalTeamServicesTemplate_FooterButtonCancel.message' : 'modalTeamServicesTemplate_FooterButtonPrevious.message')}
        tabIndex={-1}
      />
      <Button
        data-qa="subscriptions-modalNext-button"
        onClick={onNext}
        disabled={nextButtonDisabled}
        leftIcon={nextButtonAsValidate ? ICON_NAME.check : ICON_NAME.arrowForward}
        variant={BUTTON_VARIANT.primary}
        text={t(nextButtonAsValidate ? 'modalTeamServicesTemplate_FooterButtonValidate.message' : 'modalTeamServicesTemplate_FooterButtonNext.message')}
      />
    </FooterContainer>
  );
};

export default ServicesGroupModalFooter;

export const FooterContainer = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: ${({ isVisible }) => (isVisible ? 'none' : 'inherit')};
`;
