import React from 'react';
import styled, { css } from 'styled-components';
import { ErrorMessage, Field, FieldAttributes, useField, useFormikContext } from 'formik';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { textLink, textP, textPSemiBold, textSmall } from '@features/ui/styles/textStyles';
import TooltipMultiInputLine, { TooltipProps } from '@features/ui-input/formik/MultiInputLine/TooltipMultiInputLine';
import ValidationMultiInputLine, {
  ValidationChipProps,
} from '@features/ui-input/formik/MultiInputLine/ValidationMultiInputLine';
import { InputTextProps } from '@features/ui-input/components/InputText';
import { InputRadioProps } from '@features/ui-input/components/InputRadio';
import RequiredChip from '@features/ui/components/RequiredChip';

export interface InputProps extends FieldAttributes<any> {
  showRequiredChip?: boolean;
  hint?: React.ReactNode;
  name: string;
  as: FieldAttributes<any>['as'];
  maxWidth?: string;
  hideError?: boolean;
  dataQa?: string;
}

type MultiInputTextProps = InputProps & (InputTextProps | InputRadioProps<string | number>);

export type TextAlignment = 'flex-start' | 'flex-end' | 'center';

export interface MultiInputLineProps extends TooltipProps, ValidationChipProps {
  inputs: Array<MultiInputTextProps>;
  label?: React.ReactNode;
  labelSpace?: boolean;
  spaceInterLine?: string,
  spaceInterInput?: string,
  labelMinWidth?: string,
  labelWidthAuto?: boolean,
  textAlignment?: TextAlignment,
  descriptionOrder?: 'column' | 'column-reverse',
  descriptionComponent?: React.ReactNode,
  description?: string,
  descriptionLink?: string,
  descriptionLinkText?: string,
  globalError?: string,
  withoutOuterInputsBlocksWrapper?: boolean
}

const sortComponents = (components, sortFunction) => components.sort(sortFunction);

const getFieldAttributes = (fieldName: string) => {
  const [, meta, helpers] = useField(fieldName);
  return {
    meta,
    helpers,
  };
};

const flexAlignmentToTextAlignment = flex => {
  switch (flex) {
    case 'flex-end':
      return 'right';
    case 'flex-start':
      return 'left';
    default:
      return 'left';
  }
};

/**
 * MultiInputLine is tightly coupled with Formik and delegates most of its work to Formik's `Field` component.
 * It relies on its logic to work and passes it most of the important props, such as `component`, `as` and `children`
 * Read about it here : https://formik.org/docs/api/field
 *
 * MultiInputLine passes most of its props to Formik's Field component, however it also handles the consistent display of
 * everything that's around the input itself such as the label, error, etc. All other props are passed to the Field.
 * @constructor
 */
const MultiInputLine = ({
  label,
  labelSpace,
  spaceInterLine = '20px',
  spaceInterInput = '2px',
  inputs,
  tooltip,
  tooltipSpace,
  validationChip,
  validationChipSpace,
  textAlignment = 'flex-end',
  labelMinWidth = '120px',
  labelWidthAuto = true,
  globalError,
  descriptionOrder = 'column',
  descriptionComponent,
  description,
  descriptionLink,
  descriptionLinkText,
  withoutOuterInputsBlocksWrapper = false,
}: MultiInputLineProps) => {
  const isAnyRequired = !!inputs?.filter((input: InputProps) => input?.showRequiredChip).length;
  const formikContext = useFormikContext();
  const isLineInError = !!inputs.filter(input => input)
    .find((input) => Object.keys(formikContext.errors)
      .includes(input.name));
  const isAllInputTouched = inputs.filter(input => input)
    .every(input => formikContext.getFieldMeta(input.name).touched);

  const tooltipElement = (
    <TooltipMultiInputLine
      tooltip={tooltip}
      tooltipSpace={tooltipSpace}
    />
  );

  const validationElement = (
    <ValidationMultiInputLine
      validationChipSpace={validationChipSpace}
      validationChip={validationChip}
      isOnError={isLineInError}
      areInputsTouched={isAllInputTouched}
    />
  );

  const outerInputBlock = [
    {
      id: 'tooltip',
      element: tooltipElement,
      sortBy: tooltip,
    }, {
      id: 'validation',
      element: validationElement,
      sortBy: validationChip,
    },
  ];

  // This method will put validation icon first if no tooltip specified (to avoid an empty blank space between input and validation icon)
  const outerInputBlockOrdered = sortComponents(outerInputBlock, (a, b) => Number(!!b.sortBy) - Number(!!a.sortBy));

  return (
    <Line spaceInterLine={spaceInterLine} withLabelWidthAuto={labelWidthAuto}>
      {(label || labelSpace) && (
        <LabelContainer>
          <Label
            labelMinWidth={labelMinWidth}
            textAlign={textAlignment}
            textVerticalAlign={(description || descriptionLink) ? 'flex-start' : 'center'}
          >
            {label}
          </Label>
          <RequiredChipWrapper>
            {isAnyRequired && <StyledRequiredChip />}
          </RequiredChipWrapper>
        </LabelContainer>
      )}
      <InputsAndDescriptionContainer descriptionOrder={descriptionOrder}>
        {(descriptionComponent || description || descriptionLink) && (
          <DescriptionContainer>
            {descriptionComponent}
            {description}
            {descriptionLink
              && <DescriptionLink target="_blank" href={descriptionLink}>{descriptionLinkText}</DescriptionLink>}
          </DescriptionContainer>
        )}
        <InputsContainer>
          <InputsWrapper>
            {inputs.map((input) => (

              <FieldWrapper
                maxWidth={input.maxWidth}
                spaceInterInput={spaceInterInput}
                key={input.type === 'radio' ? `${input.name}-${input.value}` : input.name}
                className={input.inputWrapperClassName}
              >
                <Field
                  data-qa={input.dataQa}
                  name={input.name}
                  as={input.as}
                  component={input.component}
                  {...getFieldAttributes(input.name)}
                  {...input}
                >
                  {input.children}
                </Field>
                {!input.hideError && (
                  <ErrorMessage name={input.name}>
                    {msg => (msg.trim().length ? <StyledErrorMessage>{msg}</StyledErrorMessage> : <></>)}
                  </ErrorMessage>
                )}
                {input.hint}
              </FieldWrapper>

            ))}
          </InputsWrapper>
          {globalError && (
            <StyledErrorMessage>{globalError}</StyledErrorMessage>
          )}
        </InputsContainer>
      </InputsAndDescriptionContainer>
      {!withoutOuterInputsBlocksWrapper && (
        <OuterInputsBlocksWrapper>
          {outerInputBlockOrdered.map(item => (
            <div key={item.id}>
              {item.element}
            </div>
          ))}
        </OuterInputsBlocksWrapper>
      )}
    </Line>
  );
};

export default MultiInputLine;

const InputsAndDescriptionContainer = styled.div<{ descriptionOrder: string }>`
  display: flex;
  flex-direction: ${({ descriptionOrder }) => `${descriptionOrder}`};
  grid-area: inputsAndDescription;
  justify-content: center;
  width: 100%;
  place-self: center;
`;

const DescriptionLink = styled.a`
  ${textLink};
  color: var(--color-grey4);
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${textPSemiBold};
  margin-bottom: 8px;
  color: var(--color-grey4);
`;

export const StyledErrorMessage = styled.div`
  ${textSmall};
  line-height: 20px;
  min-height: 20px;
  color: var(--color-red);

  & div:first-letter {
    text-transform: uppercase;
  }
`;

const Line = styled.div<{ spaceInterLine?: string, withLabelWidthAuto?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-areas: "label inputsAndDescription icons";
  grid-template-columns: ${({ withLabelWidthAuto }) => (withLabelWidthAuto ? 'auto' : '1fr')} 1fr auto;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: ${({ spaceInterLine }) => spaceInterLine};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mediaBreakpoint.tablet} {
    grid-template-areas: 
      "label _"
      "inputsAndDescription icons";
    grid-template-columns: 1fr auto;
  }
`;

const LabelContainer = styled.div`
  grid-area: label;
  display: flex;
`;

const Label = styled.div<{ labelMinWidth: string | undefined | null, textAlign: string, textVerticalAlign: 'center' | 'flex-start' }>`
  ${textP};
  flex-grow: 1;
  color: var(--color-grey4);
  min-width: ${({ labelMinWidth }) => labelMinWidth};
  width: ${({ labelMinWidth }) => labelMinWidth};
  min-height: 44px;
  display: flex;
  align-items: ${({ textVerticalAlign }) => textVerticalAlign};
  justify-content: ${({ textAlign }) => textAlign};
  text-align: ${({ textAlign }) => flexAlignmentToTextAlignment(textAlign)};

  ${mediaBreakpoint.tablet} {
    text-align: left;
    min-width: unset;
    justify-content: flex-start;
    min-height: 24px;
    margin-bottom: 12px;
    align-items: flex-start;
    width: unset;
  }
`;

const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  grid-area: inputs;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldWrapper = styled.div<{ spaceInterInput?: string, maxWidth?: string }>`
  flex: 1 0 0;

  margin-right: ${({ spaceInterInput }) => spaceInterInput};

  &:last-of-type {
    margin-right: 0;
  }

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
`;

const RequiredChipWrapper = styled.div`
  width: 20px;
  height: 100%;
  margin: 0 12px;

  ${mediaBreakpoint.tablet} {
    margin-right: 0;
  }
`;

const StyledRequiredChip = styled(RequiredChip)`
  margin-top: 12px;
`;

const OuterInputsBlocksWrapper = styled.div`
  grid-area: icons;
  display: flex;
`;
