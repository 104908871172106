import styled from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { textP, textPBold, textSmall } from '@features/ui/styles/textStyles';
import ToggleCard from '@features/ui/components/ToggleCard';
import { sizeColumns } from './index';

export const Container = styled.div<{isLoading: boolean}>`
  > *:first-child {
    margin-top: 40px;
  }
  ${({ isLoading }) => isLoading && 'opacity: 0.4;'}
  ${({ isLoading }) => isLoading && 'cursor: not-allowed;'}
  transition: opacity 0.3s ease-in-out;
`;

export const TableHeader = styled.div<{ sizeColumns: sizeColumns, hasBody: boolean }>`
  display: grid;
  padding-left: ${({ hasBody }) => (hasBody ? '62px' : '20px')};
  padding-right: 20px;
  grid-template-columns: ${({ sizeColumns }) => sizeColumns.desktop};
  ${mediaBreakpoint.tablet} {
    grid-template-columns: ${({ sizeColumns }) => sizeColumns.tablet};
  }
  ${mediaBreakpoint.mobile} {
    grid-template-columns: ${({ sizeColumns }) => sizeColumns.mobile};
  }
  margin-bottom: 12px;
  color: var(--color-grey3);
  text-transform: uppercase;
  ${textSmall};
  word-break: break-word;
   > div {
     padding: 3px;
   }
`;

export const TableHeaderCell = styled.div<{ isCenter: boolean }>`
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'start')};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 4px;
  }
  margin-bottom: 40px;
`;

export const TableItemHeader = styled.div<{ sizeColumns: sizeColumns }>`
  display: grid;
  grid-template-columns: ${({ sizeColumns }) => sizeColumns.desktop};
  ${mediaBreakpoint.tablet} {
    grid-template-columns: ${({ sizeColumns }) => sizeColumns.tablet};
  }
  ${mediaBreakpoint.mobile} {
    grid-template-columns: ${({ sizeColumns }) => sizeColumns.mobile};
  }
  align-items: center;
  width: 100%;

  > div {
    padding: 0;
  }
`;

export const TableItem = styled(ToggleCard)`
  & > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const Text = styled.div<{ isBold: boolean; isStrikethrough: boolean; isCenter: boolean }>`
  ${({ isBold }) => (isBold ? textPBold : textP)}
  word-break: break-word;
  padding: 5px;
  box-sizing: border-box;
  text-decoration: ${({ isStrikethrough }) => (isStrikethrough ? 'line-through' : 'none')};
  display: flex;
  align-items: ${({ isCenter }) => (isCenter ? 'center' : 'flex-start')};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  flex-direction: column;
`;

export const TableItemBody = styled.div`
  display: block;
  padding: 20px 0;
`;
export const TableItemBodyLine = styled.div`
  display: flex;
`;
export const TableItemBodyLineLabel = styled(Text)`
  text-align: right;
  min-width: 135px;
  ${mediaBreakpoint.mobile} {
    min-width: 90px;
  }
`;
