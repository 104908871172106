import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { textPSemiBold } from '@features/ui/styles/textStyles';

export interface InputRadioProps<T extends string | number> {
  type?: 'radio';
  /** Name of the input radio elements */
  name?: string;
  /** Whether the radio is checked or not */
  checked?: boolean;
  /** Value when this Radio is picked */
  value: T;
  /** Function called when the user changes the value */
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  /** Whether the component is disabled */
  disabled?: boolean;
  /** ClassName to pass to the parent div */
  className?: string;

  /** Other props are passed to the inner <input> element */
  [etc: string]: any;
}

// The `warning` is taken out of the props and not applied anywhere, because there *shouldn't* be any Radio without at
// least a defaultValue. Having a Radio where it's ok for it to be empty but no means to select an empty value means
// its starting state could never be attained again, which is otherwise doable by the user by - for example - refreshing
// the page, so it's definitely not what we want. So, don't place a Radio without a default value.

/**
 * A radio button. Can be used both controlled and uncontrolled.
 * @constructor
 */
export const InputRadio: React.FunctionComponent<InputRadioProps<string | number>> = ({
  label,
  disabled,
  className,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  warning,
  ...etc
}) => (
  <RadioContainer disabled={disabled} className={className}>
    <InvisibleRealInput
      type="radio"
      className="radioInput"
      disabled={disabled}
      readOnly
      {...etc}
    />
    <Background className="radioBackground" disabled={disabled}>
      <Circle className="radioCircle" disabled={disabled} />
    </Background>
    <RadioLabelContainer className="radioLabel" hasText={label != null} disabled={disabled}>
      {label}
    </RadioLabelContainer>
  </RadioContainer>
);

export default InputRadio;

// Radio variant styles

const RadioContainer = styled.label<{ inline?: boolean, rtl?: boolean, disabled?: boolean}>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background: 'transparent';
  padding: 0;
  margin: 10px 0;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : '')}
  & input:checked ~ .radioBackground .radioCircle {
    opacity: 1;
  }
  & input:checked ~ .radioBackground ~ .radioLabel {
    color: ${({ disabled }) => (disabled ? 'var(--color-grey3)' : 'var(--color-grey4)')};
  }

  --input-radio-main-color: var(--color-orange);

  &:hover {
    --input-radio-main-color: var(--color-orange-hover);
  }

  transition: all 0.25s;
`;

const Background = styled.div<{disabled?: boolean}>`
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 10px;
  padding: 2px;
  border: 2px solid var(--input-radio-main-color);
  background: var(--color-white);
  cursor: pointer;
  ${({ disabled }) => disabled && css`
    background: var(--color-white);
    border: 2px solid var(--color-grey3);
    cursor: not-allowed;
  `}
  justify-content: center;
  align-items: center;
  display: inline-flex;
`;

const Circle = styled.div<{ disabled: boolean; }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 7px;
  background: ${({ disabled }) => (disabled ? 'var(--color-grey3)' : 'var(--input-radio-main-color)')};
  left: 3px;
  top: 3px;
  opacity: 0;
  transition: opacity 0.1s;
`;

export const InvisibleRealInput = styled.input`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
`;

const RadioLabelContainer = styled.div<{ hasText: boolean; disabled: boolean; }>`
  ${({ hasText }) => (hasText ? `
    margin-left: 12px;
  ` : `
    visibility: hidden;
  `)};
  ${textPSemiBold};
  color: ${({ disabled }) => (disabled ? 'var(--color-grey3)' : 'var(--color-grey4)')};
`;
