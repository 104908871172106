import styled, { css } from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import Card from '@features/ui/components/Card';
import Icon from '@features/ui/components/Icon';
import { textH4 } from '@features/ui/styles/textStyles';
import InputCheckbox from '@features/ui-input/components/InputCheckbox';

export const CardContainer = styled(Card)`
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
`;

export const BodyWrapper = styled.div<{ open: boolean; maxHeight: number; }>`
 transition: max-height 0.5s;
 position: relative;
 overflow: hidden;
 height: 100%;
 max-height: 0;
  ${({ open, maxHeight }) => open && css`
    max-height: ${maxHeight}px;
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: " ";
      background: var(--color-grey1);
    }
  `}
`;

export const Body = styled.div`
  padding: 20px;
`;

export const HeaderFirstLine = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 20px;
`;

export const HeaderSecondLine = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<{ open: boolean, height?: number, hightlight?: boolean, disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: ${({ height }) => height ?? 44}px;

  ${({ disabled }) => !disabled && css`
    cursor: pointer;
  `}

  ${({ hightlight }) => hightlight && css`
    background: var(--color-orange-light);
    ::after {
      position: absolute;
      content: ' ';
      height: calc(100% + 2px);
      width: 4px;
      background: var(--color-orange);
      right: 0;
      top: 0;
      border-radius: 0 100px 100px 0
    }
  `}

  transition: all 0.3s;
`;

export const Chip = styled.div`
  background: var(--color-orange);
  margin-left: auto;
  height: 32px;
  width: 138px;
  border-radius: 20px 0 0 0;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  right: 0;
  ${textH4};
  ${mediaBreakpoint.tablet} {
    position: relative;
  }
`;

export const TagsWrapper = styled.div<{ marginBottom?: boolean }>`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 45px;
  margin-bottom: 20px;
  margin-top: -10px;
`;

export const ArrowIcon = styled(Icon) <{ open: boolean, alignArrowOnTopOnMobile?: boolean }>`
  transform: rotate(${({ open }) => (open ? '90deg' : '0deg')});
  transition: all 0.3s;
  margin-right: 10px;

  ${({ alignArrowOnTopOnMobile }) => alignArrowOnTopOnMobile && css`
    ${mediaBreakpoint.mobile} {
      align-self: flex-start;
    }
  `}
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
  width: auto;
  margin-top: -1px;
  margin-right: 12px;
`;
