import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import moment from 'moment';
import i18nConfig from '@services/translation/i18n';
import { isAuthenticated } from '@services/cookies';
import { GET_DATA_FOR, getServerSideData } from '@services/dataHandler/getPageData';
import getRoute, { buildAsUrl } from '@services/routeList';
import { rollbarServerSide } from '@services/rollbar';
import logger, { LOG_TITLE } from '@services/logger';
import { setResponsive, setWithLogin } from '@features/ui-layout/slices/uiLayoutSlice';
import { MOBILE_DEVICES_REGEX, RESPONSIVE, TABLET_DEVICES_REGEX } from './responsiveProvider';

export const buildRedirectSsrLoginForRequiredAuthPage: (
  serverSideContext: GetServerSidePropsContext
) => string = (
  serverSideContext
) => {
  const { resolvedUrl } = serverSideContext;
  return buildAsUrl(getRoute.loginForRequiredAuthPage(resolvedUrl));
};

export class SsrRequestError extends Error {
  ssrReturn?: GetServerSidePropsResult<{}> = null

  constructor(ssrReturn: GetServerSidePropsResult<{}>, ...params) {
    super(...params);
    this.ssrReturn = ssrReturn;
  }
}

interface SsrProviderParameters {
  serverSideContext?: GetServerSidePropsContext,
  store,
  process?: ({ store }) => Promise<{ overwriteReturn: GetServerSidePropsResult<{}> } | boolean | void>;
  authIsRequired?: boolean;
  withLogin?: boolean;
}
const ssrProvider: (ssrProviderParameters: SsrProviderParameters) => Promise<GetServerSidePropsResult<{}>> = async ({
  serverSideContext,
  process,
  store,
  authIsRequired = false,
  withLogin = true,
}) => {
  const t1 = moment();
  store.dispatch(setWithLogin(withLogin));
  const isAuth = isAuthenticated(serverSideContext);

  const isMobile = MOBILE_DEVICES_REGEX.test(serverSideContext.req?.headers['user-agent']);
  const isTablet = TABLET_DEVICES_REGEX.test(serverSideContext.req?.headers['user-agent']);
  if (isMobile) {
    store.dispatch(setResponsive(RESPONSIVE.MOBILE));
  } else if (isTablet) {
    store.dispatch(setResponsive(RESPONSIVE.TABLET));
  } else {
    store.dispatch(setResponsive(RESPONSIVE.DESKTOP));
  }

  if (authIsRequired && !isAuth) {
    return {
      redirect: {
        permanent: false,
        destination: buildRedirectSsrLoginForRequiredAuthPage(serverSideContext),
      },
    };
  }

  try {
    if (isAuth && withLogin) {
      await getServerSideData(store, serverSideContext, GET_DATA_FOR.AUTH);
    }

    if (process) {
      const processReturn = await process({ store });
      if (processReturn instanceof Object) {
        return processReturn.overwriteReturn;
      }
    }
  } catch (error) {
    if (error instanceof SsrRequestError) {
      return error.ssrReturn;
    }
    const referer = serverSideContext?.req?.headers?.referer;
    const url = serverSideContext?.req?.url;

    logger(LOG_TITLE.ssrProviderError, {
      message: `SSR error url: ${url}`,
      data: {
        url,
        referer,
        error,
      },
    });
    // eslint-disable-next-line no-console
    console.error(error.stack);
    rollbarServerSide.error(error, url, referer);
    return { notFound: true };
  }

  logger(LOG_TITLE.ssrProvider, {
    message: 'analyze',
    data: {
      responseTime: moment().diff(t1, 'ms', true),
      context: serverSideContext,
    },
  });
  return {
    props: {
      initialReduxState: store.getState(),
      ...(await serverSideTranslations(serverSideContext.locale, [], i18nConfig)),
      withLogin,
    },
  };
};

export default ssrProvider;
