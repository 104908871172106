import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import InputCheckbox from '@features/ui-input/components/InputCheckbox';

interface TogglePriceProps {
  name: string;
  price: string;
  currency: string;
}

const TogglePrice = ({
  name,
  price,
  currency,
  ...etc
}: TogglePriceProps) => {
  const [field] = useField(name);
  return (
    <Container>
      <InputCheckbox name={name} value={field.value} {...etc} />
      <Spacer />
      <Quantity>
        {field.value ? '1' : '0'}
      </Quantity>
      <Multiplication>×</Multiplication>
      <Price>{`${price} ${currency}`}</Price>
    </Container>
  );
};

export default TogglePrice;

const Container = styled.div`
   display: flex; 
`;

const Spacer = styled.div`
  flex: 1;
`;

const Multiplication = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0 4px 0 7px;
`;
const Price = styled.div`
  min-width: 93px;
  text-align: right;
  display:flex;
  justify-content: flex-end;
  align-items: center;
`;

const Quantity = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
