import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import Link from 'next/link';
import getRoute from '@root/src/services/routeList';
import config from '@root/src/config';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import { textH2Light, textP } from '@features/ui/styles/textStyles';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import Menu from '@features/ui-layout/components/Menu';

const ModalMenu = ({ closeModal }: {closeModal: () => void}) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  useEffect(() => {
    if (responsive === RESPONSIVE.DESKTOP) {
      closeModal();
    }
  }, [responsive]);
  return (
    <Wrapper>
      <Header>
        <MenuTitle>{t('header_menu.message')}</MenuTitle>
        <Icon name={ICON_NAME.clear} onClick={closeModal} color="var(--color-orange)" />
      </Header>
      <Menu />
      <Separator />
      <Link href={getRoute.events(config.pdg.slug).description()}>
        <a>
          <Logo src="/images/mso-logo-black.png" />
        </a>
      </Link>
      <Description>{t('header_description.message')}</Description>
    </Wrapper>
  );
};
export default ModalMenu;

const Wrapper = styled.div`
  padding: 20px;
  min-width: var(--page-min-width);
`;
const MenuTitle = styled.span`
  ${textH2Light};
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Separator = styled.div`
  height: 2px;
  margin: 20px -20px;
  width: calc(100% + 40px);
  background: var(--color-grey1);
`;
const Logo = styled.img`
  height: 44px;
  display: block;
`;
const Description = styled.span`
  ${textP};
  white-space: break-spaces;
  color: var(--color-grey4);
  margin-top: 5px;
`;
