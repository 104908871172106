import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { UrlObject } from 'url';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { setAuthCookies } from '@services/cookies';
import api from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import { mediaBreakpoint } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import { textH1 } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { selectIsAuthenticated } from '@features/users/slices/userSlice';

interface ModalLink {
  icon: ICON_NAME,
  translationKey: string,
  link: UrlObject,
  urlTargetBlank?: boolean,
  disabled: boolean // TODO: remove this when all links are implemented
}

interface LoginFormInputs {
  username: string;
  password: string;
}
interface ModalLoginProps {
  closeModal: () => void;
  redirectUrl?: string;
}
const ModalLogin = ({ closeModal, redirectUrl }: ModalLoginProps) => {
  const [passwordInputType, setPasswordInputType] = useState('password');
  const requestHandler = useRequestHandler();
  const router = useRouter();
  const { t } = useTranslation();

  /* eslint array-element-newline: 0 */
  const modalLinks: Array<ModalLink> = [
    {
      icon: ICON_NAME.lock,
      translationKey: 'modalLogin_passwordForgotten',
      link: getRoute.openModal(router).resetPassword(),
      disabled: false,
    }, /* {
    icon: ICON_NAME.person,
    translationKey: 'modalLogin_usernameForgotten',
    link: getRoute...,
    disabled: false,
  }, */ {
      icon: ICON_NAME.assignmentInd,
      translationKey: 'modalLogin_createAccount',
      link: getRoute.openModal(router).register(),
      disabled: false,
    },
  ];

  const connected = useSelector(selectIsAuthenticated);
  if (connected) {
    closeModal();
  }

  const authenticateUser = async (response: Response) => {
    const { token, user } = await response.json();
    setAuthCookies({
      token,
      userId: user._idMso,
    });

    if (redirectUrl) {
      return router.push(redirectUrl, null, { scroll: false });
    }
    return closeModal();
  };

  const onSubmitLogin = async (values: LoginFormInputs, { setSubmitting, setFieldError }) => {
    const { username, password } = values;

    const { response } = await requestHandler({
      request: api.auth.login({
        body: {
          login: username,
          password,
        },
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });
    if (!response || !response?.ok) {
      setSubmitting(false);
      setFieldError('username', ' ');
      setFieldError('password', t('modalLogin_wrongCredentials.message'));
    } else {
      await authenticateUser(response as Response);
    }
  };

  const LoginFooter = ({ closeModal }) => (
    <Footer>
      <Button
        text={t('cancel.message')}
        leftIcon={ICON_NAME.keyboardReturn}
        variant={BUTTON_VARIANT.secondary}
        onClick={closeModal}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<LoginFooter closeModal={closeModal} />} closeModal={closeModal}>
      <ModalLoginContainer>
        <Title>{t('login.message')}</Title>
        <FormWrapper>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={onSubmitLogin}
          >
            {({ isSubmitting }) => (
              <Form className="modalLoginContainer_login-form">
                <InputLine
                  label={t('username.message')}
                  name="username"
                  as={InputText}
                  textAlignment="flex-end"
                  data-qa="users-username-input"
                />
                <InputLine
                  label={t('password.message')}
                  name="password"
                  type={passwordInputType}
                  as={InputText}
                  textAlignment="flex-end"
                  rightIcon={<Icon name={passwordInputType === 'password' ? ICON_NAME.eye : ICON_NAME.eyeClosed} color="var(--color-grey3)" />}
                  onRightIconCallback={() => setPasswordInputType(prevState => (prevState === 'password' ? 'text' : 'password'))}
                  data-qa="users-password-input"
                />
                <ModalSubmitButton
                  text={t('login.message')}
                  leftIcon={ICON_NAME.accountCircle}
                  type="submit"
                  disabled={isSubmitting}
                  data-qa="users-submitLogin-button"
                />
              </Form>
            )}
          </Formik>
        </FormWrapper>
        <LinksContainer>
          {modalLinks.map(link => (
            <Button
              key={link.translationKey}
              text={t(`${link.translationKey}.message`)}
              leftIcon={link.icon}
              variant={BUTTON_VARIANT.link}
              disabled={link.disabled}
              url={link.link}
              urlTargetBlank={link.urlTargetBlank}
            />
          ))}
        </LinksContainer>
      </ModalLoginContainer>
    </ModalTemplate>
  );
};

export default ModalLogin;

const ModalLoginContainer = styled.div`
  margin-bottom: 40px;

  & .modalLoginContainer_submit{
    flex-grow: 1;
    margin-left: 164px;
    margin-top: 20px
  }

  & .modalLoginContainer_login-form{
    display: flex;
    flex-direction: column;
  }
`;

const ModalSubmitButton = styled(Button)`
  flex-grow: 1;
  margin-left: 164px;
  margin-top: 20px;
  ${mediaBreakpoint.tablet} {
    margin-left: unset;
    margin-top: 36px;
  }
`;

const FormWrapper = styled.div`
  width: 565px;
  ${mediaBreakpoint.tablet} {
    width: 100%;
  }
`;

const Title = styled.h1`
  ${textH1};
  margin-bottom: 32px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 164px;
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
  ${mediaBreakpoint.tablet} {
    margin-left: unset;
  }
`;

const Footer = styled.div`
  width: 100%;
`;
