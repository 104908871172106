/* eslint-disable no-console */
import { Orders } from '@root/src/types';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import api from '@services/api';
import { selectOrders, setOrdersList } from '@features/orders/slices/ordersSlice';

class OrderDataHandler extends InitDataHandler {
  getOrders: () => Promise<Orders | null> = async () => {
    const data = { locale: this.locale };
    const { response } = await this.requestHandler({
      request: api.subscription.orders.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setOrdersList(json));
      return selectOrders(this.store.getState());
    }
    return null;
  };
}

export default OrderDataHandler;
