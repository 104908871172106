import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textH1, textH4 } from '@features/ui/styles/textStyles';
import { useCartDataHandler } from '@features/carts/CartDataHandler';
import { selectCartItem } from '../slices/cartsSlice';

const ModalCartDeleteItemTemplate = ({ closeModal, itemId, categoryName }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const cartDataHandler = useCartDataHandler();

  const item = useSelector(selectCartItem(itemId));
  const itemPackId = item?.partOfPack;

  const deleteItem = async () => {
    setDeleting(true);
    const response = await itemPackId
      ? cartDataHandler.deletePack(itemPackId)
      : cartDataHandler.deleteCart(itemId);
    if (response) {
      closeModal();
    }
    setDeleting(false);
  };

  return (
    <ModalTemplate
      footer={(
        <Footer
          onCancel={closeModal}
          onValidate={deleteItem}
          sending={deleting}
        />
      )}
      closeModal={closeModal}
    >
      <Container>
        <Title>
          {t('modalCartDeleteItemTemplate_title.message')}
        </Title>

        <Text>
          {t('modalCartDeleteItemTemplateProceed_text.message', { name: categoryName })}
        </Text>

        {itemPackId && (
          <Text>
            {t('modalCartDeleteItemTemplateForPack_text.message', { name: categoryName })}
          </Text>
        )}
      </Container>
    </ModalTemplate>
  );
};

export default ModalCartDeleteItemTemplate;

const Footer = ({ onCancel, onValidate, sending }) => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <Button
        onClick={onCancel}
        leftIcon={ICON_NAME.keyboardReturn}
        disabled={sending}
        variant={BUTTON_VARIANT.secondary}
        text={t('modalCartDeleteItemTemplate_FooterCancel.message')}
      />
      <Button
        onClick={onValidate}
        disabled={sending}
        leftIcon={ICON_NAME.check}
        variant={BUTTON_VARIANT.primary}
        text={t('modalCartDeleteItemTemplate_FooterValidate.message')}
      />
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  padding-bottom: 40px;
`;

const Title = styled.h1`
  ${textH1};
  margin-bottom: 28px;
`;

const Text = styled.p`
  ${textH4};
  margin-top: 48px;
`;
