import React from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import RequiredChip from '@features/ui/components/RequiredChip';
import Tooltip from '@features/ui/components/Tooltip';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { selectCurrentTeamMate, setServicesProfile } from '@features/subscriptions/slices/formSubscriptionSlice';
import ServiceUserSubscription from '@features/subscriptions/components/ServiceUserSubscription';
import ServiceUserSubscriptionPreview from '@features/subscriptions/components/ServiceUserSubscriptionPreview';
import { textH3 } from '@features/ui/styles/textStyles';

interface SelectTeamMateProps {
  nbTeamMates: number
  teamMateIndex: number,
  isInEditMode: boolean,
  categoryId: number,
}

// TODO split this file for edit or create mode with a raw component
const SelectTeamMate = ({
  nbTeamMates,
  teamMateIndex,
  isInEditMode,
  categoryId,
}: SelectTeamMateProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTeamMate = useSelector(selectCurrentTeamMate(categoryId, teamMateIndex));

  return (
    <>
      <UserTitle>
        {t(teamMateIndex === 0 ? 'modalTeamServicesTemplate_createLeaderTitle.message' : 'modalTeamServicesTemplate_createTeamMateTitle.message', {
          current: teamMateIndex + 1,
          count: nbTeamMates,
        })}
        <RequiredChip />

        {teamMateIndex === 0
        && (
          <Tooltip title={t('modalTeamServicesTemplate_createLeaderTooltip.message')}>
            <Icon size="20px" color="var(--color-grey3)" name={ICON_NAME.info} />
          </Tooltip>
        )}
      </UserTitle>
      {currentTeamMate
        ? (
          <StyledServiceUserSubscriptionPreview
            user={currentTeamMate}
            onRemove={() => dispatch(setServicesProfile({
              categoryId,
              profile: null,
              index: teamMateIndex,
            }))}
            notRemovable={isInEditMode}
          />
        )
        : (
          <StyledServiceUserSubscription
            teamMateIndex={teamMateIndex}
            categoryId={categoryId}
          />
        )}
    </>
  );
};

export default SelectTeamMate;

export const StyledServiceUserSubscription = styled(ServiceUserSubscription)`
  margin-bottom: 40px;
`;

export const StyledServiceUserSubscriptionPreview = styled(ServiceUserSubscriptionPreview)`
  margin-bottom: 40px;
`;

export const UserTitle = styled.h3`
  ${textH3};
  display: flex;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-left: 10px;
    margin-right: auto;
  }

  margin-bottom: 20px;
`;
