import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import Head from 'next/head';
import App from 'next/app';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary, Provider } from '@rollbar/react';
import useNavigationHistory from '@root/src/hooks/useNavigationHistory';
import config from '@root/src/config';
import polyfill from '@utils/polyfill';
import ReduxWrapper from '@services/store';
import i18nConfig from '@services/translation/i18n';
import { useSetResponsive } from '@services/responsiveProvider';
import { containerConfig } from '@services/notificationConfig';
import { rollbarServerSide } from '@services/rollbar';
import GlobalStyle from '@features/ui/styles/global';
import InputSelectOverrides from '@features/ui-input/components/InputSelect/muiOverrides';
import ModalContainer from '@features/ui-layout/templates/ModalContainer';
import Layout from '@features/ui-layout/components/Layout';

const materialTheme = createMuiTheme({
  palette: { action: { hover: 'var(--color-orange-light)' } },
  props: { MuiButtonBase: { disableRipple: true } },
  overrides: { ...InputSelectOverrides },
});

polyfill();

function MyApp({ Component, pageProps }) {
  useSetResponsive();
  useNavigationHistory();
  const { t } = useTranslation();
  return (
    <>
      <GlobalStyle />
      <Head>
        <title>{t('head_defaultTitle.message')}</title>
        <meta name="description" content={t('header_description.message')} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="UTF-8" />
        <link rel="preload" href="/fonts/barlow-medium-webfont.woff2" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/jeko-black-webfont.woff2" as="font" crossOrigin="" />
      </Head>
      <ThemeProvider theme={materialTheme}>
        <ModalContainer />
        <ToastContainer {...containerConfig} />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </>
  );
}
const MyAppWithHoc = ReduxWrapper.withRedux(appWithTranslation(MyApp, i18nConfig));

const MyAppWithErrorCatcher = ({ Component, pageProps }) => (
  <Provider config={config.rollbar}>
    <ErrorBoundary>
      <MyAppWithHoc Component={Component} pageProps={pageProps} />
    </ErrorBoundary>
  </Provider>
);
MyAppWithErrorCatcher.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { err, req } = appContext.ctx;
  if (!process.browser && err) {
    // eslint-disable-next-line no-console
    console.error('SSR error : ', err);
    // eslint-disable-next-line no-console
    console.error(err.stack);
    rollbarServerSide.error(err, req);
  }
  return { ...appProps };
};
export default MyAppWithErrorCatcher;
