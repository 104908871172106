import { Category, EngagedUser, Event, EVENT_LIST_ITEM_TYPES, Quota, Service, ServiceGroup, SubscriptionService } from '@root/src/types';
import { removeUndefs } from '@utils/gobal';
import { athleteShortProfileFromApiToReducer } from '@features/users/formatters';

export const eventFromApiToReducer = (eventApi: any): Event => removeUndefs<Event>({
  _id: eventApi._id,
  _idMso: parseInt(eventApi._idMso, 10),
  _type: EVENT_LIST_ITEM_TYPES.event,
  name: eventApi.name,
  canceled: Boolean(eventApi.canceled),
  description: eventApi.description,
  date: eventApi.date,
  dateTo: eventApi.dateTo,
  dateFrom: eventApi.dateFrom,
  eventType: eventApi.eventType,
  sportCategory: eventApi.sportCategory,
  currency: eventApi.currency,
  serie: eventApi.serie?.name ? {
    _idMso: parseInt(eventApi.serie._idMso, 10),
    active: Boolean(eventApi.serie.active),
    isChampionship: Boolean(eventApi.serie.isChampionship),
    grouped: Boolean(eventApi.serie.grouped),
    name: eventApi.serie.name,
    passport: eventApi.serie.passport,
    passportPack: eventApi.serie.passportpack,
    reduction: eventApi.serie.reduction,
    urlPresentation: eventApi.serie.urlpresentation,
    year: eventApi.serie.year,
    slug: eventApi.serie.slug,
  } : null,
  gps: eventApi.gps != null ? ({
    latitude: parseFloat(eventApi.gps.latitude),
    longitude: parseFloat(eventApi.gps.longitude),
  }) : null,
  location: eventApi.location,
  site: eventApi.site,
  maxRegistrations: eventApi.maxRegistrations,
  npaWeather: eventApi.npameteo,
  status: {
    registration: eventApi.status.registration,
    registrationFrom: eventApi.status.registrationFrom,
    registrationTo: eventApi.status.registrationTo,
  },
  displayWithdrawalVoucher: !!eventApi.displayWithdrawalVoucher,
  urlOrganiser: eventApi.urlorganiser,
  slug: eventApi.slug,
  vip: eventApi.vip,
  showStartListFrom: eventApi.showStartListFrom,
  imageCover: eventApi.imagecover,
  logo: eventApi.logo,
  live: (eventApi.live ? {
    type: eventApi.live.type,
    url: eventApi.live.url,
    isNow: eventApi.live.isNow,
  } : null),
  hasEventResults: eventApi.hasEventResults,
  hasSerieResults: eventApi.hasSerieResults,
  hasLiveResults: eventApi.hasLiveResults,
  sponsorStartDate: eventApi.sponsorStartDate,
  sponsorEndDate: eventApi.sponsorEndDate,
});

export const eventCategoryFromApiToReducer = (
  eventCategoryApi: any,
): Category => removeUndefs<Category>({
  _idMso: parseInt(eventCategoryApi._idMso, 10),
  active: Boolean(eventCategoryApi.active),
  distance: eventCategoryApi.distance,
  name: eventCategoryApi.name,
  ageMax: eventCategoryApi.ageMax,
  ageMin: eventCategoryApi.ageMin,
  priceObj: { value: eventCategoryApi.priceObj?.value },
  description: eventCategoryApi?.description,
  gender: eventCategoryApi.gender,
  maxRegistrations: eventCategoryApi.maxRegistrations,
  team: eventCategoryApi.team ? {
    nbMembers: eventCategoryApi.team.nbMembers,
    roles: eventCategoryApi.team.roles,
  } : null,
  preServices: eventCategoryApi.preServices ? {
    vip: eventCategoryApi.vip,
    license: eventCategoryApi.preServices.license,
    licenseType: eventCategoryApi.preServices.licenseType,
    licenseMandatory: eventCategoryApi.preServices.licenseMandatory,
    insurance: eventCategoryApi.preServices.insurance,
    emergencyNumber: eventCategoryApi.preServices.emergencyNumber,
    emergencyNumberMandatory: eventCategoryApi.preServices.emergencyNumberMandatory,
    club: eventCategoryApi.preServices.club,
    clubMandatory: eventCategoryApi.preServices.clubMandatory,
  } : null,
  event: eventCategoryApi.event ? eventFromApiToReducer(eventCategoryApi.event) : null,
  eventType: eventCategoryApi.eventType,
  sportCategory: eventCategoryApi.sportCategory,
  lockgrpsupdate: eventCategoryApi.lockgrpsupdate,
  lockpostorder: eventCategoryApi.lockpostorder,
  diploma: eventCategoryApi.diploma,
});

export const subscriptionServiceGroupFromApiToReducer = (subscriptionServiceGroup): ServiceGroup => removeUndefs({
  _idMso: parseInt(subscriptionServiceGroup._idMso, 10),
  updatable: subscriptionServiceGroup.updatable,
  sortIndex: subscriptionServiceGroup.sortindex,
  title: subscriptionServiceGroup.title,
  groupType: subscriptionServiceGroup.groupType,
  services: subscriptionServiceGroup.services.map(subscriptionServiceFromApiToReducer),
  teamMember: subscriptionServiceGroup.teamMember,
});

export const subscriptionServiceFromApiToReducer = (
  subscriptionServiceApi: any,
): Service => removeUndefs<SubscriptionService>({
  ...(eventCategoryServiceFromApiToReducer(subscriptionServiceApi)),
  canBeUpdated: subscriptionServiceApi.canBeUpdated,
  notUpdatableReason: subscriptionServiceApi.notUpdatableReason,
  value: subscriptionServiceApi.value,
});

export const eventCategoryServiceGroupFromApiToReducer = (
  eventCategoryServiceGroupApi: any,
): ServiceGroup => removeUndefs<ServiceGroup>({
  _idMso: parseInt(eventCategoryServiceGroupApi._idMso, 10),
  updatable: eventCategoryServiceGroupApi.updatable,
  sortIndex: eventCategoryServiceGroupApi.sortindex,
  title: eventCategoryServiceGroupApi.title,
  groupType: eventCategoryServiceGroupApi.groupType,
  services: eventCategoryServiceGroupApi.services.map(eventCategoryServiceFromApiToReducer),
});
export const eventCategoryServiceFromApiToReducer = (
  eventCategoryServiceApi: any,
): Service => removeUndefs<Service>({
  _idMso: parseInt(eventCategoryServiceApi._idMso, 10),
  mandatory: eventCategoryServiceApi.mandatory,
  fieldType: eventCategoryServiceApi.fieldtype,
  invoiced: eventCategoryServiceApi.invoiced,
  price: eventCategoryServiceApi.price,
  image: eventCategoryServiceApi.image,
  url: eventCategoryServiceApi.url,
  updatable: eventCategoryServiceApi.updatable,
  sortIndex: eventCategoryServiceApi.sortindex,
  atLeast1: eventCategoryServiceApi.atleast1,
  label: eventCategoryServiceApi.label,
  tooltip: eventCategoryServiceApi.tooltip,
  choices: eventCategoryServiceApi.choices,
  validation: eventCategoryServiceApi.validation,
});

export const eventQuotaFromApiToReducer = (eventQuotaApi: any): Quota => {
  const max = eventQuotaApi.eventQuota?.max ?? Number.MAX_SAFE_INTEGER;
  return removeUndefs<Quota>({
    max,
    current: eventQuotaApi.eventQuota?.current,
    full: eventQuotaApi.eventQuota?.current >= max,
  });
};

export const eventCategoryQuotaFromApiToReducer = (eventCategoryQuotaApi: any): Quota => {
  const max = eventCategoryQuotaApi.categoryQuota?.max ?? Number.MAX_SAFE_INTEGER;
  return removeUndefs<Quota>({
    max,
    current: eventCategoryQuotaApi.categoryQuota?.current,
    full: eventCategoryQuotaApi
      .categoryQuota?.current >= max,
  });
};

export const engagedFromApiToReducer = (engagedApi: any): EngagedUser => removeUndefs<EngagedUser>({
  _id: engagedApi._id,
  _idMso: engagedApi._idMso,
  id: engagedApi._idMso,
  athlete: athleteShortProfileFromApiToReducer(engagedApi.athlete),
  category: parseInt(engagedApi?.category.split(':')?.[1], 10),
  club: engagedApi.club,
  teamName: engagedApi.teamname,
  insurance: engagedApi.insurance,
  emergencyNumber: engagedApi.emergencyNumber,
  orderStatus: engagedApi.orderStatus,
  registrationMadeBy: parseInt(engagedApi?.registrationMadeBy.split(':')?.[1], 10),
  registeredIn: engagedApi.registeredIn,
  teamMembers: engagedApi.teamMembers?.map(teamMate => athleteShortProfileFromApiToReducer(teamMate)) ?? [],
  bib: engagedApi.bib,
});

export const engagedDenormalizedFromApiToReducer = (engagedApi: any): EngagedUser => removeUndefs<EngagedUser>({
  _id: engagedApi._id,
  _idMso: engagedApi._idMso,
  id: engagedApi._idMso,
  athlete: athleteShortProfileFromApiToReducer(engagedApi.athlete),
  category: parseInt(engagedApi?.category.split(':')?.[1], 10),
  teamName: engagedApi.teamname,
  teamMembers: engagedApi.teamMembers?.map(teamMate => athleteShortProfileFromApiToReducer(teamMate)) ?? [],
  bib: engagedApi.bib,
  orderStatus: engagedApi.orderStatus,
  club: null,
  insurance: null,
  emergencyNumber: null,
  registrationMadeBy: null,
  registeredIn: null,
});
