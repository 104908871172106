import { css } from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';

export const textTitle = css`
  font-family: var(--font-jeko);
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;

  ${mediaBreakpoint.mobile} {
    font-size: 32px;
    line-height: 44px;
  }
`;

export const textH1 = css`
  font-family: var(--font-jeko);
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;

  ${mediaBreakpoint.tablet} {
    font-size: 28px;
  }
`;

export const textH2 = css`
  font-family: var(--font-jeko);
  font-size: 28px;
  line-height: 40px;
  font-weight: 900;

  ${mediaBreakpoint.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const textH2Light = css`
  font-family: var(--font-jeko);
  font-size: 24px;
  line-height: 32px;
  font-weight: 900;
`;

export const textH3 = css`
  font-family: var(--font-jeko);
  font-size: 18px;
  line-height: 28px;
  font-weight: 900;
`;

export const textH4 = css`
  font-family: var(--font-jeko);
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
`;

export const textP = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const textPSemiBold = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const textPBold = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;

export const textLink = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: underline;
`;

export const textPLink = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: underline;
`;

export const textSmall = css`
  font-family: var(--font-barlow);
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

export const textSmallSemiBold = css`
  font-family: var(--font-barlow);
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
`;

export const textButton = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const textButtonMenu = css`
  font-family: var(--font-jeko);
  font-size: 18px;
  line-height: 28px;
  font-weight: 900;
`;

export const textButtonDropdown = css`
  font-family: var(--font-barlow);
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 500;
`;

export const textFooterReassurance = css`
  font-family: var(--font-barlow);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const textInputHigher = css`
  font-family: var(--font-barlow);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const textInputHigherSemiBold = css`
  font-family: var(--font-barlow);
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;
