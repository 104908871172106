import React from 'react';
import { useSelector } from 'react-redux';
import useRegistration from '@root/src/hooks/useRegistration';
import { GET_DATA_FOR, useGetClientSideData } from '@services/dataHandler/getPageData';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import ServicesGroupModalFooter from '@features/subscriptions/components/ServicesGroupModalFooter';
import ServicesGroupFormCreateAndUpdateCart
  from '@features/subscriptions/components/ServicesGroupForm/ServicesGroupFormCreateAndUpdateCart';
import ServiceGroupsLayout from '@features/subscriptions/components/ServiceGroupsLayout';
import {
  useClearServiceAndPreServicesOnCloseModal,
  useFormikContext,
  useGetFormData,
  useOnNextMemberRegistration,
} from '@features/subscriptions/utilsSubscriptionModal';
import { selectCartItem } from '@features/carts/slices/cartsSlice';

interface ModalServicesGroupProps {
  closeModal: () => void;
  orderItemId: string;
  userId: string;
}

const ModalSubscriptionCartUpdate = ({
  closeModal,
  orderItemId,
  userId,
}: ModalServicesGroupProps) => {
  const isLoadingData = useGetClientSideData(GET_DATA_FOR.MODAL_CART_SUBSCRIPTION_UPDATE);
  const orderItem = useSelector(selectCartItem(orderItemId));
  const categoryId = orderItem.category._idMso;
  const eventId = orderItem.event._idMso;

  const editForIndex = orderItem.team.length
    ? orderItem.team.findIndex(teamMate => teamMate.teamMember._idMso === parseInt(userId, 10))
    : 0;

  // Under here it's close to the create version
  // TODO it can be merge in a hook
  const {
    nbTeamMates,
    teamMateIndex,
    changeTeamMate,
    addServiceToCurrentTeamMate,
    isLoading,
  } = useRegistration({
    categoryId,
    orderItemId,
    teamMateIndexToEdit: editForIndex,
    ready: !isLoadingData,
  });

  useClearServiceAndPreServicesOnCloseModal();

  const { formikContext, setFormikContext } = useFormikContext();
  const { teamMateRegisteredValues, storedCurrentTeamMate, schemaValidator } = useGetFormData(
    teamMateIndex,
    categoryId,
  );
  const onNextMemberRegistration = useOnNextMemberRegistration(formikContext, teamMateIndex, categoryId);

  const handleSubmit = async (values) => {
    changeTeamMate(1);
    addServiceToCurrentTeamMate(values);
  };

  return (
    <ModalTemplate
      footer={(
        <ServicesGroupModalFooter
          onNext={onNextMemberRegistration}
          onPrevious={closeModal}
          previousButtonAsCancel
          nextButtonAsValidate
          nextButtonDisabled={formikContext?.isSubmitting || !storedCurrentTeamMate}
          hideFooter={isLoading || isLoadingData}
        />
      )}
      closeModal={closeModal}
    >
      <ServiceGroupsLayout
        categoryId={categoryId}
        nbTeamMates={nbTeamMates}
        isLoading={isLoading || isLoadingData}
        inEditMode
        teamMateIndex={teamMateIndex}
      >
        <ServicesGroupFormCreateAndUpdateCart
          eventId={eventId}
          key={teamMateIndex}
          initialValues={teamMateRegisteredValues}
          schemaValidator={schemaValidator}
          onSubmit={handleSubmit}
          getFormikContext={setFormikContext}
        />
      </ServiceGroupsLayout>
    </ModalTemplate>
  );
};

export default ModalSubscriptionCartUpdate;
