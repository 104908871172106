import React from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import InputLine, { InputLineProps } from '@features/ui-input/formik/InputLine';
import { StackableCardSection } from '@features/ui/components/StackableCard';
import { textLink, textP } from '@features/ui/styles/textStyles';

interface ServiceLineRawProps {
  labelText?: string,
  labelUrl?: string,
  useCard?: boolean,
  className?: string;
}

const ServiceLineRaw = ({
  useCard,
  labelText,
  labelUrl,
  className,
  ...etc
}: ServiceLineRawProps & InputLineProps) => {
  const { t } = useTranslation();

  const Container = useCard ? StackableCardSection : NoCardContainer;
  const labelComponent = labelUrl ? (
    <LabelUrlContainer>
      <div>{labelText}</div>
      <a href={labelUrl} target="_blank" rel="noreferrer">{t('serviceLine_moreInfoLinkText.message')}</a>
    </LabelUrlContainer>
  ) : labelText;
  return (
    <Container className={className}>
      <InputLine
        label={labelComponent}
        {...etc}
      />
    </Container>
  );
};

export default ServiceLineRaw;

const NoCardContainer = styled.div`
  margin: 20px 0;
`;

const LabelUrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${textP};
  color: var(--color-grey4);

  & a {
    ${textLink};
    color: var(--color-grey4);
    font-weight: 500;
    cursor: pointer;
    margin-top: 5px;
  }
`;
