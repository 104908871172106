import React from 'react';
import PhoneInputWithCountrySelect, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useField } from 'formik';
import { StyledInput } from '@features/ui-input/components/InputText';

interface InputPhoneProps {
  name: string;
  className?: string;
}

const InputPhone = ({
  name,
  className,
  ...etc
}: InputPhoneProps) => {
  const [, meta, helpers] = useField(name);
  // This values are passed as props to StyledInput (which is a styled component) by PhoneInputWithCountrySelect
  const styledInputProps = { hasError: meta && meta.touched && meta.error };

  return (
    <PhoneInputWithCountrySelect
      {...etc}
      onChange={(value) => helpers.setValue(formatPhoneNumberIntl(value))}
      inputComponent={StyledInput}
      {...styledInputProps}
      name={name}
      type="tel"
      autoComplete="tel"
      defaultCountry="CH"
    />
  );
};

export default InputPhone;
