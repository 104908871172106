import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import RequiredChip from '@features/ui/components/RequiredChip';
import { StyledLoaderContainer } from '@features/subscriptions/components/ServicesGroupForm/styles';
import SelectTeamMate from '@features/subscriptions/components/SelectTeamMate';
import Loader from '@features/ui/components/Loader';
import { textH2, textP } from '@features/ui/styles/textStyles';
import TitleWithDescription from '@features/ui/components/TitleWithDescription';
import { selectSubscriptionCategory, selectSubscriptionEvent } from '../slices/formSubscriptionSlice';

interface serviceGroup {
  nbTeamMates: number;
  isLoading: boolean;
  categoryId: number;
  inEditMode: boolean; // TODO this should be delete after refacto (=split) the SelectTeamMate
  teamMateIndex: number;
  children: React.ReactNode;
}

const ServiceGroupsLayout = ({
  isLoading,
  nbTeamMates,
  teamMateIndex,
  categoryId,
  inEditMode,
  children,
}: serviceGroup) => {
  const { t } = useTranslation();
  const isSolo = nbTeamMates === 1;

  const category = useSelector(selectSubscriptionCategory(categoryId));
  const event = useSelector(selectSubscriptionEvent(categoryId));

  return (
    <>
      {isLoading
        ? (<StyledLoaderContainer><Loader /></StyledLoaderContainer>)
        : (
          <Container>
            {isSolo && (
              <>
                <Title>
                  {t('modalTeamServicesTemplate_soloTitle.message', {
                    eventName: event.name,
                    categoryName: category.name,
                  })}
                </Title>
                <Instructions
                  title={t('modalTeamServicesTemplate_soloInstructionTitle.message')}
                  description={t('modalTeamServicesTemplate_soloInstructionSubTitle.message')}
                />
              </>
            )}

            {!isSolo && (
              <>
                <Title>{t('modalTeamServicesTemplate_teamMateTitle.message', { index: teamMateIndex + 1 })}</Title>
                <Instructions
                  title={t('modalTeamServicesTemplate_teamMateInstructionTitle.message')}
                  description={t('modalTeamServicesTemplate_teamMateInstructionSubTitle.message')}
                />
                <SelectTeamMate
                  nbTeamMates={nbTeamMates}
                  teamMateIndex={teamMateIndex}
                  isInEditMode={inEditMode}
                  categoryId={categoryId}
                />
              </>
            )}

            <Instructions
              title={t('modalTeamServicesTemplate_teamMatePleaseFillTitle.message')}
            >
              <MandatoryFields>
                <RequiredChip />
                {t('modalTeamServicesTemplate_teamMateMandatoryFields.message')}
              </MandatoryFields>
            </Instructions>

            { children }

          </Container>
        )}
    </>
  );
};

export default ServiceGroupsLayout;

export const Title = styled.h1`
  ${textH2};
  margin-bottom: 28px;
`;

export const Container = styled.div`
  padding-bottom: 40px;
  max-width: 1150px;
`;

export const Instructions = styled(TitleWithDescription)`
  margin-bottom: 34px;
`;

export const MandatoryFields = styled.div`
  margin-top: 20px;
  display: flex;
  ${textP};

  > div:first-child {
    margin-right: 18px;
  }
`;
