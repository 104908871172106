import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';
import { parseInt } from 'lodash';
import useSubscriptionInfo from '@root/src/hooks/useSubscriptionInfo';
import { SubscriptionService } from '@root/src/types';
import { GET_DATA_FOR, useGetClientSideData } from '@services/dataHandler/getPageData';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import ServicesGroupModalFooter from '@features/subscriptions/components/ServicesGroupModalFooter';
import ServiceGroupsLayout from '@features/subscriptions/components/ServiceGroupsLayout';
import {
  useClearServiceAndPreServicesOnCloseModal,
  useFormikContext,
  useGetFormData,
  useOnNextMemberRegistration,
} from '@features/subscriptions/utilsSubscriptionModal';
import {
  selectCurrentSubscription, selectServicesGroup, setSubscriptionServicesGroups,
} from '@features/subscriptions/slices/subscriptionDetailServerSlice';
import ServicesGroupFormUpdateAccount
  from '@features/subscriptions/components/ServicesGroupForm/ServicesGroupFormUpdateAccount';
import Loader from '@features/ui/components/Loader';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { selectSubscriptionEvent, selectSubscriptionServices } from '@features/subscriptions/slices/formSubscriptionSlice';

interface ModalServicesGroupProps {
  closeModal: () => void;
  userId: string;
}

const ModalSubscriptionAccountUpdate = ({
  closeModal,
  userId,
}: ModalServicesGroupProps) => {
  const isLoading = useGetClientSideData(GET_DATA_FOR.ACCOUNT_SUBSCRIPTION_DETAIL);
  return isLoading ? <Loader /> : (
    <ModalSubscriptionAccountUpdateRender
      closeModal={closeModal}
      userId={userId}
    />
  );
};
const ModalSubscriptionAccountUpdateRender = ({
  closeModal,
  userId,
}: ModalServicesGroupProps) => {
  const isLoading = useGetClientSideData(GET_DATA_FOR.MODAL_ACCOUNT_SUBSCRIPTION_DETAIL);
  const dispatch = useDispatch();
  const subscription = useSelector(selectCurrentSubscription);
  const servicesGroups = useSelector(selectServicesGroup);
  const [isUpdatingServices, setIsUpdatingServices] = useState(false);
  const requestHandler = useRequestHandler();
  const { t } = useTranslation();
  const { formikContext, setFormikContext } = useFormikContext();
  const { isTeamRegistration, teamSize } = useSubscriptionInfo(subscription);

  const { category } = subscription;
  const formServices = useSelector(selectSubscriptionServices(category._idMso));
  const event = useSelector(selectSubscriptionEvent(category._idMso));
  const teamMateIndex = formServices.findIndex(item => item.profile._idMso === parseInt(userId, 10));
  const { teamMateRegisteredValues, storedCurrentTeamMate, schemaValidator } = useGetFormData(
    teamMateIndex,
    category._idMso,
  );
  const onNextMemberRegistration = useOnNextMemberRegistration(formikContext, teamMateIndex, category._idMso);
  useClearServiceAndPreServicesOnCloseModal();

  const isServiceUpdatable = (groupID: number, serviceID: number) => {
    const service = servicesGroups
      .find(serviceGroup => serviceGroup._idMso === groupID)
      .services.find(service => service._idMso === serviceID) as SubscriptionService;
    return service.canBeUpdated && !service.invoiced;
  };

  const onSubmit = async (values) => {
    setIsUpdatingServices(true);

    const updates = Object.entries(values).reduce((acc, current) => {
      const athlete = formServices[teamMateIndex].profile;

      const groupID = current[0].split('_')[1];
      const services = current[1];
      Object.entries(services).forEach(entry => {
        const serviceID = entry[0].split('_')[1];
        const serviceValue = entry[1];

        if (isServiceUpdatable(parseInt(groupID), parseInt(serviceID))) {
          acc.push({
            group: `mso:${groupID}`,
            service: `mso:${serviceID}`,
            value: serviceValue,
            ...(isTeamRegistration && { teamMember: `mso:${athlete._idMso}` }),
          });
        }
      });
      return acc;
    }, []);

    const body = { updates };

    const { response } = await requestHandler({
      request: api.subscription.registrations.services.update({
        subscriptionId: subscription._id,
        body,
      }),
    });

    if (response && response?.ok) {
      const servicesGroupApi = await response.json();
      dispatch(setSubscriptionServicesGroups({ servicesGroupApi }));
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('modalSubscriptionAccountUpdate_updateSuccess.message')}
        text=""
      />);
    } else {
      toast(<Alert
        variant={ALERT_VARIANTS.ERROR}
        title={t('modalSubscriptionAccountUpdate_updateError.message')}
        text=""
      />);
    }
    closeModal();
  };

  return (
    <ModalTemplate
      footer={(
        <ServicesGroupModalFooter
          onNext={onNextMemberRegistration}
          onPrevious={closeModal}
          previousButtonAsCancel
          nextButtonAsValidate
          nextButtonDisabled={formikContext?.isSubmitting || !storedCurrentTeamMate}
          hideFooter={isUpdatingServices || isLoading}
        />
      )}
      closeModal={closeModal}
    >
      <ServiceGroupsLayout
        categoryId={category._idMso}
        nbTeamMates={teamSize}
        isLoading={isUpdatingServices || isLoading}
        inEditMode
        teamMateIndex={teamMateIndex}
      >
        <ServicesGroupFormUpdateAccount
          key={teamMateIndex}
          defaultValues={teamMateRegisteredValues}
          schemaValidator={schemaValidator}
          onSubmit={onSubmit}
          getFormikContext={setFormikContext}
          eventId={event._idMso}
        />
      </ServiceGroupsLayout>
    </ModalTemplate>
  );
};

export default ModalSubscriptionAccountUpdate;
