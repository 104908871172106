import { DateTime } from 'luxon';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import React from 'react';
import Link from 'next/link';
import { Event, LIVE_RESULTS_STATUS, LIVE_TYPES, REGISTRATION_STATUS } from '@root/src/types';
import config from '@root/src/config';
import routeList from '@services/routeList';
import { Tag } from '@features/eventList/components/EventCard/styles';
import Indicator from '@features/ui/components/Indicator';

export enum TAGS {
  ALL = 'ALL',
  REGISTRATION_OPEN = 'REGISTRATION_OPEN',
  ENGAGED_LIST = 'ENGAGED_LIST',
  LIVE_TIMING = 'LIVE_TIMING',
  RESULTS = 'RESULTS',
  RANKED_SERIES = 'RANKED_SERIES'
}

export const mapTagToTranslationKey = {
  [TAGS.ALL]: 'tags_all.message',
  [TAGS.LIVE_TIMING]: 'tags_liveTiming.message',
  [TAGS.RANKED_SERIES]: 'tags_rankedSeries.message',
  [TAGS.RESULTS]: 'tags_results.message',
  [TAGS.REGISTRATION_OPEN]: 'tags_registrationsOpen.message',
  [TAGS.ENGAGED_LIST]: 'tags_engagedList.message',
};

const useEventInfo = (event: Event) => {
  const { t } = useTranslation();

  const isSeries = event?.serie?.isChampionship;
  const hasLiveTiming = !!event.live;
  const isLiveTimingNow = event.live?.isNow;
  const areRegistrationOpen = event.status.registration === REGISTRATION_STATUS.OPEN;
  const areRegistrationClosed = event.status.registration === REGISTRATION_STATUS.CLOSED;
  const areRegistrationCancelled = event.status.registration === REGISTRATION_STATUS.CANCELLED;
  const areRegistrationNotYetOpen = event.status.registration === REGISTRATION_STATUS.NOT_YET_OPEN;
  const hasEngagedList = DateTime.fromISO(event.showStartListFrom) <= DateTime.now();
  const hasResults = event.hasEventResults;
  const hasRankedSeries = event.hasSerieResults;
  const { hasLiveResults } = event;

  const liveTimingUrl: string | null = (() => {
    switch (event.live?.type) {
      case LIVE_TYPES.LIVETRAIL:
      case LIVE_TYPES.EXTERNAL:
        return event.live.url;
      case LIVE_TYPES.STANDARD:
      case LIVE_TYPES.P_POINTS:
        return `${config.urls.base}${event.live.url}`;
      default:
        return null;
    }
  })();

  const mapTagToUrl = {
    [TAGS.LIVE_TIMING]: liveTimingUrl,
    [TAGS.RANKED_SERIES]: routeList.events(event.slug).series(),
    [TAGS.RESULTS]: getResultsRoute(event),
    [TAGS.REGISTRATION_OPEN]: routeList.events(event.slug).subscriptions(),
    [TAGS.ENGAGED_LIST]: routeList.events(event.slug).engaged(),
  };

  const tags = [
    hasLiveTiming && TAGS.LIVE_TIMING,
    hasEngagedList && TAGS.ENGAGED_LIST,
    areRegistrationOpen && TAGS.REGISTRATION_OPEN,
    hasRankedSeries && TAGS.RANKED_SERIES,
    (hasResults
      || hasLiveResults === LIVE_RESULTS_STATUS.OFFLINE
      || hasLiveResults === LIVE_RESULTS_STATUS.ONLINE
    ) && TAGS.RESULTS,
  ].filter(Boolean) as string[];

  const allTags = tags.map(tag => t(mapTagToTranslationKey[tag]));

  const SimpleTag = ({ tag }) => (
    <Tag>
      {tag === TAGS.LIVE_TIMING && isLiveTimingNow && <Indicator />}
      {t(mapTagToTranslationKey[tag])}
    </Tag>
  );

  const isTagNotClickable = (tag) => tag === TAGS.LIVE_TIMING && !isLiveTimingNow;

  const generateTags = () => tags.map(tag => (
    <Container key={tag} onClick={(e) => e.stopPropagation()}>
      { isTagNotClickable(tag)
        ? <SimpleTag tag={tag} />
        : (
          <Link href={mapTagToUrl[tag]}>
            <a>
              <SimpleTag tag={tag} />
            </a>
          </Link>
        )}
    </Container>
  ));

  return {
    isSeries,
    hasLiveTiming,
    hasResults,
    hasLiveResults,
    hasRankedSeries,
    areRegistrationOpen,
    areRegistrationClosed,
    areRegistrationCancelled,
    areRegistrationNotYetOpen,
    hasEngagedList,
    isLiveTimingNow,
    liveTimingUrl,
    allTags,
    generateTags,
  };
};

export default useEventInfo;

export const getResultsRoute = (event: Event) => (
  event.hasLiveResults === LIVE_RESULTS_STATUS.NO
    ? routeList.events(event.slug).results()
    : routeList.events(event.slug).liveResults()
);

const Container = styled.div`
  a {
    display: inline-block;
  }
`;
