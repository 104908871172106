import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useRouter } from 'next/router';
import {
  RANKINGS_LIST_TYPE,
  SERIES_COMPUTE_MODE,
  SeriesRanking,
  SeriesRankingScratchAndCategory,
  TSeriesRankingList,
} from '@root/src/types';
import { isFloat } from '@utils/gobal';
import downloadPDF from '@utils/downloadPDF';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import List, { TABLE_ITEM_HEADER_TYPE, TableDefinition } from '@features/ui/components/List';
import { selectRankingListFiltered, selectResultsListType } from '@features/results/slices/resultsSlice';
import { ICON_NAME } from '@features/ui/components/Icon';
import { useShouldHighlight } from '@features/eventDetail/utils';
import {
  formatLocality,
  getAthleteAndTeamValue,
  getPersonName,
} from '@features/eventDetail/templates/EventListPageTemplate';
import { useSeriesListInfo } from '@features/results/utils';
import { selectSerieDetail } from '@features/serieDetail/slices/serieDetailServerSlice';
import CountryFlag from '@features/ui/components/CountryFlag';
import Rank from '@features/ui/components/Rank';

const SeriesRankingList = ({ rankingId } : { rankingId: string }) => {
  const { t } = useTranslation();
  const serie = useSelector(selectSerieDetail);
  const rankingList = useSelector(selectRankingListFiltered(rankingId)) as TSeriesRankingList;
  const resultsListType = useSelector(selectResultsListType);
  const { currentSerie } = useSeriesListInfo();
  const shouldHighlight = useShouldHighlight();
  const requestHandler = useRequestHandler();
  const router = useRouter();
  const listName = rankingList.name || currentSerie.name;

  const sizeForPtsOrTime = getSizeForPtsOrTime(currentSerie.computeMode, false);
  const sizeForPtsOrTimeLarge = getSizeForPtsOrTime(currentSerie.computeMode, true);

  const tableDefinition: TableDefinition<SeriesRanking> = [
    {
      size: {
        desktop: '45px',
        tablet: '45px',
        mobile: 'minmax(30px,45px)',
      },
      name: t('eventResultsList_headerPosition.message'),
      renderCell: (ranking) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        isBold: true,
        content: <Rank position={ranking.position} />,
      }),
    }, {
      size: {
        desktop: 'minmax(100px,1fr)',
        tablet: 'minmax(100px,1fr)',
        mobile: 'minmax(100px,1fr)',
      },
      name: t('eventList_headerName.message'),
      renderCell: (ranking) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        isBold: true,
        content: getAthleteAndTeamValue({
          data: ranking,
          getKey: getPersonName,
          t,
        }),
      }),
    },
  ];

  if (resultsListType !== RANKINGS_LIST_TYPE.DAKINE) {
    tableDefinition.push({
      size: {
        desktop: '50px',
        tablet: null,
        mobile: null,
      },
      name: t('eventList_headerYear.message'),
      renderCell: (ranking: SeriesRankingScratchAndCategory) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        content: getAthleteAndTeamValue({
          data: ranking,
          key: 'birthDateObj.year',
        }),
      }),
    });
    tableDefinition.push({
      size: {
        desktop: 'minmax(50px, 1fr)',
        tablet: null,
        mobile: null,
      },
      name: t('eventList_headerLocality.message'),
      renderCell: (ranking: SeriesRankingScratchAndCategory) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        content: formatLocality(getAthleteAndTeamValue({
          data: ranking,
          key: 'legAddress.locality',
        })),
      }),
    });
    tableDefinition.push({
      size: {
        desktop: '60px',
        tablet: null,
        mobile: null,
      },
      name: t('eventList_headerDepartement.message'),
      renderCell: (ranking: SeriesRankingScratchAndCategory) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        content: getAthleteAndTeamValue({
          data: ranking,
          key: 'legAddress.department',
        }),
      }),
    });
    tableDefinition.push({
      size: {
        desktop: '75px',
        tablet: null,
        mobile: null,
      },
      name: t('eventList_headerNationality.message'),
      renderCell: (ranking: SeriesRankingScratchAndCategory) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        content: (
          <CountryFlag nationality={getAthleteAndTeamValue({
            data: ranking,
            key: 'nationality',
          })}
          />
        ),
      }),
    });
  }

  tableDefinition.push({
    size: {
      desktop: sizeForPtsOrTime,
      tablet: sizeForPtsOrTime,
      mobile: sizeForPtsOrTime,
    },
    name: t(
      currentSerie.computeMode === SERIES_COMPUTE_MODE.TIME
        ? 'seriesResultsList_headerPointsTiming.message'
        : 'seriesResultsList_headerPoints.message'
    ),
    renderCell: (ranking) => ({
      type: TABLE_ITEM_HEADER_TYPE.TEXT,
      isBold: true,
      content: getRoundedValue(ranking.points),
    }),
  });
  tableDefinition.push({
    size: {
      desktop: '40px',
      tablet: '40px',
      mobile: '40px',
    },
    name: t('seriesResultsList_headerNbRaces.message'),
    renderCell: (ranking) => ({
      type: TABLE_ITEM_HEADER_TYPE.TEXT,
      content: ranking.nbRace,
    }),
  });
  tableDefinition.push({
    size: {
      desktop: sizeForPtsOrTime,
      tablet: sizeForPtsOrTime,
      mobile: null,
    },
    name: t('seriesResultsList_headerGap.message'),
    renderCell: (ranking) => ({
      type: TABLE_ITEM_HEADER_TYPE.TEXT,
      content: getRoundedValue(ranking.gap),
    }),
  });

  rankingList.extraHeaders.forEach((header, index) => {
    let size = {
      desktop: index > 4 ? null : sizeForPtsOrTime,
      tablet: index > 2 ? null : sizeForPtsOrTime,
      mobile: null,
    };
    if (resultsListType === RANKINGS_LIST_TYPE.DAKINE) {
      size = {
        desktop: index > 5 ? null : sizeForPtsOrTimeLarge,
        tablet: index > 3 ? null : sizeForPtsOrTime,
        mobile: null,
      };
    }
    tableDefinition.push({
      size,
      name: header.name,
      renderCell: (ranking) => {
        const results = ranking.results ? ranking.results[index] : null;
        return ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: getRoundedValue(results ? results.points : ''),
          isBold: header.mandatory,
          isStrikethrough: results ? !results.count : false,
        });
      },
    });
  });

  return (
    <List
      listHeaderName={listName}
      listHeaderCount={rankingList.rankings.length}
      listHeaderIcon={rankingList.isTeam ? ICON_NAME.iconGroups : ICON_NAME.person}
      listHeaderDownloadClick={() => {
        const requestObject = {
          request: api.results.series[resultsListType.toLowerCase()].download({
            categoryId: rankingList._idMso,
            serieResultsId: currentSerie._idMso?.toString(),
            serieId: serie._idMso,
            locale: router.locale,
          }),
        };
        const request = requestHandler(requestObject);
        const fileName = `${t(
          'resultsList_titleExportSuffixNameFile.message'
        )}-${listName}.csv`;
        downloadPDF(request, fileName);
      }}
      tableItemShouldHighlight={(ranking) => shouldHighlight(ranking.athlete._idMso)}
      tableDefinition={tableDefinition}
      items={rankingList.rankings}
      uniqueId={rankingId}
    />
  );
};
export default SeriesRankingList;

const getSizeForPtsOrTime: (computeMode:SERIES_COMPUTE_MODE, large: boolean) => string = (computeMode, large) => {
  if (computeMode === SERIES_COMPUTE_MODE.TIME) {
    if (large) return 'minmax(100px, 150px)';
    return 'minmax(60px, 100px)';
  }
  if (large) return 'minmax(50px,100px)';
  return 'minmax(50px,70px)';
};

const getRoundedValue = (value: string | number) => {
  if (typeof value === 'number' && isFloat(value)) {
    return Math.round(value * 10) / 10;
  }
  return value;
};
