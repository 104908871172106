import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNull, isObject, mergeWith, parseInt, size } from 'lodash';
import { useTranslation } from 'next-i18next';
import { FormikContextType } from 'formik';
import { useRouter } from 'next/router';
import api from '@services/api';
import { RequestHandlerTypeClientSide, useRequestHandler } from '@services/api/handlers';
import yup, { setYupLocale } from '@services/translation/translatedYup';
import {
  clearFormSubscriptions,
  selectCurrentTeamMate,
  selectSubscriptionDefaultPreServices,
  selectSubscriptionEvent,
  selectSubscriptionFor,
  selectSubscriptionPreServices,
  selectSubscriptionsDetails,
  selectTeamMateSubscription,
  setServicesProfile,
} from '@features/subscriptions/slices/formSubscriptionSlice';
import { selectCurrentUserDetail } from '@features/users/slices/userSlice';
import { scrollToTop } from '@features/subscriptions/components/ServicesGroupForm/utils';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { selectEventCategoryServicesGroups } from '@features/eventDetail/slices/eventDetailServerSlice';
import { generateDefaultValues } from '@features/subscriptions/factories/servicesDefaultValuesFactory';
import { generateSchema } from '@features/subscriptions/factories/servicesValidationSchemaFactory';

export const useCloseModalIfServicesIsMissing = (
  categoryId: number,
  closeModal: () => void,
) => {
  const subscriptions = useSelector(selectSubscriptionsDetails);
  const defaultPreServices = useSelector(selectSubscriptionDefaultPreServices(categoryId));
  const preServices = useSelector(selectSubscriptionPreServices(categoryId));

  useEffect(() => {
    if (size(subscriptions) === 0 || (!defaultPreServices && !preServices)) {
      closeModal();
    }
  }, []);
};
export const useClearServiceAndPreServicesOnCloseModal = () => {
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(clearFormSubscriptions());
  }, []);
};
export const useSetServicesProfile = (
  categoryId: number,
  teamMateIndex,
) => {
  const userDetail = useSelector(selectCurrentUserDetail);
  const subscribeFor = useSelector(selectSubscriptionFor);
  const dispatch = useDispatch();

  useEffect(() => {
    if (teamMateIndex === 0) {
      dispatch(setServicesProfile({
        categoryId,
        profile: subscribeFor ?? userDetail,
        index: 0,
      }));
    }
  }, [categoryId]);
};

const isMemberSelectedAuthorizedForThisCategory: ({
  requestHandler,
  eventId,
  athleteId,
  categoryId,
}: {
  requestHandler: RequestHandlerTypeClientSide;
  eventId: number;
  athleteId: number;
  categoryId: number;
}) => Promise<boolean> = async ({
  requestHandler,
  eventId,
  athleteId,
  categoryId,
}) => {
  const { response } = await requestHandler({
    request: api.events.categories.read({
      eventId,
      athleteId,
    }),
  });
  if (response && response?.ok) {
    const categories: [{ _idMso: string }] = await response.json();
    return !!categories.find(category => parseInt(category._idMso, 10) === categoryId);
  }
  return false;
};
export const useOnNextMemberRegistration = (formikContext, teamMateIndex: number, categoryId: number) => {
  const requestHandler = useRequestHandler();
  const storedCurrentTeamMate = useSelector(selectCurrentTeamMate(categoryId, teamMateIndex));
  const event = useSelector(selectSubscriptionEvent(categoryId));
  const { t } = useTranslation();

  return async () => {
    scrollToTop();
    const isAuthorized = await isMemberSelectedAuthorizedForThisCategory({
      requestHandler,
      eventId: event._idMso,
      athleteId: storedCurrentTeamMate._idMso,
      categoryId,
    });
    if (isAuthorized) {
      await formikContext?.submitForm();
    } else {
      toast(
        <Alert
          variant={ALERT_VARIANTS.ERROR}
          title={t('modalServicesGroupFrom_userNotAllowedTitle.message')}
          text={t('modalServicesGroupFrom_userNotAllowedContent.message')}
        />,
      );
    }
  };
};
export const useFormikContext = () => {
  const [formikContext, setFormikContext] = useState<FormikContextType<any>>(null);
  const router = useRouter();
  setYupLocale(router.locale); // Need to use this before generating the schemas

  return {
    formikContext,
    setFormikContext,
  };
};
export const useGetFormData = (teamMateIndex, categoryId) => {
  const { t } = useTranslation();

  const event = useSelector(selectSubscriptionEvent(categoryId));
  const servicesGroups = useSelector(selectEventCategoryServicesGroups(event._idMso));

  const teamMateSubscription = useSelector(selectTeamMateSubscription(categoryId, teamMateIndex));
  const defaultValues = generateDefaultValues(servicesGroups);

  // This will replace all null values with default value (in teammate services)
  // This happens when a new service is added in BO and the teammate was already registered
  const teamMateRegisteredValues = mergeWith(
    {},
    teamMateSubscription,
    defaultValues,
    (teamMateVal, defaultVal) => {
      if (isObject(defaultVal)) return undefined;
      return isNull(teamMateVal) ? defaultVal : teamMateVal;
    }
  );
  const storedCurrentTeamMate = useSelector(selectCurrentTeamMate(categoryId, teamMateIndex));

  const schemaValidator = yup.object().shape(generateSchema(servicesGroups, t));

  return {
    teamMateRegisteredValues,
    storedCurrentTeamMate,
    defaultValues,
    schemaValidator,
  };
};
export const useShouldWeRefreshClientSideData = (categoryId: number) => {
  // If the category has changed (= we loaded the next category of a pack registration),
  // we refresh the database data
  const currentCatId = useRef(categoryId);
  let refreshClientSideData = false;
  if (currentCatId.current !== categoryId) {
    refreshClientSideData = true;
    currentCatId.current = categoryId;
  }

  return { refreshClientSideData };
};
