import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { FormikContextType } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import CreateUpdateMobileNumberForm, { CreateUpdateMobileNumberFormInputs } from '@features/account/components/CreateUpdateMobileNumberForm';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { selectMobileNumberByID } from '@features/users/slices/userSlice';
import { textH1 } from '@features/ui/styles/textStyles';

interface ModalCreateUpdateMobileNumberProps {
  closeModal: () => void,
  mobileNumberID: string
}

const ModalCreateUpdateMobileNumber = ({
  closeModal,
  mobileNumberID,
}: ModalCreateUpdateMobileNumberProps) => {
  const { t } = useTranslation();
  const [formikContext, setFormikContext] = useState<FormikContextType<CreateUpdateMobileNumberFormInputs>>(null);
  const mobileNumber = useSelector(selectMobileNumberByID(mobileNumberID));

  const errorsCode = {
    'error.phoneNumberAlreadyUsed': t('modalCreateUpdateMobileNumber_errorCodePhoneNumberAlreadyUsed.message'),
    'error.badPhoneNumberFormat': t('modalCreateUpdateMobileNumber_errorCodeBadPhoneNumberFormat.message'),
  };

  const submitForm = async () => {
    await formikContext?.submitForm();
  };

  const handleError = (errorCode) => {
    toast(<Alert
      variant={ALERT_VARIANTS.ERROR}
      title={errorsCode[errorCode]}
      text=""
    />);
  };

  const onSubmitted = (success, errorCode) => {
    if (success) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={mobileNumberID
          ? t('createUpdateMobileNumberForm_submitUpdateSuccess.message')
          : t('createUpdateMobileNumberForm_submitCreateSuccess.message')}
        text=""
      />);
      closeModal();
    } else {
      handleError(errorCode);
    }
  };

  const title = mobileNumberID
    ? t('modalCreateUpdateMobileNumber_title_update.message')
    : t('modalCreateUpdateMobileNumber_title_create.message');

  const FooterForm = () => (
    <Footer>
      <Button
        text={t('cancel.message')}
        leftIcon={ICON_NAME.keyboardReturn}
        variant={BUTTON_VARIANT.secondary}
        onClick={closeModal}
      />
      <Button
        text={t('modalCreateUpdateMobileNumber_submitButton.message')}
        leftIcon={ICON_NAME.check}
        onClick={submitForm}
      />
    </Footer>
  );

  const initialValues: CreateUpdateMobileNumberFormInputs = {
    id: mobileNumber?._id,
    phoneNumber: mobileNumber?.num ?? '',
    def: mobileNumber?.def ?? false,
    description: mobileNumber?.description ?? '',
  };

  return (
    <ModalTemplate footer={<FooterForm />} closeModal={closeModal}>
      <Title>{title}</Title>
      <FormWrapper>
        <CreateUpdateMobileNumberForm
          initialValues={initialValues}
          getFormikContext={setFormikContext}
          submitCallback={onSubmitted}
        />
      </FormWrapper>
    </ModalTemplate>
  );
};

export default ModalCreateUpdateMobileNumber;

const Title = styled.h1`
  ${textH1}
  max-width: calc(100% - 44px);
`;

const FormWrapper = styled.div`
  margin: 32px 117px 40px 0;
  max-width: 553px;

  ${mediaBreakpoint.tablet} {
    margin-right: unset;
    max-width: unset;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
