module.exports = {
  docker: {
    urlBase: 'http://localhost:3000/',
    urlApi: {
      clientSide: 'http://localhost:8000/api',
      serverSide: 'http://localhost:8000/api',
    },
  },
  local: {
    urlBase: 'http://localhost:3000/',
    urlApi: {
      clientSide: 'https://mso-dev.appti.ch/api',
      serverSide: 'https://mso-dev.appti.ch/api',
    },
  },
  dev: {
    urlBase: 'https://mso-dev.appti.ch/',
    urlApi: {
      clientSide: 'https://mso-dev.appti.ch/api',
      serverSide: 'https://mso-dev.appti.ch/api',
    },
  },
  uat: {
    urlBase: 'https://preprod2800.mso.swiss/',
    urlApi: {
      clientSide: 'https://preprod2800.mso.swiss/api',
      serverSide: 'http://preprod2800.mso.internal/api',
    },
  },
  prd: {
    urlBase: 'https://www.mso.swiss/',
    urlApi: {
      clientSide: 'https://www.mso.swiss/api',
      serverSide: 'http://www.mso.internal/api',
    },
  },
};
