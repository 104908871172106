import styled from 'styled-components';
import { useRouter } from 'next/router';
import { LOCALES } from '@root/src/types';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textH2, textH4 } from '@features/ui/styles/textStyles';
import { numberToOrdinalSuffix } from '@features/results/utils';

export enum RESULT_CHIP_VARIANTS {
  left = 'left',
  right = 'right'
}

interface ResultChipVariant {
  radius: string
}

const RESULT_CHIP_PROPERTIES: {
  [key in keyof typeof RESULT_CHIP_VARIANTS]: ResultChipVariant
} = {
  [RESULT_CHIP_VARIANTS.left]: { radius: '0 20px 0 8px' },
  [RESULT_CHIP_VARIANTS.right]: { radius: '20px 0 8px 0' },
};

interface ResultChipProps {
  variant: RESULT_CHIP_VARIANTS,
  result: number | string,
  className?: string,
}

const ResultChip = ({
  variant,
  result,
  className,
}: ResultChipProps) => {
  const attributes = RESULT_CHIP_PROPERTIES[variant];
  const { locale } = useRouter();

  const resultIsNumber = typeof result === 'number' || !Number.isNaN(parseInt(result, 10));

  return (
    <Wrapper {...attributes} className={className}>
      <StyledIcon name={ICON_NAME.militaryTech} color="var(--color-white)" size="24px" />
      <Text>{result}</Text>
      {resultIsNumber && (
        <TextSuffix isExponent={locale !== LOCALES.GERMAN}>
          {numberToOrdinalSuffix(result as number, locale as LOCALES)}
        </TextSuffix>
      )}
    </Wrapper>
  );
};

export default ResultChip;

const Wrapper = styled.div<ResultChipVariant>`
  display: flex;
  background: var(--color-orange);
  padding: 8px 17px;
  border-radius: ${({ radius }) => radius};
  align-items: center;
  max-width: 200px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const Text = styled.span`
  ${textH2};
  color: var(--color-white);
`;

const TextSuffix = styled.span<{isExponent: boolean}>`
  ${({ isExponent }) => (isExponent ? textH4 : textH2)};
  color: var(--color-white);
  margin-left: ${({ isExponent }) => (isExponent ? '3px' : '0')};;
  align-self: flex-start;
  margin-top: 1px;
`;
