import { chain, isArray, isEmpty, isObject, omit } from 'lodash';
import { GetServerSidePropsContext } from 'next';
import config from '@root/src/config';
import { getAuthCookies } from '@services/cookies';
import logger, { LOG_TITLE } from '@services/logger';

interface CallWrapperParameters {
  endpoint: string;
  method: REQUEST_METHOD;
  params?: {};
  query?: {};
  body?: {};
  ctx?: GetServerSidePropsContext;
  token?: string;
}

export const callWrapper: (callWrapperParameters: CallWrapperParameters) => Promise<Response> = ({
  endpoint,
  method,
  params,
  query,
  body,
  ctx,
  token,
}) => {
  let url = endpoint;

  // Build params
  if (!isEmpty(params)) {
    url = chain(params)
      .toPairs()
      .reduce((s, [v, k]) => s.replace(new RegExp(`:${v}`, 'gi'), k as any), endpoint)
      .value();
  }

  if (url[url.length - 1] !== '/') {
    url += '/';
  }

  // Build query
  if (!isEmpty(query)) {
    const res = chain(query)
      .toPairs()
      .map(([k, v]) => {
        if (v == null) return '';
        // if (isArray(v)) return `${k}=${v.join(',')}`;
        if (isObject(v) || isArray(v)) return `${k}=${JSON.stringify(v)}`;
        return `${k}=${v}`;
      })
      .join('&');
    url += `?${res}`;
  }

  const { token: tokenFromCookie } = getAuthCookies(ctx);

  return callApi({
    endpoint: url,
    method,
    token: token || tokenFromCookie,
    body,
    isSSRequest: !!ctx,
  });
};

export enum REQUEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

interface CallApiParameters {
  endpoint: string;
  method: REQUEST_METHOD;
  token?: string;
  body?: {};
  contentType?: string;
  isSSRequest: boolean;
}

const callApi: (callApiParameters: CallApiParameters) => Promise<Response> = ({
  endpoint,
  method,
  token,
  body,
  contentType = 'application/json',
  isSSRequest,
}) => {
  const baseUrl = isSSRequest ? config.urlApi.serverSide : config.urlApi.clientSide;
  const url = `${baseUrl}${endpoint}`;

  const options: Parameters<typeof fetch>[1] = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': contentType,
      Connection: 'keep-alive',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }
  if (body) {
    options.body = JSON.stringify(body);
  }

  logger(LOG_TITLE.fetchOptions, {
    data: {
      url,
      options: omit(options, 'headers.Authorization'),
    },
  });
  return fetch(url, options);
};
