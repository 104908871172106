import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { UrlObject } from 'url';
import Link from 'next/link';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textButton, textButtonMenu } from '@features/ui/styles/textStyles';

export enum BUTTON_VARIANT {
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
  linkSecondary = 'linkSecondary',
  menu = 'menu',
  menuContrast = 'menuContrast',
}

interface ButtonVariant {
  background: string;
  backgroundHover: string;
  backgroundDisabled: string;
  backgroundSelected: string;
  border: string;
  borderHover: string;
  borderDisabled: string;
  borderSelected: string;
  textColor: string;
  textHover: string;
  textDisabled: string;
  textSelected: string;
  iconColor: string;
  iconHover: string;
  iconDisabled: string;
  iconSelected: string;
  boxShadow: string;
  textFont: FlattenSimpleInterpolation;
}

const BUTTON_COLOR: {
  [key in keyof typeof BUTTON_VARIANT]: ButtonVariant;
} = {
  [BUTTON_VARIANT.primary]: {
    background: 'var(--color-orange)',
    backgroundHover: 'var(--color-orange-hover)',
    backgroundDisabled: 'var(--color-grey2)',
    backgroundSelected: 'var(--color-orange)',
    border: 'var(--color-orange)',
    borderHover: 'var(--color-orange-hover)',
    borderDisabled: 'var(--color-grey2)',
    borderSelected: 'var(--color-orange-hover)',
    textColor: 'var(--color-white)',
    textHover: 'var(--color-white)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-white)',
    iconColor: 'var(--color-white)',
    iconHover: 'var(--color-white)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-white)',
    boxShadow: null,
    textFont: textButton,
  },
  [BUTTON_VARIANT.secondary]: {
    background: 'var(--color-white)',
    backgroundHover: 'var(--color-orange-light)',
    backgroundDisabled: 'var(--color-grey1)',
    backgroundSelected: 'var(--white)',
    border: 'var(--color-orange)',
    borderHover: 'var(--color-orange-hover)',
    borderDisabled: 'var(--color-grey1)',
    borderSelected: 'var(--color-orange-hover)',
    textColor: 'var(--color-orange)',
    textHover: 'var(--color-orange-hover)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-orange-hover)',
    iconColor: 'var(--color-orange)',
    iconHover: 'var(--color-orange-hover)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-orange-hover)',
    boxShadow: null,
    textFont: textButton,
  },
  [BUTTON_VARIANT.link]: {
    background: 'transparent',
    backgroundHover: 'var(--color-orange-light)',
    backgroundDisabled: 'transparent',
    backgroundSelected: 'var(--color-white)',
    border: 'transparent',
    borderHover: 'var(--color-orange-light)',
    borderDisabled: 'transparent',
    borderSelected: 'var(--color-orange-light)',
    textColor: 'var(--color-grey4)',
    textHover: 'var(--color-grey4)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-grey4)',
    iconColor: 'var(--color-orange)',
    iconHover: 'var(--color-orange)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-orange)',
    boxShadow: null,
    textFont: textButton,
  },
  [BUTTON_VARIANT.linkSecondary]: {
    background: 'transparent',
    backgroundHover: 'var(--color-orange-light)',
    backgroundDisabled: 'transparent',
    backgroundSelected: 'transparent',
    border: 'transparent',
    borderHover: 'var(--color-orange-light)',
    borderDisabled: 'transparent',
    borderSelected: 'transparent',
    textColor: 'var(--color-white)',
    textHover: 'var(--color-grey4)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-orange-hover)',
    iconColor: 'var(--color-orange)',
    iconHover: 'var(--color-orange)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-orange-hover)',
    boxShadow: null,
    textFont: textButton,
  },
  [BUTTON_VARIANT.menu]: {
    background: 'transparent',
    backgroundHover: 'transparent',
    backgroundDisabled: 'transparent',
    backgroundSelected: 'var(--color-white)',
    border: 'transparent',
    borderHover: 'transparent',
    borderDisabled: 'transparent',
    borderSelected: 'var(--color-orange-light)',
    textColor: 'var(--color-grey4)',
    textHover: 'var(--color-orange-hover)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-grey4)',
    iconColor: 'var(--color-orange)',
    iconHover: 'var(--color-orange)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-orange)',
    boxShadow: null,
    textFont: textButtonMenu,
  },
  [BUTTON_VARIANT.menuContrast]: {
    background: 'transparent',
    backgroundHover: 'var(--color-white)',
    backgroundDisabled: 'transparent',
    backgroundSelected: 'var(--color-white)',
    border: 'transparent',
    borderHover: 'transparent',
    borderDisabled: 'transparent',
    borderSelected: 'var(--color-orange-light)',
    textColor: 'var(--color-white)',
    textHover: 'var(--color-orange-hover)',
    textDisabled: 'var(--color-grey3)',
    textSelected: 'var(--color-grey4)',
    iconColor: 'var(--color-white)',
    iconHover: 'var(--color-orange)',
    iconDisabled: 'var(--color-grey3)',
    iconSelected: 'var(--color-orange)',
    boxShadow: null,
    textFont: textButtonMenu,
  },
};

interface GenericButtonProps {
  text?: string;
  leftIcon?: ICON_NAME;
  rightIcon?: ICON_NAME;
  rightComponent?: React.ReactNode;
  iconSize?: string;
  variant?: BUTTON_VARIANT;
  disabled?: boolean;
  tabIndex?: number;
  className?: string;
}

interface OnClickButtonProps extends GenericButtonProps {
  onClick?: (e) => void;
}

interface UrlButtonProps extends GenericButtonProps {
  url: UrlObject;
  urlScroll?: boolean;
  urlTargetBlank?: boolean;
}

export type OwnProps = OnClickButtonProps | UrlButtonProps;

const withDisabledAttributes = (buttonAttributes) => {
  const {
    backgroundDisabled,
    textDisabled,
    borderDisabled,
    iconDisabled,
  } = buttonAttributes;
  return {
    ...buttonAttributes,
    background: backgroundDisabled,
    backgroundHover: backgroundDisabled,
    backgroundSelected: backgroundDisabled,
    textColor: textDisabled,
    textHover: textDisabled,
    textSelected: textDisabled,
    border: borderDisabled,
    borderHover: borderDisabled,
    borderSelected: borderDisabled,
    iconColor: iconDisabled,
    iconHover: iconDisabled,
    iconSelected: iconDisabled,
  };
};

const withLink = (renderContent, url, urlScroll, urlTargetBlank) => (
  <Link href={url} scroll={urlScroll}>
    <a tabIndex={-1} target={urlTargetBlank ? '_blank' : '_self'}>{renderContent}</a>
  </Link>
);

const Button = (props: OwnProps & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>) => {
  const {
    text,
    leftIcon,
    rightIcon,
    rightComponent,
    iconSize = '20px',
    variant = BUTTON_VARIANT.primary,
    disabled = false,
    tabIndex,
    className,
    ...etc
  } = props;

  let onClick;
  let url;
  let urlScroll;
  let urlTargetBlank;
  if ('onClick' in props) {
    onClick = props.onClick;
  } else if ('url' in props) {
    url = props.url;
    urlScroll = props.urlScroll;
    urlTargetBlank = props.urlTargetBlank;
  }

  const attributes = disabled
    ? withDisabledAttributes(BUTTON_COLOR[variant])
    : BUTTON_COLOR[variant];

  const withLeftIcon = leftIcon != null;
  const withRightIcon = rightIcon != null;
  const iconOnly = !text && (withLeftIcon || withRightIcon);
  const renderLink = url && !disabled;
  const variantIsLink = variant === BUTTON_VARIANT.link || variant === BUTTON_VARIANT.linkSecondary;

  const renderContent = (
    <StyledButton
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      tabIndex={tabIndex}
      variantIsLink={variantIsLink}
      disabled={disabled}
      iconOnly={iconOnly}
      className={className}
      {...attributes}
      {...etc}
    >
      {leftIcon && <Icon name={leftIcon} size={iconSize} />}
      {text && (
        <Text
          withLeftIcon={withLeftIcon}
          withRightIcon={withRightIcon}
          textFont={attributes.textFont}
        >
          {text}
        </Text>
      )}
      {rightIcon && <Icon name={rightIcon} size={iconSize} /> }
      {rightComponent}
    </StyledButton>
  );

  return renderLink ? withLink(renderContent, url, urlScroll, urlTargetBlank) : renderContent;
};

export default Button;

const StyledButton = styled.button<
  {
    tabIndex?: number;
    variantIsLink: boolean;
    disabled: boolean;
    iconOnly: boolean;
  } & Partial<ButtonVariant> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ iconOnly, variantIsLink }) => {
    if (iconOnly) {
      return '10px 10px';
    }
    if (variantIsLink) {
      return '7px 13px 9px';
    }
    return '7px 10px 9px';
  }};

  transition: var(--base-transition);
  pointer-events: auto;
  white-space: nowrap;
  min-height: 44px;

  border-radius: 8px;
  border-width: 2px;
  border-style: solid;

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--page-background-color),
      0 0 0 4px var(--field-focus-border-color);
  }

  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  border-color: ${({ border }) => border};
  background: ${({ background }) => background};
  color: ${({ textColor }) => textColor};
  .mso-icon {
    fill: ${({ iconColor }) => iconColor};
  }

  &:hover {
    text-decoration: ${({ variantIsLink }) => (variantIsLink ? 'none' : 'inherit')};
    background: ${({ backgroundHover }) => backgroundHover};
    color: ${({ textHover }) => textHover};
    border-color: ${({ borderHover }) => borderHover};
    .mso-icon {
      fill: ${({ iconHover }) => iconHover};
    }
    ${({ boxShadow, disabled }) => boxShadow && !disabled && `box-shadow: ${boxShadow};`}
  }
  &:active {
    background: ${({ backgroundSelected }) => backgroundSelected};
    border-color: ${({ borderSelected }) => borderSelected};
    color: ${({ textSelected }) => textSelected};
    .mso-icon {
      fill: ${({ iconSelected }) => iconSelected};
    }
  }
`;
const Text = styled.span<{
  withLeftIcon: boolean;
  withRightIcon: boolean;
  textFont: FlattenSimpleInterpolation;
}>`
  margin-left: ${({ withLeftIcon }) => (withLeftIcon ? '8px' : '0')};
  margin-right: ${({ withRightIcon }) => (withRightIcon ? '8px' : '0')};
  ${({ textFont }) => textFont}
`;
