import { Subscription, Profile } from '@root/src/types';

const useSubscriptionInfo = (subscription: Subscription) => {
  const isTeamRegistration = subscription.team?.length > 0;
  const teamSize = subscription.team ? subscription.team.length + 1 : 1;

  const teamMemberIDinServiceGroup = (athlete: Profile) => (isTeamRegistration ? `mso:${athlete._idMso}` : null);

  return {
    isTeamRegistration,
    teamSize,
    teamMemberIDinServiceGroup,
  };
};

export default useSubscriptionInfo;
