import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import * as animationData from '@assets/lottie/loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

type LoaderProps = {
  className?:string;
  size?: number;
}

const Loader = ({ className, size }: LoaderProps) => (
  <Container className={className}>
    <Lottie
      options={defaultOptions}
      height={size ?? 100}
      width={size ?? 100}
    />
  </Container>
);

export default Loader;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
