import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  SERVICE_GROUP_TYPE,
  ServiceGroup,
  Subscription,
  Subscriptions,
} from '@root/src/types';
import { hydrateStateIfDataNotNull, RootState } from '@services/store';
import {
  eventCategoryServiceGroupFromApiToReducer,
  subscriptionServiceGroupFromApiToReducer,
} from '@features/eventDetail/formatters';
import {
  subscriptionFromApiToReducer,
  subscriptionsFromApiToReducer,
} from '@features/subscriptions/formatters';

export interface SubscriptionDetailServerSliceState {
  subscriptions: Subscriptions
  newSubscriptions: Subscriptions
  subscription: Subscription
  servicesGroups: Array<ServiceGroup>
}

const subscriptionDetailServerSlice = createSlice({
  name: 'subscriptionDetailServer',
  initialState: {
    subscriptions: null,
    newSubscriptions: null,
    subscription: null,
    services: null,
    servicesGroups: null,
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      // COMMENT 152 (search the comment)
      const { subscriptionDetailServer } = action.payload;
      return hydrateStateIfDataNotNull(state, subscriptionDetailServer);
    },
  },
  reducers: {
    setSubscriptions: (state, action: PayloadAction<{ apiData }>) => {
      state.subscriptions = subscriptionsFromApiToReducer(action.payload.apiData);
    },
    setNewSubscriptions: (state, action) => {
      state.newSubscriptions = subscriptionsFromApiToReducer(action.payload);
    },
    setSubscription: (state, action: PayloadAction<{ subscriptionApi }>) => {
      state.subscription = subscriptionFromApiToReducer(action.payload.subscriptionApi);
    },
    setSubscriptionServicesGroups: (state, action: PayloadAction<{ servicesGroupApi }>) => {
      state.servicesGroups = action.payload.servicesGroupApi.map(subscriptionServiceGroupFromApiToReducer);
    },
    setServicesGroups: (state, action: PayloadAction<{ servicesGroupApi }>) => {
      state.servicesGroups = action.payload.servicesGroupApi.map(eventCategoryServiceGroupFromApiToReducer);
    },
  },
});

export default subscriptionDetailServerSlice.reducer;

export const {
  setSubscriptions,
  setNewSubscriptions,
  setSubscriptionServicesGroups,
  setSubscription,
} = subscriptionDetailServerSlice.actions;

export const selectSubscriptions = (state: RootState) => state.subscriptionDetailServer.subscriptions;
export const selectNewSubscriptions = (state: RootState) => state.subscriptionDetailServer.newSubscriptions;
export const selectServicesGroup = (state: RootState) => state.subscriptionDetailServer.servicesGroups || [];
export const selectPreServicesGroups = (state: RootState) => state.subscriptionDetailServer.servicesGroups
  .filter(serviceGroup => serviceGroup.groupType === SERVICE_GROUP_TYPE.PRE_SERVICES);
export const selectCurrentSubscription = (state: RootState) => state.subscriptionDetailServer.subscription;

export const selectSubscriptionsList = createSelector(selectSubscriptions, subscriptions => subscriptions?.items || []);
export const selectSubscriptionsIDs = createSelector(
  selectSubscriptionsList,
  subscriptions => subscriptions
    .filter(item => item.category.event)
    .map((subscription: Subscription) => subscription._id),
);
export const selectSubscriptionByID = (id: string) => createSelector(
  selectSubscriptionsList,
  subscriptions => subscriptions.find(subscription => subscription._id === id),
);

export const selectServicesGroupById = (serviceGroupID: number) => createSelector(
  selectServicesGroup,
  (serviceGroups) => serviceGroups
    .find(serviceGroup => serviceGroup._idMso === serviceGroupID)
);

export const selectServiceByServiceGroupIDAndServiceID = (serviceGroupID: number, serviceID: number) => createSelector(
  selectServicesGroup,
  (serviceGroups) => serviceGroups
    .find(serviceGroup => serviceGroup._idMso === serviceGroupID)
    .services.find(service => service._idMso === serviceID),
);
