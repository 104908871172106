import React from 'react';
import styled from 'styled-components';
import iconAdd from '@assets/icons/add.svg';
import iconAccountCircle from '@assets/icons/account_circle.svg';
import iconArrowDropDown from '@assets/icons/arrow_drop_down.svg';
import iconArrowForward from '@assets/icons/arrow_forward.svg';
import iconArrowBack from '@assets/icons/arrow_back.svg';
import iconAssignmentInd from '@assets/icons/assignment_ind.svg';
import iconBusiness from '@assets/icons/business.svg';
import iconCheck from '@assets/icons/check.svg';
import iconCheckBox from '@assets/icons/check_box.svg';
import iconChevronLeft from '@assets/icons/chevron_left.svg';
import iconChevronRight from '@assets/icons/chevron_right.svg';
import iconCollections from '@assets/icons/collections.svg';
import iconComputer from '@assets/icons/computer.svg';
import iconClear from '@assets/icons/clear.svg';
import iconDateRange from '@assets/icons/date_range.svg';
import iconDescription from '@assets/icons/description.svg';
import iconEmojiEvent from '@assets/icons/emoji_event.svg';
import iconEvent from '@assets/icons/event.svg';
import iconMoreHoriz from '@assets/icons/more_horiz.svg';
import iconError from '@assets/icons/error.svg';
import iconExpandMore from '@assets/icons/expand_more.svg';
import iconHistory from '@assets/icons/history.svg';
import iconInfo from '@assets/icons/info.svg';
import iconKeyboardReturn from '@assets/icons/keyboard_return.svg';
import iconLanguage from '@assets/icons/language.svg';
import iconLocationOn from '@assets/icons/location_on.svg';
import iconMail from '@assets/icons/mail.svg';
import iconMenu from '@assets/icons/menu.svg';
import iconOutlinedFlag from '@assets/icons/outlined_flag.svg';
import iconPerson from '@assets/icons/person.svg';
import iconPhone from '@assets/icons/phone.svg';
import iconPhotoCamera from '@assets/icons/photo_camera.svg';
import iconPlace from '@assets/icons/place.svg';
import iconPlaylistAddCheck from '@assets/icons/playlist_add_check.svg';
import iconRadioButtonChecked from '@assets/icons/radio_button_checked.svg';
import iconSearch from '@assets/icons/search.svg';
import iconShoppingCart from '@assets/icons/shopping_cart.svg';
import iconSort from '@assets/icons/sort.svg';
import iconStars from '@assets/icons/stars.svg';
import iconTimer from '@assets/icons/timer.svg';
import iconMilitaryTech from '@assets/icons/military_tech.svg';
import iconWbSunny from '@assets/icons/wb_sunny.svg';
import iconAssetBib from '@assets/icons/asset_bib.svg';
import iconAssetOnline from '@assets/icons/asset_online.svg';
import iconAssetPayment from '@assets/icons/asset_payment.svg';
import iconAssetResult from '@assets/icons/asset_result.svg';
import iconAssetTeam from '@assets/icons/asset_team.svg';
import iconCookie from '@assets/icons/cookie.svg';
import iconHelp from '@assets/icons/help.svg';
import iconPaymentBill from '@assets/icons/payment_bill.svg';
import iconPaymentMastercard from '@assets/icons/payment_mastercard.svg';
import iconPaymentPost from '@assets/icons/payment_post.svg';
import iconPaymentSamsung from '@assets/icons/payment-samsungpay.svg';
import iconPaymentSofort from '@assets/icons/payment-sofort.svg';
import iconPaymentApplePay from '@assets/icons/payment-applepay.svg';
import iconPaymentGooglePay from '@assets/icons/payment-googlepay.svg';
import iconSecure from '@assets/icons/secure.svg';
import iconPaymentTransfer from '@assets/icons/payment_transfer.svg';
import iconPaymentTwint from '@assets/icons/payment_twint.svg';
import iconPaymentVisa from '@assets/icons/payment_visa.svg';
import iconPin from '@assets/icons/pin.svg';
import iconSocialFacebook from '@assets/icons/social_facebook.svg';
import iconSocialInstagram from '@assets/icons/social_instagram.svg';
import iconSocialLinkedin from '@assets/icons/social_linkedin.svg';
import iconSocialStrava from '@assets/icons/social_strava.svg';
import iconSocialTwitter from '@assets/icons/social_twitter.svg';
import iconSocialYoutube from '@assets/icons/social_youtube.svg';
import iconSportAlpineSkiing from '@assets/icons/sport_alpine_skiing.svg';
import iconSportCrossCountrySkiing from '@assets/icons/sport_cross_country_skiing.svg';
import iconSportCycling from '@assets/icons/sport_cycling.svg';
import iconSportMoto from '@assets/icons/sport_moto.svg';
import iconSportTriathlon from '@assets/icons/sport_triathlon.svg';
import iconSportTrail from '@assets/icons/sport_trail.svg';
import iconSportVtt from '@assets/icons/sport_vtt.svg';
import iconSportOther from '@assets/icons/sport_other.svg';
import iconExportPDF from '@assets/icons/export_pdf.svg';
import iconExportTXT from '@assets/icons/export_txt.svg';
import iconExportXLS from '@assets/icons/export_xls.svg';
import iconEye from '@assets/icons/eye.svg';
import iconEyeClosed from '@assets/icons/eye_closed.svg';
import iconReplay from '@assets/icons/replay.svg';
import iconRefresh from '@assets/icons/refresh.svg';
import iconLock from '@assets/icons/lock.svg';
import iconHelpCircle from '@assets/icons/help_circle.svg';
import iconGroups from '@assets/icons/groups.svg';
import iconDelete from '@assets/icons/delete.svg';
import iconRemoveCircle from '@assets/icons/remove_circle.svg';
import iconPersonAdd from '@assets/icons/person_add.svg';
import iconRadioUnchecked from '@assets/icons/radio_unchecked.svg';
import iconSuccess from '@assets/icons/success.svg';
import iconPayment from '@assets/icons/payment.svg';
import iconEdit from '@assets/icons/edit.svg';
import iconHome from '@assets/icons/home.svg';
import iconLogout from '@assets/icons/logout.svg';
import iconReceipt from '@assets/icons/receipt.svg';
import iconRemove from '@assets/icons/remove.svg';
import iconMobilePhone from '@assets/icons/mobile_phone.svg';
import iconDownloadFile from '@assets/icons/file_download.svg';
import iconSMS from '@assets/icons/sms.svg';
import iconSend from '@assets/icons/send.svg';
import iconLoading from '@assets/icons/loading_animated.svg';
import iconMedalGold from '@assets/icons/medal_gold.svg';
import iconMedalSilver from '@assets/icons/medal_silver.svg';
import iconMedalBronze from '@assets/icons/medal_bronze.svg';
import { FLAG_NAME, flagSvgContent } from '@features/ui/components/CountryFlag/utils';

export enum ICON_NAME {
  add = 'add',
  accountCircle = 'accountCircle',
  arrowDropDown = 'arrowDropDown',
  arrowForward = 'arrowForward',
  arrowBack = 'arrowBack',
  assignmentInd = 'assignmentInd',
  business = 'business',
  check = 'check',
  checkBox = 'checkBox',
  chevronRight = 'chevronRight',
  chevronLeft = 'chevronLeft',
  collections = 'collections',
  computer = 'computer',
  clear = 'clear',
  dateRange = 'dateRange',
  description = 'description',
  downloadFile = 'downloadFile',
  emojiEvent = 'emojiEvent',
  event = 'event',
  error = 'error',
  expandMore = 'expandMore',
  history = 'history',
  info = 'info',
  keyboardReturn = 'keyboardReturn',
  language = 'language',
  locationOn = 'locationOn',
  mail = 'mail',
  menu = 'menu',
  moreHoriz = 'moreHoriz',
  militaryTech = 'militaryTech',
  outlinedFlag = 'outlinedFlag',
  person = 'person',
  phone = 'phone',
  photoCamera = 'photoCamera',
  place = 'place',
  playlistAddCheck = 'playlistAddCheck',
  radioButtonChecked = 'radioButtonChecked',
  search = 'search',
  shoppingCart = 'shoppingCart',
  sort = 'sort',
  stars = 'stars',
  timer = 'timer',
  wbSunny = 'wbSunny',
  eye = 'eye',
  eyeClosed = 'eyeClosed',
  replay = 'replay',
  refresh = 'refresh',
  lock = 'lock',
  helpCircle = 'helpCircle',
  home = 'home',
  logout = 'logout',
  receipt = 'receipt',
  remove = 'remove',
  mobilePhone = 'mobilePhone',
  sms = 'sms',
  send = 'send',
  // customs icons
  assetBib = 'assetBib',
  assetOnline = 'assetOnline',
  assetPayment = 'assetPayment',
  assetResults = 'assetResult',
  assetTeam = 'assetTeam',
  cookie = 'cookie',
  help = 'help',
  sportCycling = 'sportCycling',
  sportMoto = 'sportMoto',
  sportAlpineSkiing = 'sportAlpineSkiing',
  sportCrossCountrySkiing = 'sportCrossCountrySkiing',
  sportTrail = 'sportTrail',
  sportTriathlon = 'sportTriathlon',
  sportVtt = 'sportVtt',
  sportOther = 'sportOther',
  socialFacebook = 'socialFacebook',
  socialInsta = 'socialInsta',
  socialLinkedin = 'socialLinkedin',
  socialStrava = 'socialStrava',
  socialTwitter = 'socialTwitter',
  socialYoutube = 'socialYoutube',
  pin = 'pin',
  paymentBill = 'paymentBill',
  paymentMaster = 'paymentMaster',
  paymentPost = 'paymentPost',
  paymentTransfer = 'paymentTransfer',
  paymentTwint = 'paymentTwint',
  paymentVisa = 'paymentsVisa',
  paymentGoogle = 'paymentGoogle',
  paymentApple = 'paymentApple',
  paymentSofort = 'paymentSofort',
  paymentSamsung = 'paymentSamsung',
  secure = 'secure',
  exportPDF = 'exportPDF',
  exportXLS = 'exportXLS',
  exportTXT = 'exportTXT',
  iconGroups = 'iconGroups',
  iconDelete = 'iconDelete',
  iconRemoveCircle = 'iconRemoveCircle',
  iconPersonAdd = 'iconPersonAdd',
  iconRadioUnchecked = 'iconRadioUnchecked',
  iconSuccess = 'iconSuccess',
  iconPayment = 'iconPayment',
  iconEdit = 'iconEdit',
  loading = 'iconLoading',
  medalGold = 'medalGold',
  medalSilver = 'medalSilver',
  medalBronze = 'medalBronze',
}

export interface IconProps {
  name: ICON_NAME | FLAG_NAME;
  size?: string;
  color?: string;
  className?: string;
  onClick?: (e) => void;
  domAttributes?: Partial<{ [key in keyof HTMLDivElement]: any }>;
}

const Icon = ({
  name,
  size = '20px',
  color = 'var(--main-text-color)',
  className,
  onClick,
  domAttributes,
}: IconProps) => {
  const SvgContent = {
    [ICON_NAME.add]: iconAdd,
    [ICON_NAME.accountCircle]: iconAccountCircle,
    [ICON_NAME.arrowDropDown]: iconArrowDropDown,
    [ICON_NAME.arrowForward]: iconArrowForward,
    [ICON_NAME.arrowBack]: iconArrowBack,
    [ICON_NAME.assignmentInd]: iconAssignmentInd,
    [ICON_NAME.business]: iconBusiness,
    [ICON_NAME.check]: iconCheck,
    [ICON_NAME.checkBox]: iconCheckBox,
    [ICON_NAME.chevronRight]: iconChevronLeft,
    [ICON_NAME.chevronLeft]: iconChevronRight,
    [ICON_NAME.collections]: iconCollections,
    [ICON_NAME.computer]: iconComputer,
    [ICON_NAME.clear]: iconClear,
    [ICON_NAME.dateRange]: iconDateRange,
    [ICON_NAME.description]: iconDescription,
    [ICON_NAME.downloadFile]: iconDownloadFile,
    [ICON_NAME.emojiEvent]: iconEmojiEvent,
    [ICON_NAME.event]: iconEvent,
    [ICON_NAME.error]: iconError,
    [ICON_NAME.expandMore]: iconExpandMore,
    [ICON_NAME.history]: iconHistory,
    [ICON_NAME.info]: iconInfo,
    [ICON_NAME.keyboardReturn]: iconKeyboardReturn,
    [ICON_NAME.language]: iconLanguage,
    [ICON_NAME.locationOn]: iconLocationOn,
    [ICON_NAME.mail]: iconMail,
    [ICON_NAME.menu]: iconMenu,
    [ICON_NAME.moreHoriz]: iconMoreHoriz,
    [ICON_NAME.militaryTech]: iconMilitaryTech,
    [ICON_NAME.outlinedFlag]: iconOutlinedFlag,
    [ICON_NAME.person]: iconPerson,
    [ICON_NAME.phone]: iconPhone,
    [ICON_NAME.photoCamera]: iconPhotoCamera,
    [ICON_NAME.place]: iconPlace,
    [ICON_NAME.playlistAddCheck]: iconPlaylistAddCheck,
    [ICON_NAME.radioButtonChecked]: iconRadioButtonChecked,
    [ICON_NAME.search]: iconSearch,
    [ICON_NAME.shoppingCart]: iconShoppingCart,
    [ICON_NAME.sort]: iconSort,
    [ICON_NAME.stars]: iconStars,
    [ICON_NAME.timer]: iconTimer,
    [ICON_NAME.wbSunny]: iconWbSunny,
    [ICON_NAME.eye]: iconEye,
    [ICON_NAME.eyeClosed]: iconEyeClosed,
    [ICON_NAME.replay]: iconReplay,
    [ICON_NAME.refresh]: iconRefresh,
    [ICON_NAME.lock]: iconLock,
    [ICON_NAME.helpCircle]: iconHelpCircle,
    [ICON_NAME.iconGroups]: iconGroups,
    [ICON_NAME.iconDelete]: iconDelete,
    [ICON_NAME.iconRemoveCircle]: iconRemoveCircle,
    [ICON_NAME.iconPersonAdd]: iconPersonAdd,
    [ICON_NAME.iconRadioUnchecked]: iconRadioUnchecked,
    [ICON_NAME.iconPayment]: iconPayment,
    [ICON_NAME.iconEdit]: iconEdit,
    [ICON_NAME.home]: iconHome,
    [ICON_NAME.logout]: iconLogout,
    [ICON_NAME.remove]: iconRemove,
    [ICON_NAME.receipt]: iconReceipt,
    [ICON_NAME.mobilePhone]: iconMobilePhone,
    [ICON_NAME.sms]: iconSMS,
    [ICON_NAME.send]: iconSend,
    // customs icons
    [ICON_NAME.assetBib]: iconAssetBib,
    [ICON_NAME.assetOnline]: iconAssetOnline,
    [ICON_NAME.assetPayment]: iconAssetPayment,
    [ICON_NAME.assetResults]: iconAssetResult,
    [ICON_NAME.assetTeam]: iconAssetTeam,
    [ICON_NAME.cookie]: iconCookie,
    [ICON_NAME.help]: iconHelp,
    [ICON_NAME.sportCycling]: iconSportCycling,
    [ICON_NAME.sportMoto]: iconSportMoto,
    [ICON_NAME.sportAlpineSkiing]: iconSportAlpineSkiing,
    [ICON_NAME.sportCrossCountrySkiing]: iconSportCrossCountrySkiing,
    [ICON_NAME.sportTrail]: iconSportTrail,
    [ICON_NAME.sportTriathlon]: iconSportTriathlon,
    [ICON_NAME.sportVtt]: iconSportVtt,
    [ICON_NAME.sportOther]: iconSportOther,
    [ICON_NAME.socialFacebook]: iconSocialFacebook,
    [ICON_NAME.socialInsta]: iconSocialInstagram,
    [ICON_NAME.socialLinkedin]: iconSocialLinkedin,
    [ICON_NAME.socialStrava]: iconSocialStrava,
    [ICON_NAME.socialTwitter]: iconSocialTwitter,
    [ICON_NAME.socialYoutube]: iconSocialYoutube,
    [ICON_NAME.pin]: iconPin,
    [ICON_NAME.paymentBill]: iconPaymentBill,
    [ICON_NAME.paymentMaster]: iconPaymentMastercard,
    [ICON_NAME.paymentPost]: iconPaymentPost,
    [ICON_NAME.paymentTransfer]: iconPaymentTransfer,
    [ICON_NAME.paymentTwint]: iconPaymentTwint,
    [ICON_NAME.paymentVisa]: iconPaymentVisa,
    [ICON_NAME.paymentSamsung]: iconPaymentSamsung,
    [ICON_NAME.paymentApple]: iconPaymentApplePay,
    [ICON_NAME.paymentGoogle]: iconPaymentGooglePay,
    [ICON_NAME.paymentSofort]: iconPaymentSofort,
    [ICON_NAME.secure]: iconSecure,
    [ICON_NAME.exportPDF]: iconExportPDF,
    [ICON_NAME.exportXLS]: iconExportXLS,
    [ICON_NAME.exportTXT]: iconExportTXT,
    [ICON_NAME.iconSuccess]: iconSuccess,
    [ICON_NAME.loading]: iconLoading,
    [ICON_NAME.medalGold]: iconMedalGold,
    [ICON_NAME.medalSilver]: iconMedalSilver,
    [ICON_NAME.medalBronze]: iconMedalBronze,
    ...flagSvgContent,
  }[name];

  let classNameProcess = 'mso-icon';
  if (className) {
    classNameProcess += className;
  }
  const withOnClick = !!onClick;
  return (
    <IconWrapper
      onClick={onClick}
      withOnClick={withOnClick}
      heightProp={size}
      colorProp={color}
      className={classNameProcess}
      {...domAttributes}
    >
      <SvgContent />
    </IconWrapper>
  );
};

export default Icon;

const IconWrapper = styled.div<{
  heightProp: string;
  colorProp: string;
  withOnClick: boolean;
}>`
  height: ${({ heightProp }) => heightProp};
  transition: all 0.3s, height 0;
  fill: ${({ colorProp }) => colorProp};
  cursor: ${({ withOnClick }) => (withOnClick ? 'pointer' : 'inherit')};
  svg {
    height: 100%;
  }
`;
