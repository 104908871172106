import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { GetServerSidePropsContext } from 'next';

enum COOKIE_NAME {
  TOKEN = 'mso_token',
  USER_ID = 'mso_user_id',
}
export const setAuthCookies = ({ token, userId, ctx }: {
  token: string;
  userId: number;
  ctx?: GetServerSidePropsContext;
}) => {
  setCookie(ctx, COOKIE_NAME.TOKEN, token, {
    path: '/',
    maxAge: 60 * 60 * 24 * 30,
    secure: false,
  });
  setCookie(ctx, COOKIE_NAME.USER_ID, userId.toString(), {
    path: '/',
    maxAge: 60 * 60 * 24 * 30,
    secure: false,
  });
};

export const getAuthCookies = (ctx?: GetServerSidePropsContext) => {
  const cookies = parseCookies(ctx);
  return {
    token: cookies[COOKIE_NAME.TOKEN],
    userId: parseInt(cookies[COOKIE_NAME.USER_ID], 10),
  };
};

export const destroyAuthCookies = (ctx?: GetServerSidePropsContext) => {
  destroyCookie(ctx, COOKIE_NAME.TOKEN, {
    path: '/',
    secure: false,
  });
  destroyCookie(ctx, COOKIE_NAME.USER_ID, {
    path: '/',
    secure: false,
  });
};

export const isAuthenticated = (ctx?: GetServerSidePropsContext): boolean => {
  const { userId, token } = getAuthCookies(ctx);
  return !!(userId && token);
};
