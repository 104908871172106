import { createGlobalStyle } from 'styled-components';
import fontCss from '@features/ui/styles/global/font';
import resetCss from '@features/ui/styles/global/reset';
import inputPhoneCss from '@features/ui-input/components/InputPhone/styles';
import reactToastifyCss from '@features/ui/styles/reactToastify';
import dateRangeCss from '@features/ui/components/DateRange/styles';

const globalStyle = createGlobalStyle`
  ${fontCss};
  ${resetCss};
  /*  --------------------------------------------------------------------------------
  --- VAR
  -------------------------------------------------------------------------------- */
  :root {
    /* Size */
    --max-width: 1024px;

    /* Color */
    --color-blue: #1CBAA8;
    --color-blue-hover: #3CD7C5;
    --color-blue-light: #F3FBFA;
    --color-orange: #F77805;
    --color-orange-hover: #FF891E;
    --color-orange-light: #FFF7F0;
    --color-dark: #0D0C0C;
    --color-grey4: #403D3D;
    --color-grey3: #949E9D;
    --color-grey2: #DCE0E0;
    --color-grey1: #F5F7F7;
    --color-white: #FFFFFF;
    --color-red: #F1422C;
    /* Helper colors, not defined in styleguide */
    --modal-background: rgba(13, 12, 12, 0.5);

    /* Fonts */
    --font-barlow: 'barlow', sans-serif;
    --font-jeko: 'jeko-black', sans-serif;


    /* Page constants */
    --navbar-height: 58px;
    --page-min-width: 320px;
    --page-max-width: 1600px;
    --modal-small-width: 600px;
    --modal-large-width: 1200px;
    --jumpHackScroll: calc(17px - (100vw - 100%));

    /* z-index */
    --z-index-under-content: -10;
    --z-index-content: 0;
    --z-index-over-content: 50;
    --z-index-header: 150;
    --z-index-modal-overlay: 300;
    --z-index-modal-content: 350;
    --z-index-modal-content-template-body: 370;
    --z-index-modal-content-template-footer: 390;
    /* /!\ WARNING /!\ - keep in mind - */
    /* Material-UI popover : z-index=1300 */

      /* Misc helpers */
    --base-transition: all 0.25s;
    --dropdown-shadow: 0 0 5px 0 rgba(68, 99, 117, 0.5);
    --alert-shadow: 0 2px 16px 0 rgba(128,122,122,0.25);
    --menu-shadow: 0 0 10px 0 rgba(68, 99, 117, 0.5);
    --menu-height: 101px;


    --box-shadow-color: rgba(128,122,122,0.2);

  }
  /*  --------------------------------------------------------------------------------
  --- GLOBAL
  -------------------------------------------------------------------------------- */
  html {
    height: 100%;
    font-family: var(--font-barlow);
    font-weight: 400;
    font-size: 16px;
    font-feature-settings: "lnum";
    box-sizing: border-box;
    min-width: var(--page-min-width);
  }

  @media (max-width: 1080px) {
    /* in mobile inside modals should be above modal z index */
    .DateRangePicker_picker {
      z-index: calc(var(--z-index-modal-content-template-footer)) !important;
    }
  }

  /* for event tabs */
  @media (min-width: 1080px) {
    body {
      overflow-x: hidden;
    }
  }

  body {
    margin: 0;
    /* !important is used to overwrite the padding inject
     by material-ui when you use the popover API and it is open.
     Because we want to use our own hack for better render.
     cf: --jumpHackScroll */
    padding: 0 !important;
    background: var(--color-white);
    color: var(--color-dark);
    z-index: var(--z-index-content);
  }

  a,
  a:active,
  button,
  button:active {
    outline: none;
    color: inherit;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  ${reactToastifyCss};
  ${dateRangeCss};
  ${inputPhoneCss};
`;
export default globalStyle;
