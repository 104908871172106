import { Order, Orders } from '@root/src/types';
import { removeUndefs } from '@utils/gobal';

export const ordersFromApiToReducer = (
  ordersApi: any,
): Orders => removeUndefs<Orders>({
  items: ordersApi.items.map(orderFromApiToReducer),
  total: ordersApi.total,
  pages: ordersApi.pages,
});

export const orderFromApiToReducer = (
  orderApi: any,
): Order => removeUndefs<Order>({
  _idMso: parseInt(orderApi._idMso, 10),
  _id: orderApi._id,
  createdAt: orderApi.createdAt,
  created: orderApi.created,
  uniqid: orderApi.uniqid,
  orderid: orderApi.orderid,
  orderStatus: orderApi.orderStatus,
  fidAthlete: orderApi.fid_athlete,
  price: {
    totalItems: orderApi.price.totalItems,
    totalFee: orderApi.price.totalFee,
    totalOrder: orderApi.price.totalOrder,
    totalReduction: orderApi.price.totalReduction,
    totalInsurance: orderApi.price.totalInsurance,
    fee: orderApi.price.fee ? {
      amount: orderApi.price.fee.amount,
      amountFixed: orderApi.price.fee.amountFixed,
      amountVariable: orderApi.price.fee.amountVariable,
      percent: orderApi.price.fee.percent,
    } : null,
    currency: orderApi.price.currency,
  },
  paymentMethod: orderApi.paymentMethod,
  paymentBrand: orderApi.paymentBrand || null,
});
