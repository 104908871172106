import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { DEFAULT_PRE_SERVICES } from '@root/src/types';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import yup from '@services/translation/translatedYup';
import InputText from '@features/ui-input/components/InputText';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { selectEventFromCategoryId } from '@features/eventDetail/slices/eventDetailServerSlice';

export enum VIP_ERROR {
  INVALID = 'error.invalidVipCode',
  ALREADY_USED = 'error.vipCodeAlreadyUsed'
}
export enum VIP_VALIDATION_INPUT {
  VIP_IS_VALID = 'VIP_IS_VALID',
  VIP_ERROR = 'VIP_ERROR'
}
export const vipDefaultValue = {
  [VIP_VALIDATION_INPUT.VIP_IS_VALID]: false,
  [VIP_VALIDATION_INPUT.VIP_ERROR]: '',
};
export const vipSchemaValidator = (t) => ({ [VIP_VALIDATION_INPUT.VIP_IS_VALID]: yup.boolean().oneOf([true], t('InputVIP_required.message')) });

const InputVIP = (props) => {
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const requestHandler = useRequestHandler();
  const formikContext = useFormikContext();
  const { t } = useTranslation();

  const { categoryID, ...rest } = props;
  const event = useSelector(selectEventFromCategoryId(categoryID)); // TODO: replace by a better approach

  const manageCodeError = (codeError: VIP_ERROR) => {
    switch (codeError) {
      case VIP_ERROR.INVALID:
        formikContext.setFieldValue(VIP_VALIDATION_INPUT.VIP_ERROR, t('InputVIP_invalidVipCode.message'));
        break;
      case VIP_ERROR.ALREADY_USED:
        formikContext.setFieldValue(VIP_VALIDATION_INPUT.VIP_ERROR, t('InputVIP_vipCodeAlreadyUsed.message'));
        break;
      default:
    }
  };

  const isValid = async (e) => {
    e.preventDefault();
    const vipMeta = formikContext.getFieldMeta(DEFAULT_PRE_SERVICES.VIP);
    const vipCode = vipMeta.value as string;
    setIsValidatingCode(true);
    const { response } = await requestHandler({
      request: api.subscription.vips.checkCode({
        categoryId: categoryID,
        eventId: event._idMso,
        vipCode: vipCode as string,
      }),
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (handlerResponse) => handlerResponse },
    });
    if (response) {
      if (!response?.ok) {
        const re = await response.json();
        manageCodeError(re.code as VIP_ERROR);
      }
      setIsValidatingCode(false);
      formikContext.setFieldValue(VIP_VALIDATION_INPUT.VIP_IS_VALID, response.ok);
    }
  };

  const isInputDisabled = formikContext.getFieldMeta(VIP_VALIDATION_INPUT.VIP_IS_VALID).value as boolean;

  return (
    <Container>
      <InputText
        {...rest}
        disabled={isInputDisabled}
        onClear={() => formikContext.setFieldValue(VIP_VALIDATION_INPUT.VIP_IS_VALID, false)}
      />
      <Button
        text={t('InputVIP_validationButton.message')}
        variant={BUTTON_VARIANT.secondary}
        onClick={isValid}
        disabled={isValidatingCode}
      />
    </Container>
  );
};

export default InputVIP;

const Container = styled.div`
  display: flex;
  & Button {
    margin-left: 4px;
  }
`;
