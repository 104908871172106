import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const formatToMoment = (dateString: string) => {
  if (dateString === '' || dateString == null) {
    return null;
  }
  return moment(dateString, 'Y-M-D');
};

interface DateRangeProps {
  initialStartDate?: string,
  initialEndDate?: string,
  onDateChanged: ({
    startDate,
    endDate,
  }: { startDate?: moment.Moment, endDate?: moment.Moment }) => void,
  isFullScreen?: boolean,
  isVertical?: boolean,
  className?: string,
  customIconInput?: React.ReactElement
}

const DateRange = ({
  initialStartDate,
  initialEndDate,
  onDateChanged,
  isFullScreen,
  isVertical,
  className,
  customIconInput,
}: DateRangeProps) => {
  const router = useRouter();
  const [focus, setFocus] = useState(null);
  const [startDate, setStartDate] = useState(formatToMoment(initialStartDate));
  const [endDate, setEndDate] = useState(formatToMoment(initialEndDate));
  const [uniqId] = useState(Math.random()); // should be enough...
  const { t } = useTranslation();

  moment.locale(router.locale);

  useEffect(() => {
    setStartDate(formatToMoment(initialStartDate));
    setEndDate(formatToMoment(initialEndDate));
  }, [initialStartDate, initialEndDate]);

  return (
    <div className={className}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        startDateId={`startDate_uniq_id_${uniqId}`}
        endDateId={`endDate_uniq_id_${uniqId}`}
        hideKeyboardShortcutsPanel
        showClearDates
        displayFormat="DD.MM.YYYY"
        customInputIcon={customIconInput}
        isOutsideRange={() => false}
        startDatePlaceholderText={t('dateRange_startDateInputPlaceholder.message')}
        endDatePlaceholderText={t('dateRange_endDateInputPlaceholder.message')}
        onDatesChange={({
          startDate,
          endDate,
        }) => {
          setEndDate(endDate);
          setStartDate(startDate);
          onDateChanged(({
            startDate,
            endDate,
          }));
        }}
        focusedInput={focus}
        onFocusChange={focusedInput => setFocus(focusedInput)}
        customArrowIcon={<></>}
        orientation={isVertical ? 'vertical' : 'horizontal'}
        withFullScreenPortal={isFullScreen}
      />
    </div>
  );
};

export default DateRange;
