import React, { useRef, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components';
import Button from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textButtonDropdown } from '@features/ui/styles/textStyles';

export interface DropdownDataItem {
  text: string;
  onClick?: (dataItem: DropdownDataItem) => void;
}

interface OwnProps {
  placeholder?: string;
  data: Array<DropdownDataItem>;
  icon?: ICON_NAME;
  iconSize?: string;
  className?: string;
}

const ButtonDropdown = ({
  data,
  placeholder,
  icon,
  iconSize = '20px',
  className,
}: OwnProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dataItemSelected, setDataItemSelected] = useState<DropdownDataItem>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'button-dropdown-popover' : undefined;
  const dropDownComponentRef = useRef(null);

  const onDataItemClicked = (dataItem: DropdownDataItem) => {
    setDataItemSelected(dataItem);
    handleClose();
    dataItem.onClick?.(dataItem);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={dropDownComponentRef} className={className}>
      <Button
        text={dataItemSelected?.text || placeholder}
        onClick={handleClick}
        leftIcon={icon}
        rightIcon={ICON_NAME.expandMore}
        iconSize={iconSize}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            marginTop: '5px',
            width: `${dropDownComponentRef.current?.clientWidth}px`,
            boxSizing: 'border-box',
            border: '1px solid var(--color-grey1)',
            borderRadius: '4px',
            boxShadow: '0 2px 12px 0 var(--box-shadow-color)',
          },
        }}
      >
        <StyledContent>
          {data.map((item) => (
            <StyledItem
              key={item.text}
              onClick={() => onDataItemClicked(item)}
            >
              {item.text}
            </StyledItem>
          ))}
        </StyledContent>
      </Popover>
    </div>
  );
};
export default ButtonDropdown;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledItem = styled.div`
  ${textButtonDropdown};
  padding: 14px 16px;
  cursor: pointer;
  width: 100%;
  :hover {
   background-color: var(--color-orange-light);
  }
`;
