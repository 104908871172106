import { ThemeOptions } from '@material-ui/core';

const overrides: ThemeOptions['overrides'] = {
  MuiInputBase: {
    root: {
      color: 'var(--color-grey4)',
      background: 'var(--color-grey1) !important',
      height: '40px !important',
      borderRadius: '4px',
    },
  },
  MuiSelect: {
    select: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      paddingRight: '20px !important',
      paddingLeft: '16px !important',
    },
    root: { paddingLeft: '6px' },
  },
  MuiPaper: {
    elevation8: {
      boxShadow: '0px 1px 5px rgba(68, 99, 117, 0.2)',
      borderRadius: '5px',
    },
  },
  MuiList: {
    padding: {
      paddingTop: '0',
      paddingBottom: '0',
    },
  },
  MuiListItem: {
    root: {
      color: 'var(--color-grey4)',
      textSize: '16px',
      fontWeight: 500,
      minHeight: '40px !important',
      fontFamily: 'barlow,sans-serif !important',
    },
    gutters: {
      paddingLeft: '16px',
      paddingRight: '8px',
    },
  },
};

export default overrides;
