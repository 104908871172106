import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'next-i18next';
import config from '@root/src/config';
import routeList, { buildAsUrl } from '@services/routeList';
import { textH4, textLink, textP } from '@features/ui/styles/textStyles';

const SubmitConfirmed = ({ email }) => {
  const { t } = useTranslation();
  const helpLink = config.helpUrl.faq;

  return (
    <div>
      <Title>
        {t('modalRequestPasswordResetFormSend_title.message', { email })}
      </Title>
      <Description>
        {t('modalRequestPasswordResetFormSend_description.message')}
        <br />
        <Trans
          i18nKey="modalRequestPasswordResetFormSend_descriptionSupport.message"
        >
          <StyledA target="_blank" href={helpLink} rel="noreferrer">support</StyledA>
          <StyledA href={buildAsUrl(routeList.infos.contact())}>contact</StyledA>
        </Trans>
      </Description>
    </div>
  );
};

export default SubmitConfirmed;

const Title = styled.h4`
  ${textH4};
`;

const Description = styled.p`
  ${textP};
  margin-top: 12px;
`;

const StyledA = styled.a`
  ${textLink};
  color: var(--color-black);
`;
