import React from 'react';
import styled from 'styled-components';
import InputInt from '@features/ui-input/components/InputInt';

interface InputPriceProps {
  name: string;
  price: string;
  currency: string;
}

const InputPrice = ({
  name,
  price,
  currency,
  ...etc
}: InputPriceProps) => (
  <Container>
    <InputInt name={name} {...etc} />
    <Spacer />
    <Multiplication>×</Multiplication>
    <Price>{`${price} ${currency}`}</Price>
  </Container>
);

export default InputPrice;

const Container = styled.div`
   display: flex; 
`;

const Spacer = styled.div`
  flex: 1;
`;

const Multiplication = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;
const Price = styled.div`
  min-width: 93px;
  text-align: right;
  display:flex;
  justify-content: flex-end;
  align-items: center;
`;
