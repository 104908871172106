import { handlerResponse } from '@services/api/handlers'; // TODO 946

export const downloadPDF = async (request: Promise<handlerResponse>, fileName: string) => {
  const { response } = await request;
  if (response && response?.ok) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler);
    a.click();
  }
};

export default downloadPDF;
