import React from 'react';
import styled from 'styled-components';
import { Profile } from '@root/src/types';
import { mediaBreakpoint } from '@root/src/services/responsiveProvider';
import Card from '@features/ui/components/Card';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textH4 } from '@features/ui/styles/textStyles';

interface ServiceUserSubscriptionPreviewProps {
  user: Profile;
  onRemove: (user: Profile) => void;
  className?: string;
  notRemovable?: boolean;
}

const ServiceUserSubscriptionPreview = ({
  user,
  onRemove,
  notRemovable,
  className,
}: ServiceUserSubscriptionPreviewProps) => (
  <Container className={className}>
    <Name>
      {user.firstName}
      {' '}
      {user.lastName}
    </Name>
    <div>
      {user.birthDateObj.year}
      {' '}
      {user.legAddress?.address}
    </div>
    <div>
      {!notRemovable
        && (
          <Icon
            onClick={() => onRemove(user)}
            name={ICON_NAME.iconRemoveCircle}
            size="20px"
            color="var(--color-orange)"
          />
        )}
    </div>
  </Container>
);

export default ServiceUserSubscriptionPreview;

const Container = styled(Card)`
  display: grid;
  padding: 20px;
  grid-template-columns: 448px auto 1fr;
  align-items: center;
  > div:last-child {
    justify-self: flex-end;
  }

  ${mediaBreakpoint.mobile} {
    grid-template-columns: 1fr;
  }
`;

const Name = styled.div`
  ${textH4};
`;
