/* eslint-disable react/no-array-index-key */
// It's so bad, but we don't have enough information...
import React, { ReactElement } from 'react';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import ListHeader from '@features/ui/components/List/ListHeader';
import {
  Container,
  TableHeader,
  TableHeaderCell,
  TableItem,
  TableItemBody,
  TableItemBodyLine,
  TableItemBodyLineLabel,
  TableItemHeader,
  TableWrapper,
  Text,
} from './styles';

export enum TABLE_ITEM_HEADER_TYPE {
  TEXT = 'TEXT',
  ICON = 'ICON',
}

interface TableItemText {
  type: TABLE_ITEM_HEADER_TYPE.TEXT;
  isBold?: boolean;
  isStrikethrough?: boolean;
  content?: string | string[]| number | number[] | ReactElement;
}
interface TableItemIcon {
  type: TABLE_ITEM_HEADER_TYPE.ICON;
  iconName?: ICON_NAME;
  iconColor?: string;
}
export type TableItemType = TableItemText | TableItemIcon;
export interface sizeColumns { desktop: string; tablet: string; mobile: string; }
const sizeName = ['desktop', 'tablet', 'mobile'];

interface TableOneDefinition<T> {
  name: string;
  size: sizeColumns;
  isCenter?: boolean;
  renderCell: (e: T) => TableItemType
}
export type TableDefinition<T> = Array<TableOneDefinition<T>>
interface SplitTableDef<T> {
  body: TableDefinition<T>;
  header: TableDefinition<T>;
}
interface ListOwnProps<T> {
  listHeaderName: string;
  listHeaderCount: number;
  listHeaderIcon: ICON_NAME;
  listHeaderDownloadClick?: (e) => void;
  tableItemShouldHighlight: (e: any) => boolean;
  tableDefinition: TableDefinition<T>;
  items: Array<{ id: number } & T>;
  uniqueId: number | string;
  isLoading?: boolean;
}
const List = <T extends unknown>({
  listHeaderName,
  listHeaderCount,
  listHeaderIcon,
  listHeaderDownloadClick,
  tableItemShouldHighlight,
  tableDefinition,
  items,
  uniqueId,
  isLoading = false,
}: ListOwnProps<T>) => {
  if (items.length === 0) {
    return <div />;
  }
  const responsive = useResponsive();
  const sizeColumns = getColonSize(tableDefinition);

  const tableDefSplit = splitTableDefinition(tableDefinition, responsive);

  const renderTableItemHeaders = (headerTableDef: TableDefinition<T>, item) => (
    <TableItemHeader sizeColumns={sizeColumns}>
      {headerTableDef.map((def, index) => (
        <span key={`table_header_${item.id}_${index}_${uniqueId}`}>
          {
            getCell(def.renderCell(item), def.isCenter)
          }
        </span>
      ))}
    </TableItemHeader>
  );

  const renderTableItemBody = (bodyTableDef: TableDefinition<T>, item) => (
    <TableItemBody>
      {bodyTableDef.map((def, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableItemBodyLine key={`table_body_${item.id}_${index}_${uniqueId}`}>
          <TableItemBodyLineLabel
            isBold={false}
            isStrikethrough={false}
            isCenter={false}
          >
            {def.name}
          </TableItemBodyLineLabel>
          {getCell(def.renderCell(item))}
        </TableItemBodyLine>
      ))}
    </TableItemBody>
  );

  return (
    <Container isLoading={isLoading}>
      <ListHeader
        title={listHeaderName}
        count={listHeaderCount}
        icon={listHeaderIcon}
        onDownloadClick={listHeaderDownloadClick}
      />
      <TableHeader sizeColumns={sizeColumns} hasBody={tableDefSplit.body.length > 0}>
        { tableDefSplit.header.map((header) => (
          <TableHeaderCell key={header.name} isCenter={header.isCenter}>
            {header.name}
          </TableHeaderCell>
        ))}
      </TableHeader>
      <TableWrapper>
        {items.map(item => (
          <TableItem
            highlight={tableItemShouldHighlight(item)}
            key={item.id}
            disabled={tableDefSplit.body.length === 0}
            header={renderTableItemHeaders(tableDefSplit.header, item)}
            body={renderTableItemBody(tableDefSplit.body, item)}
          />
        ))}
      </TableWrapper>
    </Container>
  );
};

export default List;

const getCell = (tableItemHeader: TableItemType, isCenter?: boolean) => {
  if (tableItemHeader.type === TABLE_ITEM_HEADER_TYPE.TEXT) {
    if (Array.isArray(tableItemHeader.content)) {
      return tableItemHeader.content.map((content, index) => (
        <Text
          key={index}
          isStrikethrough={tableItemHeader.isStrikethrough}
          isBold={tableItemHeader.isBold}
          isCenter={isCenter}
        >
          {content}
        </Text>
      ));
    }
    return (
      <Text
        isStrikethrough={tableItemHeader.isStrikethrough}
        isBold={tableItemHeader.isBold}
        isCenter={isCenter}
      >
        {tableItemHeader.content}
      </Text>
    );
  }
  if (tableItemHeader.type === TABLE_ITEM_HEADER_TYPE.ICON) {
    return (
      <Icon
        name={tableItemHeader.iconName}
        color={tableItemHeader.iconColor}
      />
    );
  }
  return <></>;
};

const splitTableDefinition: <T extends unknown>(a: TableDefinition<T>, b: RESPONSIVE)
  => SplitTableDef<T> = <T extends unknown>(tableDefinition, responsive) => {
    const tableProcessed: SplitTableDef<T> = {
      body: [],
      header: [],
    };
    tableDefinition.forEach((def) => {
      for (let i = 0; i <= sizeName.length - 1; i += 1) {
        const key = sizeName[i];
        if (def.size[key] != null && responsive === RESPONSIVE[key.toUpperCase()]) {
          return tableProcessed.header.push(def);
        }
        if (def.size[key] == null && responsive === RESPONSIVE[key.toUpperCase()]) {
          return tableProcessed.body.push(def);
        }
      }
      return null;
    });
    return tableProcessed;
  };

const getColonSize = (tableHeaders) => {
  const cssColumns = {
    desktop: '',
    tablet: '',
    mobile: '',
  };
  tableHeaders.forEach(header => {
    sizeName.forEach(key => {
      if (header.size[key]) {
        cssColumns[key] = `${cssColumns[key]} ${header.size[key]}`;
      }
    });
  });
  return cssColumns;
};
