/* eslint-disable no-console */
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { GetServerSidePropsContext } from 'next';
import moment from 'moment/moment';
import getRoute from '@services/routeList';
import { destroyAuthCookies } from '@services/cookies';
import { buildRedirectSsrLoginForRequiredAuthPage, SsrRequestError } from '@services/ssrProvider';
import logger, { LOG_TITLE } from '@services/logger';
import apiGenericNotification from './apiGenericNotification';

export enum STATUS_RANGE {
  ALL = 'ALL',
  BACKEND_DOWN = 'BACKEND_DOWN',
  REDIRECTION = 'REDIRECTION',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  SERVER_ERROR = 'SERVER_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
}

// Sadly type as false boolean instead of null  because TS dont git error if you try to access un sub key
interface ErrorResponse { response: null, error: TypeError } // TODO check what's id the parent type of the AbortError (cf: mozilla fetch doc)
export type handlerResponse = { response: Response } | ErrorResponse // TODO 946 this should not be export - need refacto

type HandlerOverwrite = { [key in keyof typeof STATUS_RANGE]?: (response: handlerResponse) => Promise<handlerResponse> }
// TODO use it after change the ts config to strict
// type HandlerOverwrite = {
//   [key in keyof typeof STATUS_RANGE]?: (
//     response: key extends typeof STATUS_RANGE.BACKEND_DOWN ? ErrorResponse : Response
//   ) => Promise<handlerResponse>
// }

interface RequestHandlerParametersClientSide {
  request: Promise<Response>;
  overwrite?: HandlerOverwrite;
}
interface RequestHandlerParametersServerSide extends RequestHandlerParametersClientSide {
  serverSideContext: GetServerSidePropsContext;
}
export type RequestHandlerParametersCommon = RequestHandlerParametersClientSide | RequestHandlerParametersServerSide;

type RequestHandlerTypeGeneric<Params> = (params: Params) => Promise<handlerResponse>;
export type RequestHandlerTypeCommon = RequestHandlerTypeGeneric<RequestHandlerParametersCommon>
export type RequestHandlerTypeServerSide = RequestHandlerTypeGeneric<RequestHandlerParametersServerSide>;
export type RequestHandlerTypeClientSide = RequestHandlerTypeGeneric<RequestHandlerParametersClientSide>;

export const useRequestHandler = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const requestHandler: RequestHandlerTypeClientSide = async ({
    request,
    overwrite,
  }) => {
    try {
      const response = await request;

      if (overwrite?.ALL) {
        return overwrite?.ALL({ response });
      }

      if (response.status >= 300 && response.status < 400) {
        console.info(
          `Request handler error >= 300 && < 400 : status=${response.status}`,
        );
        if (overwrite?.REDIRECTION) {
          return overwrite?.REDIRECTION({ response });
        }
        return { response };
      }

      if (response.status === 401) {
        console.info('Request handler error 401');
        if (overwrite?.UNAUTHORIZED) {
          return overwrite?.UNAUTHORIZED({ response });
        }
        destroyAuthCookies();
        await router.push(
          getRoute.openModal(router).login(), undefined, { scroll: false }
        );
        return { response };
      }

      if (response.status === 403) {
        console.info('Request handler error 403');
        if (overwrite?.FORBIDDEN) {
          return overwrite?.FORBIDDEN({ response });
        }
        destroyAuthCookies();
        await router.push(getRoute.home());
        return { response };
      }

      if (response.status >= 500) {
        console.error(`Request handler error >=500 : status=${response.status}`);
        if (overwrite?.SERVER_ERROR) {
          return overwrite?.SERVER_ERROR({ response });
        }
        apiGenericNotification(t);
        return { response };
      }

      if (response.status >= 400 && response.status < 500) {
        console.warn(`Request handler error >=400 && < 500 : status=${response.status}`);
        if (overwrite?.CLIENT_ERROR) {
          return overwrite?.CLIENT_ERROR({ response });
        }
        apiGenericNotification(t);
        return { response };
      }

      if (!response.ok) {
        console.error(`Request error : ${JSON.stringify(response)}`);
      }
      return { response };
    } catch (error) {
      console.error(`Request error : the backend is down : ${JSON.stringify(error)}`);
      if (overwrite?.BACKEND_DOWN) {
        return overwrite?.BACKEND_DOWN({
          response: null,
          error,
        });
      }
      await router.push(getRoute.error());
      return {
        response: null,
        error,
      };
    }
  };
  return requestHandler;
};
export const ssrRequestHandler: RequestHandlerTypeServerSide = async ({
  request,
  serverSideContext,
  overwrite,
}) => {
  try {
    const t1 = moment();
    const response = await request;
    logger(LOG_TITLE.fetchResponse, {
      message: response.url,
      data: {
        responseTime: moment().diff(t1, 'ms', true),
        response,
      },
    });

    if (overwrite?.ALL) {
      return overwrite?.ALL({ response });
    }

    if (response.status >= 300 && response.status < 400) {
      if (overwrite?.REDIRECTION) {
        return overwrite?.REDIRECTION({ response });
      }
      console.info(`ServerSide call api error : status=${response.status}`);
      return { response };
    }

    if (response.status === 401) {
      if (overwrite?.UNAUTHORIZED) {
        return overwrite?.UNAUTHORIZED({ response });
      }
      destroyAuthCookies(serverSideContext);
      console.log(`ServerSide call api error : status=${response.status}`);
      throw new SsrRequestError({
        redirect: {
          permanent: false,
          destination: buildRedirectSsrLoginForRequiredAuthPage(serverSideContext),
        },
      });
    }

    if (response.status === 403) {
      if (overwrite?.FORBIDDEN) {
        return overwrite?.FORBIDDEN({ response });
      }
      destroyAuthCookies(serverSideContext);
      console.log(`ServerSide call api error : status=${response.status}`);
      throw new SsrRequestError({
        redirect: {
          permanent: false,
          destination: getRoute.home().pathname as string,
        },
      });
    }

    if (response.status >= 500) {
      if (overwrite?.SERVER_ERROR) {
        return overwrite?.SERVER_ERROR({ response });
      }
      console.error(`ServerSide call api error : status=${response.status}`);
      throw new SsrRequestError({ notFound: true });
    }

    if (response.status >= 400 && response.status < 500) {
      if (overwrite?.CLIENT_ERROR) {
        return overwrite?.CLIENT_ERROR({ response });
      }
      console.warn(`ServerSide call api error : status=${response.status}`);
      throw new SsrRequestError({ notFound: true });
    }

    return { response };
  } catch (error) {
    if (error instanceof SsrRequestError) {
      throw error;
    }

    console.error(`ServerSide call api error : backend down : ${JSON.stringify(error)}`);
    if (overwrite?.BACKEND_DOWN) {
      return overwrite?.BACKEND_DOWN({
        response: null,
        error,
      });
    }

    throw new SsrRequestError({ notFound: true });
  }
};
