import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { Event, Pack, PackDetails, REGISTRATION_STATUS, Serie } from '@root/src/types';
import { hydrateStateIfDataNotNull, RootState } from '@services/store';
import { eventCategoryFromApiToReducer, eventFromApiToReducer } from '@features/eventDetail/formatters';
import { packsFromApiToReducer, serieFromApiToReducer } from '../formatters';

export interface SerieDetailServerSliceState {
  serie: Serie;
  events: Array<Event>;
  packs: Array<Pack>;
  packDetails: PackDetails;
}

const serieDetailServerSlice = createSlice({
  name: 'serieDetailServer',
  initialState: {
    serie: null,
    events: [],
    packs: [],
    packDetails: [],
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const { serieDetailServer } = action.payload;
      return hydrateStateIfDataNotNull(state, serieDetailServer);
    },
  },
  reducers: {
    setSerie: (state, action: PayloadAction<{ apiSerie: any }>) => {
      state.serie = serieFromApiToReducer(action.payload.apiSerie);
    },
    setSerieEvents: (state, action: PayloadAction<{ apiEvents: any }>) => {
      state.events = action.payload.apiEvents.map(e => eventFromApiToReducer(e));
    },
    setSeriePacks: (state, action: PayloadAction<{ apiPacks: any }>) => {
      state.packs = action.payload.apiPacks
        .map(p => packsFromApiToReducer(p))
        .sort((packA: Pack, packB: Pack) => {
          if (packA.maxChoice > packB.maxChoice) { return 1; }
          if (packA.maxChoice < packB.maxChoice) { return -1; }
          return 0;
        });
    },
    setSeriePackDetails: (state, action: PayloadAction<{ apiPack: any }>) => {
      state.packDetails = action.payload.apiPack.events.map(e => ({
        event: eventFromApiToReducer(e.event),
        categories: e.categories.map(eventCategoryFromApiToReducer),
      }));
    },
  },
});
export default serieDetailServerSlice.reducer;

export const {
  setSerie,
  setSerieEvents,
  setSeriePacks,
  setSeriePackDetails,
} = serieDetailServerSlice.actions;

// Serie detail
export const selectSerieDetail = (state: RootState) => state.serieDetailServer.serie;
export const selectSerieRegistrationsOpen = createSelector(
  selectSerieDetail,
  serie => serie?.status.registration === REGISTRATION_STATUS.OPEN
);

export const selectSerieSlug = createSelector(
  selectSerieDetail,
  serie => serie?.slug
);

// Serie others
export const selectSerieEvents = (state: RootState) => state.serieDetailServer.events;
export const selectSeriePacks = (state: RootState) => state.serieDetailServer.packs;
export const selectSeriePackDetails = (state: RootState) => state.serieDetailServer.packDetails;
