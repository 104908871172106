import { ParsedUrlQuery } from 'querystring';
import { formatStringToNumberOrNull } from '@utils/gobal';
import { QUERY_ENUM } from '@services/routeList';
import rollbarClientSide from '@services/rollbar';

export const getQuerySerieId = (query: ParsedUrlQuery, url?: string) => {
  const serieSlug = query[QUERY_ENUM.serieSlug] as string;
  if (serieSlug == null) {
    const errorMessage = `getQuerySerieId : serieSlug doesn't exist: serieSlug:${serieSlug} url:${url}`;
    if (!url) rollbarClientSide.error(errorMessage);
    throw Error(errorMessage);
  }
  const serieSplit = serieSlug.split('-');
  if (serieSplit.length >= 1) {
    const numberId = formatStringToNumberOrNull(serieSplit[0] as string);
    if (numberId !== null) {
      return numberId;
    }
    // eslint-disable-next-line max-len
    const errorMessage = `getQuerySerieId : serieId from slug is not a number : serieSlug:${serieSlug} serieIdParse:${numberId} url:${url}`;
    if (!url) rollbarClientSide.error(errorMessage);
    throw Error(errorMessage);
  }
  const errorMessage = 'The slug in URL should be [id]-[slug], if you get this error you probably forget to add the slug part.';
  if (!url) rollbarClientSide.error(errorMessage);
  throw Error(errorMessage);
};
