import React from 'react';
import { InputTextProps } from '@features/ui-input/components/InputText';
import MultiInputLine, { InputProps, MultiInputLineProps } from './MultiInputLine';

export interface InputLineProps extends Omit<MultiInputLineProps, 'inputs'>, InputProps{}

interface InputLinePropsWithInputText extends InputLineProps, InputTextProps{}

/**
 * InputLine simply renders a MultiInputLine with a single input.
 * @constructor
 */
const InputLine = ({
  label,
  labelSpace,
  spaceInterLine,
  spaceInterInput,
  tooltip,
  tooltipSpace,
  labelMinWidth,
  validationChip,
  validationChipSpace,
  textAlignment,
  descriptionOrder,
  descriptionComponent,
  description,
  descriptionLink,
  descriptionLinkText,
  labelWidthAuto,
  withoutOuterInputsBlocksWrapper,
  ...etc
}: InputLineProps | InputLinePropsWithInputText) => (
  <MultiInputLine
    label={label}
    labelSpace={labelSpace}
    spaceInterLine={spaceInterLine}
    spaceInterInput={spaceInterInput}
    tooltip={tooltip}
    tooltipSpace={tooltipSpace}
    labelMinWidth={labelMinWidth}
    validationChip={validationChip}
    validationChipSpace={validationChipSpace}
    textAlignment={textAlignment}
    descriptionOrder={descriptionOrder}
    descriptionComponent={descriptionComponent}
    description={description}
    descriptionLink={descriptionLink}
    descriptionLinkText={descriptionLinkText}
    labelWidthAuto={labelWidthAuto}
    withoutOuterInputsBlocksWrapper={withoutOuterInputsBlocksWrapper}
    inputs={[etc]}
  />
);

export default InputLine;
