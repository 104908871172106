import { round } from 'lodash';
import { EVENT_LIST_ITEM_TYPES, Pack, Serie } from '@root/src/types';
import { removeUndefs } from '@utils/gobal';

export const serieFromApiToReducer = (serieApi: any): Serie => removeUndefs<Serie>({
  _id: serieApi._id,
  _idMso: parseInt(serieApi._idMso, 10),
  _type: EVENT_LIST_ITEM_TYPES.serie,
  slug: serieApi.slug,
  computeMode: serieApi.computeMode,
  serieResultsType: serieApi.serieResultsType,
  name: serieApi.name,
  year: serieApi.year,
  description: serieApi.description,
  isChampionship: !!serieApi.isChampionship,
  logo: serieApi.logo,
  imageCover: serieApi.imagecover,
  urlOrganiser: serieApi.urlOrganiser,
  serieDates: {
    earliestEventDate: serieApi.serieDates?.earliestEventDate,
    latestEventDate: serieApi.serieDates?.latestEventDate,
  },
  status: {
    registration: serieApi.status?.registration,
    registrationFrom: serieApi.status?.registrationFrom,
    registrationTo: serieApi.status?.registrationTo,
  },
  locations: serieApi.locations,
  serieCategory: serieApi.serieCategory,
  serieType: serieApi.serieType,
  previousSerieSlug: serieApi.previousSerieSlug,
  previousSerieLabel: serieApi.previousSerieLabel,
});

export const packsFromApiToReducer = (packsApi: any): Pack => ({
  _id: packsApi._id,
  _idMso: parseInt(packsApi._idMso, 10),
  description: packsApi.description,
  maxChoice: packsApi.maxchoice,
  reduction: round(packsApi.reduction, 2),
});
