import React from 'react';
import styled from 'styled-components';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';

export interface ValidationChipProps {
  validationChip?: boolean,
  validationChipSpace?: boolean,
}

interface OwnProps extends ValidationChipProps {
  areInputsTouched?: boolean,
  isOnError?: boolean
}

const ValidationMultiInputLine = ({
  validationChip,
  validationChipSpace,
  areInputsTouched,
  isOnError,
} : OwnProps) => (
  <div>
    {(validationChip || validationChipSpace) && (
      <ValidationSpace>
        {validationChip && areInputsTouched && (
          <ValidationChip isOnError={isOnError}>
            <Icon
              name={isOnError ? ICON_NAME.clear : ICON_NAME.check}
              color="var(--color-white)"
            />
          </ValidationChip>
        )}
      </ValidationSpace>
    )}
  </div>
);

export default ValidationMultiInputLine;

const ValidationSpace = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ValidationChip = styled.div<{isOnError: boolean}>`
  background-color: ${({ isOnError }) => (isOnError ? 'var(--color-red)' : 'var(--color-blue)')};
  border-radius: 20px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
