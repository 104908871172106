import { FieldType, Service, ServiceGroup } from '@root/src/types';

const servicesDefaultValueFactory = (service: Service): boolean | string | number => {
  switch (service.fieldType) {
    case FieldType.Checkbox: {
      return false;
    }
    case FieldType.Int:
    case FieldType.Select:
    case FieldType.Input:
    default:
      return '';
  }
};

export default servicesDefaultValueFactory;

// Create the default values for all the field from the service fieldType
export const generateDefaultValues = (servicesGroups: ServiceGroup[]) => servicesGroups.map(group => {
  const services = group
    .services
    .map(service => ({ [`service_${service._idMso}`]: servicesDefaultValueFactory(service) }))
    .reduce((a, b) => ({
      ...a,
      ...b,
    }), {});
  return ({ [`group_${group._idMso}`]: services });
}).reduce((a, b) => ({
  ...a,
  ...b,
}), {});
