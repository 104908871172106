import { UrlObject } from 'url';
import { NextRouter, useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { i18n } from 'next-i18next';
import { RANKINGS_LIST_TYPE, SPORT_CATEGORIES } from '@root/src/types';
import { formatStringToNumberOrNull, testValidString } from '@utils/gobal';
import { MODAL_NAME } from '@features/ui-layout/templates/ModalContainer';

const clearUrlFromExtraQueryParams = (queryAndPath, toDelete: Array<string>) => {
  toDelete.forEach(key => {
    // eslint-disable-next-line no-param-reassign
    delete queryAndPath.query[key];
  });

  return queryAndPath;
};

export const routeRegex = {
  serie: /^\/series\//,
  serieDescription: /^\/series\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/description/,
  serieEvents: /^\/series\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/events/,
  serieSubscriptions: /^\/series\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/subscriptions/,
  serieResults: /^\/series\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/results/,
  event: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+/,
  eventDescription: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/description/,
  eventSubscription: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/subscriptions/,
  eventEngaged: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/engaged/,
  eventResults: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/results/,
  eventLiveResults: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/liveresults/,
  eventSeries: /^\/events\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/series/,
  subscriptions: /^\/(events|series)\/[0-9]+-([a-z0-9A-Z0-9-.'])+\/subscriptions/,
};

export const useTestRoute = () => {
  const router = useRouter();
  const fullRoute = router.asPath;
  return (regex) => regex.test(fullRoute);
};

// This function don't work if you use it with a route which contains
// dynamic routing like /event/[eventSlug]/...
// Important: this function is also used when we display links within the <Trans> comp,
// as Next/Link is not supported in these cases.
export const buildAsUrl = (urlObject: UrlObject) => {
  let url = urlObject.pathname;
  if (urlObject.query) {
    url += '?';
    const queryList = Object.keys(urlObject.query);
    queryList.forEach((key, index) => {
      url += `${key}=${encodeURIComponent(urlObject.query[key])}`;
      if (index !== (queryList.length - 1)) {
        url += '&';
      }
    });
  }
  return url;
};

export const getQueryAsValidNumber: (query: ParsedUrlQuery, name: QUERY_ENUM) => number | null = (query, name) => {
  const queryValue = query[name];
  if (queryValue != null) {
    return formatStringToNumberOrNull(queryValue as string);
  }
  return null;
};

export enum QUERY_ENUM {
  modal = 'modal',
  modalId = 'modalId',
  modalParamOne = 'modalParamOne',
  eventSlug = 'eventSlug',
  serieSlug = 'serieSlug',
  subscriptionId = 'subscriptionId',
  filterSearch = 'filterSearch',
  filterStartDate = 'filterStartDate',
  filterEndDate = 'filterEndDate',
  filterSportCategory = 'filterSportCategory',
  filterSite = 'filterSite',
  filterEventType = 'filterEventType',
  filterTag='filterTag',
  page = 'page',
  serieId = 'serieId',
  type = 'type',
  typeId = 'typeId',
}

const getRoute = {
  error: (): UrlObject => ({ pathname: '/500' }),
  faq: (): UrlObject => ({ pathname: '/faq' }),
  home: (): UrlObject => ({ pathname: '/' }),
  contact: (): UrlObject => {
    let codeLanguage = i18n.language;
    if (codeLanguage === 'en') codeLanguage = 'en-us';

    return { href: `https://help.mso.swiss/hc/${codeLanguage}/requests/new` };
  },
  account: {
    receipts: (): UrlObject => ({ pathname: '/account/receipts' }),
    profile: (): UrlObject => ({ pathname: '/account/profile' }),
    mobiles: (): UrlObject => ({ pathname: '/account/mobiles' }),
    friends: (): UrlObject => ({ pathname: '/account/friends' }),
    password: (): UrlObject => ({ pathname: '/account/password' }),
    subscriptions: (): UrlObject => ({ pathname: '/account/subscriptions' }),
    subscription: (subscriptionId): UrlObject => ({
      pathname: '/account/subscriptions/[subscriptionId]',
      query: { subscriptionId },
    }),
    preferences: (): UrlObject => ({ pathname: '/account/preferences' }),
  },
  cart: {
    paymentSuccess: (): UrlObject => ({ pathname: '/cart/success' }),
    index: (): UrlObject => ({ pathname: '/cart' }),
    payment: (): UrlObject => ({ pathname: '/cart/payment' }),
    athletes: (): UrlObject => ({ pathname: '/cart/athletes' }),
  },
  loginForRequiredAuthPage: (redirectUrl): UrlObject => clearUrlFromExtraQueryParams({
    pathname: '/',
    query: {
      [QUERY_ENUM.modal]: MODAL_NAME.login,
      [QUERY_ENUM.modalParamOne]: redirectUrl,
    },
  }, [QUERY_ENUM.modalId]),

  // When you use the closeModal/openModal you should set the scroll option to false
  closeModal: (router: NextRouter) => clearUrlFromExtraQueryParams({
    pathname: router.pathname,
    query: router.query,
  }, [QUERY_ENUM.modal, QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
  openModal: (router: NextRouter) => ({
    eventFilterMobile: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.eventFilterMobile,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    menu: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.menu,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    categoryServices: (categoryId: number, eventId: number): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.subscriptionCartCreate,
        [QUERY_ENUM.modalId]: categoryId,
        [QUERY_ENUM.modalParamOne]: eventId,
      },
    }, []),
    editSubscriptionCart: (orderItemId: string, userId: number): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.subscriptionCartUpdate,
        [QUERY_ENUM.modalId]: orderItemId,
        [QUERY_ENUM.modalParamOne]: userId,
      },
    }, []),
    editSubscriptionAccount: (userId: number): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.subscriptionAccountUpdate,
        [QUERY_ENUM.modalId]: userId,
      },
    }, []),
    login: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.login,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    register: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.register,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    resetPassword: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.resetPassword,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    deleteCartItem: (itemId, categoryId): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.deleteCartItem,
        [QUERY_ENUM.modalId]: itemId,
        [QUERY_ENUM.modalParamOne]: categoryId,
      },
    }, []),
    editAccessOnMyProfile: (accessId: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.editAccessOnMyProfile,
        [QUERY_ENUM.modalId]: accessId,
      },
    }, [QUERY_ENUM.modalParamOne]),
    removeAccessOnMyProfile: (accessId: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.removeAccessOnMyProfile,
        [QUERY_ENUM.modalId]: accessId,
      },
    }, [QUERY_ENUM.modalParamOne]),
    removeAccessOnMyFriends: (athleteId: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.removeAccessOnMyFriends,
        [QUERY_ENUM.modalId]: athleteId,
      },
    }, [QUERY_ENUM.modalParamOne]),
    editFriend: (athleteId: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.accountEditFriend,
        [QUERY_ENUM.modalId]: athleteId,
      },
    }, [QUERY_ENUM.modalParamOne]),
    addFriend: (): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.addFriend,
      },
    }, [QUERY_ENUM.modalId, QUERY_ENUM.modalParamOne]),
    createUpdateMobileNumber: (mobileNumberID?: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.createUpdateMobileNumber,
        ...mobileNumberID && { [QUERY_ENUM.modalId]: mobileNumberID },
      },
    }, [QUERY_ENUM.modalParamOne]),
    deleteMobileNumber: (mobileNumberID: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.deleteMobileNumber,
        [QUERY_ENUM.modalId]: mobileNumberID,
      },
    }, [QUERY_ENUM.modalParamOne]),
    validateMobileNumber: (mobileNumberID: string): UrlObject => clearUrlFromExtraQueryParams({
      pathname: router.pathname,
      query: {
        ...router.query,
        [QUERY_ENUM.modal]: MODAL_NAME.accountValidateNumber,
        [QUERY_ENUM.modalId]: mobileNumberID,
      },
    }, [QUERY_ENUM.modalParamOne]),
  }),

  series: (serieSlug?: string) => ({
    description: (): UrlObject => ({
      pathname: '/series/[serieSlug]',
      query: { [QUERY_ENUM.serieSlug]: serieSlug },
    }),
    events: (): UrlObject => ({
      pathname: '/series/[serieSlug]/events',
      query: { [QUERY_ENUM.serieSlug]: serieSlug },
    }),
    subscriptions: (): UrlObject => ({
      pathname: '/series/[serieSlug]/subscriptions',
      query: { [QUERY_ENUM.serieSlug]: serieSlug },
    }),
    results: (query?: {
      type: RANKINGS_LIST_TYPE,
      serieId: number,
      typeId?: number | string,
    }): UrlObject => {
      const queryProcessed = {};
      queryProcessed[QUERY_ENUM.serieSlug] = serieSlug;
      if (query) {
        if (query.type != null) {
          queryProcessed[QUERY_ENUM.type] = query.type;
        }
        if (query.typeId != null) {
          queryProcessed[QUERY_ENUM.typeId] = query.typeId;
        }
        if (query.serieId != null) {
          queryProcessed[QUERY_ENUM.serieId] = query.serieId;
        }
      }
      return {
        pathname: '/series/[serieSlug]/results',
        query: queryProcessed,
      };
    },
  }),

  events: (eventSlug?: string) => ({
    list: (query: {
      search?: string,
      startDate?: string,
      endDate?: string,
      sportCategory?: SPORT_CATEGORIES,
      site?: string,
      eventType?: string,
      page?: number,
      filterTag?: string,
    }): UrlObject => {
      const queryProcessed = {};
      if (query.page != null) {
        queryProcessed[QUERY_ENUM.page] = query.page;
      }
      if (testValidString(query.search?.trim())) {
        queryProcessed[QUERY_ENUM.filterSearch] = query.search?.trim();
      }
      if (testValidString(query.startDate?.trim())) {
        queryProcessed[QUERY_ENUM.filterStartDate] = query.startDate?.trim();
      }
      if (testValidString(query.endDate?.trim())) {
        queryProcessed[QUERY_ENUM.filterEndDate] = query.endDate?.trim();
      }
      if (testValidString(query.sportCategory?.trim())) {
        queryProcessed[QUERY_ENUM.filterSportCategory] = query.sportCategory?.trim();
      }
      if (testValidString(query.site?.trim())) {
        queryProcessed[QUERY_ENUM.filterSite] = query.site?.trim();
      }
      if (testValidString(query.eventType)) {
        queryProcessed[QUERY_ENUM.filterEventType] = query.eventType;
      }
      if (testValidString(query.filterTag?.trim())) {
        queryProcessed[QUERY_ENUM.filterTag] = query.filterTag.trim();
      }
      return {
        pathname: '/events',
        query: queryProcessed,
      };
    },
    description: (): UrlObject => ({
      pathname: '/events/[eventSlug]',
      query: { [QUERY_ENUM.eventSlug]: eventSlug },
    }),
    subscriptions: (): UrlObject => ({
      pathname: '/events/[eventSlug]/subscriptions',
      query: { [QUERY_ENUM.eventSlug]: eventSlug },
    }),
    engaged: (): UrlObject => ({
      pathname: '/events/[eventSlug]/engaged',
      query: { [QUERY_ENUM.eventSlug]: eventSlug },
    }),
    results: (query?: {
      type: RANKINGS_LIST_TYPE,
      typeId?: number
    }): UrlObject => {
      const queryProcessed = {};
      queryProcessed[QUERY_ENUM.eventSlug] = eventSlug;
      if (query && query.type != null) {
        queryProcessed[QUERY_ENUM.type] = query.type;
      }
      if (query && query.typeId != null) {
        queryProcessed[QUERY_ENUM.typeId] = query.typeId;
      }
      return {
        pathname: '/events/[eventSlug]/results',
        query: queryProcessed,
      };
    },
    liveResults: (query?: {
      type: RANKINGS_LIST_TYPE,
      typeId?: number
    }): UrlObject => {
      const queryProcessed = {};
      queryProcessed[QUERY_ENUM.eventSlug] = eventSlug;
      if (query && query.type != null) {
        queryProcessed[QUERY_ENUM.type] = query.type;
      }
      if (query && query.typeId != null) {
        queryProcessed[QUERY_ENUM.typeId] = query.typeId;
      }
      return {
        pathname: '/events/[eventSlug]/liveresults',
        query: queryProcessed,
      };
    },
    series: (query?: {
      type: RANKINGS_LIST_TYPE,
      serieId: number,
      typeId?: number | string,
    }): UrlObject => {
      const queryProcessed = {};
      queryProcessed[QUERY_ENUM.eventSlug] = eventSlug;
      if (query) {
        if (query.type != null) {
          queryProcessed[QUERY_ENUM.type] = query.type;
        }
        if (query.typeId != null) {
          queryProcessed[QUERY_ENUM.typeId] = query.typeId;
        }
        if (query.serieId != null) {
          queryProcessed[QUERY_ENUM.serieId] = query.serieId;
        }
      }
      return {
        pathname: '/events/[eventSlug]/series',
        query: queryProcessed,
      };
    },
  }),

  infos: { contact: (): UrlObject => ({ pathname: '/contact' }) },
};

export default getRoute;
