import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik, FormikContextType } from 'formik';
import api from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import { mobileNumberFromMobileNumberFormToApi } from '@features/users/formatters';
import InputText from '@features/ui-input/components/InputText';
import InputLine from '@features/ui-input/formik/InputLine';
import InputCheckbox from '@features/ui-input/components/InputCheckbox';
import GetFormik from '@features/ui-input/formik/GetFormik';
import CreateUpdateMobileNumberSchema from '@features/account/components/CreateUpdateMobileNumberSchema';
import InputPhone from '@features/ui-input/components/InputPhone';

export interface CreateUpdateMobileNumberFormInputs {
  id?: string,
  phoneNumber: string,
  description: string,
  def: boolean,
}

interface CreateUpdateMobileNumberFormProps {
  initialValues: CreateUpdateMobileNumberFormInputs
  getFormikContext?: (formik: FormikContextType<CreateUpdateMobileNumberFormInputs>) => void,
  submitCallback: (ok: boolean, errorCode?: string) => void;
}

const CreateUpdateMobileNumberForm = ({
  getFormikContext,
  initialValues,
  submitCallback,
}: CreateUpdateMobileNumberFormProps) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();

  const createMobileNumber = async (values: CreateUpdateMobileNumberFormInputs) => {
    const { response } = await requestHandler({
      request: api.profile.mobileNumbers.create(
        { body: mobileNumberFromMobileNumberFormToApi(values) },
      ),
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (response) => response },
    });

    if (response) {
      const result = await response.json();
      const errorCode = result.code;

      await submitCallback(response.ok, errorCode);
    }
  };

  const updateMobileNumber = async (values: CreateUpdateMobileNumberFormInputs) => {
    const { response } = await requestHandler({
      request: api.profile.mobileNumbers.update(
        {
          mobileNumberID: values.id as string,
          body: mobileNumberFromMobileNumberFormToApi(values),
        },
      ),
    });

    if (response) {
      const result = await response.json();
      const errorCode = result.code;
      await submitCallback(response.ok, errorCode);
    }
  };

  const onSubmitForm = async (values: CreateUpdateMobileNumberFormInputs) => {
    if (values.id) {
      await updateMobileNumber(values);
    } else {
      await createMobileNumber(values);
    }
  };

  const validationSchema = CreateUpdateMobileNumberSchema(t);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={validationSchema}
    >
      <Form>
        <GetFormik getFormikContext={getFormikContext} />
        <InputLine
          name="phoneNumber"
          showRequiredChip
          label={t('createUpdateMobileNumberForm_numberLabel.message')}
          as={InputPhone}
          labelMinWidth="202px"
        />
        <InputLine
          name="description"
          label={t('createUpdateMobileNumberForm_descriptionLabel.message')}
          as={InputText}
          labelMinWidth="202px"
        />
        <InputLine
          name="def"
          label={t('createUpdateMobileNumberForm_defaultNumberLabel.message')}
          as={InputCheckbox}
          checkboxLabel={t('createUpdateMobileNumberForm_defaultNumberText.message')}
          labelMinWidth="202px"
        />
      </Form>
    </Formik>
  );
};

export default CreateUpdateMobileNumberForm;
