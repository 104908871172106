import { configureStore, Store } from '@reduxjs/toolkit';
import { Context, createWrapper, MakeStore } from 'next-redux-wrapper';
import config from '@root/src/config';
import userReducer, { UserSliceState } from '@features/users/slices/userSlice';
import uiLayoutReducer, { UiLayoutSliceState } from '@features/ui-layout/slices/uiLayoutSlice';
import serieDetailServerReducer, { SerieDetailServerSliceState } from '@features/serieDetail/slices/serieDetailServerSlice';
import eventDetailServerReducer, { EventDetailServerSliceState } from '@features/eventDetail/slices/eventDetailServerSlice';
import formSubscriptionSlice, { FormSubscriptionSliceState } from '@features/subscriptions/slices/formSubscriptionSlice';
import cartsReducer, { CartsSliceState } from '@features/carts/slices/cartsSlice';
import eventDetailClientReducer, { EventDetailClientSliceState } from '@features/eventDetail/slices/eventDetailClientSlice';
import eventsListServerReducer, { EventListServerSliceState } from '@features/eventList/slices/eventListServerSliceState';
import subscriptionDetailServerSlice, { SubscriptionDetailServerSliceState } from '@features/subscriptions/slices/subscriptionDetailServerSlice';
import ordersReducer, { OrdersSliceState } from '@features/orders/slices/ordersSlice';
import resultsReducer, { ResultsSliceState } from '@features/results/slices/resultsSlice';

export interface RootState {
  uiLayout: UiLayoutSliceState;
  serieDetailServer: SerieDetailServerSliceState;
  eventDetailServer: EventDetailServerSliceState;
  eventDetailClient: EventDetailClientSliceState;
  eventListServer: EventListServerSliceState;
  user: UserSliceState;
  carts: CartsSliceState;
  orders: OrdersSliceState
  formSubscription: FormSubscriptionSliceState;
  subscriptionDetailServer: SubscriptionDetailServerSliceState;
  results: ResultsSliceState
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const makeStore: MakeStore<Store> = (context: Context) => configureStore({
  reducer: {
    uiLayout: uiLayoutReducer,
    serieDetailServer: serieDetailServerReducer,
    eventDetailServer: eventDetailServerReducer,
    eventDetailClient: eventDetailClientReducer,
    eventListServer: eventsListServerReducer,
    user: userReducer,
    carts: cartsReducer,
    orders: ordersReducer,
    formSubscription: formSubscriptionSlice,
    subscriptionDetailServer: subscriptionDetailServerSlice,
    results: resultsReducer,
  },
  devTools: config.devTools,
});

const ReduxWrapper = createWrapper(makeStore);
export default ReduxWrapper;

// This function should be use use if the slice is set in server
// and client side, event if it's not at the same time. To used it,
// the initial value of the slice should always be set to null !
export const hydrateStateIfDataNotNull = (state, data) => {
  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (value != null) {
      state[key] = value;
    }
  });
  return state;
};
