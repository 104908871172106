import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { textP } from '@features/ui/styles/textStyles';

interface Step {
  title: string,
  component: (stepProps: StepProps) => React.ReactElement
}

export interface StepProps {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: (number) => void;
  currentStep: number;
  previousStep: number | undefined;
  isLast: boolean;
  isFirst: boolean;
  stepperView?: React.ReactElement;
}

enum STEP_CHIP_VARIANT {
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE'
}

interface StepChipVariant {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
}

const STEP_CHIP_COLOR: {
  [key in keyof typeof STEP_CHIP_VARIANT]: StepChipVariant
} = {
  [STEP_CHIP_VARIANT.PAST]: {
    textColor: 'var(--color-blue)',
    backgroundColor: 'var(--color-blue-light)',
    borderColor: 'var(--color-blue-light)',
  },
  [STEP_CHIP_VARIANT.CURRENT]: {
    textColor: 'var(--color-white)',
    backgroundColor: 'var(--color-blue)',
    borderColor: 'var(--color-blue)',
  },
  [STEP_CHIP_VARIANT.FUTURE]: {
    textColor: 'var(--color-grey4)',
    backgroundColor: 'var(--color-white)',
    borderColor: 'var(--color-grey2)',
  },
};

const getStepperComponent = (steps: Step[], step: number, props: StepProps): React.ReactElement => {
  const ComponentToRender = steps[step - 1].component;
  return <ComponentToRender {...props} />;
};

const getStepVariant = (currentStep: number, step: number): STEP_CHIP_VARIANT => {
  if (step < currentStep) {
    return STEP_CHIP_VARIANT.PAST;
  }
  if (step === currentStep) {
    return STEP_CHIP_VARIANT.CURRENT;
  }
  return STEP_CHIP_VARIANT.FUTURE;
};

const useStepper = (steps : Step[]) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === steps.length;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const previousStep = usePrevious(currentStep);

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const goToPreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep > 0) {
      setCurrentStep(previousStep);
    }
  };
  const goToStep = (step: number) => {
    if (step > steps.length) {
      setCurrentStep(steps.length);
    } else if (step < 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(step);
    }
  };

  const stepperView = (
    <StepperContainer>
      <StepperWrapper>
        {steps.map((step, i) => {
          const variant = getStepVariant(currentStep, i + 1);

          return (
            <React.Fragment key={step.title}>
              <StepChip {...STEP_CHIP_COLOR[variant]}>
                {i + 1}
                {variant === STEP_CHIP_VARIANT.CURRENT && (
                  <StepChipText>
                    {`. ${step.title}`}
                  </StepChipText>
                )}
              </StepChip>
              {(i < steps.length - 1) && (<StepSeparator />)}
            </React.Fragment>
          );
        })}
      </StepperWrapper>
      {getStepperComponent(steps, currentStep, {
        goToStep,
        goToNextStep,
        goToPreviousStep,
        currentStep,
        isFirst: isFirstStep,
        isLast: isLastStep,
        previousStep,
      })}
    </StepperContainer>
  );

  return ({
    goToStep,
    goToNextStep,
    goToPreviousStep,
    currentStep,
    isFirst: isFirstStep,
    isLast: isLastStep,
    stepperView,
    previousStep,
  });
};

export default useStepper;

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  align-items: center;
`;

const StepChip = styled.div<Partial<StepChipVariant>>`
  ${textP}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  min-width: 48px;
  padding: 0 20px;
  border-radius: 22px;
  color: ${({ textColor }) => textColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const StepChipText = styled.div`
  ${mediaBreakpoint.tablet} {
    display: none;
  }
`;

const StepSeparator = styled.div`
  height: 1px;
  width: 20px;
  background-color: var(--color-grey2);
  margin: 0 8px;
`;
