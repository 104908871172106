import { Form, Formik, FormikContextType } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { DateTime } from 'luxon';
import CANTONS from '@root/src/services/enum/cantons';
import { SPORT_CATEGORIES } from '@root/src/types';
import { QUERY_API_DATE_FORMAT_MOMENT } from '@utils/dates';
import { QUERY_ENUM } from '@services/routeList';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textH2, textH4 } from '@features/ui/styles/textStyles';
import InputLine from '@features/ui-input/formik/InputLine';
import GetFormik from '@features/ui-input/formik/GetFormik';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DateRange from '@features/ui/components/DateRange';
import InputSelect from '@features/ui-input/components/InputSelect';
import { sportDescriptionMapping } from '@features/eventList/components/EventCard';
import EventsSuggestions from '@features/home/components/EventsSuggestions';
import TagFilters from '@features/eventList/components/TagFilters';
import { pushNewEventList } from '@features/eventList/utils';
import { selectSitesForSearch, selectSportsForSearch } from '@features/eventList/slices/eventListServerSliceState';

interface EventSearchFormProps {
  className?: string;
  showReset?: boolean;
  showSubmitButton?: boolean;
  getFormik?: (context: FormikContextType<any>) => void;
  isMobile?: boolean;
}

export const getInitialValues = (query) => ({
  search: query[QUERY_ENUM.filterSearch] || '',
  startDate: query[QUERY_ENUM.filterStartDate] || '',
  endDate: query[QUERY_ENUM.filterEndDate] || '',
  sportCategory: query[QUERY_ENUM.filterSportCategory] || '',
  site: query[QUERY_ENUM.filterSite] || '',
  eventType: query[QUERY_ENUM.filterEventType] || '',
});

export const resetValues = (router) => {
  pushNewEventList(router, {
    search: '',
    startDate: '',
    endDate: '',
    sportCategory: '',
    site: '',
    eventType: '',
    filterTag: '',
  });
};

const EventSearchForm = ({
  className,
  showReset = false,
  showSubmitButton = false,
  getFormik,
  isMobile = false,
}: EventSearchFormProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [formikContext, setFormikContext] = useState<FormikContextType<any>>(null);

  useEffect(() => {
    if (formikContext) {
      const updateValues = getInitialValues(router.query);
      Object.keys(updateValues).forEach(key => {
        formikContext.setFieldValue(key, updateValues[key]);
      });
    }
  }, [router.query]);

  const onSubmit = (values) => pushNewEventList(router, values);

  const onInputChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e != null) {
      formikContext.setFieldValue(e.target.name, e.target.value);

      if (e.target.name === 'sportCategory') {
        formikContext.setFieldValue('eventType', '');
      }
    }
    if (!isMobile) {
      formikContext.submitForm();
    }
  };

  const sites = useSelector(selectSitesForSearch);
  const selectedSportCategory = formikContext?.getFieldProps('sportCategory').value;
  const eventTypes = useSelector(selectSportsForSearch(selectedSportCategory));
  const disableEventTypesSelect = eventTypes.length === 0;

  const sitesSorted = useMemo(() => {
    const sitesCanton = [];
    const sitesCountries = [];
    const CANTONS_NAMES = CANTONS.map(({ siteName }) => siteName);
    sites.forEach(site => {
      if (CANTONS_NAMES.includes(site.value)) {
        sitesCanton.push(site);
      } else {
        sitesCountries.push(site);
      }
    });
    return [...sitesCanton, ...sitesCountries];
  }, [sites, CANTONS]);

  return (
    <div className={className}>
      <Formik
        initialValues={getInitialValues(router.query)}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <GetFormik getFormikContext={context => {
              setFormikContext(context);
              getFormik?.(context);
            }}
            />
            <TitleLine>
              <Title>{t('eventSearchForm_title.message')}</Title>
              {showReset && (
                <Button
                  type="button"
                  variant={BUTTON_VARIANT.link}
                  leftIcon={ICON_NAME.refresh}
                  text={t('eventSearchForm_refresh.message')}
                  onClick={() => resetValues(router)}
                />
              )}
            </TitleLine>
            {isMobile && (<StyledTagFilters />)}
            <SearchInputWrapper>
              <EventsSuggestions
                apiParams={
                  {
                    filteredRange: [
                      {
                        id: 'date',
                        type: 'date',
                        valueGte: values.startDate,
                        valueLte: values.endDate,
                      },
                    ],
                    ...(values.sportCategory && {
                      filtered: [
                        {
                          id: 'sportCategory',
                          value: values.sportCategory,
                        },
                      ],
                    }),
                  }
                }
              />
              {showSubmitButton && <Button type="submit" leftIcon={ICON_NAME.search} />}
            </SearchInputWrapper>

            <SearchSection>
              <h4>{t('eventSearchForm_searchSectionDate.message')}</h4>
              <DateRange
                isFullScreen={isMobile}
                isVertical={isMobile}
                initialStartDate={values.startDate}
                initialEndDate={values.endDate}
                onDateChanged={({
                  startDate,
                  endDate,
                }) => {
                  setFieldValue('startDate', startDate?.format?.(QUERY_API_DATE_FORMAT_MOMENT) ?? '');
                  setFieldValue('endDate', endDate?.format?.(QUERY_API_DATE_FORMAT_MOMENT) ?? '');
                  onInputChange();
                }}
              />
              <StyledButton
                variant={BUTTON_VARIANT.link}
                leftIcon={ICON_NAME.eye}
                text={t('eventSearchForm_searchSectionDate30DaysInFuture.message')}
                type="button"
                onClick={() => {
                  const now = DateTime.now();
                  const inOneMonth = now.plus({ days: 30 });
                  setFieldValue('startDate', now.toFormat('y-LL-dd'));
                  setFieldValue('endDate', inOneMonth.toFormat('y-LL-dd'));
                  onInputChange();
                }}
              />
            </SearchSection>

            <SearchSection>
              <h4>{t('eventSearchForm_searchSectionSportType.message')}</h4>
              <InputLine
                options={Object.values(SPORT_CATEGORIES).map(value => ({
                  label: t(sportDescriptionMapping[value]),
                  value,
                }))}
                name="sportCategory"
                placeholder
                customPlaceholderText={t('eventSearchForm_searchSectionSportTypePlaceholder.message')}
                as={InputSelect}
                onChange={onInputChange}
              />

              <InputLine
                options={eventTypes}
                name="eventType"
                placeholder
                customPlaceholderText={t('eventSearchForm_searchSectionSportDisciplinePlaceholder.message')}
                as={InputSelect}
                onChange={onInputChange}
                disabled={disableEventTypesSelect}
              />
            </SearchSection>

            <SearchSection>
              <h4>{t('eventSearchForm_searchSectionLocalisation.message')}</h4>
              <InputLine
                options={sitesSorted}
                name="site"
                placeholder
                customPlaceholderText={t('eventSearchForm_searchSectionSitePlaceholder.message')}
                as={InputSelect}
                onChange={onInputChange}
              />
            </SearchSection>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EventSearchForm;

const SearchInputWrapper = styled.div`
  display: flex;
  width: 100%;

  > *:first-child {
    margin-right: 4px;
  }
`;

const Title = styled.h2`
  ${textH2};
`;

const TitleLine = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const SearchSection = styled.div`
  margin-top: 30px;

  h4 {
    ${textH4};
    margin: 20px 0;
  }
`;

const StyledButton = styled(Button)<OwnProps>`
  margin-top: 12px;
`;

const StyledTagFilters = styled(TagFilters)`
    margin-bottom: 28px;
`;
