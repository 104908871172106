import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Orders } from '@root/src/types';
import { RootState } from '@services/store';
import { ordersFromApiToReducer } from '@features/orders/formatters';

export interface OrdersSliceState {
  list: Orders,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState: { list: null },
  reducers: {
    setOrdersList: (state, action) => {
      state.list = ordersFromApiToReducer(action.payload);
    },
  },
});

export const { setOrdersList } = ordersSlice.actions;

export default ordersSlice.reducer;

export const selectOrders = (state: RootState) => state.orders.list;
export const selectOrdersItems = createSelector(
  selectOrders,
  orders => orders?.items || []
);
export const selectOrdersItemsId = createSelector(
  selectOrdersItems,
  items => items.map(order => order._idMso)
);
export const selectOrderById = (id:number) => createSelector(
  selectOrdersItems,
  items => items.find(order => order._idMso === id)
);
