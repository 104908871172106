import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectEventCategoryServicesGroupsIds } from '@features/eventDetail/slices/eventDetailServerSlice';
import ServicesListTypeServiceOrder from '@features/subscriptions/components/ServicesList/ServicesListTypeServiceOrder';
import GetFormik from '@features/ui-input/formik/GetFormik';

const ServicesGroupFormCreateAndUpdateCart = ({
  eventId,
  initialValues,
  schemaValidator,
  onSubmit,
  getFormikContext,
}) => {
  const servicesGroupsIds = useSelector(selectEventCategoryServicesGroupsIds(eventId));
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaValidator}
      onSubmit={onSubmit}
      enableReinitialize
    >

      <StyledForm>
        <GetFormik getFormikContext={getFormikContext} />
        {servicesGroupsIds.map(serviceGroupId => (
          <ServicesListTypeServiceOrder
            key={serviceGroupId}
            serviceGroupId={serviceGroupId}
            eventId={eventId}
          />
        ))}
      </StyledForm>
    </Formik>
  );
};

export default ServicesGroupFormCreateAndUpdateCart;

const StyledForm = styled(Form)`
  > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;
