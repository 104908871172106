import Icon, { ICON_NAME } from '@features/ui/components/Icon';

interface RankProps {
  position: number | string;
}

const Rank = ({ position }: RankProps) => {
  if (!position) {
    return null;
  }

  const positionAsNumber = typeof position === 'string'
    ? parseInt(position, 10)
    : position;

  if (!Number.isNaN(positionAsNumber) && positionAsNumber > 0 && positionAsNumber < 4) {
    return <Icon name={medalIcon[position]} />;
  }

  return <>{position}</>;
};

export default Rank;

const medalIcon = {
  1: ICON_NAME.medalGold,
  2: ICON_NAME.medalSilver,
  3: ICON_NAME.medalBronze,
};
