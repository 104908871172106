import React from 'react';
import styled from 'styled-components';
import { textH4, textP } from '../styles/textStyles';

interface TitleWithDescriptionProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
}

const TitleWithDescription = ({ title, description, children, className }: TitleWithDescriptionProps) => (
  <Container className={className}>
    <h4>{title}</h4>
    {description && <p>{description}</p>}
    {children}
  </Container>
);

export default TitleWithDescription;

const Container = styled.div`
  & h4 {
    ${textH4};
  }
  & p {
    ${textP};
  }
`;
