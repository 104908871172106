import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import packageJson from '@root/package.json';
import { handlerResponse, STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import { mediaBreakpoint } from '@services/responsiveProvider';

const Versioning = () => {
  const { version } = packageJson;
  const requestHandler = useRequestHandler();
  const [userApiVersion, setUserApiVersion] = useState('-');
  const [raceApiVersion, setRaceApiVersion] = useState('-');
  const [resultApiVersion, setResultApiVersion] = useState('-');
  const [subscriptionApiVersion, setSubscriptionApiVersion] = useState('-');

  const getUserApiVersion = () => {
    requestHandler({
      request: api.auth.version({}),
      overwrite: {
        [STATUS_RANGE.ALL]: async (response) => response,
        [STATUS_RANGE.BACKEND_DOWN]: async (response) => response,
      },
    }).then((result: handlerResponse) => {
      const { response } = result;
      if (response) {
        response.json().then(res => setUserApiVersion(res.version));
      }
    });
  };

  const getRaceApiVersion = () => {
    requestHandler({
      request: api.events.version({}),
      overwrite: {
        [STATUS_RANGE.ALL]: async (response) => response,
        [STATUS_RANGE.BACKEND_DOWN]: async (response) => response,
      },
    }).then((result: handlerResponse) => {
      const { response } = result;
      if (response) {
        response.json().then(res => setRaceApiVersion(res.version));
      }
    });
  };

  const getResultApiVersion = () => {
    requestHandler({
      request: api.results.version({}),
      overwrite: {
        [STATUS_RANGE.ALL]: async (response) => response,
        [STATUS_RANGE.BACKEND_DOWN]: async (response) => response,
      },
    }).then((result: handlerResponse) => {
      const { response } = result;
      if (response) {
        response.json().then(res => setResultApiVersion(res.version));
      }
    });
  };

  const getSubscriptionApiVersion = () => {
    requestHandler({
      request: api.subscription.version({}),
      overwrite: {
        [STATUS_RANGE.ALL]: async (response) => response,
        [STATUS_RANGE.BACKEND_DOWN]: async (response) => response,
      },
    }).then((result: handlerResponse) => {
      const { response } = result;
      if (response) {
        response.json().then(res => setSubscriptionApiVersion(res.version));
      }
    });
  };

  useEffect(() => {
    getUserApiVersion();
    getRaceApiVersion();
    getResultApiVersion();
    getSubscriptionApiVersion();
  }, []);

  return (
    <VersioningContainer>
      <VersioningItem>{`users-service: ${userApiVersion}`}</VersioningItem>
      <VersioningItem>{`races-service: ${raceApiVersion}`}</VersioningItem>
      <VersioningItem>{`results-service: ${resultApiVersion}`}</VersioningItem>
      <VersioningItem>{`subscriptions-service: ${subscriptionApiVersion}`}</VersioningItem>
      <VersioningItem>{`frontend-service: ${version}`}</VersioningItem>
    </VersioningContainer>
  );
};

const VersioningContainer = styled.div`
  display: flex;
  justify-content: center;
  ${mediaBreakpoint.mobile} {
    flex-direction: column;
  }
`;
const VersioningItem = styled.div`
  display: flex;
  &+&{
    margin-left: 20px;
  }
  ${mediaBreakpoint.mobile} {
    &+&{
      margin-left: unset;
    }
    justify-content: center;
  }
`;

export default Versioning;
