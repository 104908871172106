import React, { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import config from '@root/src/config';
import { setYupLocale } from '@services/translation/translatedYup';
import GetFormik from '@features/ui-input/formik/GetFormik';
import InputLine, { InputLineProps } from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import InputCheckbox from '@features/ui-input/components/InputCheckbox';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textP } from '@features/ui/styles/textStyles';
import UserAccountFormValidationSchema from '@features/users/components/UserAccountFormSchema';

export enum USER_ACCOUNT_FORM_CONTEXT {
  REGISTER,
  REGISTER_FRIEND,
  ACTIVATE_ACCOUNT,
  RESET_PASSWORD
}

export interface UserAccountFormInputs {
  username: string,
  email: string,
  password: string,
  passwordConfirm: string,
  termOfUse: boolean,
  privacyPolicy: boolean,
  newsletter: boolean
}

export const defaultInitialValuesUserAccountForm = {
  username: '',
  email: '',
  password: '',
  passwordConfirm: '',
  termOfUse: false,
  privacyPolicy: false,
  newsletter: false,
};

interface UserAccountFormProps {
  initialValueUserAccount?: UserAccountFormInputs;
  setFormikContextAccount: (context: FormikProps<UserAccountFormInputs>) => void;
  handleSubmit?: () => void;
  context?: USER_ACCOUNT_FORM_CONTEXT;
}

export const PasswordHints = (t) => {
  const passwordHintMessages = [t('userAccountForm_passwordConfirmCondition1.message'), t('userAccountForm_passwordConfirmCondition2.message'), t('userAccountForm_passwordConfirmCondition3.message')];

  return (
    <PasswordHintContainer>
      {t('userAccountForm_passwordConfirmHintTitle.message')}
      {passwordHintMessages.map(hint => (
        <div key={hint}>{`- ${hint}`}</div>
      ))}
    </PasswordHintContainer>
  );
};

const fieldUsername = (t, context) => (
  <InputLine
    key="username"
    name="username"
    label={t('userAccountForm_usernameLabel.message')}
    as={InputText}
    showRequiredChip
    disabled={context === USER_ACCOUNT_FORM_CONTEXT.ACTIVATE_ACCOUNT}
    validationChip={context !== USER_ACCOUNT_FORM_CONTEXT.ACTIVATE_ACCOUNT}
    validationChipSpace
    labelMinWidth="194px"
  />
);

const fieldEmail = (t) => (
  <InputLine
    key="email"
    name="email"
    label={t('userAccountForm_emailLabel.message')}
    as={InputText}
    showRequiredChip
    validationChip
    labelMinWidth="194px"
  />
);

interface fieldPasswordI extends Partial<InputLineProps> {
  key: string
}

export const fieldPassword = (t, propsToOverride: fieldPasswordI = { key: 'password' }) => {
  const [passwordInputType, setPasswordInputType] = useState('password');
  return (
    <InputLine
      name="password"
      label={t('userAccountForm_passwordLabel.message')}
      as={InputText}
      type={passwordInputType}
      showRequiredChip
      validationChip
      labelMinWidth="194px"
      rightIcon={(
        <Icon
          name={passwordInputType === 'password' ? ICON_NAME.eye : ICON_NAME.eyeClosed}
          color="var(--color-grey3)"
        />
      )}
      onRightIconCallback={() => setPasswordInputType(prevState => (prevState === 'password' ? 'text' : 'password'))}
      {...propsToOverride}
    />
  );
};

export const fieldPasswordConfirm = (t, withHint: boolean = true, propsToOverride: fieldPasswordI = { key: 'passwordConfirm' }) => {
  const [passwordConfirmInputType, setPasswordConfirmInputType] = useState('password');

  return (
    <InputLine
      name="passwordConfirm"
      label={t('userAccountForm_passwordConfirmLabel.message')}
      as={InputText}
      type={passwordConfirmInputType}
      showRequiredChip
      validationChip
      labelMinWidth="194px"
      rightIcon={(
        <Icon
          name={passwordConfirmInputType === 'password' ? ICON_NAME.eye : ICON_NAME.eyeClosed}
          color="var(--color-grey3)"
        />
      )}
      onRightIconCallback={() => setPasswordConfirmInputType(prevState => (prevState === 'password' ? 'text' : 'password'))}
      hint={withHint ? PasswordHints(t) : ''}
      {...propsToOverride}
    />
  );
};

const fieldTermOfUse = (t) => (
  <InputLine
    key="termOfUse"
    name="termOfUse"
    label={t('userAccountForm_termOfUseLabel.message')}
    checkboxLabel={t(
      'userAccountForm_termOfUseText.message',
      {
        link: (
          <a
            key="userAccountForm_termOfUseText"
            tabIndex={-1}
            target="_blank"
            href={config.documentsPath.termOfUse}
            rel="noreferrer"
          >
            {t('userAccountForm_generalCondition.message')}
          </a>
        ),
      },
    )}
    as={InputCheckbox}
    showRequiredChip
    validationChipSpace
    labelMinWidth="194px"
  />
);

const fieldPrivacyPolicy = (t) => (
  <InputLine
    key="privacyPolicy"
    name="privacyPolicy"
    label={t('userAccountForm_privacyPolicyLabel.message')}
    as={InputCheckbox}
    checkboxLabel={t('userAccountForm_privacyPolicyText.message',
      {
        link: (
          <a
            key="userAccountForm_privacyPolicy"
            tabIndex={-1}
            target="_blank"
            href={config.documentsPath.privacyPolicy}
            rel="noreferrer"
          >
            {t('userAccountForm_privacyPolicy.message')}
          </a>
        ),
      })}
    showRequiredChip
    validationChipSpace
    labelMinWidth="194px"
  />
);

const fieldNewsletter = (t) => (
  <InputLine
    key="newsletter"
    name="newsletter"
    label={t('userAccountForm_newsletterLabel.message')}
    as={InputCheckbox}
    checkboxLabel={t('userAccountForm_newsletterText.message')}
    validationChipSpace
    labelMinWidth="194px"
  />
);

const getFields = (context: USER_ACCOUNT_FORM_CONTEXT, t) => {
  switch (context) {
    case USER_ACCOUNT_FORM_CONTEXT.REGISTER:
      return [
        fieldUsername(t, context),
        fieldEmail(t),
        fieldPassword(t),
        fieldPasswordConfirm(t),
        fieldTermOfUse(t),
        fieldPrivacyPolicy(t),
        fieldNewsletter(t),
      ];
    case USER_ACCOUNT_FORM_CONTEXT.REGISTER_FRIEND:
      return [fieldUsername(t, context), fieldEmail(t)];
    case USER_ACCOUNT_FORM_CONTEXT.ACTIVATE_ACCOUNT:
      return [
        fieldUsername(t, context),
        fieldPassword(t),
        fieldPasswordConfirm(t),
        fieldTermOfUse(t),
        fieldPrivacyPolicy(t),
        fieldNewsletter(t),
      ];
    case USER_ACCOUNT_FORM_CONTEXT.RESET_PASSWORD:
      return [fieldPassword(t), fieldPasswordConfirm(t)];
    default:
      return [];
  }
};

const UserAccountForm = ({
  initialValueUserAccount,
  handleSubmit,
  setFormikContextAccount,
  context = USER_ACCOUNT_FORM_CONTEXT.REGISTER,
}: UserAccountFormProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  setYupLocale(router.locale);

  const validationSchema = UserAccountFormValidationSchema(t, context);
  const initialValues = initialValueUserAccount ?? defaultInitialValuesUserAccountForm;
  const fields = getFields(context, t);

  return (
    <UserAccountContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <GetFormik getFormikContext={setFormikContextAccount} />
            {fields}
          </Form>
        )}
      </Formik>
    </UserAccountContainer>
  );
};

export default UserAccountForm;

const PasswordHintContainer = styled.div`
  ${textP};
  margin-top: 20px;
  color: var(--color-grey4);
`;
const UserAccountContainer = styled.div`
  a {
    color: var(--color-dark);
    text-decoration: underline;
  }
`;
