import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useRouter } from 'next/router';
import { EventDakineRanking, EventRanking, RANKINGS_LIST_TYPE, TEventDakineRankingList, TEventRankingList } from '@root/src/types';
import downloadPDF from '@utils/downloadPDF';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import List, { TABLE_ITEM_HEADER_TYPE, TableDefinition } from '@features/ui/components/List';
import { selectLiveResultsEnabled, selectRankingList, selectResultsListCountForRanking, selectResultsListType } from '@features/results/slices/resultsSlice';
import { ICON_NAME } from '@features/ui/components/Icon';
import { getQueryEventId, useShouldHighlight } from '@features/eventDetail/utils';
import {
  formatLocality,
  getAthleteAndTeamValue,
  getPersonName,
} from '@features/eventDetail/templates/EventListPageTemplate';
import CountryFlag from '@features/ui/components/CountryFlag';
import Rank from '@features/ui/components/Rank';

const isEventDakineRankingList = (
  rankingList: TEventRankingList | TEventDakineRankingList,
  resultsListType: RANKINGS_LIST_TYPE,
): rankingList is TEventDakineRankingList => resultsListType === RANKINGS_LIST_TYPE.DAKINE;

const EventRankingList = ({ rankingId } : { rankingId: number }) => {
  const { t } = useTranslation();
  const rankingList = useSelector(selectRankingList(rankingId)) as TEventRankingList | TEventDakineRankingList;
  const resultsListType = useSelector(selectResultsListType);
  const resultsCount = useSelector(selectResultsListCountForRanking(rankingId));
  const liveResultsContext = useSelector(selectLiveResultsEnabled);
  const shouldHighlight = useShouldHighlight();
  const requestHandler = useRequestHandler();
  const router = useRouter();
  const eventId = getQueryEventId(router.query);

  let tableDefinition: TableDefinition<EventRanking | EventDakineRanking> = [];
  if (!isEventDakineRankingList(rankingList, resultsListType)) {
    const displayTeamNamesInNotTeamRanking = !rankingList.isTeam
      && rankingList.rankings.some(({ teamName }) => !!teamName);

    let tableDefinitionCatOrScratch: TableDefinition<EventRanking> = [
      {
        size: {
          desktop: '55px',
          tablet: '55px',
          mobile: 'minmax(30px,45px)',
        },
        isCenter: true,
        name: t('eventResultsList_headerPosition.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          isBold: true,
          content: <Rank position={ranking.result?.position} />,
        }),
      }, {
        size: {
          desktop: '75px',
          tablet: '75px',
          mobile: 'minmax(30px,75px)',
        },
        name: t('eventResultsList_headerBib.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: ranking.bib,
        }),
      },
    ];
    if (rankingList.isTeam) {
      tableDefinitionCatOrScratch.push({
        size: {
          desktop: '1fr',
          tablet: 'minmax(80px,0.5fr)',
          mobile: null,
        },
        name: t('eventList_headerTeamName.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: ranking.teamName,
        }),
      });
    }
    tableDefinitionCatOrScratch.push({
      size: {
        desktop: '2fr',
        tablet: '1.5fr',
        mobile: 'minmax(80px,1fr)',
      },
      name: t('eventList_headerName.message'),
      renderCell: (ranking) => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        isBold: true,
        content: getAthleteAndTeamValue({
          data: ranking,
          getKey: getPersonName,
          t,
        }),
      }),
    });
    if (displayTeamNamesInNotTeamRanking) {
      tableDefinitionCatOrScratch.push({
        size: {
          desktop: '1fr',
          tablet: 'minmax(80px,0.5fr)',
          mobile: null,
        },
        name: t('eventList_headerSoloClubName.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: ranking.teamName,
        }),
      });
    }
    tableDefinitionCatOrScratch = tableDefinitionCatOrScratch.concat([
      {
        size: {
          desktop: '50px',
          tablet: '50px',
          mobile: null,
        },
        name: t('eventList_headerYear.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: getAthleteAndTeamValue({
            data: ranking,
            key: 'birthDateObj.year',
          }),
        }),
      },
      {
        size: {
          desktop: '1.3fr',
          tablet: null,
          mobile: null,
        },
        name: t('eventList_headerLocality.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: formatLocality(getAthleteAndTeamValue({
            data: ranking,
            key: 'legAddress.locality',
          })),
        }),
      },
      {
        size: {
          desktop: '60px',
          tablet: '60px',
          mobile: null,
        },
        name: t('eventList_headerDepartement.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: getAthleteAndTeamValue({
            data: ranking,
            key: 'legAddress.department',
          }),
        }),
      },
      {
        size: {
          desktop: '75px',
          tablet: null,
          mobile: null,
        },
        name: t('eventList_headerNationality.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: (
            <CountryFlag nationality={getAthleteAndTeamValue({
              data: ranking,
              key: 'nationality',
            })}
            />
          ),
        }),
      },
    ]);
    if (resultsListType === RANKINGS_LIST_TYPE.SCRATCH) {
      tableDefinitionCatOrScratch.push({
        size: {
          desktop: '2fr',
          tablet: null,
          mobile: null,
        },
        name: t('eventList_headerCategoryName.message'),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: ranking.categoryName,
        }),
      });
    }
    rankingList.extraHeaders.forEach((header, index) => {
      tableDefinitionCatOrScratch.push({
        size: {
          desktop: index > 3 ? null : '120px',
          tablet: index > 2 ? null : '120px',
          mobile: index > 0 ? null : 'minmax(70px,120px)',
        },
        name: t(header.name),
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          content: ranking.result?.timings ? ranking.result.timings[index] : '',
          isBold: index === 0,
        }),
      });
    });

    tableDefinition = tableDefinitionCatOrScratch;
  }

  if (isEventDakineRankingList(rankingList, resultsListType) && rankingList?.headers) {
    const tableDefinitionDakine: TableDefinition<EventDakineRanking> = [
      {
        size: {
          desktop: '60px',
          tablet: '60px',
          mobile: '60px',
        },
        name: rankingList.headers[0],
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          isBold: true,
          content: ranking.columns[0],
        }),
      },
    ];

    tableDefinitionDakine.push(...rankingList
      .headers
      .map((header, idx) => ({
        size: {
          desktop: '1fr',
          tablet: '1fr',
          mobile: '1fr',
        },
        name: header,
        renderCell: (ranking) => ({
          type: TABLE_ITEM_HEADER_TYPE.TEXT,
          isBold: true,
          content: ranking.columns[idx],
        }),
      }))
      .slice(1, rankingList.headers.length));

    tableDefinitionDakine.push({
      size: {
        desktop: '1fr',
        tablet: '1fr',
        mobile: '1fr',
      },
      name: '',
      renderCell: () => ({
        type: TABLE_ITEM_HEADER_TYPE.TEXT,
        content: '',
      }),
    });

    tableDefinition = tableDefinitionDakine;
  }

  const onDownloadClick = () => {
    let request;
    if (resultsListType === RANKINGS_LIST_TYPE.CATEGORIES) {
      request = requestHandler({
        request: api.results.event.categories.download({
          categoryId: rankingList._idMso,
          eventId,
          locale: router.locale,
        }),
      });
    } else if (resultsListType === RANKINGS_LIST_TYPE.SCRATCH) {
      request = requestHandler({
        request: api.results.event.scratch.download({
          scratchId: rankingList._idMso,
          eventId,
          locale: router.locale,
        }),
      });
    } else {
      request = requestHandler({
        request: api.results.event.rankings.download({
          importId: rankingList._idMso,
          eventId,
          locale: router.locale,
        }),
      });
    }
    const fileName = `${t('resultsList_titleExportSuffixNameFile.message')}-${rankingList.name}.csv`;
    downloadPDF(request, fileName);
  };

  if (!rankingList.rankings) {
    return null;
  }

  return (
    <List
      listHeaderName={rankingList.name}
      listHeaderCount={rankingList.total ?? resultsCount}
      listHeaderIcon={rankingList.isTeam ? ICON_NAME.iconGroups : ICON_NAME.person}
      listHeaderDownloadClick={!liveResultsContext && onDownloadClick}
      tableItemShouldHighlight={(ranking) => shouldHighlight(ranking.athlete?._idMso)}
      tableDefinition={tableDefinition}
      items={rankingList.rankings}
      uniqueId={rankingId}
    />
  );
};
export default EventRankingList;
