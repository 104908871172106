import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useField } from 'formik';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { Event, EVENT_LIST_ITEM_TYPES, Serie } from '@root/src/types';
import { formatDateAsYear } from '@utils/dates';
import { useRequestHandler } from '@services/api/handlers';
import api, { EventsListApiParameters, EVENT_LIST_MODELS } from '@services/api';
import routeList from '@services/routeList';
import InputText from '@features/ui-input/components/InputText';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import InputLine from '@features/ui-input/formik/InputLine';
import { eventFromApiToReducer } from '@features/eventDetail/formatters';
import { serieFromApiToReducer } from '@features/serieDetail/formatters';
import { SerieLabelSmall } from '@features/ui/components/SerieLabel';

interface SuggestionsProps {
  fieldName?: string;
  apiParams?: EventsListApiParameters,
  debounceTime?: number,
  numberOfCharactersBeforeSuggestions?: number
}

const EventsSuggestions = ({
  fieldName = 'search',
  numberOfCharactersBeforeSuggestions = 3,
  debounceTime = 500,
  apiParams,
}: SuggestionsProps) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();
  const router = useRouter();

  const [isVisible, setIsVisible] = useState(false);
  const [suggestions, setSuggestions] = useState<Array<Event | Serie>>([]);
  const [field, , helpers] = useField(fieldName);
  const { setValue } = helpers;
  const inputRef = useRef(null);
  const [selectedSuggestionIdByKey, setSelectedSuggestionIdByKey] = useState<string>();

  const requestSuggestionsDebounced = useMemo(
    () => debounce(async (e, params) => {
      const { response } = await requestHandler({
        request: api.events.list({
          pageSize: 5,
          page: 0,
          q: `${e.target.value.trim()}*`,
          sorted: [
            {
              id: 'date',
              desc: true,
            },
          ],
          ...params,
          models: [EVENT_LIST_MODELS.baseRace, EVENT_LIST_MODELS.baseEvent],
        }),
      });
      if (response.ok) {
        const events = await response.json();
        const formattedEvents = events.items
          .map(e => (e._type === EVENT_LIST_ITEM_TYPES.event
            ? eventFromApiToReducer(e)
            : serieFromApiToReducer(e)));

        setSuggestions(formattedEvents);
        setSelectedSuggestionIdByKey(formattedEvents[0]?.id);
      }
    }, debounceTime),
    [],
  );

  const handleOnChange = (e) => {
    setIsVisible(true);
    field.onChange(e);
    if (e.target.value.trim().length >= numberOfCharactersBeforeSuggestions) {
      requestSuggestionsDebounced(e, apiParams);
    } else {
      setSuggestions([]);
    }
  };

  const navigateUpOrDownInSuggestions = (pressedKey: string) => {
    let suggestionIndexToSelect = suggestions.findIndex((s) => s._id === selectedSuggestionIdByKey);

    suggestionIndexToSelect = pressedKey === 'ArrowDown' ? suggestionIndexToSelect + 1 : suggestionIndexToSelect - 1;

    if (suggestionIndexToSelect < 0) {
      suggestionIndexToSelect = suggestions.length - 1;
    } else if (suggestionIndexToSelect >= suggestions.length) {
      suggestionIndexToSelect = 0;
    }

    setSelectedSuggestionIdByKey(suggestions[suggestionIndexToSelect]?._id);
  };

  const setInputValue = (value: string) => setValue(value);

  const suggestionTextToShow = (
    suggestion: Event | Serie
  ) => {
    const year = suggestion._type === EVENT_LIST_ITEM_TYPES.event
      ? formatDateAsYear(suggestion.date)
      : suggestion.year;

    return `${suggestion.name} - ${year}`;
  };

  const routeEventOrSerie = (suggestion: Event | Serie) => {
    if (suggestion._type === EVENT_LIST_ITEM_TYPES.event) {
      router.push(routeList.events(suggestion.slug).description());
    } else {
      router.push(routeList.series(suggestion.slug).description());
    }
  };

  const handleOnKeyUp = (e) => {
    let suggestion = null;

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        navigateUpOrDownInSuggestions(e.key);
        break;
      case 'Enter':
        suggestion = suggestions.find((s) => s._id === selectedSuggestionIdByKey)
          ?? suggestions[0];
        setInputValue(suggestionTextToShow(suggestion));
        routeEventOrSerie(suggestion);
        break;
      default:
        break;
    }
  };

  useEffect(() => () => {
    requestSuggestionsDebounced.cancel();
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setIsVisible(false)}>
      <Container ref={inputRef}>
        <InputLine
          name={fieldName}
          as={InputText}
          spaceInterLine="0"
          leftIcon={<Icon name={ICON_NAME.search} color="var(--color-grey3)" />}
          placeholder={t('findEventsBar_eventPlaceholder.message')}
          onChange={handleOnChange}
          onClick={() => setIsVisible(true)}
          autoComplete="off"
          onKeyUp={(e) => suggestions.length > 0 && handleOnKeyUp(e)}
          data-qa="eventList-search-input"
        />
        {isVisible && (
          <AutoCompleteContainer marginTopPx={inputRef.current?.offsetHeight || 0}>
            {suggestions.map((suggestion) => (
              <AutoCompleteResult
                key={suggestion._id}
                className={selectedSuggestionIdByKey === suggestion._id ? 'selected' : ''}
                onClick={() => {
                  setInputValue(suggestionTextToShow(suggestion));
                  routeEventOrSerie(suggestion);
                }}
                onMouseOver={() => setSelectedSuggestionIdByKey(suggestion._id)}
                data-qa={`eventList-suggestion-item-${suggestion._idMso}`}
              >
                <p>
                  {suggestionTextToShow(suggestion)}
                  {suggestion._type === EVENT_LIST_ITEM_TYPES.serie && <SerieLabelSmallWrapper />}
                </p>
              </AutoCompleteResult>
            ))}
          </AutoCompleteContainer>
        )}
      </Container>
    </ClickAwayListener>
  );
};
export default EventsSuggestions;

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;

  > :first-child {
    align-content: center;
  }

`;

const AutoCompleteContainer = styled.div<{ marginTopPx: number }>`
  position: absolute;
  width: 100%;
  margin-top: ${({ marginTopPx }) => marginTopPx}px;
  z-index: var(--z-index-over-content);
  box-shadow: 0 2px 12px 0 rgba(128, 122, 122, 0.2);

`;

const AutoCompleteResult = styled.div`
  display: flex;
  width: 100%;
  background-color: var(--color-white);
  padding: 10px 40px;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid var(--color-grey1);
  border-right: 1px solid var(--color-grey1);


  &:first-child {
    border-top: 1px solid var(--color-grey1);
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid var(--color-grey1);
  }

  &.selected {
    background-color: var(--color-grey1);
  }
`;

const SerieLabelSmallWrapper = styled(SerieLabelSmall)`
  margin-left: 6px;
`;
