import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import config from '@root/src/config';
import { mediaBreakpoint, RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textH4 } from '@features/ui/styles/textStyles';
import LanguagesDropdown from '@features/ui/components/LanguagesDropdown';
import { selectIsAuthenticated } from '@features/users/slices/userSlice';
import { selectCurrentCartDetail } from '@features/carts/slices/cartsSlice';
import { selectWithLogin } from '@features/ui-layout/slices/uiLayoutSlice';
import AccountMenu from '@features/account/components/AccountMenu';
import Logout from '@features/account/components/Logout';
import { selectNewSubscriptions } from '@features/subscriptions/slices/subscriptionDetailServerSlice';

interface MenuProps {
  showContrastColor?: boolean;
}

const Menu = ({ showContrastColor }: MenuProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const logged = useSelector(selectIsAuthenticated);
  const order = useSelector(selectCurrentCartDetail);
  const withLogin = useSelector(selectWithLogin);
  const userSubscriptions = useSelector(selectNewSubscriptions);
  const responsive = useResponsive();
  const numberItemsInCard = order?.items?.length;
  const numberEvents = userSubscriptions?.total;
  const isMobile = responsive !== RESPONSIVE.DESKTOP;
  return (
    <Wrapper>
      <SubWrapper>
        <MenuButton
          isMobile={isMobile}
          variant={showContrastColor ? BUTTON_VARIANT.menuContrast : BUTTON_VARIANT.menu}
          url={{ pathname: config.helpUrl.faq[router.locale] }}
          text={t('header_help.message')}
        />

        {!logged && <StyledLanguagesDropdown isMobile={isMobile} />}
        {/* Used to avoid the use of fit-content */}
        {logged && (
          <>
            <MenuButton
              isMobile={isMobile}
              variant={showContrastColor ? BUTTON_VARIANT.menuContrast : BUTTON_VARIANT.menu}
              url={getRoute.cart.index()}
              text={t('header_cart.message')}
              rightComponent={numberItemsInCard ? <RoundChip>{numberItemsInCard}</RoundChip> : undefined}
            />
            {isMobile
              ? <Separator />
              : (
                <MenuButton
                  isMobile={isMobile}
                  variant={showContrastColor ? BUTTON_VARIANT.menuContrast : BUTTON_VARIANT.menu}
                  url={getRoute.account.subscriptions()}
                  text={t('header_myEvents.message')}
                  rightComponent={numberEvents ? <RoundChip>{numberEvents}</RoundChip> : undefined}
                />
              )}
          </>
        )}
      </SubWrapper>
      <SubWrapper>
        {/* Used to avoid the use of fit-content */}
        {withLogin && (
          <>
            {!logged && (
              <MenuButton
                isMobile={isMobile}
                variant={BUTTON_VARIANT.primary}
                text={t('header_login.message')}
                leftIcon={ICON_NAME.accountCircle}
                url={getRoute.openModal(router).login()}
                urlScroll={false}
                data-qa="layout-login-button"
              />
            )}
            {logged && (
              <>
                {!isMobile ? (
                  <Button
                    variant={BUTTON_VARIANT.primary}
                    text={t('menu_myAccountButton.message')}
                    leftIcon={ICON_NAME.accountCircle}
                    url={getRoute.account.profile()}
                    data-qa="layout-myAccount-button"

                  />
                ) : (
                  <>
                    <AccountMenu />
                    <LogoutWrapper>
                      <Logout />
                    </LogoutWrapper>
                  </>
                )}
              </>
            )}
          </>
        )}
      </SubWrapper>
    </Wrapper>
  );
};
export default Menu;

const StyledLanguagesDropdown = styled(LanguagesDropdown)<{ isMobile: boolean }>`
  margin-right: 12px;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : '8px')};
  margin-top: ${({ isMobile }) => (isMobile ? '8px' : '0')};

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaBreakpoint.tablet} {
    flex-direction: column;
  }

  > *:not(:last-child) {
    margin-bottom: 0;

    ${mediaBreakpoint.tablet} {
      margin-right: 0;
      flex-direction: column;
    }
  }
`;

const SubWrapper = styled.div`
  display: flex;

  ${mediaBreakpoint.tablet} {
    flex-direction: column;
  }
`;

export const RoundChip = styled.div`
  border-radius: 50%;
  background: var(--color-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  ${textH4};

  width: 28px;
  height: 28px;
  margin-left: 8px;
`;

const MenuButton = styled(Button)<OwnProps & { isMobile: boolean }>`
  margin-right: ${({ isMobile }) => (isMobile ? '0' : '8px')};
  margin-top: ${({ isMobile }) => (isMobile ? '8px' : '0')};
`;

const Separator = styled.div`
  width: 200vw;
  margin-left: -50%;
  height: 2px;
  background: var(--color-grey1);
  margin-top: 20px;
  margin-bottom: 20px;
`;

const LogoutWrapper = styled.div`
  margin-top: 12px;
`;
