export enum LOG_TITLE {
 fetchOptions = 'fetchOptions',
 fetchResponse = 'fetchResponse',
 ssrProviderError = 'ssrProviderError',
 ssrProvider = 'ssrProvider',
}

const logger = (title: LOG_TITLE, { message, data }: {message?: string, data?: any}) => {
  const log = {
    msg: title,
    msgComment: message,
    data,
  };
  // Note: cache should not be re-used by repeated calls to JSON.stringify.
  let cache = [];
  const logStringify = JSON.stringify(log, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (cache.includes(value)) return null;

      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null;
  // eslint-disable-next-line no-console
  console.log(logStringify);
};

export default logger;
