import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { setYupLocale } from '@services/translation/translatedYup';
import GetFormik from '@features/ui-input/formik/GetFormik';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import { textH4, textP } from '@features/ui/styles/textStyles';
import ValidationSchema from '@features/account/components/RequestPasswordReset/ValidationSchema';

export interface FormAskUsernameInputs {
  username: string
}

const FormAskUsername = ({ setFormikContext, onSubmit }) => {
  const { t } = useTranslation();
  const router = useRouter();
  setYupLocale(router.locale);
  const initialValues: FormAskUsernameInputs = { username: '' };

  return (
    <Wrapper>
      <Title>{t('modalRequestPasswordResetForm_title.message')}</Title>
      <Description>{t('modalRequestPasswordResetForm_description.message')}</Description>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema(t)}
      >
        <StyledForm>
          <GetFormik getFormikContext={setFormikContext} />
          <InputLine
            label={t('username.message')}
            name="username"
            as={InputText}
            showRequiredChip
            textAlignment="flex-end"
          />
        </StyledForm>
      </Formik>
    </Wrapper>
  );
};

export default FormAskUsername;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h4`
  ${textH4}
  width: 100%;
`;

const Description = styled.p`
  ${textP}
  margin-top: 4px;
`;

const StyledForm = styled(Form)`
  margin-top: 28px;
  max-width: 565px;
`;
