interface MsoIds {
  _idMso: number;
}

interface ApiList<P> {
  items: Array<P>;
  total: number;
  pages: number;
}

export interface Quota {
  max?: number;
  current?: number;
  full?: boolean;
}

export enum CURRENCY {
  CHF = 'CHF',
  EUR = 'EUR',
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  MIXED = 'MIXED',
}

// If you add or remove a value from here
// You should check ./i18n.config.js + ./pontoon/config.toml + ./src/features/ui/component/DateRange
// Check also every place where LOCALES is used
export enum LOCALES {
  FRENCH = 'fr',
  ENGLISH = 'en',
  GERMAN = 'de'
}

export enum EVENT_LIST_SEARCH_FILTER {
  ATHLETE = 'ATHLETE',
  DEPARTEMENT = 'DEPARTEMENT',
  YEAR = 'YEAR',
  LOCALITY = 'LOCALITY',
  NATIONALITY = 'NATIONALITY',
  TEAM = 'TEAM'
}

export interface ListFilter {
  query: string;
  filterBy: EVENT_LIST_SEARCH_FILTER | LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS;
}

export enum ENGAGED_LIST_SEARCH_FILTER_KEYS {
  athlete = 'athlete',
  location = 'location',
  nationality = 'nationality',
  year = 'year',
  canton = 'canton',
  teamname = 'teamname',
}

export enum RESULTS_LIST_SEARCH_FILTER_KEYS {
  athlete = 'athlete',
  location = 'location',
  nationality = 'nationality',
  year = 'year',
  canton = 'canton',
  teamname = 'teamname',
}

export interface SelectOptions {
  label: string;
  value: number | string;
}

// ----------------------------------------------------------------------
// ------------------- USER ---------------------------------------------
// ----------------------------------------------------------------------
export interface ProfileVerification extends MsoIds {
  _id: string;
  birthDateObj?: BirthDateObj;
  firstName: string;
  gender: GENDER;
  lastName: string;
}

export interface Profile extends MsoIds {
  _id: string;
  accessLevel: ACCESS_LEVEL;
  gender: GENDER;
  language: string;
  firstName: string;
  lastName: string;
  legAddress?: LegAddress;
  nationality?: string;
  club?: string;
  licence?: string;
  birthDateObj?: BirthDateObj;
  login: string;
  email?: Email;
  legPhone?: string;
  mPhones: {
    phone: string;
    description?: string;
    accessCode?: string; // TODO change to enum when back is ready
    validated: boolean;
    activationCode?: string; // TODO clean this, probably useless back info
    activationCodeTs?: Date; // TODO clean this, probably useless back info
  }[];
  status: string;
  updatedAt: Date;
  createdAt: Date;
  age: number;
  fullName: string;
  newsletter: boolean,
  licenseCAS?: {
    uniqid: string
    articleName: string,
    articleIdentity: string,
    articleDescription: string,
    created: string,
    payed: string,
    _id: string,
    validUntil: string,
  } & MsoIds
}

export interface AthleteShortProfile extends MsoIds {
  _id: string;
  firstName: string;
  lastName: string;
  legAddress?: LegAddress;
  nationality?: string;
  birthDateObj?: BirthDateObj;
}

export interface BirthDateObj {
  year: number;
  month?: number;
  day?: number;
}

export interface Email {
  validated: boolean;
  email: string;
}

export interface LegAddress {
  address?: string;
  postalCode?: string;
  locality: string;
  department: string;
  country?: string;
}

export interface ApiRegisterUserBody {
  firstName: string,
  lastName: string,
  birthDateObj: {
    year: number,
    month: number
    day: number
  },
  gender: GENDER,
  nationality: string,
  legAddress: {
    address: string,
    postalcode: string,
    locality: string,
    department?: string,
    country: string,
  },
  language: string,
  club?: string,
  legPhone?: string,
  licence?: string,
  login: string,
  email: string,
  password: string,
  acceptcg: boolean,
  acceptPrivacyPolicy: boolean,
  newsletter: boolean,
}

export interface ApiUpdateProfile {
  firstName: string,
  lastName: string,
  birthDateObj: {
    year: number,
    month: number
    day: number
  },
  gender: GENDER,
  nationality: string,
  legAddress: {
    address: string,
    postalcode: string,
    locality: string,
    department?: string,
    country: string,
  },
  language: string,
}

export interface ApiUpdatePassword {
  oldPassword: string,
  newPassword: string
}

export interface AccessesOnMyProfile {
  level: number,
  athlete: Profile
}

export type AccessesOnMyFriends = ApiList<Profile>

export enum ACCESS_LEVEL {
  NOT_ALLOWED = 1,
  ALLOWED = 2,
  OWNER = 3
}

export interface MobileNumber extends MsoIds {
  _id: string,
  num: string,
  description: string,
  created: string,
  validated: string,
  code: string,
  coderequestnum: number,
  requested: string,
  def: boolean
}

export interface ApiCreateUpdateMobileNumber {
  num: string,
  description: string,
  def: boolean,
}

export interface ApiContactFormRequestBody {
  name: string;
  email: string;
  phone?: string;
  subject?: string;
  message: string;
  captcha: string;
  lang: string;
}

export interface Preferences {
  optIn: boolean,
  bike: boolean,
  running: boolean,
  triathlon: boolean,
  ski: boolean,
  motorSports: boolean
}

// ----------------------------------------------------------------------
// ------------------ EVENT ---------------------------------------------
// ----------------------------------------------------------------------
export enum REGISTRATION_STATUS {
  NOT_YET_OPEN = 'NOT_YET_OPEN',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export interface EventStatus {
  registration: REGISTRATION_STATUS;
  registrationFrom: string;
  registrationTo: string;
}

export enum LIVE_TYPES {
  STANDARD = 'STANDARD',
  P_POINTS = 'P_POINTS',
  LIVETRAIL = 'LIVETRAIL',
  EXTERNAL = 'EXTERNAL'
}

export enum EVENT_LIST_ITEM_TYPES {
  event = 'event',
  serie = 'serie',
}

export interface Sport extends MsoIds {
  _id: string;
  sportCategory: SPORT_CATEGORIES;
  name: string;
}

export interface Event extends MsoIds {
  _id: string;
  _type: EVENT_LIST_ITEM_TYPES.event,
  canceled?: boolean;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  description?: string;
  eventType?: string;
  sportCategory?: SPORT_CATEGORIES;
  currency: CURRENCY;
  serie?: {
    active: boolean;
    isChampionship: boolean;
    grouped: boolean;
    name: string;
    passport: boolean;
    passportPack: string;
    reduction: string;
    urlPresentation: string;
    year: number;
    slug: string;
  } & MsoIds;
  gps?: {
    longitude: number;
    latitude: number;
  };
  status?: EventStatus;
  location?: string;
  site?: string;
  maxRegistrations?: number;
  name?: string;
  npaWeather?: number;
  displayWithdrawalVoucher: boolean;
  urlOrganiser?: string;
  slug: string;
  vip: boolean;
  quotas?: Quota;
  hasDiscountVoucher?: boolean;
  showStartListFrom?: string;
  imageCover?: string;
  logo?: string;
  live?: {
    type: LIVE_TYPES,
    url: string,
    isNow: boolean
  },
  hasEventResults: boolean,
  hasSerieResults: boolean,
  hasLiveResults: LIVE_RESULTS_STATUS,
  sponsorStartDate?: string,
  sponsorEndDate?: string,
}

export enum TEAM_MEMBER_ROLE {
  LEAD = 'LEAD',
  MEMBER_2 = 'MEMBER_2',
  MEMBER_3 = 'MEMBER_3',
  MEMBER_4 = 'MEMBER_4',
  MEMBER_5 = 'MEMBER_5',
  MEMBER_6 = 'MEMBER_6',
  MEMBER_7 = 'MEMBER_7',
  MEMBER_8 = 'MEMBER_8',
  MEMBER_9 = 'MEMBER_9',
  MEMBER_10 = 'MEMBER_10',
}

export enum PRE_SERVICE_LICENCE_TYPE {
  CAS = 'CAS',
  CUSTOM = 'CUSTOM'
}

export interface Category extends MsoIds {
  active: boolean,
  distance: string,
  name: string,
  description?: string,
  ageMin: number,
  ageMax: number,
  maxRegistrations: number,
  priceObj: { value: number },
  gender: GENDER,
  team?: {
    nbMembers: number;
    roles: TEAM_MEMBER_ROLE[];
  };
  preServices?: {
    vip: boolean;
    license?: boolean,
    licenseType?: PRE_SERVICE_LICENCE_TYPE,
    licenseMandatory?: boolean,
    insurance?: boolean,
    emergencyNumber?: boolean,
    emergencyNumberMandatory?: boolean,
    club?: boolean,
    clubMandatory?: boolean
  },
  event?: Event,
  eventType?: string;
  sportCategory?: SPORT_CATEGORIES;
  quotas?: Quota;
  lockgrpsupdate?: string,
  lockpostorder?: string,
  diploma?: number,
}

export enum SERVICE_GROUP_TYPE {
  PRE_SERVICES = 'PRE_SERVICES',
  SERVICES = 'SERVICES'
}

export interface ServiceGroup extends MsoIds {
  updatable: boolean;
  sortIndex: number;
  title: string;
  groupType: SERVICE_GROUP_TYPE;
  services: Array<Service|SubscriptionService>;
  teamMember?: string
}

export interface Service extends MsoIds {
  mandatory: boolean;
  fieldType: FieldType;
  invoiced: boolean;
  price?: number;
  image?: string;
  url?: string;
  updatable: boolean;
  sortIndex: number;
  atLeast1?: boolean;
  label: string;
  tooltip?: string;
  choices?: string[];
  validation: string;
}

export interface SubscriptionService extends Service {
  canBeUpdated: boolean;
  notUpdatableReason?: string,
  value?: any;
}

export enum FieldType {
  Checkbox = 'checkbox',
  Input = 'input',
  Int = 'int',
  Select = 'select',
  Phone = 'phone',
}

export interface EngagedUser extends MsoIds {
  id: number; // Used by the List component
  _id: string;
  registeredIn: string;
  athlete: AthleteShortProfile;
  category: number;
  club: string;
  teamName: string;
  insurance: boolean;
  emergencyNumber: string;
  registrationMadeBy: number;
  orderStatus: ORDER_STATUS;
  teamMembers: AthleteShortProfile[]
  bib: number;
}

export interface EngagedUserListRaw {
  [categoryMsoId: string]: Array<EngagedUser>;
}
export type EngagedStatsFromAPI = Array<{
  _idMso: string;
  count: number;
  athletesCount: number;
  gender: GENDER;
  name: string;
  distance: string;
}>;
export interface EngagedStats {
  [categoryId: number]: {
    subscriptionsCount: number;
    athletesCount: number;
  };
}

// ----------------------------------------------------------------------
// ------------------- ORDER ------------------------------------
// ----------------------------------------------------------------------
export enum ORDER_STATUS {
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  PAID = 'PAID',
  DELETED = 'DELETED',
}

export interface Order extends MsoIds {
  _id: string;
  createdAt: string;
  created: string;
  uniqid: string;
  orderid: string;
  orderStatus: ORDER_STATUS;
  fidAthlete: number;
  price: OrderPrice;
  paymentMethod: PAYMENT_METHOD_LEGACY;
  paymentBrand?: PAYMENT_DATATRANS_BRAND;
}

export type Orders = ApiList<Order>

// ----------------------------------------------------------------------
// ------------------- CART ------------------------------------
// ----------------------------------------------------------------------
export enum CART_STATUS {
  EMPTY = 'EMPTY',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  // VALIDATED = 'VALIDATED',
  // PAID = 'PAID',
}

export interface Cart extends MsoIds {
  _id: string,
  orderid: string;
  orderMadeBy?: {
    fullName?: string;
  } & MsoIds;
  cartStatus: CART_STATUS;
  paymentMethod?: PAYMENT_METHOD;
  items: OrderItem[];
  club: string;
  createdBy?: {
    athleteId: string;
  };
  price: OrderPrice;
  transaction?: Transaction;
  refused?: {
    reason?: string;
    code?: string; // TODO add the right enum when backend add it
  };
  datatrans?: {
    transactionId: string;
  };
  paymentMethodsAvailable: Array<PAYMENT_METHOD>;
  createdAt: string;
  created: string;
  uniqid: string;
  currency?: CURRENCY,
}

export enum DiscountType {
  PERCENTAGE,
  AMOUNT
}

export interface DiscountVoucher extends MsoIds {
  _id: string,
  code: string,
  redutype: DiscountType,
  percent: number,
  amount: number
}

export interface OrderItem {
  _id: string; // Use to delete the item on the basket
  athlete: {
    gender: string;
    birthDateObj?: BirthDateObj;
    fullName: string;
  } & MsoIds;

  // Example how to use the event type here :
  // const t = {} as CurrentOrderItem;
  // if (typeof t.event !== 'string') {
  //   const u = t.event._idMso; // <- here the type is valid
  // }
  event: OrderEvent;

  category: {
    name: string;
  } & MsoIds | Category;
  team?: Array<RegistrationTeamMate>;
  club?: string;
  emergencyNumber?: string;
  license?: string;
  insurance: boolean;
  price: {
    registration: number;
    services: number;
    insurance: number;
    discount: number;
    total: number;
  };
  reduction?: DiscountVoucher;
  serviceGroupsOrders: ServiceGroupOrder[];
  hasDiscountVoucher?: boolean;
  partOfPack?: string;
  packReduction?: {
    redutype: DiscountType;
    percent: number;
    amount: number;
  }
}

export interface ServiceGroupOrder {
  group: MsoIds;
  groupType: SERVICE_GROUP_TYPE;
  teamMember: MsoIds;
  serviceOrders: ServiceOrder[];
}

export interface RegistrationTeamMate {
  teamMember: {
    fullName: string
  } & MsoIds;
  role: TEAM_MEMBER_ROLE;
}

export interface OrderEvent extends MsoIds {
  name: string;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  location?: string;
  urlOrganiser?: string;
  slug?: string;
}

export interface ServiceOrder extends Service {
  service: MsoIds;
  value: boolean | number | string;
  unitPrice: number;
}

export interface OrderPrice {
  totalItems: number;
  totalFee: number;
  totalOrder: number;
  totalReduction: number;
  totalInsurance: number;
  fee?: {
    amount: number;
    amountFixed: number;
    amountVariable: number;
    percent: number;
  };
  currency: CURRENCY;
}

export enum TRANSACTION_STATUS {
  INITIALIZED = 'initialized',
  CHALLENGE_REQUIRED = 'challenge_required',
  CHALLENGE_ONGOING = 'challenge_ongoing',
  AUTHENTICATED = 'authenticated',
  AUTHORIZED = 'authorized',
  SETTLED = 'settled',
  CANCELED = 'canceled',
  TRANSMITTED = 'transmitted',
  FAILED = 'failed',
}

export enum TRANSACTION_TYPE {
  PAYMENT = 'payment',
  CREDIT = 'credit',
  CARD_CHECK = 'card_check',
}

export interface Transaction {
  type: TRANSACTION_TYPE;
  status: TRANSACTION_STATUS;
  currency: CURRENCY;
  paymentMethod: string;
  detail?: {
    fail?: {
      reason: string;
      message: string;
    }
  };
}

export enum YES_NO {
  YES = 'yes',
  NO = 'no',
}

export const INSURANCE_ACCEPTANCE = 'insuranceAcceptance';

export enum DEFAULT_PRE_SERVICES {
  LICENSE = 'license',
  LICENSE_MANDATORY = 'licenseMandatory',
  INSURANCE = 'insurance',
  EMERGENCY_NUMBER = 'emergencyNumber',
  EMERGENCY_NUMBER_MANDATORY = 'emergencyNumberMandatory',
  CLUB = 'club',
  CLUB_MANDATORY = 'clubMandatory',
  VIP = 'vip'
}

// Used before the payment
export enum PAYMENT_METHOD {
  INVOICE = 'INVOICE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DATATRANS = 'DATATRANS',
  FREE = 'FREE',
}

// Used after the payment
export enum PAYMENT_METHOD_LEGACY {
  INVOICE = 'INVOICE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  DATATRANS = 'DATATRANS',
  FREE = 'FREE',
  POSTAL_INVOICE = 'POSTAL_INVOICE',
  TILL = 'TILL',
  PAYPAL = 'PAYPAL',
  POSTFINANCE = 'POSTFINANCE',
}

export enum PAYMENT_DATATRANS_BRAND {
  VIS = 'VIS',
  ECA = 'ECA',
  APL = 'APL',
  PAY = 'PAY',
  SAM = 'SAM',
  TWI = 'TWI',
  PFC = 'PFC',
  PEF = 'PEF',
  DIB = 'DIB',
}

export enum PAYMENT_METHOD_INFO_CODE {
  DATATRANS_FIXED_FEES = 'frais_fixe_postfinance',
  INVOICE_FIXED_FEES = 'frais_fixe_facture',
  BANK_TRANSFER_FIXED_FEES = 'frais_fixe_virement',
  DATATRANS_VARIABLE_FEES = 'frais_variable_postfinance',
  INVOICE_VARIABLE_FEES = 'frais_variable_facture',
  BANK_TRANSFER_VARIABLE_FEES = 'frais_variable_virement'
}

export interface PaymentMethodInfo {
  code: string,
  value: string
}

export enum SPORT_CATEGORIES {
  running = 'RUNNING', // cours à pied
  triathlon = 'TRIATHLON', // Triathlon Multisport
  cycling = 'CYCLING', // Cyclisme
  alpineSkiing = 'ALPINE_SKIING', // Ski aplinisme
  crossCountrySkiing = 'CROSS_COUNTRY_SKIING', // Ski de fond
  motorSport = 'MOTOR_SPORT', // Sport motorisé
  other = 'OTHER', // other
}

// ----------------------------------------------------------------------
// ------------------- Subscriptions ------------------------------------
// ----------------------------------------------------------------------

export interface Subscription extends MsoIds {
  registerin: string;
  accessLevel: ACCESS_LEVEL;
  athlete?: Profile;
  category: Category;
  club: string;
  emergencyNumber?: string;
  license?: string;
  teamname: string;
  _id: string;
  registrationStatus: REGISTRATION_STATUS;
  registrationMadeBy: {
    fullName: string;
  } & MsoIds;
  fidOrder: number;
  insurance: boolean;
  team: RegistrationTeamMate[];
  order: {
    orderStatus: ORDER_STATUS;
    _id: string;
  } & MsoIds;
  bib?: number;
  chip?: number;
  result?: Result;
}

export type Subscriptions = ApiList<Subscription>

// ----------------------------------------------------------------------
// ------------------- Results ------------------------------------------
// ----------------------------------------------------------------------

// SOME TERMINOLOGY:
// Ranking: the position of an athlete for a given category (+ times, splits, etc.)
// Rankings: the list of rankings for a given scratch / category
// Result: the rankings, as well as information about scratch / category (name, id, etc.)
// Results: the list of results for a given scratch / category
export enum RESULTS_TYPE {
  EVENT = 'EVENT',
  SERIES = 'SERIES',
}
export enum RANKINGS_LIST_TYPE {
  SCRATCH = 'SCRATCH',
  CATEGORIES = 'CATEGORIES',
  DAKINE = 'DAKINE',
}
export enum SERIES_COMPUTE_MODE {
  POINTS_SUM = 'POINTS_SUM',
  TIME = 'TIME',
  POINTS_AVERAGE = 'POINTS_AVERAGE',
}
export interface Result {
  position: number | string,
  timings?: Array<string>,
  points?: string,
}
export type ResultAvailability = {
  [RANKINGS_LIST_TYPE.SCRATCH]: boolean,
  [RANKINGS_LIST_TYPE.CATEGORIES]: boolean,
  [RANKINGS_LIST_TYPE.DAKINE]: boolean,
}
export interface Ranking {
  id: number;
  categoryName?: string;
  teamMembers?: AthleteShortProfile[];
}
export interface EventRanking extends Ranking {
  result?: Result;
  bib: number;
  athlete: AthleteShortProfile;
  teamName?: string;
}
export interface EventDakineRanking extends Ranking {
  result?: Result;
  columns: Array<string>;
}
export interface SeriesRankingCommon extends Ranking {
  position: number;
  gap: number;
  nbRace: number;
  points: number | string;
  results: Array<{ points: number | string; count: boolean; }>;
  athlete: AthleteShortProfile | { fullName: string }; // TODO 248 Removed athlete from here
}

export interface SeriesRankingDakine extends SeriesRankingCommon {
  athlete: { fullName: string }
}
export interface SeriesRankingScratchAndCategory extends SeriesRankingCommon {
  athlete: AthleteShortProfile
}

// TODO 248 Understand why type in seriesRankingFromApiToReducer give an error
// if the type is like that : SeriesRankingScratchAndCategory | SeriesRankingDakine
export type SeriesRanking = SeriesRankingCommon
export interface RankingList {
  isTeam: boolean;
  extraHeaders: Array<{name: string; mandatory: boolean;}>;
}
export interface TEventRankingList extends RankingList, MsoIds {
  rankings: Array<EventRanking>;
  name: string;
  total: number;
}
export interface TEventDakineRankingList extends RankingList, MsoIds {
  rankings: Array<EventDakineRanking>;
  name: string;
  headers: Array<string>;
  total: number;
}
export interface TSeriesRankingList extends RankingList {
  _idMso: string;
  rankings: Array<SeriesRanking>;
  name?: string; // Fucking optional for series when it's scratch + dakine
}
export type GenericRankingList = TEventRankingList | TSeriesRankingList;

export type TEventResultsList = Array<TEventRankingList>
export type TEventDakineResultsList = Array<TEventDakineRankingList>
export type TSeriesResultsList = Array<TSeriesRankingList>
export type GenericResultsList = TEventResultsList | TEventDakineResultsList | TSeriesResultsList;

export type ResultsListOptions = Array<{ label: string; value: number; }>

export interface ResultsCount {
  [rankingId: number]: number;
}

// Live results
export enum LIVE_RESULTS_TYPES {
  OFFICIAL = 'OFFICIAL',
  CATEGORY = 'CATEGORY',
  GENDER = 'GENDER',
  SCRATCH = 'SCRATCH',
}
export enum LIVE_RESULTS_GROUPS {
  ALL = 'ALL',
  NATIONALITY = 'NATIONALITY',
  CATEGORY = 'CATEGORY',
  GENDER = 'GENDER',
}
export enum LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS {
  category = 'category',
  gender = 'gender',
  athlete = 'athlete',
  year = 'year',
  nationality = 'nationality',
  canton = 'canton',
  teamname = 'teamname',
  location = 'location',
}

export enum LIVE_RESULTS_STATUS {
  NO = 'NO', // the race does not support live results
  EMPTY = 'EMPTY', // the race has not happend yet (results to come in later)
  ONLINE = 'ONLINE', // the race is currently happening (results coming in live)
  OFFLINE = 'OFFLINE', // the race is over (results are final)
}

export interface LiveResultsContextFromApi {
  'fid_race': number;
  eventName: string;
  splits: Array<{
    id: number;
    name: string;
    distance: number;
  }>;
  parcours: Array<{
    name: string;
    distance: number;
  }>;
  rankings: Array<{
    id: number;
    name: string;
    isCategory: boolean;
    active: boolean;
  }>
}

export interface LiveResultsRankingFromApi{
  category: number;
  bib: number;
  teamname: string;
  chip: number;
  rank: string;
  raceTime: string | null;
  gap: string | null;
  avgSpeed: number | null;
  splitTimes: Array<string>;
  'fid_athlete': number;

  athlete: {
    lastName: string;
    firstName: string;
    legAddress: {
      locality: string;
      department: string;
    },
    nationality: string;
    birthDateObj: {
        year: number;
    },
    gender: string;
  };
}

export interface LiveResultsRankingsFromApi {
  ranking: string;
  total: number;
  results:{
    [groupId: string]: Array<LiveResultsRankingFromApi>
  };
  groups: {
    [groupId: string]: {
      total: number;
      best: {
        raceTime: string;
        splitTimes: Array<string>;
      };
    };
  };
}

export interface LiveResultsOption {
  label: string;
  wiclaxRankingId: number;
  categoryId: number;
  typeId: number;
}

export type LiveResultsContext = {
  options: {
    [RANKINGS_LIST_TYPE.SCRATCH]: {
      [rankingId: number]: LiveResultsOption;
    };
    [RANKINGS_LIST_TYPE.CATEGORIES]: {
      [rankingId: number]: LiveResultsOption;
    };
  }
  splits: Array<string>;
}

// ----------------------------------------------------------------------
// ------------------ SERIES ---------------------------------------------
// ----------------------------------------------------------------------

export interface Serie extends MsoIds {
  _id: string;
  _type: EVENT_LIST_ITEM_TYPES.serie,
  slug?: string;
  computeMode: SERIES_COMPUTE_MODE;
  serieResultsType: RANKINGS_LIST_TYPE;
  name: string;
  year: number;
  description?: string;
  isChampionship?: boolean;
  logo?: string;
  imageCover?: string;
  urlOrganiser?: string;
  serieDates?: {
    earliestEventDate?: string,
    latestEventDate?: string,
  };
  status?: {
    registration: REGISTRATION_STATUS;
    registrationFrom?: string;
    registrationTo?: string;
  };
  locations?: Array<string>;
  serieCategory?: SPORT_CATEGORIES;
  serieType?: string;
  previousSerieSlug?: string;
  previousSerieLabel?: string;
}
export type SeriesList = Array<Serie>

export interface Pack extends MsoIds {
  _id: string;
  description: string;
  maxChoice: number;
  reduction: number;
}

export type PackDetails = Array<{
  event: Event;
  categories: Array<Category>;
}>
