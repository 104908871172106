import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Form, Formik, FormikProps } from 'formik';
import { useRouter } from 'next/router';
import { setYupLocale } from '@services/translation/translatedYup';
import { countryList } from '@services/enum/countries';
import cantons from '@services/enum/cantons';
import i18nConfig from '@services/translation/i18n';
import GetFormik from '@features/ui-input/formik/GetFormik';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import MultiInputLine from '@features/ui-input/formik/MultiInputLine';
import InputSelect from '@features/ui-input/components/InputSelect';
import UserAddressFormSchema from '@features/users/components/UserAddressFormSchema';
import InputPhone from '@features/ui-input/components/InputPhone';

export interface UserAddressFormInputs {
  address: string,
  postalCode: string,
  canton: string,
  locality: string,
  country: string,
  language: string,
  club: string,
  phoneNumber: string,
}

interface UserAddressFormProps {
  initialValuesUserAddress: UserAddressFormInputs,
  setFormikContextProfile?: (context: FormikProps<UserAddressFormInputs>) => void
  handleSubmit?: () => void
}

const defaultCountry = 'SUI';

export const getCantonsOptions = (t) => cantons.map(canton => ({
  label: t(`cantons:canton_${canton.code}.message`),
  value: canton.code,
}));

export const getLanguagesOptions = (t) => i18nConfig.otherLanguages.map(lang => ({
  label: t(`${lang.toUpperCase()}.message`),
  value: lang,
}));

const UserAddressForm = ({
  initialValuesUserAddress,
  setFormikContextProfile,
  handleSubmit,
}
  : UserAddressFormProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  setYupLocale(router.locale);

  useEffect(() => {
    const firstInput = document.getElementById('mso_UserAddressForm_firstInput');
    if (firstInput) firstInput.focus();
  }, []);

  const initialValues : UserAddressFormInputs = initialValuesUserAddress ?? {
    address: '',
    postalCode: '',
    locality: '',
    canton: '',
    country: countryList(t).find(country => country.value === defaultCountry).value,
    language: router.locale,
    club: '',
    phoneNumber: '',
  };

  const cantonsOptions = getCantonsOptions(t);
  const languagesOptions = getLanguagesOptions(t);
  const validationSchema = UserAddressFormSchema(t);

  return (
    <UserAddressContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <GetFormik getFormikContext={setFormikContextProfile} />
            <InputLine
              name="address"
              label={t('userAddressForm_addressLabel.message')}
              as={InputText}
              showRequiredChip
              tooltipSpace
              labelMinWidth="194px"
              id="mso_UserAddressForm_firstInput"
            />
            <MultiInputLine
              label={t('userAddressForm_postalAndLocalityLabel.message')}
              labelMinWidth="194px"
              tooltipSpace
              spaceInterInput="4px"
              inputs={[
                {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'postalCode',
                }, {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'locality',
                  inputWrapperClassName: 'inputLocality',
                },
              ]}
            />
            {values.country === 'SUI' && (
              <InputLine
                label={t('userAddressForm_cantonLabel.message')}
                as={InputSelect}
                name="canton"
                options={cantonsOptions}
                labelMinWidth="194px"
                tooltipSpace
              />
            )}

            <InputLine
              label={t('userAddressForm_countryLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="country"
              options={countryList(t)}
              labelMinWidth="194px"
              tooltipSpace
            />
            <InputLine
              label={t('userAddressForm_languageLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="language"
              options={languagesOptions}
              labelMinWidth="194px"
              tooltipSpace
            />
            <InputLine
              name="club"
              label={t('userAddressForm_clubLabel.message')}
              as={InputText}
              tooltip={t('userAddressForm_clubTooltip.message')}
              tooltipSpace
              labelMinWidth="194px"
            />
            <InputLine
              name="phoneNumber"
              label={t('userAddressForm_phoneNumberLabel.message')}
              tooltip={t('userAddressForm_phoneNumberTooltip.message')}
              as={InputPhone}
              tooltipSpace
              labelMinWidth="194px"
            />
          </Form>
        )}
      </Formik>
    </UserAddressContainer>
  );
};

export default UserAddressForm;

const UserAddressContainer = styled.div`
  .inputLocality {
    flex: 2;
  }
`;
