import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectEventCategoryServicesGroupsIds } from '@features/eventDetail/slices/eventDetailServerSlice';
import GetFormik from '@features/ui-input/formik/GetFormik';
import { ServicesListTypeServiceSubscription } from '@features/subscriptions/components/ServicesList/ServicesListTypeServiceOrder';

const ServicesGroupFormUpdateAccount = ({
  defaultValues,
  schemaValidator,
  onSubmit,
  getFormikContext,
  eventId,
}) => {
  const servicesGroupsIds = useSelector(selectEventCategoryServicesGroupsIds(eventId));
  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={schemaValidator}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <StyledForm>
        <GetFormik getFormikContext={getFormikContext} />
        {servicesGroupsIds.map(serviceGroupId => (
          <ServicesListTypeServiceSubscription
            key={serviceGroupId}
            serviceGroupId={serviceGroupId}
            eventId={eventId}
          />
        ))}
      </StyledForm>
    </Formik>
  );
};

export default ServicesGroupFormUpdateAccount;

const StyledForm = styled(Form)`
  > *:not(:last-child) {
    margin-bottom: 40px;
  }
`;
