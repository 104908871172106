const ModalExample = ({ closeModal }) => (
  <>
    <div>
      asdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdj
      khsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjdasdjkhsakjd
      <button type="button" onClick={closeModal}>CLOSE THE MODAL</button>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      {' '}
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>
      <div>asdda</div>

    </div>
    <div style={{
      height: '50px',
      position: 'sticky',
      left: 0,
      width: '100%',
      bottom: 0,
      background: 'red',
    }}
    >
      FOOTEr
    </div>
  </>
);
export default ModalExample;
