import { GetServerSidePropsContext } from 'next';
import config from '@root/src/config';
import {
  ApiContactFormRequestBody,
  ApiCreateUpdateMobileNumber,
  ApiRegisterUserBody,
  ApiUpdatePassword,
  ApiUpdateProfile,
  PAYMENT_METHOD,
  Preferences,
  ENGAGED_LIST_SEARCH_FILTER_KEYS,
  RESULTS_LIST_SEARCH_FILTER_KEYS,
  LIVE_RESULTS_TYPES,
  LIVE_RESULTS_GROUPS,
  LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS,
} from '@root/src/types';
import { callWrapper, REQUEST_METHOD } from '@services/api/fetch';
import { UserSearch } from '@features/users/components/UserVerification';
import { AccountActivation } from '@features/users/components/UserActivationForm';
import { ResetPasswordBody } from '@features/users/components/ResetPasswordForm';
import { MobileNumberValidationFormInputs } from '@features/account/components/MobileNumberValidationForm';
import { DEFAULT_PRE_SERVICES_INPUT } from '@features/carts/components/OrderItemResume/DefaultPreServiceUpdateForm';

interface ApiParameters {
  ctx?: GetServerSidePropsContext;
  locale?: string;
  body?: any;
}

export interface SortedParamType {
  id: string;
  desc: boolean;
}

export interface FilteredParamType {
  id: string;
  value: any;
}

export interface listParameters {
  pageSize?: number;
  page?: number;
  sorted?: SortedParamType[];
  filtered?: FilteredParamType[];
}

export enum EVENT_LIST_MODELS {
  baseEvent = 'baseEvent',
  baseRace = 'baseRace',
}

export type ApiType<T> = (parameters: T) => Promise<Response>; // here T is usually `AipParameters & {}`

export interface EventsListApiParameters extends ApiParameters, listParameters {
  q?: string,
  filteredRange?: Array<{ id: string, valueGte?: string | number, valueLte?: string | number, type: string }>
  models?: Array<EVENT_LIST_MODELS>;
}

export type ListParameters = ApiParameters & listParameters;

interface ApiInterface {
  version: ApiType<ApiParameters>
  auth: {
    version: ApiType<ApiParameters>
    login: ApiType<ApiParameters & { body: { login: string; password: string } }>
    logout: ApiType<ApiParameters>
    search: ApiType<ApiParameters & { query: UserSearch }>
    register: ApiType<ApiParameters & { body: ApiRegisterUserBody }>
    create: ApiType<ApiParameters & { body: ApiRegisterUserBody }>,
    activate: ApiType<ApiParameters & { body: AccountActivation }>,
    password: {
      reset: ApiType<ApiParameters & { body: ResetPasswordBody }>,
      requestReset: ApiType<ApiParameters & { body: { login: string } }>,
      update: ApiType<ApiParameters & { body: ApiUpdatePassword }>,
    }
    impersonate: ApiType<ApiParameters & { token: string }>
  },

  series: {
    read: ApiType<ApiParameters & { serieId: number }>
    packs: {
      list: ApiType<ApiParameters & { serieId: number }>
      read: ApiType<ApiParameters & { serieId: number, packId: number, athleteId?: number; }>
    }
  }

  categories: {
    read: ApiType<ApiParameters & { categoryId: number }>
  }

  events: {
    version: ApiType<ApiParameters>
    list: ApiType<EventsListApiParameters>
    read: ApiType<ApiParameters & { eventId: number }>
    quotas: {
      read: ApiType<ApiParameters & { eventId: number; }>
    }
    categories: {
      read: ApiType<ApiParameters & { eventId: number; athleteId?: number; }>
      services: {
        read: ApiType<ApiParameters & { eventId: number; categoryId: number; }>
      }
      quotas: {
        read: ApiType<ApiParameters & { eventId: number; categoryId: number; }>
      }
      engaged: {
        list: ApiType<ApiParameters & { categoryId: number; }>
        download: ApiType<ApiParameters & { categoryId: number; }>
      }
    }
  }

  sites: {
    list: ApiType<ApiParameters>
  }

  sports: {
    list: ApiType<ApiParameters>
  }

  results: {
    version: ApiType<ApiParameters>
    event: {
      rankings: {
        list: ApiType<ApiParameters & { eventId: number; }>
        read: ApiType<ApiParameters & listParameters & {
          eventId: number;
          importId: number;
          filterBy?: { [key in RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        download: ApiType<ApiParameters & { eventId: number; importId: number; }>
      }
      categories: {
        list: ApiType<ApiParameters & {
          eventId: number;
          filterBy?: { [key in RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        all: ApiType<ApiParameters & listParameters & {
          eventId: number;
          categoryId?: number;
          filterBy?: { [key in RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        download: ApiType<ApiParameters & { eventId: number; categoryId: number; }>
      }
      scratch: {
        list: ApiType<ApiParameters & {
          eventId: number;
          filterBy?: { [key in RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        read: ApiType<ApiParameters & listParameters & {
          eventId: number;
          scratchId: number;
          filterBy?: { [key in RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        download: ApiType<ApiParameters & { eventId: number; scratchId: number; }>
      }
    }
    series: {
      list: ApiType<ApiParameters & { serieId: number; }>
      categories: {
        list: ApiType<ApiParameters & { serieId: number; serieResultsId: string; }>
        read: ApiType<ApiParameters & { serieId: number; serieResultsId: string; categoryId: string; }>
        download: ApiType<ApiParameters & { serieId: number; serieResultsId: string; categoryId: string; }>
      }
      scratch: {
        list: ApiType<ApiParameters & { serieId: number; serieResultsId: string; }>
        download: ApiType<ApiParameters & { serieId: number; serieResultsId: string; }>
      }
      dakine: {
        list: ApiType<ApiParameters & { serieId: number; serieResultsId: string; }>
        download: ApiType<ApiParameters & { serieId: number; serieResultsId: string; }>
      }
    },
    live: {
      events: {
        list: ApiType<ApiParameters & {
          eventId: number;
          filterBy?: { [key in LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        rankings: {
          list: ApiType<ApiParameters & listParameters & {
            eventId: number;
            rankingId: number;
            type?: LIVE_RESULTS_TYPES;
            group?: LIVE_RESULTS_GROUPS;
            filterBy?: { [key in LIVE_RESULTS_LIST_SEARCH_FILTER_KEYS]?: string }
          }>
        }
      }
    }
    engaged: {
      categories: {
        download: ApiType<ApiParameters & { categoryId: number }>
      },
      events: {
        list: ApiType<ApiParameters & listParameters & {
          eventId: number,
          categoryId?: number,
          filterBy?: { [key in ENGAGED_LIST_SEARCH_FILTER_KEYS]?: string }
        }>
        count: ApiType<ApiParameters & { eventId: number }>
        cantons: ApiType<ApiParameters & { eventId: number }>
        nationalities: ApiType<ApiParameters & { eventId: number }>
      }
    }
  }

  subscription: {
    version: ApiType<ApiParameters>
    registrations: {
      list: ApiType<ApiParameters & listParameters>
      read: ApiType<ApiParameters & { subscriptionId: string }>
      update: ApiType<ApiParameters & {
        registrationID: string
        body: { [key in DEFAULT_PRE_SERVICES_INPUT]?: string }
      }>
      diplomaPdf: ApiType<ApiParameters & { subscriptionId: string }>
      withdrawalVoucher: ApiType<ApiParameters & { registrationID: string }>
      services: {
        read: ApiType<ApiParameters & { subscriptionId: string }>
        update: ApiType<ApiParameters & {
          subscriptionId: string, body: {
            updates: Array<{ group: string, service: string; value: any, teamMember ?: string }>
          }
        }>
      }
    }
    reductions: {
      read: ApiType<ApiParameters & { eventId: string }>,
      create: ApiType<ApiParameters & { orderItemID: string, body: { code: string } }>
      delete: ApiType<ApiParameters & { orderItemID: string }>
    }
    carts: {
      list: ApiType<ApiParameters & { pageSize?: number }>
      current: ApiType<ApiParameters>
      read: ApiType<ApiParameters & { orderId: number }>
      create: ApiType<ApiParameters>
      update: ApiType<ApiParameters & { orderId: string }>
      delete: ApiType<ApiParameters & { orderId: string }>
      packs: {
        create: ApiType<ApiParameters>
        delete: ApiType<ApiParameters & { packId: string }>
      }
      payment: {
        paymentMethod: ApiType<ApiParameters & { paymentMethod: PAYMENT_METHOD }>
        init: ApiType<ApiParameters & { returnUrl: string }>
        pay: ApiType<ApiParameters>
        updateDatatrans: ApiType<ApiParameters & { body: any }>
        cancel: ApiType<ApiParameters>
      }
    }
    paymentMethods: {
      read: ApiType<ApiParameters>
    }
    orders: {
      receipt: ApiType<ApiParameters & { orderId: string }>
      list: ApiType<ApiParameters & { pageSize?: number }>
      read: ApiType<ApiParameters & { orderId: number }>
    },

    vips: {
      checkCode: ApiType<ApiParameters & {
        eventId: number
        categoryId?: number
        vipCode: string
      }>
    }
  }

  users: {
    read: ApiType<ApiParameters & { athleteId: number | string }>,
    update: ApiType<ApiParameters & { athleteId: number }>
    contact: {
      sendForm: ApiType<ApiParameters & { body: ApiContactFormRequestBody }>
    }
  },
  profile: {
    read: ApiType<ApiParameters>,
    update: ApiType<ApiParameters & { body: ApiUpdateProfile }>,
    athletes: ApiType<ApiParameters & listParameters>,
    access: {
      list: ApiType<ApiParameters>,
      create: ApiType<ApiParameters>,
      update: ApiType<ApiParameters & { accessId: string, body: { level: number } }>,
      delete: ApiType<ApiParameters & { accessId: string }>
    },
    mobileNumbers: {
      list: ApiType<ApiParameters>,
      create: ApiType<ApiParameters & { body: ApiCreateUpdateMobileNumber }>,
      update: ApiType<ApiParameters & { mobileNumberID: string, body: ApiCreateUpdateMobileNumber }>,
      delete: ApiType<ApiParameters & { mobileNumberID: string }>
      validate: ApiType<ApiParameters & { mobileNumberID: string, body: MobileNumberValidationFormInputs }>
      requestValidation: ApiType<ApiParameters & { mobileNumberID: string }>
    },
    preferences: {
      get: ApiType<ApiParameters>,
      update: ApiType<ApiParameters & { body: Preferences }>,
    }
  }
}

const api: ApiInterface = {
  version: ({ ctx }) => callWrapper({
    endpoint: '/app/version',
    method: REQUEST_METHOD.GET,
    ctx,
  }),
  auth: {
    version: () => callWrapper({
      endpoint: '/users/status/version',
      method: REQUEST_METHOD.GET,
    }),
    search: ({
      ctx,
      query,
    }) => callWrapper({
      endpoint: '/users/athletes/search',
      method: REQUEST_METHOD.GET,
      query,
      ctx,
    }),
    login: ({
      ctx,
      body,
    }) => callWrapper({
      endpoint: '/users/auth',
      method: REQUEST_METHOD.POST,
      body,
      ctx,
    }),
    logout: ({ ctx }) => callWrapper({
      endpoint: '/auth/logout/',
      method: REQUEST_METHOD.POST,
      ctx,
    }),
    register: ({
      ctx,
      body,
    }) => callWrapper({
      endpoint: '/users/athletes/me',
      method: REQUEST_METHOD.POST,
      body,
      ctx,
    }),
    create: ({
      ctx,
      body,
    }) => callWrapper({
      endpoint: '/users/athletes',
      method: REQUEST_METHOD.POST,
      body,
      ctx,
    }),
    password: {
      update: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me',
        method: REQUEST_METHOD.PUT,
        body,
        ctx,
      }),
      requestReset: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/reset-password-request',
        method: REQUEST_METHOD.POST,
        body,
        ctx,
      }),
      reset: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/reset-password',
        method: REQUEST_METHOD.POST,
        body,
        ctx,
      }),
    },
    activate: ({
      ctx,
      body,
    }) => callWrapper({
      endpoint: '/users/athletes/reset-password',
      method: REQUEST_METHOD.POST,
      body,
      ctx,
    }),
    impersonate: ({
      ctx,
      token,
    }) => callWrapper({
      endpoint: '/users/auth/impersonation',
      method: REQUEST_METHOD.POST,
      ctx,
      token,
    }),
  },

  series: {
    read: ({
      ctx,
      locale,
      serieId,
    }) => callWrapper({
      endpoint: '/races/series/:serieId',
      method: REQUEST_METHOD.GET,
      params: { serieId: `mso:${serieId}` },
      query: { lang: locale },
      ctx,
    }),
    packs: {
      list: ({
        ctx,
        locale,
        serieId,
      }) => callWrapper({
        endpoint: '/races/series/:serieId/packs',
        method: REQUEST_METHOD.GET,
        params: { serieId: `mso:${serieId}` },
        query: { lang: locale },
        ctx,
      }),
      read: ({
        ctx,
        locale,
        serieId,
        packId,
        athleteId,
      }) => callWrapper({
        endpoint: '/races/series/:serieId/packs/:packId',
        method: REQUEST_METHOD.GET,
        params: {
          serieId: `mso:${serieId}`,
          packId: `mso:${packId}`,
        },
        query: {
          lang: locale,
          ...(athleteId && { athlete: `mso:${athleteId}` }),
        },
        ctx,
      }),
    },
  },

  categories: {
    read: ({
      ctx,
      locale,
      categoryId,
    }) => callWrapper({
      endpoint: '/races/categories/:categoryId',
      method: REQUEST_METHOD.GET,
      params: { categoryId: `mso:${categoryId}` },
      query: { lang: locale },
      ctx,
    }),
  },

  events: {
    version: () => callWrapper({
      endpoint: '/races/status/version',
      method: REQUEST_METHOD.GET,
    }),
    list: ({
      ctx,
      locale,
      q,
      pageSize = config.lists.defaultPageSize,
      page = 0,
      sorted = [
        {
          id: 'date',
          desc: true,
        },
      ],
      filteredRange,
      filtered,
      models,
    }) => callWrapper({
      endpoint: '/races/events',
      method: REQUEST_METHOD.GET,
      ctx,
      query: {
        lang: locale,
        pageSize,
        q,
        page,
        sorted,
        filteredRange,
        filtered,
        models: models.join(','),
      },
    }),
    read: ({
      ctx,
      locale,
      eventId,
    }) => callWrapper({
      endpoint: '/races/events/:eventId',
      method: REQUEST_METHOD.GET,
      params: { eventId: `mso:${eventId}` },
      query: { lang: locale },
      ctx,
    }),

    quotas: {
      read: ({
        ctx,
        locale,
        eventId,
      }) => callWrapper({
        endpoint: '/subscriptions/quotas/events/:eventId',
        method: REQUEST_METHOD.GET,
        params: { eventId: `mso:${eventId}` },
        query: { lang: locale },
        ctx,
      }),
    },
    categories: {
      read: ({
        ctx,
        locale,
        eventId,
        athleteId,
      }) => callWrapper({
        endpoint: '/races/events/:eventId/categories',
        method: REQUEST_METHOD.GET,
        params: { eventId: `mso:${eventId}` },
        query: {
          lang: locale,
          ...(athleteId ? { athlete: `mso:${athleteId}` } : {}),
        },
        ctx,
      }),

      engaged: {
        list: ({
          ctx,
          locale,
          categoryId,
        }) => callWrapper({
          endpoint: '/subscriptions/registrations/engaged/categories/:categoryId',
          method: REQUEST_METHOD.GET,
          ctx,
          query: { lang: locale },
          params: { categoryId: `mso:${categoryId}` },
        }),
        download: ({
          ctx,
          locale,
          categoryId,
        }) => callWrapper({
          endpoint: '/subscriptions/registrations/engaged/categories/:categoryId/export',
          method: REQUEST_METHOD.GET,
          ctx,
          query: { lang: locale },
          params: { categoryId: `mso:${categoryId}` },
        }),
      },
      quotas: {
        read: ({
          ctx,
          locale,
          eventId,
          categoryId,
        }) => callWrapper({
          endpoint: '/subscriptions/quotas/events/:eventId/categories/:categoryId',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            categoryId: `mso:${categoryId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
      services: {
        read: ({
          ctx,
          locale,
          eventId,
          categoryId,
        }) => callWrapper({
          endpoint: '/races/events/:eventId/categories/:categoryId/services',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            categoryId: `mso:${categoryId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
    },
  },
  sites: {
    list: ({
      ctx,
      locale,
    }) => callWrapper({
      endpoint: '/races/sites',
      method: REQUEST_METHOD.GET,
      query: { lang: locale },
      ctx,
    }),
  },
  sports: {
    list: ({
      ctx,
      locale,
    }) => callWrapper({
      endpoint: '/races/sports',
      method: REQUEST_METHOD.GET,
      query: { lang: locale },
      ctx,
    }),
  },
  results: {
    version: () => callWrapper({
      endpoint: '/results/status/version',
      method: REQUEST_METHOD.GET,
    }),
    event: {
      rankings: {
        list: ({
          ctx,
          locale,
          eventId,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/rankings',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: { lang: locale },
          ctx,
        }),
        read: ({
          ctx,
          locale,
          eventId,
          importId,
          pageSize = config.lists.defaultPageSize,
          page = config.lists.defaultPage,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/rankings/:importId',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            importId: `mso:${importId}`,
          },
          query: {
            lang: locale,
            pageSize,
            page,
          },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          eventId,
          importId,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/rankings/:importId/export',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            importId: `mso:${importId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
      categories: {
        list: ({
          ctx,
          locale,
          eventId,
          filterBy,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/categories',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: {
            lang: locale,
            numberOfResults: config.lists.numberOfResultsCategories,
            ...filterBy,
          },
          ctx,
        }),
        all: ({
          ctx,
          locale,
          eventId,
          categoryId,
          pageSize = config.lists.defaultPageSize,
          page = config.lists.defaultPage,
          filterBy = {},
        }) => callWrapper({
          endpoint: '/results/events/:eventId/categories/all/list',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: {
            lang: locale,
            ...(categoryId && { category: categoryId }),
            pageSize,
            page,
            ...filterBy,
          },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          eventId,
          categoryId,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/categories/:categoryId/export',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            categoryId: `mso:${categoryId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
      scratch: {
        list: ({
          ctx,
          locale,
          eventId,
          filterBy = {},
        }) => callWrapper({
          endpoint: '/results/events/:eventId/scratch',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: {
            lang: locale,
            numberOfResults: config.lists.numberOfResultsScratch,
            ...filterBy,
          },
          ctx,
        }),
        read: ({
          ctx,
          locale,
          eventId,
          scratchId,
          pageSize = config.lists.defaultPageSize,
          page = config.lists.defaultPage,
          filterBy = {},
        }) => callWrapper({
          endpoint: '/results/events/:eventId/scratch/:scratchId',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            scratchId: `mso:${scratchId}`,
          },
          query: {
            lang: locale,
            pageSize,
            page,
            ...filterBy,
          },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          eventId,
          scratchId,
        }) => callWrapper({
          endpoint: '/results/events/:eventId/scratch/:scratchId/export',
          method: REQUEST_METHOD.GET,
          params: {
            eventId: `mso:${eventId}`,
            scratchId: `mso:${scratchId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
    },
    series: {
      list: ({
        ctx,
        locale,
        serieId,
      }) => callWrapper({
        endpoint: '/results/series/:serieId',
        method: REQUEST_METHOD.GET,
        params: { serieId: `mso:${serieId}` },
        query: { lang: locale },
        ctx,
      }),
      categories: {
        list: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/categories',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
          },
          query: {
            lang: locale,
            numberOfResults: config.lists.numberOfResultsCategories,
          },
          ctx,
        }),
        read: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
          categoryId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/categories/:categoryId',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
            categoryId,
          },
          query: { lang: locale },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
          categoryId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/categories/:categoryId/export',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
            categoryId,
          },
          query: { lang: locale },
          ctx,
        }),
      },
      scratch: {
        list: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/scratch',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
          },
          query: { lang: locale },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/scratch/export',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
      dakine: {
        list: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/dakine',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
          },
          query: { lang: locale },
          ctx,
        }),
        download: ({
          ctx,
          locale,
          serieId,
          serieResultsId,
        }) => callWrapper({
          endpoint: '/results/series/:serieId/results/:serieResultsId/dakine/export',
          method: REQUEST_METHOD.GET,
          params: {
            serieId: `mso:${serieId}`,
            serieResultsId: `mso:${serieResultsId}`,
          },
          query: { lang: locale },
          ctx,
        }),
      },
    },
    live: {
      events: {
        list: ({
          ctx,
          locale,
          eventId,
          filterBy = {},
        }) => callWrapper({
          endpoint: '/results/live/events/:eventId',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: {
            lang: locale,
            ...filterBy,
          },
          ctx,
        }),
        rankings: {
          list: ({
            ctx,
            locale,
            eventId,
            rankingId,
            pageSize = config.lists.defaultPageSize,
            page = config.lists.defaultPage,
            type,
            group,
            filterBy = {},
          }) => callWrapper({
            endpoint: '/results/live/events/:eventId/rankings/:rankingId',
            method: REQUEST_METHOD.GET,
            params: {
              eventId: `mso:${eventId}`,
              rankingId,
            },
            query: {
              lang: locale,
              pageSize,
              page,
              ...(type ? { type } : {}),
              ...(group ? { group } : {}),

              ...filterBy,
            },
            ctx,
          }),
        },
      },
    },
    engaged: {
      categories: {
        download: ({
          ctx,
          locale,
          categoryId,
        }) => callWrapper({
          endpoint: '/results/engaged/categories/:categoryId/export',
          method: REQUEST_METHOD.GET,
          params: { categoryId: `mso:${categoryId}` },
          query: { lang: locale },
          ctx,
        }),
      },
      events: {
        list: ({
          ctx,
          locale,
          eventId,
          pageSize = config.lists.defaultPageSize,
          page = config.lists.defaultPage,
          categoryId = null,
          filterBy = {},
        }) => callWrapper({
          endpoint: '/results/engaged/events/:eventId/list',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: {
            lang: locale,
            pageSize,
            page,
            category: categoryId,
            ...filterBy,
          },
          ctx,
        }),
        count: ({
          ctx,
          locale,
          eventId,
        }) => callWrapper({
          endpoint: '/results/engaged/events/:eventId/count',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: { lang: locale },
          ctx,
        }),
        cantons: ({
          ctx,
          locale,
          eventId,
        }) => callWrapper({
          endpoint: '/results/engaged/events/:eventId/thesauri/cantons',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: { lang: locale },
          ctx,
        }),
        nationalities: ({
          ctx,
          locale,
          eventId,
        }) => callWrapper({
          endpoint: '/results/engaged/events/:eventId/thesauri/nationalities',
          method: REQUEST_METHOD.GET,
          params: { eventId: `mso:${eventId}` },
          query: { lang: locale },
          ctx,
        }),
      },
    },
  },

  subscription: {
    version: () => callWrapper({
      endpoint: '/subscriptions/status/version',
      method: REQUEST_METHOD.GET,
    }),

    registrations: {
      list: ({
        ctx,
        locale,
        filtered,
        pageSize,
      }) => callWrapper({
        endpoint: '/subscriptions/registrations',
        method: REQUEST_METHOD.GET,
        query: {
          lang: locale,
          pageSize: pageSize ?? 50, // TODO MSO-910 add load more in the client part
          page: 0,
          sorted: [
            {
              id: 'registerin',
              desc: true,
            },
          ],
          filtered: [ // TODO: Put this data as parameters
            {
              id: 'orderStatusIn',
              value: ['PAID', 'VALIDATED'],
            }, {
              id: 'registrationStatusIn',
              value: ['REGISTERED', 'RENOUNCED'],
            }, ...(filtered ?? []),
          ],
        },
        ctx,
      }),
      read: ({
        ctx,
        locale,
        subscriptionId,
      }) => callWrapper({
        endpoint: '/subscriptions/registrations/:subscriptionId',
        method: REQUEST_METHOD.GET,
        params: { subscriptionId },
        query: { lang: locale },
        ctx,
      }),
      update: ({
        ctx,
        locale,
        registrationID,
        body,
      }) => callWrapper({
        endpoint: '/subscriptions/registrations/:registrationID',
        method: REQUEST_METHOD.PUT,
        params: { registrationID },
        query: { lang: locale },
        ctx,
        body,
      }),
      diplomaPdf: ({
        ctx,
        locale,
        subscriptionId,
      }) => callWrapper({
        endpoint: '/subscriptions/registrations/:subscriptionId/diploma',
        method: REQUEST_METHOD.GET,
        params: { subscriptionId },
        query: { lang: locale },
        ctx,
      }),
      withdrawalVoucher: ({
        ctx,
        locale,
        body,
        registrationID,
      }) => callWrapper({
        endpoint: '/subscriptions/registrations/:registrationID/withdrawal-voucher',
        method: REQUEST_METHOD.GET,
        params: { registrationID },
        body,
        query: { lang: locale },
        ctx,
      }),
      services: {
        read: ({
          ctx,
          locale,
          subscriptionId,
        }) => callWrapper({
          endpoint: '/subscriptions/registrations/:subscriptionId/services',
          method: REQUEST_METHOD.GET,
          params: { subscriptionId },
          query: { lang: locale },
          ctx,
        }),
        update: ({
          ctx,
          locale,
          body,
          subscriptionId,
        }) => callWrapper({
          endpoint: '/subscriptions/registrations/:subscriptionId/services',
          method: REQUEST_METHOD.PUT,
          params: { subscriptionId },
          body,
          query: { lang: locale },
          ctx,
        }),
      },
    },

    reductions: {
      read: ({
        ctx,
        locale,
        eventId,
      }) => callWrapper({
        endpoint: '/subscriptions/reductions/:eventId',
        method: REQUEST_METHOD.GET,
        params: { eventId },
        query: { lang: locale },
        ctx,
      }),
      create: ({
        ctx,
        locale,
        body,
        orderItemID,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current/registrations/:orderItemID/reduction',
        method: REQUEST_METHOD.POST,
        params: { orderItemID },
        query: { lang: locale },
        body,
        ctx,
      }),
      delete: ({
        ctx,
        locale,
        orderItemID,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current/registrations/:orderItemID/reduction',
        method: REQUEST_METHOD.DELETE,
        params: { orderItemID },
        query: { lang: locale },
        ctx,
      }),
    },

    carts: {
      list: ({
        ctx,
        locale,
        pageSize,
      }) => callWrapper({
        endpoint: '/subscriptions/carts',
        method: REQUEST_METHOD.GET,
        query: {
          lang: locale,
          pageSize: pageSize ?? 999,
          page: 0,
          sorted: [
            {
              id: 'updatedAt',
              desc: true,
            },
          ],
        },
        ctx,
      }),
      current: ({
        ctx,
        locale,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current',
        method: REQUEST_METHOD.GET,
        query: { lang: locale },
        ctx,
      }),
      create: ({
        ctx,
        locale,
        body,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current/registrations',
        method: REQUEST_METHOD.POST,
        body,
        query: { lang: locale },
        ctx,
      }),
      read: ({
        ctx,
        locale,
        orderId,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/:orderId',
        method: REQUEST_METHOD.GET,
        params: { orderId: `mso:${orderId}` },
        query: { lang: locale },
        ctx,
      }),
      update: ({
        ctx,
        locale,
        body,
        orderId,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current/registrations/:orderId',
        method: REQUEST_METHOD.PUT,
        params: { orderId },
        body,
        query: { lang: locale },
        ctx,
      }),
      delete: ({
        ctx,
        body,
        orderId,
      }) => callWrapper({
        endpoint: '/subscriptions/carts/current/registrations/:orderId',
        method: REQUEST_METHOD.DELETE,
        params: { orderId },
        body,
        ctx,
      }),
      packs: {
        create: ({
          ctx,
          locale,
          body,
        }) => callWrapper({
          endpoint: '/subscriptions/carts/current/packs',
          method: REQUEST_METHOD.POST,
          body,
          query: { lang: locale },
          ctx,
        }),
        delete: ({
          ctx,
          body,
          packId,
        }) => callWrapper({
          endpoint: '/subscriptions/carts/current/packs/:packId',
          method: REQUEST_METHOD.DELETE,
          params: { packId },
          body,
          ctx,
        }),
      },
      payment: {
        paymentMethod: ({
          ctx,
          paymentMethod,
        }) => callWrapper({
          endpoint: '/subscriptions/carts/current/paymentMethod',
          method: REQUEST_METHOD.PUT,
          body: { paymentMethod },
          ctx,
        }),
        init: ({
          ctx,
          returnUrl,
        }) => callWrapper({
          endpoint: '/subscriptions/carts/current/init',
          method: REQUEST_METHOD.POST,
          ctx,
          body: { returnUrl },
        }),
        pay: ({ ctx }) => callWrapper({
          endpoint: '/subscriptions/carts/current/pay',
          method: REQUEST_METHOD.POST,
          ctx,
        }),
        updateDatatrans: ({
          ctx,
          body,
        }) => callWrapper({
          endpoint: '/subscriptions/carts/current/datatrans',
          method: REQUEST_METHOD.PUT,
          body,
          ctx,
        }),
        cancel: ({ ctx }) => callWrapper({
          endpoint: '/subscriptions/carts/current/cancel',
          method: REQUEST_METHOD.POST,
          ctx,
        }),
      },
    },

    paymentMethods: {
      read: ({ ctx }) => callWrapper({
        endpoint: '/subscriptions/paymentMethods',
        method: REQUEST_METHOD.GET,
        ctx,
      }),
    },

    orders: {
      list: ({
        ctx,
        locale,
        pageSize,
      }) => callWrapper({
        endpoint: '/subscriptions/orders',
        method: REQUEST_METHOD.GET,
        query: {
          lang: locale,
          pageSize: pageSize ?? 999,
          page: 0,
          sorted: [
            {
              id: 'created',
              desc: true,
            },
          ],
        },
        ctx,
      }),
      read: ({
        ctx,
        locale,
        orderId,
      }) => callWrapper({
        endpoint: '/subscriptions/orders/:orderId',
        method: REQUEST_METHOD.GET,
        params: { orderId: `mso:${orderId}` },
        query: { lang: locale },
        ctx,
      }),
      receipt: ({
        ctx,
        locale,
        body,
        orderId,
      }) => callWrapper({
        endpoint: '/subscriptions/orders/:orderId/receipt/',
        method: REQUEST_METHOD.GET,
        params: { orderId },
        body,
        query: { lang: locale },
        ctx,
      }),
    },

    vips: {
      checkCode: ({
        ctx,
        eventId,
        categoryId,
        vipCode,
      }) => callWrapper({
        endpoint: '/subscriptions/vips',
        method: REQUEST_METHOD.POST,
        body: {
          event: `mso:${eventId}`,
          ...(categoryId ? { category: `mso:${categoryId}` } : {}),
          vipCode,
        },
        ctx,
      }),
    },

  },

  users: {
    read: ({
      ctx,
      athleteId,
    }) => callWrapper({
      endpoint: '/users/athletes/:athleteId',
      method: REQUEST_METHOD.GET,
      params: { athleteId: typeof athleteId === 'string' ? athleteId : `mso:${athleteId}` },
      ctx,
    }),
    update: ({
      ctx,
      athleteId,
      body,
    }) => callWrapper({
      endpoint: '/users/athletes/:athleteId',
      method: REQUEST_METHOD.PUT,
      body,
      params: { athleteId: `mso:${athleteId}` },
      ctx,
    }),
    contact: {
      sendForm: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/contact/send-form',
        method: REQUEST_METHOD.POST,
        body,
        ctx,
      }),
    },
  },
  profile: {
    read: ({ ctx }) => callWrapper({
      endpoint: '/users/athletes/me',
      method: REQUEST_METHOD.GET,
      ctx,
    }),
    update: ({
      ctx,
      body,
    }) => callWrapper({
      endpoint: '/users/athletes/me',
      method: REQUEST_METHOD.PUT,
      body,
      ctx,
    }),
    athletes: ({
      ctx,
      body,
      sorted = [
        // If you change this sort, update the sort too for "selectAccessesOnMyProfileIDsWithoutMe"
        // to match the same sort as backend
        {
          id: 'lastName',
          desc: false,
        }, {
          id: 'firstName',
          desc: false,
        },
      ],
    }) => callWrapper({
      endpoint: '/users/athletes',
      method: REQUEST_METHOD.GET,
      body,
      ctx,
      query: {
        pageSize: 999,
        page: 0,
        sorted,
      },
    }),
    access: {
      create: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me/access',
        method: REQUEST_METHOD.POST,
        body,
        ctx,
      }),
      list: ({ ctx }) => callWrapper({
        endpoint: '/users/athletes/me/access',
        method: REQUEST_METHOD.GET,
        ctx,
      }),
      update: ({
        ctx,
        body,
        accessId,
      }) => callWrapper({
        endpoint: '/users/athletes/me/access/:accessId',
        method: REQUEST_METHOD.PUT,
        body,
        params: { accessId },
        ctx,
      }),
      delete: ({
        ctx,
        accessId,
      }) => callWrapper({
        endpoint: '/users/athletes/me/access/:accessId',
        method: REQUEST_METHOD.DELETE,
        params: { accessId },
        ctx,
      }),
    },
    mobileNumbers: {
      list: ({ ctx }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers',
        method: REQUEST_METHOD.GET,
        ctx,
      }),
      create: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers',
        method: REQUEST_METHOD.POST,
        body,
        ctx,
      }),
      update: ({
        ctx,
        mobileNumberID,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers/:mobileNumberID',
        method: REQUEST_METHOD.PUT,
        ctx,
        body,
        params: { mobileNumberID },
      }),
      delete: ({
        ctx,
        mobileNumberID,
      }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers/:mobileNumberID',
        method: REQUEST_METHOD.DELETE,
        ctx,
        params: { mobileNumberID },
      }),
      validate: ({
        ctx,
        mobileNumberID,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers/:mobileNumberID/validate',
        method: REQUEST_METHOD.POST,
        ctx,
        body,
        params: { mobileNumberID },
      }),
      requestValidation: ({
        ctx,
        mobileNumberID,
      }) => callWrapper({
        endpoint: '/users/athletes/me/mobinumbers/:mobileNumberID/request-validation-code',
        method: REQUEST_METHOD.POST,
        ctx,
        params: { mobileNumberID },
      }),
    },
    preferences: {
      get: ({ ctx }) => callWrapper({
        endpoint: '/users/athletes/me/newsletter',
        method: REQUEST_METHOD.GET,
        ctx,
      }),
      update: ({
        ctx,
        body,
      }) => callWrapper({
        endpoint: '/users/athletes/me/newsletter',
        method: REQUEST_METHOD.PUT,
        body,
        ctx,
      }),
    },
  },
};
export default api;
