import React, { useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormikContextType } from 'formik';
import { toast } from 'react-toastify';
import api from '@services/api';
import { useRequestHandler } from '@services/api/handlers';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { selectAccessOnMyFriendsByID } from '@features/users/slices/userSlice';
import ProfileForm, { ProfileFormInputs } from '@features/account/components/ProfileForm';
import { textH1, textP, textPBold } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { profileFromAccountFormToApi } from '@features/users/formatters';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

interface ModalAccountEditFriendTemplateProps {
  closeModal: () => void;
  athleteId: string;
}

const ModalAccountEditFriendTemplate = ({
  closeModal,
  athleteId,
}: ModalAccountEditFriendTemplateProps) => {
  const { t } = useTranslation();
  const [formikContext, setFormikContext] = useState<FormikContextType<ProfileFormInputs>>(null);
  const requestHandler = useRequestHandler();
  const athlete = useSelector(selectAccessOnMyFriendsByID(athleteId));

  const initialValues: ProfileFormInputs = {
    id: athlete._idMso,
    firstName: athlete.firstName,
    lastName: athlete.lastName,
    address: athlete.legAddress.address,
    email: athlete.email.email,
    birthdateDay: athlete.birthDateObj.day,
    birthdateMonth: athlete.birthDateObj.month,
    birthdateYear: athlete.birthDateObj.year,
    club: athlete.club ?? '',
    country: athlete.legAddress.country,
    canton: athlete.legAddress.department ?? '',
    gender: athlete.gender,
    language: athlete.language,
    licenseNumber: athlete.licence ?? '',
    nationality: athlete.nationality ?? '',
    postalCode: athlete.legAddress.postalCode,
    locality: athlete.legAddress.locality,
  };

  const onSubmit = async () => {
    const { response } = await requestHandler({
      request: api.users.update(
        {
          athleteId: formikContext.values.id,
          body: profileFromAccountFormToApi(formikContext.values),
        },
      ),
    });
    if (response && response?.ok) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('profileForm_updateSuccess.message')}
        text=""
      />);
      closeModal();
    }
  };

  const ModalFooter = ({ closeModal }) => (
    <Footer>
      <Button
        text={t('modalAccountEditFriendTemplate_footerCancel.message')}
        leftIcon={ICON_NAME.arrowBack}
        variant={BUTTON_VARIANT.secondary}
        onClick={closeModal}
      />
      <Button
        text={t('modalAccountEditFriendTemplate_footerUpdate.message')}
        onClick={formikContext?.submitForm}
        leftIcon={ICON_NAME.check}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<ModalFooter closeModal={closeModal} />} closeModal={closeModal}>
      <Wrapper>
        <Title>{t('modalAccountEditFriendTemplate_title.message')}</Title>
        <Trans i18nKey="modalAccountEditFriendTemplate_description.message">
          <DescriptionBold />
          <Description />
          <Description />
          <Description />
        </Trans>
        <FormWrapper>
          <ProfileForm
            initialValues={initialValues}
            getFormikContext={setFormikContext}
            onSubmit={onSubmit}
            withSubmitOnForm={false}
          />
        </FormWrapper>
      </Wrapper>
    </ModalTemplate>
  );
};

export default ModalAccountEditFriendTemplate;

const Title = styled.h1`
  ${textH1}
  margin-bottom: 32px;
`;

const Wrapper = styled.div`
  max-width: 682px;
  margin-bottom: 40px;
`;

const DescriptionBold = styled.p`
  ${textPBold};
  margin-bottom: 4px;
`;

const Description = styled.p`
  ${textP};
`;

const FormWrapper = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
