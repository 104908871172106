import { UrlObject } from 'url';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { get, set } from 'lodash';
import { NAVIGATION_PROP } from '@root/src/hooks/useNavigationHistory';
import { RootState } from '@services/store';
import { RESPONSIVE } from '@services/responsiveProvider';
import { MODAL_NAME } from '@features/ui-layout/templates/ModalContainer';

export enum REGISTRATION_TYPE {
  SERIE = 'SERIE',
  EVENT = 'EVENT',
}

export interface UiLayoutSliceState {
  responsive: RESPONSIVE;
  withLogin: boolean;
  lastModalOpen?: MODAL_NAME;
  navigationHistory?: {
    [key in NAVIGATION_PROP]: UrlObject;
  };
}

export const uiLayoutSlice = createSlice({
  name: 'uiLayout',
  initialState: {
    responsive: null,
    withLogin: true,
    lastModalOpen: null,
    navigationHistory: null,
  },
  extraReducers: {
    // Hybrid state: This is required to prevent "withLogin" to be overridden
    [HYDRATE]: (state, action) => {
      const newState = {
        ...state,
        withLogin: action.payload.uiLayout.withLogin,
      };
      if (state.responsive == null) {
        newState.responsive = action.payload.uiLayout.responsive;
      }
      return newState;
    },
  },
  reducers: {
    setResponsive: (state, action) => {
      state.responsive = action.payload;
    },
    setWithLogin: (state, action) => {
      state.withLogin = action.payload;
    },
    setLastModalOpen: (state, action: PayloadAction<{ modalName: MODAL_NAME }>) => {
      state.lastModalOpen = action.payload.modalName;
    },
    setNavigationHistoryProp: (
      state: UiLayoutSliceState,
      action: PayloadAction<{navigationProp: NAVIGATION_PROP, urlObject: UrlObject}>,
    ) => {
      set(state, `navigationHistory.${action.payload.navigationProp}`, action.payload.urlObject);
    },
  },
});

export const {
  setResponsive,
  setWithLogin,
  setLastModalOpen,
  setNavigationHistoryProp,
} = uiLayoutSlice.actions;
export default uiLayoutSlice.reducer;

// Selectors
export const selectResponsive = (state: RootState) => state.uiLayout.responsive;
export const selectWithLogin = (state: RootState) => state.uiLayout.withLogin;
export const selectLastModalOpen = (state: RootState) => state.uiLayout.lastModalOpen;

export const selectNavigationHistory = (state: RootState) => state.uiLayout.navigationHistory;
export const selectNavigationHistoryUrl = (navigationProp: NAVIGATION_PROP) => createSelector(
  selectNavigationHistory,
  navigationHistory => get(navigationHistory, navigationProp)
);
