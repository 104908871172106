import { omit, size } from 'lodash';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category, DEFAULT_PRE_SERVICES, Pack, Profile, ProfileVerification, Event } from '@root/src/types';
import { RootState } from '@services/store';

export interface ServicesValues {
  [groupId: string]: { [serviceId: string]: boolean | string | number }
}

export interface DefaultServicesValues {
  club ?: string,
  insurance?: boolean,
  vip?: string,
  emergencyNumber?: string,
  license?:string,
}

export interface ServicesValuesByTeamMate {
    profile?: Profile;
    servicesValues: ServicesValues;
}

interface SubscriptionDetails {
  event: Event;
  category: Category;
  preServices?: ServicesValues,
  defaultPreServices?: DefaultServicesValues;
  services: Array<ServicesValuesByTeamMate>;
}

export interface FormSubscriptionSliceState {
  subscriptionFor?: Profile;
  subscriptionPack?: Pack;
  subscriptionsDetails: {
    [categoryId: number]: SubscriptionDetails
  };
  completedCategories: Array<number>;
}
export const fromSubscriptionSlice = createSlice({
  name: 'formSubscriptionSlice',
  initialState: {
    subscriptionFor: null,
    subscriptionPack: null,
    subscriptionsDetails: {},
    completedCategories: [],
  },
  reducers: {
    setSubscriptionFor: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ profile: Profile }>
    ) => {
      state.subscriptionFor = action.payload.profile;
    },
    setSubscriptionPack: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ pack: Pack }>
    ) => {
      state.subscriptionPack = action.payload.pack;
    },
    setSubscription: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ category: Category, event: Event }>
    ) => {
      const categoryId = action.payload.category._idMso;
      state.subscriptionsDetails[categoryId] = ({
        category: action.payload.category,
        event: action.payload.event,
        preServices: null,
        defaultPreServices: null,
        services: [],
      });
    },
    toggleSubscription: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ category: Category, event: Event }>
    ) => {
      const categoryId = action.payload.category._idMso;

      if (state.subscriptionsDetails[categoryId]) {
        state.subscriptionsDetails = omit(state.subscriptionsDetails, categoryId);
      } else {
        state.subscriptionsDetails[categoryId] = ({
          category: action.payload.category,
          event: action.payload.event,
          preServices: null,
          defaultPreServices: null,
          services: [],
        });
      }
    },
    setServicesProfile: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ categoryId: number, profile: ProfileVerification, index: number }>
    ) => {
      const { categoryId, index, profile } = action.payload;

      const subscription = state.subscriptionsDetails[categoryId];

      if (subscription.services[index]) {
        subscription.services[index].profile = profile as Profile;
      } else {
        subscription.services[index] = {
          profile: profile as Profile,
          servicesValues: null,
        };
      }
    },
    setServicesForTeamMate: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ categoryId: number, values: ServicesValues, profile: Profile, index: number }>
    ) => {
      const { categoryId, index, profile, values } = action.payload;

      const subscription = state.subscriptionsDetails[categoryId];

      subscription.services[index] = {
        profile,
        servicesValues: values,
      };
    },
    setSubscriptionDefaultPreServices: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ categoryId: number, defaultPreServices: DefaultServicesValues }>
    ) => {
      const subscription = state.subscriptionsDetails[action.payload.categoryId];

      subscription.defaultPreServices = action.payload.defaultPreServices;
    },
    setSubscriptionPreServices: (
      state: FormSubscriptionSliceState,
      action: PayloadAction<{ preServicesValues: ServicesValues, categoryId: number }>
    ) => {
      // separate dynamic from default
      const entries = Object.entries(action.payload.preServicesValues);

      const dynamicFields = Object.fromEntries(
        entries
          .filter(([key]) => !Object.values(DEFAULT_PRE_SERVICES).includes(key as DEFAULT_PRE_SERVICES))
      );

      const defaultFields = Object.fromEntries(
        entries.filter(([key]) => Object.values(DEFAULT_PRE_SERVICES).includes(key as DEFAULT_PRE_SERVICES))
      );

      const subscription = state.subscriptionsDetails[action.payload.categoryId];

      subscription.defaultPreServices = defaultFields;
      subscription.preServices = dynamicFields;
    },
    clearFormSubscriptions: (state: FormSubscriptionSliceState) => {
      state.subscriptionsDetails = {};
      state.completedCategories = [];
    },
    pushCompletedCat: (
      state:FormSubscriptionSliceState,
      action: PayloadAction<{ categoryId: number }>
    ) => {
      state.completedCategories.push(action.payload.categoryId);
    },
  },
});

export default fromSubscriptionSlice.reducer;

export const {
  setSubscriptionFor,
  setSubscriptionPack,
  setSubscription,
  toggleSubscription,
  setServicesProfile,
  setServicesForTeamMate,
  setSubscriptionDefaultPreServices,
  setSubscriptionPreServices,
  clearFormSubscriptions,
  pushCompletedCat,
} = fromSubscriptionSlice.actions;

// Athlete
export const selectSubscriptionFor = (state: RootState) => state.formSubscription.subscriptionFor;

// Pack
export const selectSubscriptionPack = (state: RootState) => state.formSubscription.subscriptionPack;

// Subscription details
export const selectSubscriptionsDetails = (state: RootState) => state.formSubscription.subscriptionsDetails;
export const selectSubscriptionDetails = (categoryId: number) => (
  state: RootState
) => state.formSubscription.subscriptionsDetails[categoryId];

export const selectSubscriptionsCount = createSelector(
  selectSubscriptionsDetails,
  subscriptions => size(subscriptions)
);

export const selectSubscriptionIsSelected = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => !!subscription
);

// Category
export const selectSubscriptionCategory = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription.category
);

export const selectSubscriptionsCategoryIds = createSelector(
  selectSubscriptionsDetails,
  subscriptions => Object.keys(subscriptions).map(Number)
);

// Event
export const selectSubscriptionEvent = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription?.event
);

export const selectEventsWithSelectedCategory = createSelector(
  selectSubscriptionsDetails,
  subscriptions => Object.values(subscriptions)
    .reduce((acc, { event, category }) => ({
      ...acc,
      [event._idMso]: category._idMso,
    }), {})
);

// Preservices
export const selectSubscriptionDefaultPreServices = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription?.defaultPreServices
);

export const selectSubscriptionPreServices = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription?.preServices
);

// Services
export const selectSubscriptionServices = (categoryId: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription.services
);

export const selectTeamMateSubscription = (categoryId: number, index: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription.services[index]?.servicesValues
);

export const selectCurrentTeamMate = (categoryId: number, index: number) => createSelector(
  selectSubscriptionDetails(categoryId),
  subscription => subscription.services[index]?.profile
);

// Next category pointer
export const selectCompletedCats = (state: RootState) => state.formSubscription.completedCategories;

export const selectEarliestNextSubscription = createSelector(
  selectSubscriptionsDetails,
  selectCompletedCats,
  (subscriptions, completedCats) => Object.values(subscriptions)
    .filter(({ category }) => !completedCats.includes(category._idMso))
    .sort(({ event: eventA }, { event: eventB }) => {
      if (eventA.date > eventB.date) { return 1; }
      if (eventA.date < eventB.date) { return -1; }
      return 0;
    })[0]
);
