import flagAFG from '@assets/icons/flags/flag_afghanistan.svg';
import flagALB from '@assets/icons/flags/flag_albania.svg';
import flagALG from '@assets/icons/flags/flag_algeria.svg';
import flagASA from '@assets/icons/flags/flag_americansamoa.svg';
import flagAND from '@assets/icons/flags/flag_andorra.svg';
import flagANG from '@assets/icons/flags/flag_angola.svg';
import flagAIA from '@assets/icons/flags/flag_anguilla.svg';
import flagANT from '@assets/icons/flags/flag_antiguaandbarbuda.svg';
import flagARG from '@assets/icons/flags/flag_argentina.svg';
import flagARM from '@assets/icons/flags/flag_armenia.svg';
import flagARU from '@assets/icons/flags/flag_aruba.svg';
import flagAUS from '@assets/icons/flags/flag_australia.svg';
import flagAUT from '@assets/icons/flags/flag_austria.svg';
import flagAZE from '@assets/icons/flags/flag_azerbaijan.svg';
import flagBAH from '@assets/icons/flags/flag_bahamas.svg';
import flagBRN from '@assets/icons/flags/flag_bahrain.svg';
import flagBAN from '@assets/icons/flags/flag_bangladesh.svg';
import flagBAR from '@assets/icons/flags/flag_barbados.svg';
import flagBLR from '@assets/icons/flags/flag_belarus.svg';
import flagBEL from '@assets/icons/flags/flag_belgium.svg';
import flagBIZ from '@assets/icons/flags/flag_belize.svg';
import flagBEN from '@assets/icons/flags/flag_benin.svg';
import flagBER from '@assets/icons/flags/flag_bermuda.svg';
import flagBHU from '@assets/icons/flags/flag_bhutan.svg';
import flagBOL from '@assets/icons/flags/flag_bolivia.svg';
import flagBIH from '@assets/icons/flags/flag_bosniaandherzegovina.svg';
import flagBOT from '@assets/icons/flags/flag_botswana.svg';
import flagBRA from '@assets/icons/flags/flag_brazil.svg';
import flagIVB from '@assets/icons/flags/flag_britishvirginislands.svg';
import flagBRU from '@assets/icons/flags/flag_brunei.svg';
import flagBUL from '@assets/icons/flags/flag_bulgaria.svg';
import flagBUR from '@assets/icons/flags/flag_burkinafaso.svg';
import flagBDI from '@assets/icons/flags/flag_burundi.svg';
import flagCAM from '@assets/icons/flags/flag_cambodia.svg';
import flagCMR from '@assets/icons/flags/flag_cameroon.svg';
import flagCAN from '@assets/icons/flags/flag_canada.svg';
import flagCPV from '@assets/icons/flags/flag_capeverde.svg';
import flagCAY from '@assets/icons/flags/flag_caymanislands.svg';
import flagCAF from '@assets/icons/flags/flag_centralafricanrepublic.svg';
import flagCHA from '@assets/icons/flags/flag_chad.svg';
import flagCHI from '@assets/icons/flags/flag_chile.svg';
import flagCHN from '@assets/icons/flags/flag_china.svg';
import flagCOL from '@assets/icons/flags/flag_colombia.svg';
import flagCOM from '@assets/icons/flags/flag_comoros.svg';
import flagCGO from '@assets/icons/flags/flag_republicofthecongo.svg';
import flagCOD from '@assets/icons/flags/flag_democraticrepublicofcongo.svg';
import flagCOK from '@assets/icons/flags/flag_cookislands.svg';
import flagCRC from '@assets/icons/flags/flag_costarica.svg';
import flagCIV from '@assets/icons/flags/flag_ivorycoast.svg';
import flagCRO from '@assets/icons/flags/flag_croatia.svg';
import flagCUB from '@assets/icons/flags/flag_cuba.svg';
import flagCZE from '@assets/icons/flags/flag_czechrepublic.svg';
import flagDEN from '@assets/icons/flags/flag_denmark.svg';
import flagDJI from '@assets/icons/flags/flag_djibouti.svg';
import flagDMA from '@assets/icons/flags/flag_dominica.svg';
import flagDOM from '@assets/icons/flags/flag_dominicanrepublic.svg';
import flagECU from '@assets/icons/flags/flag_ecuador.svg';
import flagEGY from '@assets/icons/flags/flag_egypt.svg';
import flagESA from '@assets/icons/flags/flag_elsalvador.svg';
import flagGEQ from '@assets/icons/flags/flag_equatorialguinea.svg';
import flagERI from '@assets/icons/flags/flag_eritrea.svg';
import flagEST from '@assets/icons/flags/flag_estonia.svg';
import flagSWZ from '@assets/icons/flags/flag_swaziland.svg';
import flagETH from '@assets/icons/flags/flag_ethiopia.svg';
import flagFLK from '@assets/icons/flags/flag_falklandislands.svg';
import flagFIJ from '@assets/icons/flags/flag_fiji.svg';
import flagFIN from '@assets/icons/flags/flag_finland.svg';
import flagFRA from '@assets/icons/flags/flag_france.svg';
import flagFPO from '@assets/icons/flags/flag_frenchpolynesia.svg';
import flagGAB from '@assets/icons/flags/flag_gabon.svg';
import flagGAM from '@assets/icons/flags/flag_gambia.svg';
import flagGEO from '@assets/icons/flags/flag_georgia.svg';
import flagGER from '@assets/icons/flags/flag_germany.svg';
import flagGHA from '@assets/icons/flags/flag_ghana.svg';
import flagGIB from '@assets/icons/flags/flag_gibraltar.svg';
import flagGRE from '@assets/icons/flags/flag_greece.svg';
import flagGRL from '@assets/icons/flags/flag_greenland.svg';
import flagGRN from '@assets/icons/flags/flag_grenada.svg';
import flagGUM from '@assets/icons/flags/flag_guam.svg';
import flagGUA from '@assets/icons/flags/flag_guatemala.svg';
import flagGUI from '@assets/icons/flags/flag_guinea.svg';
import flagGBS from '@assets/icons/flags/flag_guineabissau.svg';
import flagHAI from '@assets/icons/flags/flag_haiti.svg';
import flagHON from '@assets/icons/flags/flag_honduras.svg';
import flagHKG from '@assets/icons/flags/flag_hongkong.svg';
import flagHUN from '@assets/icons/flags/flag_hungary.svg';
import flagISL from '@assets/icons/flags/flag_iceland.svg';
import flagIND from '@assets/icons/flags/flag_india.svg';
import flagINA from '@assets/icons/flags/flag_indonesia.svg';
import flagIRI from '@assets/icons/flags/flag_iran.svg';
import flagIRQ from '@assets/icons/flags/flag_iraq.svg';
import flagIRL from '@assets/icons/flags/flag_ireland.svg';
import flagISR from '@assets/icons/flags/flag_israel.svg';
import flagITA from '@assets/icons/flags/flag_italy.svg';
import flagJAM from '@assets/icons/flags/flag_jamaica.svg';
import flagJPN from '@assets/icons/flags/flag_japan.svg';
import flagJOR from '@assets/icons/flags/flag_jordan.svg';
import flagKAZ from '@assets/icons/flags/flag_kazakhstan.svg';
import flagKEN from '@assets/icons/flags/flag_kenya.svg';
import flagKIR from '@assets/icons/flags/flag_kiribati.svg';
import flagKUW from '@assets/icons/flags/flag_kwait.svg';
import flagKGZ from '@assets/icons/flags/flag_kyrgyzstan.svg';
import flagLAO from '@assets/icons/flags/flag_laos.svg';
import flagLAT from '@assets/icons/flags/flag_latvia.svg';
import flagLIB from '@assets/icons/flags/flag_lebanon.svg';
import flagLES from '@assets/icons/flags/flag_lesotho.svg';
import flagLBR from '@assets/icons/flags/flag_liberia.svg';
import flagLBA from '@assets/icons/flags/flag_libya.svg';
import flagLIE from '@assets/icons/flags/flag_liechtenstein.svg';
import flagLTU from '@assets/icons/flags/flag_lithuania.svg';
import flagLUX from '@assets/icons/flags/flag_luxembourg.svg';
import flagMAC from '@assets/icons/flags/flag_macao.svg';
import flagMAD from '@assets/icons/flags/flag_madagascar.svg';
import flagMAW from '@assets/icons/flags/flag_malawi.svg';
import flagMAS from '@assets/icons/flags/flag_malasya.svg';
import flagMDV from '@assets/icons/flags/flag_maldives.svg';
import flagMLI from '@assets/icons/flags/flag_mali.svg';
import flagMLT from '@assets/icons/flags/flag_malta.svg';
import flagMSH from '@assets/icons/flags/flag_marshallisland.svg';
import flagMRT from '@assets/icons/flags/flag_martinique.svg';
import flagMTN from '@assets/icons/flags/flag_mauritania.svg';
import flagMRI from '@assets/icons/flags/flag_mauritius.svg';
import flagMEX from '@assets/icons/flags/flag_mexico.svg';
import flagFSM from '@assets/icons/flags/flag_micronesia.svg';
import flagMDA from '@assets/icons/flags/flag_moldova.svg';
import flagMON from '@assets/icons/flags/flag_monaco.svg';
import flagMGL from '@assets/icons/flags/flag_mongolia.svg';
import flagMGO from '@assets/icons/flags/flag_montenegro.svg';
import flagMNT from '@assets/icons/flags/flag_montserrat.svg';
import flagMAR from '@assets/icons/flags/flag_morocco.svg';
import flagMOZ from '@assets/icons/flags/flag_mozambique.svg';
import flagMYA from '@assets/icons/flags/flag_myanmar.svg';
import flagNAM from '@assets/icons/flags/flag_namibia.svg';
import flagNRU from '@assets/icons/flags/flag_nauru.svg';
import flagNEP from '@assets/icons/flags/flag_nepal.svg';
import flagNED from '@assets/icons/flags/flag_netherlands.svg';
import flagNZL from '@assets/icons/flags/flag_newzealand.svg';
import flagNCA from '@assets/icons/flags/flag_nicaragua.svg';
import flagNIG from '@assets/icons/flags/flag_niger.svg';
import flagNGR from '@assets/icons/flags/flag_nigeria.svg';
import flagNIU from '@assets/icons/flags/flag_niue.svg';
import flagNFI from '@assets/icons/flags/flag_norfolkisland.svg';
import flagPRK from '@assets/icons/flags/flag_northkorea.svg';
import flagMKD from '@assets/icons/flags/flag_republicofmacedonia.svg';
import flagNMA from '@assets/icons/flags/flag_northernmarianasislands.svg';
import flagNOR from '@assets/icons/flags/flag_norway.svg';
import flagOMA from '@assets/icons/flags/flag_oman.svg';
import flagPAK from '@assets/icons/flags/flag_pakistan.svg';
import flagPLW from '@assets/icons/flags/flag_palau.svg';
import flagPLE from '@assets/icons/flags/flag_palestine.svg';
import flagPAN from '@assets/icons/flags/flag_panama.svg';
import flagPNG from '@assets/icons/flags/flag_papuanewguinea.svg';
import flagPAR from '@assets/icons/flags/flag_paraguay.svg';
import flagPER from '@assets/icons/flags/flag_peru.svg';
import flagPHI from '@assets/icons/flags/flag_philippines.svg';
import flagPOL from '@assets/icons/flags/flag_poland.svg';
import flagPOR from '@assets/icons/flags/flag_portugal.svg';
import flagPUR from '@assets/icons/flags/flag_puertorico.svg';
import flagQAT from '@assets/icons/flags/flag_qatar.svg';
import flagROU from '@assets/icons/flags/flag_romania.svg';
import flagRUS from '@assets/icons/flags/flag_russia.svg';
import flagRWA from '@assets/icons/flags/flag_rwanda.svg';
import flagSAM from '@assets/icons/flags/flag_samoa.svg';
import flagSMR from '@assets/icons/flags/flag_sanmarino.svg';
import flagSTP from '@assets/icons/flags/flag_saotomeandprince.svg';
import flagKSA from '@assets/icons/flags/flag_saudiarabia.svg';
import flagSEN from '@assets/icons/flags/flag_senegal.svg';
import flagSRB from '@assets/icons/flags/flag_serbia.svg';
import flagSEY from '@assets/icons/flags/flag_seychelles.svg';
import flagSLE from '@assets/icons/flags/flag_sierraleone.svg';
import flagSIN from '@assets/icons/flags/flag_singapore.svg';
import flagSVK from '@assets/icons/flags/flag_slovakia.svg';
import flagSLO from '@assets/icons/flags/flag_slovenia.svg';
import flagSOL from '@assets/icons/flags/flag_solomonislands.svg';
import flagSOM from '@assets/icons/flags/flag_somalia.svg';
import flagRSA from '@assets/icons/flags/flag_southafrica.svg';
import flagKOR from '@assets/icons/flags/flag_southkorea.svg';
import flagESP from '@assets/icons/flags/flag_spain.svg';
import flagSRI from '@assets/icons/flags/flag_srilanka.svg';
import flagSKN from '@assets/icons/flags/flag_saintkittsandnevis.svg';
import flagLCA from '@assets/icons/flags/flag_stlucia.svg';
import flagVIN from '@assets/icons/flags/flag_stvincentandthegrenadines.svg';
import flagSUD from '@assets/icons/flags/flag_sudan.svg';
import flagSUR from '@assets/icons/flags/flag_suriname.svg';
import flagSWE from '@assets/icons/flags/flag_sweden.svg';
import flagSUI from '@assets/icons/flags/flag_switzerland.svg';
import flagSYR from '@assets/icons/flags/flag_syria.svg';
import flagTPE from '@assets/icons/flags/flag_taiwan.svg';
import flagTJK from '@assets/icons/flags/flag_tajikistan.svg';
import flagTAN from '@assets/icons/flags/flag_tanzania.svg';
import flagTHA from '@assets/icons/flags/flag_thailand.svg';
import flagTLS from '@assets/icons/flags/flag_easttimor.svg';
import flagTOG from '@assets/icons/flags/flag_togo.svg';
import flagTGA from '@assets/icons/flags/flag_tonga.svg';
import flagTTO from '@assets/icons/flags/flag_trinidadandtobago.svg';
import flagTUN from '@assets/icons/flags/flag_tunisia.svg';
import flagTUR from '@assets/icons/flags/flag_turkey.svg';
import flagTKM from '@assets/icons/flags/flag_turkmenistan.svg';
import flagTKS from '@assets/icons/flags/flag_turksandcaicos.svg';
import flagTUV from '@assets/icons/flags/flag_tuvalu.svg';
import flagISV from '@assets/icons/flags/flag_virginislands.svg';
import flagUGA from '@assets/icons/flags/flag_uganda.svg';
import flagGBR from '@assets/icons/flags/flag_unitedkingdom.svg';
import flagUKR from '@assets/icons/flags/flag_ukraine.svg';
import flagUAE from '@assets/icons/flags/flag_unitedarabemirates.svg';
import flagURU from '@assets/icons/flags/flag_uruguay.svg';
import flagUSA from '@assets/icons/flags/flag_unitedstates.svg';
import flagUZB from '@assets/icons/flags/flag_uzbekistan.svg';
import flagVAN from '@assets/icons/flags/flag_vanuatu.svg';
import flagVEN from '@assets/icons/flags/flag_venezuela.svg';
import flagVIE from '@assets/icons/flags/flag_vietnam.svg';
import flagYEM from '@assets/icons/flags/flag_yemen.svg';
import flagZAM from '@assets/icons/flags/flag_zambia.svg';
import flagZIM from '@assets/icons/flags/flag_zimbabwe.svg';

export enum FLAG_NAME {
  flagAFG = 'flagAFG',
  flagALB = 'flagALB',
  flagALG = 'flagALG',
  flagASA = 'flagASA',
  flagAND = 'flagAND',
  flagANG = 'flagANG',
  flagAIA = 'flagAIA',
  flagANT = 'flagANT',
  flagARG = 'flagARG',
  flagARM = 'flagARM',
  flagARU = 'flagARU',
  flagAUS = 'flagAUS',
  flagAUT = 'flagAUT',
  flagAZE = 'flagAZE',
  flagBAH = 'flagBAH',
  flagBRN = 'flagBRN',
  flagBAN = 'flagBAN',
  flagBAR = 'flagBAR',
  flagBLR = 'flagBLR',
  flagBEL = 'flagBEL',
  flagBIZ = 'flagBIZ',
  flagBEN = 'flagBEN',
  flagBER = 'flagBER',
  flagBHU = 'flagBHU',
  flagBOL = 'flagBOL',
  flagBIH = 'flagBIH',
  flagBOT = 'flagBOT',
  flagBRA = 'flagBRA',
  flagIVB = 'flagIVB',
  flagBRU = 'flagBRU',
  flagBUL = 'flagBUL',
  flagBUR = 'flagBUR',
  flagBDI = 'flagBDI',
  flagCAM = 'flagCAM',
  flagCMR = 'flagCMR',
  flagCAN = 'flagCAN',
  flagCPV = 'flagCPV',
  flagAHO = 'flagAHO',
  flagCAY = 'flagCAY',
  flagCAF = 'flagCAF',
  flagCHA = 'flagCHA',
  flagCHI = 'flagCHI',
  flagCHN = 'flagCHN',
  flagCOL = 'flagCOL',
  flagCOM = 'flagCOM',
  flagCGO = 'flagCGO',
  flagCOD = 'flagCOD',
  flagCOK = 'flagCOK',
  flagCRC = 'flagCRC',
  flagCIV = 'flagCIV',
  flagCRO = 'flagCRO',
  flagCUB = 'flagCUB',
  flagCZE = 'flagCZE',
  flagDEN = 'flagDEN',
  flagDJI = 'flagDJI',
  flagDMA = 'flagDMA',
  flagDOM = 'flagDOM',
  flagECU = 'flagECU',
  flagEGY = 'flagEGY',
  flagESA = 'flagESA',
  flagGEQ = 'flagGEQ',
  flagERI = 'flagERI',
  flagEST = 'flagEST',
  flagSWZ = 'flagSWZ',
  flagETH = 'flagETH',
  flagFLK = 'flagFLK',
  flagFIJ = 'flagFIJ',
  flagFIN = 'flagFIN',
  flagFRA = 'flagFRA',
  flagFGU = 'flagFGU',
  flagFPO = 'flagFPO',
  flagGAB = 'flagGAB',
  flagGAM = 'flagGAM',
  flagGEO = 'flagGEO',
  flagGER = 'flagGER',
  flagGHA = 'flagGHA',
  flagGIB = 'flagGIB',
  flagGRE = 'flagGRE',
  flagGRL = 'flagGRL',
  flagGRN = 'flagGRN',
  flagGUD = 'flagGUD',
  flagGUM = 'flagGUM',
  flagGUA = 'flagGUA',
  flagGUI = 'flagGUI',
  flagGBS = 'flagGBS',
  flagHAI = 'flagHAI',
  flagHON = 'flagHON',
  flagHKG = 'flagHKG',
  flagHUN = 'flagHUN',
  flagISL = 'flagISL',
  flagIND = 'flagIND',
  flagINA = 'flagINA',
  flagIRI = 'flagIRI',
  flagIRQ = 'flagIRQ',
  flagIRL = 'flagIRL',
  flagISR = 'flagISR',
  flagITA = 'flagITA',
  flagJAM = 'flagJAM',
  flagJPN = 'flagJPN',
  flagJOR = 'flagJOR',
  flagKAZ = 'flagKAZ',
  flagKEN = 'flagKEN',
  flagKIR = 'flagKIR',
  flagKUW = 'flagKUW',
  flagKGZ = 'flagKGZ',
  flagLAO = 'flagLAO',
  flagLAT = 'flagLAT',
  flagLIB = 'flagLIB',
  flagLES = 'flagLES',
  flagLBR = 'flagLBR',
  flagLBA = 'flagLBA',
  flagLIE = 'flagLIE',
  flagLTU = 'flagLTU',
  flagLUX = 'flagLUX',
  flagMAC = 'flagMAC',
  flagMAD = 'flagMAD',
  flagMAW = 'flagMAW',
  flagMAS = 'flagMAS',
  flagMDV = 'flagMDV',
  flagMLI = 'flagMLI',
  flagMLT = 'flagMLT',
  flagMSH = 'flagMSH',
  flagMRT = 'flagMRT',
  flagMTN = 'flagMTN',
  flagMRI = 'flagMRI',
  flagMAY = 'flagMAY',
  flagMEX = 'flagMEX',
  flagFSM = 'flagFSM',
  flagMDA = 'flagMDA',
  flagMON = 'flagMON',
  flagMGL = 'flagMGL',
  flagMGO = 'flagMGO',
  flagMNT = 'flagMNT',
  flagMAR = 'flagMAR',
  flagMOZ = 'flagMOZ',
  flagMYA = 'flagMYA',
  flagNAM = 'flagNAM',
  flagNRU = 'flagNRU',
  flagNEP = 'flagNEP',
  flagNED = 'flagNED',
  flagNCD = 'flagNCD',
  flagNZL = 'flagNZL',
  flagNCA = 'flagNCA',
  flagNIG = 'flagNIG',
  flagNGR = 'flagNGR',
  flagNIU = 'flagNIU',
  flagNFI = 'flagNFI',
  flagPRK = 'flagPRK',
  flagMKD = 'flagMKD',
  flagNMA = 'flagNMA',
  flagNOR = 'flagNOR',
  flagOMA = 'flagOMA',
  flagPAK = 'flagPAK',
  flagPLW = 'flagPLW',
  flagPLE = 'flagPLE',
  flagPAN = 'flagPAN',
  flagPNG = 'flagPNG',
  flagPAR = 'flagPAR',
  flagPER = 'flagPER',
  flagPHI = 'flagPHI',
  flagPOL = 'flagPOL',
  flagPOR = 'flagPOR',
  flagPUR = 'flagPUR',
  flagQAT = 'flagQAT',
  flagREU = 'flagREU',
  flagROU = 'flagROU',
  flagRUS = 'flagRUS',
  flagRWA = 'flagRWA',
  flagSAM = 'flagSAM',
  flagSMR = 'flagSMR',
  flagSTP = 'flagSTP',
  flagKSA = 'flagKSA',
  flagSEN = 'flagSEN',
  flagSRB = 'flagSRB',
  flagSEY = 'flagSEY',
  flagSLE = 'flagSLE',
  flagSIN = 'flagSIN',
  flagSVK = 'flagSVK',
  flagSLO = 'flagSLO',
  flagSOL = 'flagSOL',
  flagSOM = 'flagSOM',
  flagRSA = 'flagRSA',
  flagKOR = 'flagKOR',
  flagESP = 'flagESP',
  flagSRI = 'flagSRI',
  flagSKN = 'flagSKN',
  flagLCA = 'flagLCA',
  flagSPM = 'flagSPM',
  flagVIN = 'flagVIN',
  flagSUD = 'flagSUD',
  flagSUR = 'flagSUR',
  flagSWE = 'flagSWE',
  flagSUI = 'flagSUI',
  flagSYR = 'flagSYR',
  flagTPE = 'flagTPE',
  flagTJK = 'flagTJK',
  flagTAN = 'flagTAN',
  flagTHA = 'flagTHA',
  flagTLS = 'flagTLS',
  flagTOG = 'flagTOG',
  flagTGA = 'flagTGA',
  flagTTO = 'flagTTO',
  flagTUN = 'flagTUN',
  flagTUR = 'flagTUR',
  flagTKM = 'flagTKM',
  flagTKS = 'flagTKS',
  flagTUV = 'flagTUV',
  flagISV = 'flagISV',
  flagUGA = 'flagUGA',
  flagGBR = 'flagGBR',
  flagUKR = 'flagUKR',
  flagUAE = 'flagUAE',
  flagURU = 'flagURU',
  flagUSA = 'flagUSA',
  flagUZB = 'flagUZB',
  flagVAN = 'flagVAN',
  flagVEN = 'flagVEN',
  flagVIE = 'flagVIE',
  flagWAF = 'flagWAF',
  flagYEM = 'flagYEM',
  flagZAM = 'flagZAM',
  flagZIM = 'flagZIM',
}

export const flagSvgContent = {
  [FLAG_NAME.flagAFG]: flagAFG,
  [FLAG_NAME.flagALB]: flagALB,
  [FLAG_NAME.flagALG]: flagALG,
  [FLAG_NAME.flagASA]: flagASA,
  [FLAG_NAME.flagAND]: flagAND,
  [FLAG_NAME.flagANG]: flagANG,
  [FLAG_NAME.flagAIA]: flagAIA,
  [FLAG_NAME.flagANT]: flagANT,
  [FLAG_NAME.flagARG]: flagARG,
  [FLAG_NAME.flagARM]: flagARM,
  [FLAG_NAME.flagARU]: flagARU,
  [FLAG_NAME.flagAUS]: flagAUS,
  [FLAG_NAME.flagAUT]: flagAUT,
  [FLAG_NAME.flagAZE]: flagAZE,
  [FLAG_NAME.flagBAH]: flagBAH,
  [FLAG_NAME.flagBRN]: flagBRN,
  [FLAG_NAME.flagBAN]: flagBAN,
  [FLAG_NAME.flagBAR]: flagBAR,
  [FLAG_NAME.flagBLR]: flagBLR,
  [FLAG_NAME.flagBEL]: flagBEL,
  [FLAG_NAME.flagBIZ]: flagBIZ,
  [FLAG_NAME.flagBEN]: flagBEN,
  [FLAG_NAME.flagBER]: flagBER,
  [FLAG_NAME.flagBHU]: flagBHU,
  [FLAG_NAME.flagBOL]: flagBOL,
  [FLAG_NAME.flagBIH]: flagBIH,
  [FLAG_NAME.flagBOT]: flagBOT,
  [FLAG_NAME.flagBRA]: flagBRA,
  [FLAG_NAME.flagIVB]: flagIVB,
  [FLAG_NAME.flagBRU]: flagBRU,
  [FLAG_NAME.flagBUL]: flagBUL,
  [FLAG_NAME.flagBUR]: flagBUR,
  [FLAG_NAME.flagBDI]: flagBDI,
  [FLAG_NAME.flagCAM]: flagCAM,
  [FLAG_NAME.flagCMR]: flagCMR,
  [FLAG_NAME.flagCAN]: flagCAN,
  [FLAG_NAME.flagCPV]: flagCPV,
  [FLAG_NAME.flagAHO]: flagNED,
  [FLAG_NAME.flagCAY]: flagCAY,
  [FLAG_NAME.flagCAF]: flagCAF,
  [FLAG_NAME.flagCHA]: flagCHA,
  [FLAG_NAME.flagCHI]: flagCHI,
  [FLAG_NAME.flagCHN]: flagCHN,
  [FLAG_NAME.flagCOL]: flagCOL,
  [FLAG_NAME.flagCOM]: flagCOM,
  [FLAG_NAME.flagCGO]: flagCGO,
  [FLAG_NAME.flagCOD]: flagCOD,
  [FLAG_NAME.flagCOK]: flagCOK,
  [FLAG_NAME.flagCRC]: flagCRC,
  [FLAG_NAME.flagCIV]: flagCIV,
  [FLAG_NAME.flagCRO]: flagCRO,
  [FLAG_NAME.flagCUB]: flagCUB,
  [FLAG_NAME.flagCZE]: flagCZE,
  [FLAG_NAME.flagDEN]: flagDEN,
  [FLAG_NAME.flagDJI]: flagDJI,
  [FLAG_NAME.flagDMA]: flagDMA,
  [FLAG_NAME.flagDOM]: flagDOM,
  [FLAG_NAME.flagECU]: flagECU,
  [FLAG_NAME.flagEGY]: flagEGY,
  [FLAG_NAME.flagESA]: flagESA,
  [FLAG_NAME.flagGEQ]: flagGEQ,
  [FLAG_NAME.flagERI]: flagERI,
  [FLAG_NAME.flagEST]: flagEST,
  [FLAG_NAME.flagSWZ]: flagSWZ,
  [FLAG_NAME.flagETH]: flagETH,
  [FLAG_NAME.flagFLK]: flagFLK,
  [FLAG_NAME.flagFIJ]: flagFIJ,
  [FLAG_NAME.flagFIN]: flagFIN,
  [FLAG_NAME.flagFRA]: flagFRA,
  [FLAG_NAME.flagFGU]: flagFRA,
  [FLAG_NAME.flagFPO]: flagFPO,
  [FLAG_NAME.flagGAB]: flagGAB,
  [FLAG_NAME.flagGAM]: flagGAM,
  [FLAG_NAME.flagGEO]: flagGEO,
  [FLAG_NAME.flagGER]: flagGER,
  [FLAG_NAME.flagGHA]: flagGHA,
  [FLAG_NAME.flagGIB]: flagGIB,
  [FLAG_NAME.flagGRE]: flagGRE,
  [FLAG_NAME.flagGRL]: flagGRL,
  [FLAG_NAME.flagGRN]: flagGRN,
  [FLAG_NAME.flagGUD]: flagFRA,
  [FLAG_NAME.flagGUM]: flagGUM,
  [FLAG_NAME.flagGUA]: flagGUA,
  [FLAG_NAME.flagGUI]: flagGUI,
  [FLAG_NAME.flagGBS]: flagGBS,
  [FLAG_NAME.flagHAI]: flagHAI,
  [FLAG_NAME.flagHON]: flagHON,
  [FLAG_NAME.flagHKG]: flagHKG,
  [FLAG_NAME.flagHUN]: flagHUN,
  [FLAG_NAME.flagISL]: flagISL,
  [FLAG_NAME.flagIND]: flagIND,
  [FLAG_NAME.flagINA]: flagINA,
  [FLAG_NAME.flagIRI]: flagIRI,
  [FLAG_NAME.flagIRQ]: flagIRQ,
  [FLAG_NAME.flagIRL]: flagIRL,
  [FLAG_NAME.flagISR]: flagISR,
  [FLAG_NAME.flagITA]: flagITA,
  [FLAG_NAME.flagJAM]: flagJAM,
  [FLAG_NAME.flagJPN]: flagJPN,
  [FLAG_NAME.flagJOR]: flagJOR,
  [FLAG_NAME.flagKAZ]: flagKAZ,
  [FLAG_NAME.flagKEN]: flagKEN,
  [FLAG_NAME.flagKIR]: flagKIR,
  [FLAG_NAME.flagKUW]: flagKUW,
  [FLAG_NAME.flagKGZ]: flagKGZ,
  [FLAG_NAME.flagLAO]: flagLAO,
  [FLAG_NAME.flagLAT]: flagLAT,
  [FLAG_NAME.flagLIB]: flagLIB,
  [FLAG_NAME.flagLES]: flagLES,
  [FLAG_NAME.flagLBR]: flagLBR,
  [FLAG_NAME.flagLBA]: flagLBA,
  [FLAG_NAME.flagLIE]: flagLIE,
  [FLAG_NAME.flagLTU]: flagLTU,
  [FLAG_NAME.flagLUX]: flagLUX,
  [FLAG_NAME.flagMAC]: flagMAC,
  [FLAG_NAME.flagMAD]: flagMAD,
  [FLAG_NAME.flagMAW]: flagMAW,
  [FLAG_NAME.flagMAS]: flagMAS,
  [FLAG_NAME.flagMDV]: flagMDV,
  [FLAG_NAME.flagMLI]: flagMLI,
  [FLAG_NAME.flagMLT]: flagMLT,
  [FLAG_NAME.flagMSH]: flagMSH,
  [FLAG_NAME.flagMRT]: flagMRT,
  [FLAG_NAME.flagMTN]: flagMTN,
  [FLAG_NAME.flagMRI]: flagMRI,
  [FLAG_NAME.flagMAY]: flagFRA,
  [FLAG_NAME.flagMEX]: flagMEX,
  [FLAG_NAME.flagFSM]: flagFSM,
  [FLAG_NAME.flagMDA]: flagMDA,
  [FLAG_NAME.flagMON]: flagMON,
  [FLAG_NAME.flagMGL]: flagMGL,
  [FLAG_NAME.flagMGO]: flagMGO,
  [FLAG_NAME.flagMNT]: flagMNT,
  [FLAG_NAME.flagMAR]: flagMAR,
  [FLAG_NAME.flagMOZ]: flagMOZ,
  [FLAG_NAME.flagMYA]: flagMYA,
  [FLAG_NAME.flagNAM]: flagNAM,
  [FLAG_NAME.flagNRU]: flagNRU,
  [FLAG_NAME.flagNEP]: flagNEP,
  [FLAG_NAME.flagNED]: flagNED,
  [FLAG_NAME.flagNCD]: flagFRA,
  [FLAG_NAME.flagNZL]: flagNZL,
  [FLAG_NAME.flagNCA]: flagNCA,
  [FLAG_NAME.flagNIG]: flagNIG,
  [FLAG_NAME.flagNGR]: flagNGR,
  [FLAG_NAME.flagNIU]: flagNIU,
  [FLAG_NAME.flagNFI]: flagNFI,
  [FLAG_NAME.flagPRK]: flagPRK,
  [FLAG_NAME.flagMKD]: flagMKD,
  [FLAG_NAME.flagNMA]: flagNMA,
  [FLAG_NAME.flagNOR]: flagNOR,
  [FLAG_NAME.flagOMA]: flagOMA,
  [FLAG_NAME.flagPAK]: flagPAK,
  [FLAG_NAME.flagPLW]: flagPLW,
  [FLAG_NAME.flagPLE]: flagPLE,
  [FLAG_NAME.flagPAN]: flagPAN,
  [FLAG_NAME.flagPNG]: flagPNG,
  [FLAG_NAME.flagPAR]: flagPAR,
  [FLAG_NAME.flagPER]: flagPER,
  [FLAG_NAME.flagPHI]: flagPHI,
  [FLAG_NAME.flagPOL]: flagPOL,
  [FLAG_NAME.flagPOR]: flagPOR,
  [FLAG_NAME.flagPUR]: flagPUR,
  [FLAG_NAME.flagQAT]: flagQAT,
  [FLAG_NAME.flagREU]: flagFRA,
  [FLAG_NAME.flagROU]: flagROU,
  [FLAG_NAME.flagRUS]: flagRUS,
  [FLAG_NAME.flagRWA]: flagRWA,
  [FLAG_NAME.flagSAM]: flagSAM,
  [FLAG_NAME.flagSMR]: flagSMR,
  [FLAG_NAME.flagSTP]: flagSTP,
  [FLAG_NAME.flagKSA]: flagKSA,
  [FLAG_NAME.flagSEN]: flagSEN,
  [FLAG_NAME.flagSRB]: flagSRB,
  [FLAG_NAME.flagSEY]: flagSEY,
  [FLAG_NAME.flagSLE]: flagSLE,
  [FLAG_NAME.flagSIN]: flagSIN,
  [FLAG_NAME.flagSVK]: flagSVK,
  [FLAG_NAME.flagSLO]: flagSLO,
  [FLAG_NAME.flagSOL]: flagSOL,
  [FLAG_NAME.flagSOM]: flagSOM,
  [FLAG_NAME.flagRSA]: flagRSA,
  [FLAG_NAME.flagKOR]: flagKOR,
  [FLAG_NAME.flagESP]: flagESP,
  [FLAG_NAME.flagSRI]: flagSRI,
  [FLAG_NAME.flagSKN]: flagSKN,
  [FLAG_NAME.flagLCA]: flagLCA,
  [FLAG_NAME.flagSPM]: flagFRA,
  [FLAG_NAME.flagVIN]: flagVIN,
  [FLAG_NAME.flagSUD]: flagSUD,
  [FLAG_NAME.flagSUR]: flagSUR,
  [FLAG_NAME.flagSWE]: flagSWE,
  [FLAG_NAME.flagSUI]: flagSUI,
  [FLAG_NAME.flagSYR]: flagSYR,
  [FLAG_NAME.flagTPE]: flagTPE,
  [FLAG_NAME.flagTJK]: flagTJK,
  [FLAG_NAME.flagTAN]: flagTAN,
  [FLAG_NAME.flagTHA]: flagTHA,
  [FLAG_NAME.flagTLS]: flagTLS,
  [FLAG_NAME.flagTOG]: flagTOG,
  [FLAG_NAME.flagTGA]: flagTGA,
  [FLAG_NAME.flagTTO]: flagTTO,
  [FLAG_NAME.flagTUN]: flagTUN,
  [FLAG_NAME.flagTUR]: flagTUR,
  [FLAG_NAME.flagTKM]: flagTKM,
  [FLAG_NAME.flagTKS]: flagTKS,
  [FLAG_NAME.flagTUV]: flagTUV,
  [FLAG_NAME.flagISV]: flagISV,
  [FLAG_NAME.flagUGA]: flagUGA,
  [FLAG_NAME.flagGBR]: flagGBR,
  [FLAG_NAME.flagUKR]: flagUKR,
  [FLAG_NAME.flagUAE]: flagUAE,
  [FLAG_NAME.flagURU]: flagURU,
  [FLAG_NAME.flagUSA]: flagUSA,
  [FLAG_NAME.flagUZB]: flagUZB,
  [FLAG_NAME.flagVAN]: flagVAN,
  [FLAG_NAME.flagVEN]: flagVEN,
  [FLAG_NAME.flagVIE]: flagVIE,
  [FLAG_NAME.flagWAF]: flagFRA,
  [FLAG_NAME.flagYEM]: flagYEM,
  [FLAG_NAME.flagZAM]: flagZAM,
  [FLAG_NAME.flagZIM]: flagZIM,
};
