import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useRegistration from '@root/src/hooks/useRegistration';
import { GET_DATA_FOR, useGetClientSideData } from '@services/dataHandler/getPageData';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { scrollToTop } from '@features/subscriptions/components/ServicesGroupForm/utils';
import ServicesGroupModalFooter from '@features/subscriptions/components/ServicesGroupModalFooter';
import ServicesGroupFormCreateAndUpdateCart
  from '@features/subscriptions/components/ServicesGroupForm/ServicesGroupFormCreateAndUpdateCart';
import ServiceGroupsLayout from '@features/subscriptions/components/ServiceGroupsLayout';
import {
  useClearServiceAndPreServicesOnCloseModal,
  useCloseModalIfServicesIsMissing,
  useFormikContext,
  useGetFormData,
  useOnNextMemberRegistration,
  useSetServicesProfile,
  useShouldWeRefreshClientSideData,
} from '@features/subscriptions/utilsSubscriptionModal';
import { selectEventCategoryServicesGroupsIds } from '@features/eventDetail/slices/eventDetailServerSlice';
import { selectSubscriptionDetails, selectSubscriptionEvent, selectSubscriptionPack } from '../slices/formSubscriptionSlice';

interface ModalServicesGroupProps {
  closeModal: () => void;
  categoryId: number;
}

const ModalSubscriptionCartCreate = ({
  closeModal,
  categoryId,
}: ModalServicesGroupProps) => {
  const categoryIdNumber = Number(categoryId);
  if (!Number.isFinite(categoryIdNumber)) {
    throw Error(`ModalCreateSubscription : invalid categoryId ${categoryId}`);
  }

  useCloseModalIfServicesIsMissing(categoryId, closeModal);
  useClearServiceAndPreServicesOnCloseModal();

  const subscription = useSelector(selectSubscriptionDetails(categoryId));
  if (!subscription) {
    return null;
  }

  const pack = useSelector(selectSubscriptionPack);

  const { refreshClientSideData } = useShouldWeRefreshClientSideData(categoryId);
  const isLoadingDataBase = useGetClientSideData(
    pack ? GET_DATA_FOR.SERIE_SUBSCRIPTIONS : GET_DATA_FOR.EVENT_SUBSCRIPTION,
    [refreshClientSideData],
  );

  const event = useSelector(selectSubscriptionEvent(categoryId));
  const servicesGroupsIds = useSelector(selectEventCategoryServicesGroupsIds(event?._idMso));
  const [validatingServicesGroups, setValidatingServicesGroups] = useState(true);

  const {
    nbTeamMates,
    teamMateIndex,
    changeTeamMate,
    addServiceToCurrentTeamMate,
    isLoading,
  } = useRegistration({ categoryId: categoryIdNumber });

  useSetServicesProfile(categoryId, teamMateIndex);

  const { formikContext, setFormikContext } = useFormikContext();
  const onNextMemberRegistration = useOnNextMemberRegistration(formikContext, teamMateIndex, categoryIdNumber);
  const {
    teamMateRegisteredValues,
    storedCurrentTeamMate,
    defaultValues,
    schemaValidator,
  } = useGetFormData(teamMateIndex, categoryId);

  const onPreviousMemberRegistration = async () => {
    if (teamMateIndex === 0) {
      closeModal();
      return;
    }
    scrollToTop();
    changeTeamMate(-1);
  };

  const handleSubmit = async (values) => {
    changeTeamMate(1);
    addServiceToCurrentTeamMate(values);
  };

  useEffect(() => {
    if (!isLoadingDataBase && servicesGroupsIds.length === 0) {
      (async () => {
        setValidatingServicesGroups(true);
        await handleSubmit({});
        setValidatingServicesGroups(false);
      })();
    } else {
      setValidatingServicesGroups(false);
    }
  }, [isLoadingDataBase]);

  return (
    <ModalTemplate
      footer={(
        <ServicesGroupModalFooter
          onNext={onNextMemberRegistration}
          onPrevious={onPreviousMemberRegistration}
          previousButtonAsCancel={teamMateIndex === 0}
          nextButtonAsValidate={teamMateIndex === nbTeamMates - 1}
          nextButtonDisabled={formikContext?.isSubmitting || !storedCurrentTeamMate}
          hideFooter={isLoading || isLoadingDataBase}
        />
      )}
      closeModal={closeModal}
    >
      <ServiceGroupsLayout
        nbTeamMates={nbTeamMates}
        isLoading={isLoading || isLoadingDataBase || validatingServicesGroups}
        inEditMode={false}
        teamMateIndex={teamMateIndex}
        categoryId={categoryId}
      >
        <ServicesGroupFormCreateAndUpdateCart
          eventId={event._idMso}
          key={teamMateIndex}
          initialValues={teamMateRegisteredValues ?? defaultValues}
          schemaValidator={schemaValidator}
          onSubmit={handleSubmit}
          getFormikContext={setFormikContext}
        />
      </ServiceGroupsLayout>
    </ModalTemplate>
  );
};

export default ModalSubscriptionCartCreate;
