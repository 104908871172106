import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { mediaBreakpoint, ResponsiveWrapper } from '@services/responsiveProvider';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textFooterReassurance } from '@features/ui/styles/textStyles';

interface ReassuranceData {
  iconName: ICON_NAME;
  description: string;
  gridAreaName: string;
}

const reassuranceData: ReassuranceData[] = [
  {
    iconName: ICON_NAME.assetOnline,
    description: 'footer_reassuranceRegister',
    gridAreaName: 'register',
  },
  {
    iconName: ICON_NAME.assetTeam,
    description: 'footer_reassuranceTeam',
    gridAreaName: 'team',
  },
  {
    iconName: ICON_NAME.assetBib,
    description: 'footer_reassuranceOrder',
    gridAreaName: 'order',
  },
  {
    iconName: ICON_NAME.assetPayment,
    description: 'footer_reassurancePayment',
    gridAreaName: 'payments',
  },
  {
    iconName: ICON_NAME.assetResults,
    description: 'footer_reassuranceResults',
    gridAreaName: 'results',
  },
];

const Reassurance = () => {
  const { t } = useTranslation();

  return (
    <>
      <ReassuranceHeader />
      <ResponsiveWrapper>
        <ReassuranceContainer>
          {reassuranceData.map(reassuranceItem => (
            <ReassuranceItem key={reassuranceItem.iconName}>
              <ReassuranceIcon gridAreaName={reassuranceItem.gridAreaName}>
                <Icon name={reassuranceItem.iconName} size="30px" color="var(--color-orange-hover)" />
              </ReassuranceIcon>
              <ReassuranceIconDescription>
                {t(`${reassuranceItem.description}.message`)}
              </ReassuranceIconDescription>
            </ReassuranceItem>
          ))}
        </ReassuranceContainer>
      </ResponsiveWrapper>
    </>
  );
};

export default Reassurance;

const ReassuranceHeader = styled.div`
  height: 10px;
  box-shadow: 0 4px 8px 0 rgba(128,122,122,0.08);
`;

const ReassuranceContainer = styled.div`
  ${textFooterReassurance};
  display: grid;
  column-gap: 42px;
  row-gap: 20px;
  padding: 42px 0 52px;
  grid-template-areas: "register team order payments results";
  
  ${mediaBreakpoint.tablet} {
    padding: 30px 15% 32px;
    grid-template-areas:
      "register team" 
      "order payments"
      "results results";
  }
  
  ${mediaBreakpoint.mobile} {
    padding: 30px 5% 40px;
    grid-template-areas:
      "register"
      "team"
      "order"
      "payments"
      "results";
  }
`;

const ReassuranceItem = styled.div`
  display: flex;
  align-items: center;
`;

const ReassuranceIcon = styled.div<{gridAreaName}>`
  grid-area: ${({ gridAreaName }) => gridAreaName};
`;

const ReassuranceIconDescription = styled.div`
  margin-left: 22px;
`;
