// TODO 1531
const poll = async ({
  fn,
  validate,
  onValidate,
  oldResult,
  interval = 1000,
}: {
  fn: Function, validate: Function, onValidate?: Function, oldResult?: any, interval?: number
}) => {
  const result = await fn(oldResult);
  if (validate(result)) {
    if (onValidate != null) {
      onValidate(result);
    }
    return null;
  }
  const instance = setTimeout(poll, interval, {
    fn,
    validate,
    onValidate,
    oldResult: result,
  });
  setTimeoutInstance(instance);
  return instance;
};
export default poll;

export const setTimeoutInstance = (instance) => {
  if (window['msoInstances'] == null) {
    window['msoInstances'] = [];
  }
  window['msoInstances'].push(instance);
};
export const removeTimeoutInstances = () => {
  if (window['msoInstances'] && window['msoInstances'].length > 0) {
    window['msoInstances'].forEach(instance => clearTimeout(instance));
  }
  window['msoInstances'] = [];
};
