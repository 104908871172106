export default [
  {
    code: 'ZH',
    nameInOfficialLang: 'Zürich',
    siteName: 'Zürich',
  },
  {
    code: 'BE',
    nameInOfficialLang: 'Bern; Berne',
    siteName: 'Bern',
  },
  {
    code: 'LU',
    nameInOfficialLang: 'Luzern',
    siteName: 'Luzern',
  },
  {
    code: 'UR',
    nameInOfficialLang: 'Uri',
    siteName: 'Uri',
  },
  {
    code: 'SZ',
    nameInOfficialLang: 'Schwyz',
    siteName: 'Schwyz',
  },
  {
    code: 'OW',
    nameInOfficialLang: 'Obwalden',
    siteName: 'Obwalden',
  },
  {
    code: 'NW',
    nameInOfficialLang: 'Nidwalden',
    siteName: 'Nidwalden',
  },
  {
    code: 'GL',
    nameInOfficialLang: 'Glarus',
    siteName: 'Glarus',
  },
  {
    code: 'ZG',
    nameInOfficialLang: 'Zug',
    siteName: 'Zug',
  },
  {
    code: 'FR',
    nameInOfficialLang: 'Fribourg; Freiburg',
    siteName: 'Fribourg',
  },
  {
    code: 'SO',
    nameInOfficialLang: 'Solothurn',
    siteName: 'Solothurn',
  },
  {
    code: 'BS',
    nameInOfficialLang: 'Basel-Stadt',
    siteName: 'Basel',
  },
  {
    code: 'BL',
    nameInOfficialLang: 'Basel-Landschaft',
    siteName: 'Basel-Landschaft',
  },
  {
    code: 'SH',
    nameInOfficialLang: 'Schaffhausen',
    siteName: 'Schaffhausen',
  },
  {
    code: 'AR',
    nameInOfficialLang: 'Appenzell Ausserrhoden',
    siteName: 'Appenzell Ausserrhoden',
  },
  {
    code: 'AI',
    nameInOfficialLang: 'Appenzell Innerrhoden',
    siteName: 'Appenzell Innerrhoden',
  },
  {
    code: 'SG',
    nameInOfficialLang: 'St. Gallen',
    siteName: 'St. Gallen',
  },
  {
    code: 'GR',
    nameInOfficialLang: 'Graubünden; Grischun; Grigioni',
    siteName: 'Graubünden',
  },
  {
    code: 'AG',
    nameInOfficialLang: 'Aargau',
    siteName: 'Aargau',
  },
  {
    code: 'TG',
    nameInOfficialLang: 'Thurgau',
    siteName: 'Thurgau',
  },
  {
    code: 'TI',
    nameInOfficialLang: 'Ticino',
    siteName: 'Ticino',
  },
  {
    code: 'VD',
    nameInOfficialLang: 'Vaud',
    siteName: 'Vaud',
  },
  {
    code: 'VS',
    nameInOfficialLang: 'Valais; Wallis',
    siteName: 'Valais',
  },
  {
    code: 'NE',
    nameInOfficialLang: 'Neuchâtel',
    siteName: 'Neuchâtel',
  },
  {
    code: 'GE',
    nameInOfficialLang: 'Genève',
    siteName: 'Genève',
  },
  {
    code: 'JU',
    nameInOfficialLang: 'Jura',
    siteName: 'Jura',
  },
];
