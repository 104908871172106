import { loginCharAccepted, numberInStringRegex, specialCharInStringRegex } from '@utils/regexs';
import yup from '@services/translation/translatedYup';
import { USER_ACCOUNT_FORM_CONTEXT } from '@features/users/components/UserAccountForm';

export const usernameFieldValidation = (t) => ({
  username: yup.string()
    .required()
    .label(t('userAccountForm_usernameLabel.message'))
    .test('username', t('userAccountForm_loginCharError.message'), (value) => loginCharAccepted.test(value))
    .test('username', 'TODO: Less than 50 char', (value) => value?.length < 50),
});

export const emailFieldValidation = (t) => ({
  email: yup.string()
    .email()
    .required()
    .label(t('userAccountForm_emailLabel.message')),
});

export const passwordFieldValidation = (t) => ({
  password: yup.string()
    .required()
    .label(t('userAccountForm_passwordLabel.message'))
    .test('password', t('userAccountForm_passwordError.message'), (value) => {
      const isLengthValid = value?.length >= 8;
      const hasANumber = numberInStringRegex.test(value);
      const hasASpecialChar = specialCharInStringRegex.test(value);
      return isLengthValid && hasANumber && hasASpecialChar;
    }),
});

export const passwordConfirmFieldValidation = (t) => ({
  passwordConfirm: yup.string()
    .required()
    .label(t('userAccountForm_passwordConfirmLabel.message'))
    .test(
      'passwordConfirm',
      t('userAccountForm_passwordConfirmError.message'),
      (value, context) => context.parent.password === value
    ),
});

export const termOfUseFieldValidation = (t) => ({
  termOfUse: yup.boolean()
    .required()
    .label(t('userAccountForm_termOfUseLabel.message'))
    .oneOf([true], t('userAccountForm_termOfUseError.message')),
});

export const privacyPolicyFieldValidation = (t) => ({
  privacyPolicy: yup.boolean()
    .required()
    .label(t('userAccountForm_privacyPolicyLabel.message'))
    .oneOf([true], t('userAccountForm_privacyPolicyError.message')),
});

const UserAccountFormValidationSchema = (t, context: USER_ACCOUNT_FORM_CONTEXT) => {
  switch (context) {
    case USER_ACCOUNT_FORM_CONTEXT.REGISTER:
      return yup.object().shape({
        ...usernameFieldValidation(t),
        ...emailFieldValidation(t),
        ...passwordFieldValidation(t),
        ...passwordConfirmFieldValidation(t),
        ...termOfUseFieldValidation(t),
        ...privacyPolicyFieldValidation(t),
      });
    case USER_ACCOUNT_FORM_CONTEXT.ACTIVATE_ACCOUNT:
      return yup.object().shape({
        ...passwordFieldValidation(t),
        ...passwordConfirmFieldValidation(t),
        ...termOfUseFieldValidation(t),
        ...privacyPolicyFieldValidation(t),
      });
    case USER_ACCOUNT_FORM_CONTEXT.REGISTER_FRIEND:
      return yup.object().shape({
        ...usernameFieldValidation(t),
        ...emailFieldValidation(t),
      });
    case USER_ACCOUNT_FORM_CONTEXT.RESET_PASSWORD:
      return yup.object().shape({
        ...passwordFieldValidation(t),
        ...passwordConfirmFieldValidation(t),
      });
    default:
      return {};
  }
};

export default UserAccountFormValidationSchema;
