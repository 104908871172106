import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik, FormikContextType } from 'formik';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { GENDER } from '@root/src/types';
import { countryList } from '@services/enum/countries';
import { mediaBreakpoint } from '@services/responsiveProvider';
import { useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import MultiInputLine from '@features/ui-input/formik/MultiInputLine';
import InputSelect from '@features/ui-input/components/InputSelect';
import { getGenderChoice } from '@features/users/components/UserProfileForm';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button from '@features/ui/components/Button';
import ProfileFormSchema from '@features/account/components/ProfileFormSchema';
import { getCantonsOptions, getLanguagesOptions } from '@features/users/components/UserAddressForm';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { selectCurrentUserDetail } from '@features/users/slices/userSlice';
import { profileFromAccountFormToApi } from '@features/users/formatters';
import GetFormik from '@features/ui-input/formik/GetFormik';

export interface ProfileFormInputs {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  address: string,
  postalCode: string,
  locality: string,
  canton: string,
  country: string,
  birthdateDay: number,
  birthdateMonth: number,
  birthdateYear: number,
  gender: GENDER,
  language: string,
  nationality: string,
  club: string,
  licenseNumber: string
  licenseCAS?: string;
}

interface ProfileFormProps {
  initialValues?: ProfileFormInputs
  getFormikContext?: (formik: FormikContextType<ProfileFormInputs>) => void
  withSubmitOnForm?: boolean
  onSubmit?: () => void,
}

const ProfileForm = ({
  initialValues,
  getFormikContext,
  withSubmitOnForm = true,
  onSubmit,
}: ProfileFormProps) => {
  const { t } = useTranslation();
  const [validationDateError, setValidationDateError] = useState('');

  const userDetail = useSelector(selectCurrentUserDetail);
  const cantonsOptions = getCantonsOptions(t);
  const languagesOptions = getLanguagesOptions(t);
  const genderChoice = getGenderChoice(t);
  const requestHandler = useRequestHandler();

  const updateProfile = async (values: ProfileFormInputs) => {
    const { response } = await requestHandler({
      request: api.profile
        .update({ body: profileFromAccountFormToApi(values) }),
    });
    if (response && response?.ok) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('profileForm_updateSuccess.message')}
        text=""
      />);
    }
  };

  const initialValuesProfile: ProfileFormInputs = initialValues ?? {
    id: userDetail._idMso,
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    address: userDetail.legAddress.address,
    email: userDetail.email.email,
    birthdateDay: userDetail.birthDateObj.day,
    birthdateMonth: userDetail.birthDateObj.month,
    birthdateYear: userDetail.birthDateObj.year,
    club: userDetail.club ?? '',
    country: userDetail.legAddress.country,
    canton: userDetail.legAddress.department ?? '',
    gender: userDetail.gender,
    language: userDetail.language,
    licenseNumber: userDetail.licence ?? '',
    licenseCAS: userDetail.licenseCAS?.articleDescription,
    nationality: userDetail.nationality ?? '',
    postalCode: userDetail.legAddress.postalCode,
    locality: userDetail.legAddress.locality,
  };

  return (
    <Formik
      initialValues={initialValuesProfile}
      onSubmit={onSubmit ?? updateProfile}
      validationSchema={ProfileFormSchema(setValidationDateError, t)}
    >
      {({
        values,
        errors,
      }) => {
        const {
          birthdateDay,
          birthdateMonth,
          birthdateYear,
        } = errors;
        const hasSpecificDateError = `${birthdateDay}${birthdateMonth}${birthdateYear}`.trim() !== '';

        return (
          <StyledForm>
            {getFormikContext && <GetFormik getFormikContext={getFormikContext} />}
            <InputLine
              name="id"
              label={t('profileForm_idMSOLabel.message')}
              as={InputText}
              disabled
              tooltipSpace
              labelMinWidth="319px"
            />
            <InputLine
              label={t('userProfileForm_firstnameLabel.message')}
              name="firstName"
              as={InputText}
              showRequiredChip
              tooltipSpace
              labelMinWidth="319px"
            />
            <InputLine
              label={t('userProfileForm_lastnameLabel.message')}
              name="lastName"
              as={InputText}
              showRequiredChip
              tooltipSpace
              labelMinWidth="319px"
            />
            <InputLine
              key="email"
              name="email"
              label={t('userAccountForm_emailLabel.message')}
              as={InputText}
              showRequiredChip
              validationChipSpace
              labelMinWidth="319px"
              disabled
            />
            <InputLine
              name="address"
              label={t('userAddressForm_addressLabel.message')}
              as={InputText}
              showRequiredChip
              tooltipSpace
              labelMinWidth="319px"
            />
            <MultiInputLine
              label={t('userAddressForm_postalAndLocalityLabel.message')}
              labelMinWidth="319px"
              tooltipSpace
              spaceInterInput="4px"
              inputs={[
                {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'postalCode',
                }, {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'locality',
                  inputWrapperClassName: 'inputLocality',
                },
              ]}
            />
            {values.country === 'SUI' && (
              <InputLine
                label={t('userAddressForm_cantonLabel.message')}
                as={InputSelect}
                name="canton"
                options={cantonsOptions}
                labelMinWidth="319px"
                tooltipSpace
              />
            )}
            <InputLine
              label={t('userAddressForm_countryLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="country"
              options={countryList(t)}
              labelMinWidth="319px"
              tooltipSpace
            />
            <MultiInputLine
              tooltipSpace
              label={t('userProfileForm_birthdateLabel.message')}
              tooltip={t('userProfileForm_birthdateTooltip.message')}
              labelMinWidth="319px"
              spaceInterInput="4px"
              globalError={!hasSpecificDateError && validationDateError}
              inputs={[
                {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'birthdateDay',
                  type: 'number',
                }, {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'birthdateMonth',
                  type: 'number',

                }, {
                  as: InputText,
                  showRequiredChip: true,
                  name: 'birthdateYear',
                  type: 'number',
                  disabled: true,
                  inputWrapperClassName: 'inputBirthdateYear',
                },
              ]}
            />
            <InputLine
              label={t('userProfileForm_genderLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="gender"
              disabled
              options={genderChoice}
              tooltip={t('userProfileForm_genderTooltip.message')}
              labelMinWidth="319px"
            />
            <InputLine
              label={t('userAddressForm_languageLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="language"
              options={languagesOptions}
              labelMinWidth="319px"
              tooltipSpace
            />
            <InputLine
              label={t('userProfileForm_nationalityLabel.message')}
              as={InputSelect}
              showRequiredChip
              name="nationality"
              options={countryList(t)}
              tooltipSpace
              labelMinWidth="319px"
            />
            <InputLine
              name="club"
              label={t('userAddressForm_clubLabel.message')}
              as={InputText}
              tooltip={t('userAddressForm_clubTooltip.message')}
              labelMinWidth="319px"
            />
            <InputLine
              name="licenseNumber"
              label={t('profileForm_licenceLabel.message')}
              as={InputText}
              tooltip={t('profileForm_licenceNumberTooltip.message')}
              labelMinWidth="319px"
            />
            {values.licenseCAS && (
              <InputLine
                name="licenseCAS"
                label={t('profileForm_licenceCasLabel.message')}
                as={InputText}
                disabled
                tooltipSpace
                labelMinWidth="319px"
              />
            )}

            {withSubmitOnForm && (
              <SubmitButton
                text={t('profileForm_submitButton.message')}
                leftIcon={ICON_NAME.check}
                type="submit"
              />
            )}
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ProfileForm;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;
const SubmitButton = styled(Button)`
  flex-grow: 1;
  margin-left: 364px;
  margin-right: 44px;
  margin-top: 20px;
  ${mediaBreakpoint.tablet} {
    margin-left: unset;
    margin-top: 36px;
  }
`;
