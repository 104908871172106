import { Event, EVENT_LIST_ITEM_TYPES, Serie } from '@root/src/types';
import { serieFromApiToReducer } from '@features/serieDetail/formatters';
import { eventFromApiToReducer } from '@features/eventDetail/formatters';

export const eventOrSerieFromApiToReducer = (eventOrSerieApi: any): Event | Serie => {
  if (eventOrSerieApi._type === EVENT_LIST_ITEM_TYPES.serie) {
    return serieFromApiToReducer(eventOrSerieApi);
  }

  return eventFromApiToReducer(eventOrSerieApi);
};
