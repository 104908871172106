import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    background-color: var(--color-red);
  }
  50% {
    background-color: var(--color-white);
  }
  100% {
    background-color: var(--color-red);
  }
`;

const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 1px;
  background-color: var(--color-red);
  margin-right: 8px;
  animation: ${blink} 2s linear infinite;
`;

export default Indicator;
