import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRollbarPerson } from '@rollbar/react';
import { mediaBreakpoint, ResponsiveWrapper } from '@services/responsiveProvider';
import Footer from '@features/ui-layout/components/Footer';
import Header from '@features/ui-layout/components/Header';
import { selectCurrentUserDetail } from '@features/users/slices/userSlice';

interface LayoutProps {
  children?: React.ReactNode,
}

const Layout = ({ children } : LayoutProps) => {
  const userDetail = useSelector(selectCurrentUserDetail);
  useRollbarPerson(userDetail ? {
    id: userDetail._idMso,
    email: userDetail.email?.email,
    username: `${userDetail.firstName} ${userDetail.lastName}`,
  } : null);
  return (
    <Wrapper>
      <Header />
      <MainContent>
        <ResponsiveWrapper>
          {children}
        </ResponsiveWrapper>
      </MainContent>
      <Footer />
    </Wrapper>
  );
};
export default Layout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
const MainContent = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: var(--menu-height);
  ${mediaBreakpoint.tablet} {
    margin-top: 78px;
  }
`;
