import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { mediaBreakpoint } from '@root/src/services/responsiveProvider';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';

interface FooterProps {
  onNext: () => void,
  onPrevious: () => void,
  goToStep: (number) => void,
  currentStep: number,
  isFirstStep: boolean,
  isLastStep: boolean,
  onSubmit: () => void,
  nextButtonDisabled: boolean,
  hasDuplicates: boolean,
}

const Footer = ({
  onNext,
  onPrevious,
  goToStep,
  currentStep,
  isFirstStep,
  isLastStep,
  onSubmit,
  hasDuplicates,
  nextButtonDisabled,
}: FooterProps) => {
  const { t } = useTranslation();
  const stepNumberAfterVerification = 3;

  const isVerification = currentStep === stepNumberAfterVerification - 1;

  return (
    <Container>
      {isFirstStep && <div />}
      {!isFirstStep && (
        <Button
          onClick={() => {
            if (currentStep === stepNumberAfterVerification && !hasDuplicates) {
              goToStep(stepNumberAfterVerification - 2);
            } else {
              onPrevious();
            }
          }}
          text={t('previous.message')}
          leftIcon={ICON_NAME.arrowBack}
          variant={BUTTON_VARIANT.secondary}
          tabIndex={-1}
        />
      )}
      {isLastStep && (
        <Button
          onClick={onSubmit}
          leftIcon={ICON_NAME.check}
          text={t('modalRegister_buttonSubmitLabel.message')}
          disabled={nextButtonDisabled}
        />
      )}
      {!isLastStep && !isVerification && (
        <Button
          onClick={onNext}
          disabled={nextButtonDisabled}
          leftIcon={ICON_NAME.arrowForward}
          text={t('next.message')}
        />
      )}
      {!isLastStep && isVerification && (
        <Button
          onClick={onNext}
          disabled={nextButtonDisabled}
          leftIcon={ICON_NAME.iconPersonAdd}
          variant={BUTTON_VARIANT.link}
          text={t('footerServuceUserSubscription_AddTeamMate.message')}
        />
      )}
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;

  ${mediaBreakpoint.mobile} {
    flex-wrap: wrap;
    > * {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;
