import { useRouter } from 'next/router';
import { useStore } from 'react-redux';
import { Category, EngagedStats, EngagedUser, Event, Quota, SERVICE_GROUP_TYPE, ServiceGroup } from '@root/src/types';
import { useRequestHandler } from '@services/api/handlers';
import api, { ListParameters } from '@services/api';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import {
  pushEngaged,
  selectEventDetail,
  selectEventCategories,
  selectEventCategoryServicesGroups,
  selectEventEngaged,
  selectEventHasDiscountVoucher,
  selectEventQuota,
  setCategories,
  setCategoryQuotas,
  setEvent,
  setEventQuotas,
  setHasEventDiscount,
  setServices,
  pushEngagedPaginated,
  selectEventEngagedStats,
  setEngagedStats,
  selectEventEngagedCantons,
  setEngagedCantons,
  setEngagednationalities,
  selectEventEngagedNationalities,
} from '@features/eventDetail/slices/eventDetailServerSlice';
import {
  selectEventCategoryPreServicesGroups,
  setPreServicesGroup,
} from '@features/eventDetail/slices/eventDetailClientSlice';
import { eventCategoryFromApiToReducer } from './formatters';

class GetEventData extends InitDataHandler {
  getEvent: (eventId: number) => Promise<Event | null> = async (eventId) => {
    const data = {
      eventId,
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.events.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEvent({
        apiEvent: json,
        eventId,
      }));
      return selectEventDetail(eventId)(this.store.getState());
    }

    return null;
  };

  getEventQuotas: (eventId: number) => Promise<Quota | null | undefined> = async (eventId) => {
    const data = {
      eventId,
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.events.quotas.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEventQuotas({
        quotaApi: json,
        eventId,
      }));
      return selectEventQuota(eventId)(this.store.getState());
    }

    return null;
  }

  getEventCategories: (eventId: number, athleteId?: number) => Promise<Category[] | null> =
    async (eventId, athleteId) => {
      const data: any = {
        eventId,
        locale: this.locale,
      };
      if (athleteId) {
        data.athleteId = athleteId;
      }

      const { response } = await this.requestHandler({
        request: api.events.categories.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
      });

      if (response && response?.ok) {
        const json = await response.json();
        this.dispatch(setCategories({
          apiCategories: json,
          eventId,
        }));
        return selectEventCategories(eventId)(this.store.getState());
      }

      return null;
    };

  getEngagedList: (
    eventId: number,
    categoryId: number
  ) => Promise<EngagedUser[] | null> = async (eventId, categoryId) => {
    const data = {
      categoryId,
      locale: this.locale,
    };
    const { response } = await this.requestHandler({
      request: api.events.categories.engaged.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });
    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(pushEngaged({
        engagedApi: json,
        eventId,
      }));
      return selectEventEngaged(eventId)(this.store.getState());
    }
    return null;
  };

  getEngagedListCount: (
    eventId: number,
  ) => Promise<EngagedStats | null>
  = async (eventId) => {
    const data = {
      eventId,
      locale: this.locale,
    };
    const { response } = await this.requestHandler({
      request: api.results.engaged.events.count({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });
    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEngagedStats({
        engagedApi: json,
        eventId,
      }));
      return selectEventEngagedStats(eventId)(this.store.getState());
    }
    return null;
  };

  getEngagedListCantons: (
    eventId: number,
  ) => Promise<Array<string> | null>
  = async (eventId) => {
    const data = {
      eventId,
      locale: this.locale,
    };
    const { response } = await this.requestHandler({
      request: api.results.engaged.events.cantons({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });
    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEngagedCantons({
        engagedApi: json,
        eventId,
      }));
      return selectEventEngagedCantons(eventId)(this.store.getState());
    }
    return null;
  };

  getEngagedListNationalities: (
    eventId: number,
  ) => Promise<Array<string> | null>
  = async (eventId) => {
    const data = {
      eventId,
      locale: this.locale,
    };
    const { response } = await this.requestHandler({
      request: api.results.engaged.events.nationalities({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });
    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setEngagednationalities({
        engagedApi: json,
        eventId,
      }));
      return selectEventEngagedNationalities(eventId)(this.store.getState());
    }
    return null;
  };

  getEngagedPaginatedList: (
    eventId: number,
    parameters?: ListParameters,
  ) => Promise<EngagedUser[] | null>
  = async (
    eventId, parameters,
  ) => {
    let data: any = {
      locale: this.locale,
      eventId,
    };
    if (parameters != null) {
      data = {
        ...data,
        ...parameters,
      };
    }
    if (data.q == null) {
      data.q = '*';
    }
    const { response } = await this.requestHandler({
      request: api.results.engaged.events.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(pushEngagedPaginated({
        engagedApi: json,
        eventId,
      }));
      return selectEventEngaged(eventId)(this.store.getState());
    }
    return null;
  };

  getHasDiscountVoucher: (eventId: number) => Promise<boolean | null | undefined> = async (eventId) => {
    const data = {
      eventId: `mso:${eventId}`,
      locale: this.locale,
    };

    const { response } = await this.requestHandler({
      request: api.subscription.reductions.read({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const json = await response.json();
      this.dispatch(setHasEventDiscount({
        reductionsApi: json,
        eventId,
      }));
      return selectEventHasDiscountVoucher(eventId)(this.store.getState());
    }

    return null;
  };

  getEventCategoryService:
    (eventId:number, categoryId: number, groupType: SERVICE_GROUP_TYPE) => Promise<ServiceGroup[] | null> =
    async (eventId, categoryId, groupType) => {
      const data = {
        eventId,
        categoryId,
        locale: this.locale,
      };

      const { response } = await this.requestHandler({
        request: api.events.categories.services.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
      });

      if (response && response?.ok) {
        const json = await response.json();

        if (groupType === SERVICE_GROUP_TYPE.PRE_SERVICES) {
          this.dispatch(setPreServicesGroup({
            apiPreServices: json,
            category: categoryId,
          }));
          return selectEventCategoryPreServicesGroups(categoryId)(this.store.getState());
        }
        this.dispatch(setServices({
          apiServices: json,
          eventId,
        }));
        return selectEventCategoryServicesGroups(eventId)(this.store.getState());
      }

      return null;
    }

  getEventCategoryQuota: (eventId: number, categoryId: number) => Promise<true | null> =
    async (eventId, categoryId) => {
      const data = {
        eventId,
        categoryId,
        locale: this.locale,
      };

      const { response } = await this.requestHandler({
        request: api.events.categories.quotas.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
      });

      if (response && response?.ok) {
        const json = await response.json();
        this.dispatch(setCategoryQuotas({
          categoryId,
          quotaApi: json,
          eventId,
        }));
        return true;
      }

      return null;
    };

  _getEventCategory: (
    categoryId: number
  ) => Promise<Category | null> = async (categoryId) => {
    const { response } = await this.requestHandler({ request: api.categories.read({ categoryId }) });

    if (response && response?.ok) {
      const json = await response.json();
      return eventCategoryFromApiToReducer(json);
    }
    return null;
  }
}

export default GetEventData;

export const useGetEventData = () => {
  const store = useStore();
  const requestHandler = useRequestHandler();
  const router = useRouter();

  return new GetEventData({
    store,
    requestHandler,
    router,
  });
};
