import { toast } from 'react-toastify';
import api from '@root/src/services/api';
import { RequestHandlerTypeClientSide, STATUS_RANGE } from '@root/src/services/api/handlers';
import { Profile } from '@root/src/types';
import { ServicesValuesByTeamMate } from '@features/subscriptions/slices/formSubscriptionSlice';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

export const scrollToTop = () => {
  // Desktop
  document.getElementById('mso_modalContent')
    ?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  // Mobile
  document.querySelector('#mso_modalContent > div')
    ?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
};

export const makeFriendShip = async (
  services: Array<ServicesValuesByTeamMate>,
  userDetail: Profile,
  setSending,
  t,
  requestHandler: RequestHandlerTypeClientSide,
) => {
  const friendAccessPromises = services
    .filter(
      servicePerTeamMate => parseInt(servicePerTeamMate.profile?._idMso as any, 10)
        !== parseInt(userDetail._idMso as any, 10),
    )
    .map(async (servicePerTeamMate) => {
      const { profile } = servicePerTeamMate;
      const { response: addFriendResponse } = await requestHandler({
        request: api.profile.access.create({
          body: {
            athlete: profile._id,
            birthDateObj: profile.birthDateObj,
            level: 1,
          },
        }),
        overwrite: {
          [STATUS_RANGE.CLIENT_ERROR]: async (response) => response,
          [STATUS_RANGE.SERVER_ERROR]: async (response) => response,
        },
      });

      if (addFriendResponse && !addFriendResponse.ok) {
        const error = await addFriendResponse.json();
        if (error.code !== 'error.accessAlreadyExists') {
          throw new Error(error.message);
        }
      }
    });

  try {
    await Promise.all(friendAccessPromises);
  } catch (err) {
    setSending(false);
    toast(<Alert
      variant={ALERT_VARIANTS.ERROR}
      title={t('modalServicesGroupFrom_friendRequestErrorTitle.message')}
      text={err.message}
    />);
  }
};
