import yup from '@root/src/services/translation/translatedYup';

const MobileNumberValidationSchema = (t) => (
  yup.object().shape({
    code: yup.string()
      .required()
      .length(6)
      .label(t('mobileNumberValidationForm_inputCode.message')),
  })
);

export default MobileNumberValidationSchema;
