import ICU from 'i18next-icu';
import path from 'path';
import i18n from '@root/i18n.config';

const icu = new ICU();
const i18nConfig = {
  i18n,
  localePath: path.resolve('./src/assets/locales'),
  defaultLanguage: i18n.defaultLocale,
  otherLanguages: i18n.locales,
  serializeConfig: false,
  use: [icu],
};
export default i18nConfig;
