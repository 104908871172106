import React from 'react';
import { useTranslation } from 'next-i18next';
import { SPORT_CATEGORIES } from '@root/src/types';
import { formatDateAsYear, formatEventDate } from '@utils/dates';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import {
  Container,
  Footer,
  IconContainer,
  NameWrapper,
  Section,
  SectionNotVisibleOnMobile,
  SportContainer,
  TagsWrapper,
  Timing,
} from '@features/eventList/components/EventCard/styles';
import { formatFullLocation } from '@features/eventDetail/utils';

interface EventCardRawProps {
  sportCategory: SPORT_CATEGORIES;
  eventType: string;
  name: string;
  athletes?: string;
  nbAthletes?: number;
  categoryAndDistance?: string;
  location: string;
  site?: string;
  dateFrom?: string;
  dateTo?: string;
  date: string;
  tags?: React.ReactElement[];
  onClick?: (e: React.SyntheticEvent) => void;
  className?: string;
  footer?: React.ReactElement;
  resultsTiming?: string;
}

export const sportIconMapping = {
  [SPORT_CATEGORIES.alpineSkiing]: ICON_NAME.sportAlpineSkiing,
  [SPORT_CATEGORIES.crossCountrySkiing]: ICON_NAME.sportCrossCountrySkiing,
  [SPORT_CATEGORIES.running]: ICON_NAME.sportTrail,
  [SPORT_CATEGORIES.cycling]: ICON_NAME.sportCycling,
  [SPORT_CATEGORIES.motorSport]: ICON_NAME.sportMoto,
  [SPORT_CATEGORIES.triathlon]: ICON_NAME.sportTriathlon,
  [SPORT_CATEGORIES.other]: ICON_NAME.sportOther,
};

export const sportDescriptionMapping = {
  [SPORT_CATEGORIES.alpineSkiing]: 'eventCard_sportAlpineSkiing.message',
  [SPORT_CATEGORIES.crossCountrySkiing]: 'eventCard_sportCrossCountrySkiing.message',
  [SPORT_CATEGORIES.running]: 'eventCard_sportTrail.message',
  [SPORT_CATEGORIES.cycling]: 'eventCard_sportCycling.message',
  [SPORT_CATEGORIES.motorSport]: 'eventCard_sportMoto.message',
  [SPORT_CATEGORIES.triathlon]: 'eventCard_sportTriathlon.message',
  [SPORT_CATEGORIES.other]: 'eventCard_sportOther.message',
};

export const EventCardRaw = ({
  sportCategory,
  eventType,
  name,
  athletes,
  nbAthletes,
  categoryAndDistance,
  location,
  site,
  dateFrom,
  dateTo,
  date,
  tags,
  onClick,
  className,
  footer,
  resultsTiming,
}: EventCardRawProps) => {
  const { t } = useTranslation();
  return (
    <Container
      onClick={onClick}
      className={className}
    >
      <Section>
        <Icon name={sportIconMapping[sportCategory]} size="36px" />
      </Section>
      <NameWrapper>
        <h4>{name}</h4>
        <h3>{formatDateAsYear(date)}</h3>
        {athletes && (
          <IconContainer>
            <Icon name={nbAthletes > 1 ? ICON_NAME.iconGroups : ICON_NAME.person} color="var(--color-grey3)" />
            {athletes}
          </IconContainer>
        )}
        {categoryAndDistance && (
          <IconContainer>
            <Icon name={ICON_NAME.playlistAddCheck} color="var(--color-grey3)" />
            {categoryAndDistance}
          </IconContainer>
        )}
        {resultsTiming && (
          <IconContainer>
            <Icon name={ICON_NAME.timer} color="var(--color-grey3)" />
            <Timing>{resultsTiming}</Timing>
          </IconContainer>
        )}
      </NameWrapper>
      <SectionNotVisibleOnMobile>{formatEventDate(date, dateFrom, dateTo, ' ')}</SectionNotVisibleOnMobile>
      <SectionNotVisibleOnMobile>
        {formatFullLocation(location, site)}
      </SectionNotVisibleOnMobile>
      <SectionNotVisibleOnMobile>
        <SportContainer>
          <div>{eventType}</div>
          <div>{t(sportDescriptionMapping[sportCategory])}</div>
        </SportContainer>
      </SectionNotVisibleOnMobile>
      <Section justiyContent="flex-end">
        {onClick && (
          <Icon name={ICON_NAME.arrowForward} color="var(--color-orange)" />
        )}
      </Section>
      <TagsWrapper>
        {tags}
      </TagsWrapper>
      <Footer>{footer}</Footer>
    </Container>
  );
};
