import React from 'react';
import { useTranslation } from 'next-i18next';
import { Form, Formik, FormikContextType } from 'formik';
import GetFormik from '@features/ui-input/formik/GetFormik';
import InputLine from '@features/ui-input/formik/InputLine';
import InputText from '@features/ui-input/components/InputText';
import MobileNumberValidationSchema from '@features/account/components/MobileNumberValidationSchema';

export interface MobileNumberValidationFormInputs {
  code: string;
}

interface MobileNumberValidationFormProps {
  getFormikContext?: (formik: FormikContextType<MobileNumberValidationFormInputs>) => void,
  onSubmit: () => void;
  className?: string;
}

const MobileNumberValidationForm = ({
  getFormikContext,
  onSubmit,
  className,
}: MobileNumberValidationFormProps) => {
  const { t } = useTranslation();
  const initialValues: MobileNumberValidationFormInputs = { code: '' };
  const validationSchema = MobileNumberValidationSchema(t);

  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <GetFormik getFormikContext={getFormikContext} />
          <InputLine
            name="code"
            label={t('mobileNumberValidationForm_inputCode.message')}
            as={InputText}
            showRequiredChip
            labelMinWidth="202px"
          />
        </Form>

      </Formik>
    </div>
  );
};

export default MobileNumberValidationForm;
