import React from 'react';
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useRequestHandler } from '@services/api/handlers';
import { mediaBreakpoint } from '@services/responsiveProvider';
import api from '@services/api';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1, textP, textPBold } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import { selectAccessOnMyFriendsByID, selectAccessOnMyProfileByID } from '@features/users/slices/userSlice';

interface ModalRemoveAccessAthlete {
  id: string,
  fullName: string,
}

interface ModalRemoveAccessProps {
  closeModal: () => void;
  athlete: ModalRemoveAccessAthlete
}

const ModalRemoveAccess = ({
  closeModal,
  athlete,
}: ModalRemoveAccessProps) => {
  const { t } = useTranslation();
  const requestHandler = useRequestHandler();

  const removeAccess = async () => {
    const { response } = await requestHandler({ request: api.profile.access.delete({ accessId: athlete.id }) });

    if (response && response?.ok) {
      toast(<Alert
        variant={ALERT_VARIANTS.SUCCESS}
        title={t('modalRemoveAccess_updateSuccess.message')}
        text=""
      />);
      closeModal();
    }
  };

  const ModalFooter = () => (
    <Footer>
      <Button
        onClick={closeModal}
        leftIcon={ICON_NAME.arrowBack}
        variant={BUTTON_VARIANT.secondary}
        text={t('modalRemoveAccess_cancelButton.message')}
      />
      <Button
        onClick={removeAccess}
        leftIcon={ICON_NAME.check}
        text={t('modalRemoveAccess_submitButton.message')}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<ModalFooter />} closeModal={closeModal}>
      <Container>
        <Title>
          {t('modalRemoveAccess_title.message')}
        </Title>
        <Trans i18nKey="modalRemoveAccess_description.message" tOptions={{ fullName: athlete.fullName }}>
          <DescriptionBold />
          <Description />
        </Trans>
      </Container>
    </ModalTemplate>
  );
};

export const ModalRemoveAccessProfile = ({
  closeModal,
  accessId,
}) => {
  const access = useSelector(selectAccessOnMyProfileByID(accessId));

  const athlete: ModalRemoveAccessAthlete = {
    id: accessId,
    fullName: access.athlete.fullName,
  };

  return <ModalRemoveAccess closeModal={closeModal} athlete={athlete} />;
};

export const ModalRemoveAccessFriend = ({
  closeModal,
  athleteId,
}) => {
  const athleteFromReducer = useSelector(selectAccessOnMyFriendsByID(athleteId));

  const athlete: ModalRemoveAccessAthlete = {
    id: athleteId,
    fullName: athleteFromReducer.fullName,
  };

  return <ModalRemoveAccess closeModal={closeModal} athlete={athlete} />;
};

const Container = styled.div`
  max-width: 682px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
 ${textH1}
 margin-bottom: 28px;
`;

const DescriptionBold = styled.p`
  ${textPBold};
  margin-bottom: 4px;
`;

const Description = styled.p`
  ${textP}
`;

const Footer = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${mediaBreakpoint.tablet} {
    margin-top: unset;
  }
`;
