import { ServiceGroup, Subscription, Subscriptions } from '@root/src/types';
import api from '@services/api';
import InitDataHandler from '@services/dataHandler/InitDataHandler';
import {
  selectCurrentSubscription,
  selectServicesGroup,
  selectSubscriptions,
  setNewSubscriptions,
  setSubscription,
  setSubscriptions,
  setSubscriptionServicesGroups,
} from '@features/subscriptions/slices/subscriptionDetailServerSlice';

class GetSubscriptionData extends InitDataHandler {
  getSubscriptions: (params?: { eventID: string }) => Promise<Subscriptions | null> = async (params) => {
    const data = {
      locale: this.locale,
      ...(params?.eventID ? {
        filtered: [
          {
            id: 'event',
            value: params.eventID,
          },
        ],
      } : {}),
    };
    const { response } = await this.requestHandler({
      request: api.subscription.registrations.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });
    if (response && response?.ok) {
      const subscriptions = await response.json();
      this.dispatch(setSubscriptions({ apiData: subscriptions }));
      return selectSubscriptions(this.store.getState());
    }
    return null;
  };

  getNewSubscriptions: () => Promise<Subscriptions | null> = async () => {
    const data = {
      locale: this.locale,
      filtered: [
        {
          id: 'eventAfter',
          value: new Date().toLocaleDateString('en-CA'),
        },
      ],
      pageSize: 0,
    };
    const { response } = await this.requestHandler({
      request: api.subscription.registrations.list({
        ctx: this.serverSideContext,
        ...data,
      }),
      serverSideContext: this.serverSideContext,
    });

    if (response && response?.ok) {
      const subscriptions = await response.json();
      this.dispatch(setNewSubscriptions(subscriptions));
      return selectSubscriptions(this.store.getState());
    }
    return null;
  };

  getSubscription: (params: { subscriptionId: string }) => Promise<Subscription | null> =
    async ({ subscriptionId }) => {
      if (subscriptionId == null) {
        // eslint-disable-next-line no-console
        console.error('GetSubscriptionData getSubscription : '
          + `required value are not set : subscriptionId=${subscriptionId}`);
        return null;
      }
      const data = {
        subscriptionId,
        locale: this.locale,
      };

      const { response } = await this.requestHandler({
        request: api.subscription.registrations.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
      });

      if (response && response?.ok) {
        const json = await response.json();
        this.dispatch(setSubscription({ subscriptionApi: json }));
        return selectCurrentSubscription(this.store.getState());
      }
      return null;
    };

  getSubscriptionServicesGroups: (params: { subscriptionId: string }) => Promise<ServiceGroup[] | null> =
    async ({ subscriptionId }) => {
      if (subscriptionId == null) {
        // eslint-disable-next-line no-console
        console.error('GetSubscriptionData getSubscriptionServices : '
          + `required value are not set : subscriptionId=${subscriptionId}`);
        return null;
      }
      const data = {
        subscriptionId,
        locale: this.locale,
      };

      const { response } = await this.requestHandler({
        request: api.subscription.registrations.services.read({
          ctx: this.serverSideContext,
          ...data,
        }),
        serverSideContext: this.serverSideContext,
      });

      if (response && response.ok) {
        const json = await response.json();
        this.dispatch(setSubscriptionServicesGroups({ servicesGroupApi: json }));
        return selectServicesGroup(this.store.getState());
      }
      return null;
    }
}

export default GetSubscriptionData;
