import React from 'react';
import styled from 'styled-components';
import Tooltip from '@features/ui/components/Tooltip';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';

export interface TooltipProps {
  tooltip?: React.ReactNode,
  tooltipSpace?: boolean,
}

const TooltipMultiInputLine = ({ tooltip, tooltipSpace } : TooltipProps) => (
  <div>
    {(tooltip || tooltipSpace) && (
      <TooltipSpace>
        {tooltip && (
          <Tooltip title={tooltip} placement="top-end">
            <Icon
              name={ICON_NAME.info}
              size="24px"
              color="var(--color-grey3)"
            />
          </Tooltip>
        )}
      </TooltipSpace>
    )}
  </div>
);

export default TooltipMultiInputLine;

const TooltipSpace = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
