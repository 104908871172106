import {
  EventDakineRanking,
  EventRanking,
  EVENT_LIST_ITEM_TYPES,
  RANKINGS_LIST_TYPE,
  Serie,
  SeriesRanking,
  TEventDakineRankingList,
  TEventRankingList,
  TSeriesRankingList,
  LiveResultsRankingFromApi,
  LiveResultsOption,
  LiveResultsContext,
  LiveResultsRankingsFromApi,
  Category,
} from '@root/src/types';
import { formatStringToNumberOrNull, removeUndefs } from '@utils/gobal';
import { athleteShortProfileFromApiToReducer } from '@features/users/formatters';

export const eventResultsCategoryFromApiToRankingListReducer = (
  eventResultsCategory: any
): TEventRankingList => removeUndefs<TEventRankingList>({
  _idMso: parseInt(eventResultsCategory.category?._idMso, 10),
  name: eventResultsCategory.category?.name || eventResultsCategory.category?._idMso,
  isTeam: eventResultsCategory.category?.isTeam ?? false,
  extraHeaders: eventResultsCategory.format?.slice(1) || [],
  rankings: eventResultsCategory.ranking.map(eventRankingFromApiToReducer),
  total: eventResultsCategory.total,
});

export const eventResultsScratchFromApiToRankingListReducer = (
  eventResultsScratch: any
): TEventRankingList => removeUndefs<TEventRankingList>({
  _idMso: parseInt(eventResultsScratch?._idMso, 10),
  name: eventResultsScratch.rankingName,
  isTeam: false,
  rankings: eventResultsScratch.ranking.map(eventRankingFromApiToReducer),
  extraHeaders: eventResultsScratch.format?.slice(1) || [],
  total: eventResultsScratch.total,
});

export const eventResultsDakineFromApiToRankingListReducer = (
  eventResultsScratch: any
): TEventDakineRankingList => removeUndefs<TEventDakineRankingList>({
  _idMso: parseInt(eventResultsScratch?._idMso, 10),
  name: eventResultsScratch.rankingName,
  rankings: eventResultsScratch.ranking?.map(eventRankingDakineFromApiToReducer),
  headers: eventResultsScratch.format?.map(({ name }) => name),
  isTeam: false,
  extraHeaders: [],
  total: eventResultsScratch.total,
});

const eventRankingDakineFromApiToReducer = (ranking: any): EventDakineRanking => removeUndefs<EventDakineRanking>({
  id: ranking._idMso,
  columns: ranking.results,
});

const eventRankingFromApiToReducer = (ranking: any): EventRanking => removeUndefs<EventRanking>({
  id: ranking.athlete?._idMso,
  athlete: athleteShortProfileFromApiToReducer(ranking.athlete),
  teamMembers: ranking.teamMembers?.map(teamMate => athleteShortProfileFromApiToReducer(teamMate)) ?? null,
  teamName: ranking.teamname,
  bib: ranking.bib,
  result: resultFromApiToReducer(ranking.results),
  categoryName: ranking.category?.name,
});

export const resultFromApiToReducer = (results: any) => {
  if (
    results != null
    && results[0] != null
  ) {
    const position = formatStringToNumberOrNull(results[0]);
    if (position == null) return null;
    const timings = results.slice(1);
    return {
      position,
      timings: timings.length > 0 ? timings : null,
    };
  }
  return null;
};

export const seriesResultsFromApiToRankingListReducer = (rankingType: RANKINGS_LIST_TYPE, seriesResult: any)
  : TSeriesRankingList => removeUndefs<TSeriesRankingList>({
    _idMso: seriesResult.category?._id ?? '0', // For Series scratch or dakine because it's not a fucking list
    name: seriesResult.category?.name,
    isTeam: false,
    extraHeaders: seriesResult.format || [],
    rankings: seriesResult.ranking?.map((a) => seriesRankingFromApiToReducer(rankingType, a)),
  });
const seriesRankingFromApiToReducer = (
  rankingType: RANKINGS_LIST_TYPE,
  ranking: any
): SeriesRanking => removeUndefs<SeriesRanking>({
  id: rankingType === RANKINGS_LIST_TYPE.DAKINE ? ranking.athlete?.fullName : ranking.athlete._idMso,
  athlete: rankingType === RANKINGS_LIST_TYPE.DAKINE
    ? { fullName: ranking.athlete?.fullName as string }
    : athleteShortProfileFromApiToReducer(ranking.athlete),
  categoryName: ranking.categoryName,
  teamMembers: ranking.teamMembers?.map(teamMate => athleteShortProfileFromApiToReducer(teamMate)) ?? null,
  position: ranking.pos,
  gap: ranking.gap,
  nbRace: ranking.nbc,
  points: ranking.points,
  results: ranking.results.map(result => ({
    points: result.points,
    count: result.count,
  })),
});

export const seriesListFromApiToReducer = (serie: any): Serie => removeUndefs<Serie>({
  _id: serie._id,
  _idMso: parseInt(serie._idMso, 10),
  _type: EVENT_LIST_ITEM_TYPES.serie,
  computeMode: serie.computeMode,
  serieResultsType: serie.serieResultsType,
  name: serie.name,
  year: serie.year,
});

export const rankingsFromApiToReducer = (rankings) => {
  const hasScratch = !!rankings.filter(item => item.resultsType === RANKINGS_LIST_TYPE.SCRATCH).length;
  const hasCategories = !!rankings.filter(item => item.resultsType === RANKINGS_LIST_TYPE.CATEGORIES).length;
  const hasDakine = !!rankings.filter(item => !item.resultsType).length;
  return {
    [RANKINGS_LIST_TYPE.SCRATCH]: hasScratch,
    [RANKINGS_LIST_TYPE.CATEGORIES]: hasCategories,
    [RANKINGS_LIST_TYPE.DAKINE]: hasDakine,
  };
};

export const getResultsFormatter = (rankingType: RANKINGS_LIST_TYPE) => {
  const formatterMap = {
    [RANKINGS_LIST_TYPE.SCRATCH]: eventResultsScratchFromApiToRankingListReducer,
    [RANKINGS_LIST_TYPE.CATEGORIES]: eventResultsCategoryFromApiToRankingListReducer,
    [RANKINGS_LIST_TYPE.DAKINE]: eventResultsDakineFromApiToRankingListReducer,
  };

  return formatterMap[rankingType];
};

export const liveResultsFromApiToReducer = (
  liveResultsFromApi: LiveResultsRankingsFromApi,
  liveResultsContext: LiveResultsContext,
  currentOption: LiveResultsOption,
  categories: Array<Category>,
): Array<TEventRankingList> => {
  const resultId = currentOption.categoryId ?? currentOption.typeId;

  const fixedHeaders = [
    {
      name: 'eventResultsList_headerTime.message',
      mandatory: true,
    }, {
      name: 'eventResultsList_headerGap.message',
      mandatory: false,
    }, {
      name: 'eventResultsList_headerAverageSpeed.message',
      mandatory: false,
    },
  ];

  return Object.entries(liveResultsFromApi.results).map(([wiclaxResultId, result]) => {
    const stats = liveResultsFromApi.groups[wiclaxResultId];

    const splitsMask = stats.best.splitTimes
      .map((splitTime) => (!!splitTime));

    const splitsHeaders = splitsMask
      .map((splitTime, i) => (splitTime ? {
        name: liveResultsContext.splits[i],
        mandatory: false,
      } : null))
      .filter((splitHeader) => !!splitHeader);

    return removeUndefs<TEventRankingList>({
      _idMso: resultId,
      name: currentOption.label,
      isTeam: false, // TODO: support team results
      rankings: result.map(ranking => liveResultsRankingFromApiToReducer(ranking, categories, splitsMask)),
      extraHeaders: [...fixedHeaders, ...splitsHeaders],
      total: stats.total,
    });
  });
};

const liveResultsRankingFromApiToReducer = (
  ranking: LiveResultsRankingFromApi,
  categories: Array<Category>,
  splitsMask: Array<boolean>,
): EventRanking => {
  const fixedTimings = [ranking.raceTime, ranking.gap, ranking.avgSpeed?.toString()];
  const splitsTimings = splitsMask
    .map((splitTime, i) => (splitTime ? ranking.splitTimes[i] : null))
    .filter((splitHeader) => !!splitHeader);

  const categoryName = categories.find(category => category._idMso === ranking.category).name;

  return removeUndefs<EventRanking>({
    id: ranking.bib,
    athlete: athleteShortProfileFromApiToReducer({
      _idMso: ranking.fid_athlete,
      ...ranking.athlete,
    }),
    teamName: ranking.teamname,
    bib: ranking.bib,
    result: {
      position: ranking.rank,
      timings: [...fixedTimings, ...splitsTimings],
    },
    categoryName,
    // teamMembers: ranking.teamMembers?.map(teamMate => athleteShortProfileFromApiToReducer(teamMate)) ?? null, // TODO: support team members
  });
};
