import { FormikContextType } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import EventSearchForm, { resetValues } from '@features/eventList/components/EventSearchForm';

const ModalEventFilter = ({ closeModal }) => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  const [formikContext, setFormikContext] = useState<FormikContextType<any>>(null);
  const router = useRouter();

  useEffect(() => {
    if (responsive === RESPONSIVE.DESKTOP) {
      closeModal();
    }
  }, [responsive]);

  const ModalFooter = () => (
    <Footer>
      <Button
        type="button"
        variant={BUTTON_VARIANT.link}
        leftIcon={ICON_NAME.refresh}
        text={t('eventSearchForm_refresh.message')}
        onClick={() => resetValues(router)}
      />
      <Button
        text={t('eventSearchModal_send.message')}
        leftIcon={ICON_NAME.arrowForward}
        variant={BUTTON_VARIANT.primary}
        onClick={() => formikContext?.submitForm()}
      />
    </Footer>
  );

  return (
    <ModalTemplate footer={<ModalFooter />} closeModal={closeModal}>
      <StyledEventSearchForm getFormik={setFormikContext} isMobile />
    </ModalTemplate>
  );
};

export default ModalEventFilter;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledEventSearchForm = styled(EventSearchForm)`
  width: 100%;
`;
