import { DateTime } from 'luxon';
import { isArray, isEqual, isObject, transform } from 'lodash';

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const removeUndefs = <T>(obj: T): T => {
  if (obj === undefined) {
    return null;
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      // WARNING
      // This function is mainly running by formatters and there are used by the reducer
      // So the object sending is params is often a draft object (from immer) then you can recall this function again (obj[key] = removeUndefs(obj[key])
      // To avoid this problem, you should re-assign each keys on the formatter
      // Example :
      // API object = { keyA: { keyB: [] } }
      // If you do the formatter like that :
      //      formatter = removeUndefs(apiObj => ({ keyA: apiObj.keyA }))
      // instead of
      //      formatter = removeUndefs(apiObj => ({ keyA: apiObj.keyA ? { keyB : apiObj.keyA.keyB } : null }))
      // If keyA is undefined, the removeUndefs will crash because a is draft (= frozen object)
      // If you try to assign the children after the execution of the formatter, it will be not possible because the object is frozen
      try {
        obj[key] = removeUndefs(obj[key]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }
  return obj;
};

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const undefsNullsToEmptyString = <T>(obj: T): T => {
  if (obj == null) {
    return '' as any;
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      obj[key] = undefsNullsToEmptyString(obj[key]);
    }
  }
  return obj;
};

// This function remove all keys with empty strings
// -> backend doesn't accept empty strings or null when not required...
export const removeEmpty = (obj) => Object.fromEntries(
  Object.entries(obj)
    .filter(([, v]) => !(typeof v === 'string' && v.trim() === ''))
    .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v]),
);

// This regex will capitalize each word separated by ' ' espace and by dash. Ex: (Jean-CLAUDE) => Jean-Claude
export const capitalizeWords = (str) => str.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
  (firstLetter) => firstLetter.toUpperCase());

export const dashIfNullOrEmpty = str => (str && str.length > 0 ? str : '-');

export const testValidString = (value: string | null | undefined) => value != null && value !== '';

export const formatNumber = (number: Number | string, currency = null) => {
  if (number == null) {
    return '-';
  }

  let output = '';

  if (typeof number === 'string') {
    output = parseFloat(number as string).toFixed(2);
  }
  output = (number as Number).toFixed(2);

  return currency ? `${output} ${currency}` : output;
};

interface DateObject {
  day: number,
  month: number,
  year: number
}

export const handleBirthdateValidation = (date: DateObject, setDateError, t, minYear) => {
  const isDateFilled = date.day && date.month && date.year;
  if (!isDateFilled || date.year < minYear) {
    return true;
  }
  const isDateValid = DateTime.fromObject(date).isValid;
  setDateError?.(isDateValid ? '' : t('athleteForm_birthdateInvalid.message'));
  return isDateValid;
};

/**
 * Source: https://davidwells.io/snippets/get-difference-between-two-objects-javascript
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        // eslint-disable-next-line no-plusplus
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value;
      }
    });
  }

  return changes(newObj, origObj);
}

/**
 * Returns a random int
 */
export const randomInt = (): number => parseInt(Math.random().toString().split('.')[1], 10);

export const formatPhoneNumberFormToApi = (number) => number?.replace(/\s/g, '') ?? '';

export const isFloat = (n) => Number(n) === n && n % 1 !== 0;

export const formatStringToNumberOrNull: (value: string | null | undefined | number) => null | number = (value) => {
  if (value == null) return null;
  if (typeof value === 'number') return value;
  const valueCastToNumber = parseInt(value.replace(/\s+/g, ''), 10);
  return Number.isNaN(valueCastToNumber) ? null : valueCastToNumber;
};
