import { css } from 'styled-components';
import { textP } from '@features/ui/styles/textStyles';

const dateRangeCss = css`
  .DateRangePickerInput {
    border: none;
    display: flex;
    width: 100%;
  }

  .DateInput {
    background: var(--color-grey1);
    border-radius: 4px;
    height: 42px;
    width: 100%;

    input {
      background: var(--color-grey1);
      border-radius: 4px;
      height: 42px;
      ${textP};
      color: var(--color-grey3);
    }
  }

  .DateInput input, .DateInput input:focus {
    border-bottom: none;
  }

  .DateRangePickerInput_clearDates {
    display: flex;
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 45px;
  }

  .CalendarMonth_caption {
    padding-bottom: 45px;
  }

  .DateInput_1 {
    margin-right: 5px;
  }

  .DayPicker_portal__vertical {
    height: 100%;
  }

  .CalendarDay {
    vertical-align: middle;
  }

  .CalendarDay__selected {
    background: var(--color-blue);
    color: white;
    border: none;
  }

  .CalendarDay__hovered_span:hover, &.CalendarDay__hovered_span {
    background: var(--color-blue-hover);
    color: white;
  }

  .CalendarDay__selected_span {
    background: var(--color-blue-hover);
    color: white;
    border: none;
  }
`;
export default dateRangeCss;
