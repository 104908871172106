import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { textPSemiBold } from '@features/ui/styles/textStyles';

type UncontrolledProps = {
  isUncontrolled: true;
  defaultValue: boolean;
  value?: never;
};

type ControlledProps = {
  isUncontrolled?: false;
  value: boolean;
  defaultValue?: never;
}

interface GenericInputCheckboxProps {
  /** Also clickable label to display */
  checkboxLabel?: string | React.ReactNode;
  /** Function called when the user changes the value */
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  /** Whether the component is disabled */
  disabled?: boolean;
  /** ClassName to pass to the parent div */
  className?: string;
  /** Whether clicking on the checkbox propagates or not */
  stopPropagation?: any;

  /** Other props are passed to the inner <input> element */
  [etc: string]: any;
}

export type InputCheckboxProps = (GenericInputCheckboxProps & ControlledProps)
  | (GenericInputCheckboxProps & UncontrolledProps);

/**
 * A checkbox. Can be used both controlled and uncontrolled.
 * @constructor
 */
export const InputCheckbox: React.FunctionComponent<InputCheckboxProps> = ({
  defaultValue,
  value,
  checkboxLabel: label,
  onChange,
  disabled,
  className,
  stopPropagation,
  ...etc
}) => {
  if (!(defaultValue === false || defaultValue === true || value === true || value === false || etc.checked)) {
    // eslint-disable-next-line no-console
    console.warn('Checkbox component incorrect value or default value :', defaultValue, value, etc);
  }

  const realOnClick = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <CheckboxContainer
      disabled={disabled}
      className={className}
      onClick={stopPropagation && handleStopPropagation}
    >
      <InvisibleRealInput
        type="checkbox"
        onChange={realOnClick}
        disabled={disabled}
        checked={value}
        defaultChecked={defaultValue}
        {...etc}
      />
      <Background
        disabled={disabled}
        className="mso_checkboxSquare"
      >
        <Checkmark className="mso_checkmark">
          <CheckmarkStem disabled={disabled} className="mso_checkmark_stem" />
          <CheckmarkKick disabled={disabled} className="mso_checkmark_kick" />
        </Checkmark>
      </Background>
      <CheckboxLabelContainer hasText={label != null} disabled={disabled} className="label">
        {label}
      </CheckboxLabelContainer>
    </CheckboxContainer>
  );
};

export default InputCheckbox;

const CheckboxContainer = styled.label<{disabled?: boolean}>`
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 2px;
  padding: 0px;
  ${({ disabled }) => (disabled ? 'cursor: not-allowed;' : '')}

  /* Checked */
  & input:focus-visible ~ .mso_checkboxSquare {
    background: var(--color-orange-light);
  }
  & input:checked ~ .mso_checkboxSquare {
    background: var(--color-orange);
    border: 1px solid var(--color-orange);
    &:hover {
      background: var(--color-orange-hover);
    }

    /* disabled style */
    ${({ disabled }) => disabled && css`
      &, &:hover {
        border: 1px solid var(--color-grey3);
        background: var(--color-grey3);
      }
    `}
  }
  & input:checked ~ .mso_checkboxSquare .mso_checkmark {
    opacity: 1;
  }
`;

const checkboxStyles = (disabled) => {
  if (disabled) {
    return css`
      border: 2px solid var(--color-grey3);
      padding: 1px;
      cursor: not-allowed;
    `;
  }
  return css`
    border: 2px solid var(--color-orange);
    &:hover {
      border: 2px solid var(--color-orange-hover);
    }
    padding: 1px;
    cursor: pointer;
  `;
};

const Background = styled.div<{disabled?: boolean}>`
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 2px;
  margin-top: 2px;
  ${({ disabled }) => checkboxStyles(disabled)}
  justify-content: center;
  align-items: center;
  display: inline-flex;
`;

const Checkmark = styled.span`
  opacity: 0;
  transition: opacity 0.1s;
  width: 16px;
  height: 16px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
`;

const CheckmarkStem = styled.div<{ disabled: boolean; }>`
  position: absolute;
  width: 2px;
  height: 11px;
  background: var(--color-white);
  left: 8px;
  top: 1px;
  border-radius: 1px;
`;

const CheckmarkKick = styled.div<{ disabled: boolean; }>`
  position: absolute;
  width: 7px;
  height: 2px;
  background: var(--color-white);
  left: 3px;
  top: 11px;
  border-radius: 1px;
`;

export const InvisibleRealInput = styled.input`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
`;

const CheckboxLabelContainer = styled.div<{hasText?: boolean, disabled?:boolean}>`
  ${({ hasText }) => (hasText ? 'margin-left: 12px;' : 'visibility: hidden;')};
  ${textPSemiBold};
  color: ${({ disabled }) => (disabled ? 'var(--color-grey3)' : 'var(--color-grey4)')};
`;
