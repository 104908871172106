import { TFunction } from 'next-i18next';
import React from 'react';
import { FieldType, Service } from '@root/src/types';
import InputText, { InputTextProps } from '@features/ui-input/components/InputText';
import InputSelect, { InputSelectProps } from '@features/ui-input/components/InputSelect';
import InputSelectPrice from '@features/ui-input/components/InputSelect/InputSelectPrice';
import InputCheckbox, { InputCheckboxProps } from '@features/ui-input/components/InputCheckbox';
import InputYesNo from '@features/ui-input/components/InputYesNo';
import InputPrice from '@features/ui-input/components/InputPrice';
import TogglePrice from '@features/ui-input/components/TogglePrice';
import InputPhone from '@features/ui-input/components/InputPhone';

interface BaseInputProps {
  as: React.ReactNode;
  showRequiredChip?: boolean;
  type?: string;
}

type FactoryProductType = BaseInputProps & (InputCheckboxProps | InputTextProps | InputSelectProps);

const servicesInputFactory = (service: Service, t: TFunction, isPreService = false): FactoryProductType => {
  switch (service.fieldType) {
    case FieldType.Checkbox: {
      if (service.invoiced) {
        return {
          as: TogglePrice,
          showRequiredChip: service.mandatory,
          checkboxLabel: t('servicesInputFactory_checkboxYes.message'),
        };
      }
      if (isPreService) {
        return {
          as: InputYesNo,
          showRequiredChip: service.mandatory,
          description: service.tooltip,
          descriptionLink: service.url,
          descriptionLinkText: t('servicesInputFactory_checkboxMoreInfoLink.message'),
          checkboxLabel: t('servicesInputFactory_checkboxYes.message'),
        };
      }
      return {
        as: InputCheckbox,
        showRequiredChip: service.mandatory,
        checkboxLabel: t('servicesInputFactory_checkboxYes.message'),
      };
    }
    case FieldType.Phone: {
      return {
        as: InputPhone,
        showRequiredChip: service.mandatory,
      };
    }
    case FieldType.Input: {
      return {
        as: InputText,
        type: 'text',
        showRequiredChip: service.mandatory,
      };
    }
    case FieldType.Int: {
      if (service.invoiced) {
        return {
          as: InputPrice,
          type: 'text',
          showRequiredChip: service.mandatory,
        };
      }
      return {
        as: InputText,
        type: 'number',
        showRequiredChip: service.mandatory,
      };
    }
    case FieldType.Select: {
      if (service.invoiced) {
        return {
          as: InputSelectPrice,
          showRequiredChip: service.mandatory,
          placeholder: service.choices[0],
          selectNoneLabel: t('servicesInputFactory_selectNone.message'),
          options: service.choices.map(choice => ({
            label: choice,
            value: choice,
          })),
        };
      }

      return {
        as: InputSelect,
        showRequiredChip: service.mandatory,
        placeholder: service.choices[0],
        options: service.choices.map(choice => ({
          label: choice,
          value: choice,
        })),
      };
    }
    default:
      return {
        as: InputText,
        showRequiredChip: service.mandatory,
      };
  }
};

export default servicesInputFactory;
