export const countryList = (t) => [
  {
    label: t('countries:country_GER.message'),
    value: 'GER',
  },
  {
    label: t('countries:country_BEL.message'),
    value: 'BEL',
  },
  {
    label: t('countries:country_FRA.message'),
    value: 'FRA',
  },
  {
    label: t('countries:country_ITA.message'),
    value: 'ITA',
  },
  {
    label: t('countries:country_SUI.message'),
    value: 'SUI',
  },
  {
    label: t('countries:country_AFG.message'),
    value: 'AFG',
  },
  {
    label: t('countries:country_ALB.message'),
    value: 'ALB',
  },
  {
    label: t('countries:country_ALG.message'),
    value: 'ALG',
  },
  {
    label: t('countries:country_ASA.message'),
    value: 'ASA',
  },
  {
    label: t('countries:country_AND.message'),
    value: 'AND',
  },
  {
    label: t('countries:country_ANG.message'),
    value: 'ANG',
  },
  {
    label: t('countries:country_AIA.message'),
    value: 'AIA',
  },
  {
    label: t('countries:country_ANT.message'),
    value: 'ANT',
  },
  {
    label: t('countries:country_ARG.message'),
    value: 'ARG',
  },
  {
    label: t('countries:country_ARM.message'),
    value: 'ARM',
  },
  {
    label: t('countries:country_ARU.message'),
    value: 'ARU',
  },
  {
    label: t('countries:country_AUS.message'),
    value: 'AUS',
  },
  {
    label: t('countries:country_AUT.message'),
    value: 'AUT',
  },
  {
    label: t('countries:country_AZE.message'),
    value: 'AZE',
  },
  {
    label: t('countries:country_BAH.message'),
    value: 'BAH',
  },
  {
    label: t('countries:country_BRN.message'),
    value: 'BRN',
  },
  {
    label: t('countries:country_BAN.message'),
    value: 'BAN',
  },
  {
    label: t('countries:country_BAR.message'),
    value: 'BAR',
  },
  {
    label: t('countries:country_BLR.message'),
    value: 'BLR',
  },
  {
    label: t('countries:country_BIZ.message'),
    value: 'BIZ',
  },
  {
    label: t('countries:country_BEN.message'),
    value: 'BEN',
  },
  {
    label: t('countries:country_BER.message'),
    value: 'BER',
  },
  {
    label: t('countries:country_BHU.message'),
    value: 'BHU',
  },
  {
    label: t('countries:country_BOL.message'),
    value: 'BOL',
  },
  {
    label: t('countries:country_BIH.message'),
    value: 'BIH',
  },
  {
    label: t('countries:country_BOT.message'),
    value: 'BOT',
  },
  {
    label: t('countries:country_BRA.message'),
    value: 'BRA',
  },
  {
    label: t('countries:country_IVB.message'),
    value: 'IVB',
  },
  {
    label: t('countries:country_BRU.message'),
    value: 'BRU',
  },
  {
    label: t('countries:country_BUL.message'),
    value: 'BUL',
  },
  {
    label: t('countries:country_BUR.message'),
    value: 'BUR',
  },
  {
    label: t('countries:country_BDI.message'),
    value: 'BDI',
  },
  {
    label: t('countries:country_CAM.message'),
    value: 'CAM',
  },
  {
    label: t('countries:country_CMR.message'),
    value: 'CMR',
  },
  {
    label: t('countries:country_CAN.message'),
    value: 'CAN',
  },
  {
    label: t('countries:country_CPV.message'),
    value: 'CPV',
  },
  {
    label: t('countries:country_AHO.message'),
    value: 'AHO',
  },
  {
    label: t('countries:country_CAY.message'),
    value: 'CAY',
  },
  {
    label: t('countries:country_CAF.message'),
    value: 'CAF',
  },
  {
    label: t('countries:country_CHA.message'),
    value: 'CHA',
  },
  {
    label: t('countries:country_CHI.message'),
    value: 'CHI',
  },
  {
    label: t('countries:country_CHN.message'),
    value: 'CHN',
  },
  {
    label: t('countries:country_COL.message'),
    value: 'COL',
  },
  {
    label: t('countries:country_COM.message'),
    value: 'COM',
  },
  {
    label: t('countries:country_CGO.message'),
    value: 'CGO',
  },
  {
    label: t('countries:country_COD.message'),
    value: 'COD',
  },
  {
    label: t('countries:country_COK.message'),
    value: 'COK',
  },
  {
    label: t('countries:country_CRC.message'),
    value: 'CRC',
  },
  {
    label: t('countries:country_CIV.message'),
    value: 'CIV',
  },
  {
    label: t('countries:country_CRO.message'),
    value: 'CRO',
  },
  {
    label: t('countries:country_CUB.message'),
    value: 'CUB',
  },
  {
    label: t('countries:country_CYP.message'),
    value: 'CYP',
  },
  {
    label: t('countries:country_CZE.message'),
    value: 'CZE',
  },
  {
    label: t('countries:country_DEN.message'),
    value: 'DEN',
  },
  {
    label: t('countries:country_DJI.message'),
    value: 'DJI',
  },
  {
    label: t('countries:country_DMA.message'),
    value: 'DMA',
  },
  {
    label: t('countries:country_DOM.message'),
    value: 'DOM',
  },
  {
    label: t('countries:country_ECU.message'),
    value: 'ECU',
  },
  {
    label: t('countries:country_EGY.message'),
    value: 'EGY',
  },
  {
    label: t('countries:country_ESA.message'),
    value: 'ESA',
  },
  {
    label: t('countries:country_GEQ.message'),
    value: 'GEQ',
  },
  {
    label: t('countries:country_ERI.message'),
    value: 'ERI',
  },
  {
    label: t('countries:country_EST.message'),
    value: 'EST',
  },
  {
    label: t('countries:country_SWZ.message'),
    value: 'SWZ',
  },
  {
    label: t('countries:country_ETH.message'),
    value: 'ETH',
  },
  {
    label: t('countries:country_FLK.message'),
    value: 'FLK',
  },
  {
    label: t('countries:country_FAR.message'),
    value: 'FAR',
  },
  {
    label: t('countries:country_FIJ.message'),
    value: 'FIJ',
  },
  {
    label: t('countries:country_FIN.message'),
    value: 'FIN',
  },
  {
    label: t('countries:country_FGU.message'),
    value: 'FGU',
  },
  {
    label: t('countries:country_FPO.message'),
    value: 'FPO',
  },
  {
    label: t('countries:country_GAB.message'),
    value: 'GAB',
  },
  {
    label: t('countries:country_GAM.message'),
    value: 'GAM',
  },
  {
    label: t('countries:country_GEO.message'),
    value: 'GEO',
  },
  {
    label: t('countries:country_GHA.message'),
    value: 'GHA',
  },
  {
    label: t('countries:country_GIB.message'),
    value: 'GIB',
  },
  {
    label: t('countries:country_GRE.message'),
    value: 'GRE',
  },
  {
    label: t('countries:country_GRL.message'),
    value: 'GRL',
  },
  {
    label: t('countries:country_GRN.message'),
    value: 'GRN',
  },
  {
    label: t('countries:country_GUD.message'),
    value: 'GUD',
  },
  {
    label: t('countries:country_GUM.message'),
    value: 'GUM',
  },
  {
    label: t('countries:country_GUA.message'),
    value: 'GUA',
  },
  {
    label: t('countries:country_GUI.message'),
    value: 'GUI',
  },
  {
    label: t('countries:country_GBS.message'),
    value: 'GBS',
  },
  {
    label: t('countries:country_GUY.message'),
    value: 'GUY',
  },
  {
    label: t('countries:country_HAI.message'),
    value: 'HAI',
  },
  {
    label: t('countries:country_HON.message'),
    value: 'HON',
  },
  {
    label: t('countries:country_HKG.message'),
    value: 'HKG',
  },
  {
    label: t('countries:country_HUN.message'),
    value: 'HUN',
  },
  {
    label: t('countries:country_ISL.message'),
    value: 'ISL',
  },
  {
    label: t('countries:country_IND.message'),
    value: 'IND',
  },
  {
    label: t('countries:country_INA.message'),
    value: 'INA',
  },
  {
    label: t('countries:country_IRI.message'),
    value: 'IRI',
  },
  {
    label: t('countries:country_IRQ.message'),
    value: 'IRQ',
  },
  {
    label: t('countries:country_IRL.message'),
    value: 'IRL',
  },
  {
    label: t('countries:country_ISR.message'),
    value: 'ISR',
  },
  {
    label: t('countries:country_JAM.message'),
    value: 'JAM',
  },
  {
    label: t('countries:country_JPN.message'),
    value: 'JPN',
  },
  {
    label: t('countries:country_JOR.message'),
    value: 'JOR',
  },
  {
    label: t('countries:country_KAZ.message'),
    value: 'KAZ',
  },
  {
    label: t('countries:country_KEN.message'),
    value: 'KEN',
  },
  {
    label: t('countries:country_KIR.message'),
    value: 'KIR',
  },
  {
    label: t('countries:country_KUW.message'),
    value: 'KUW',
  },
  {
    label: t('countries:country_KGZ.message'),
    value: 'KGZ',
  },
  {
    label: t('countries:country_LAO.message'),
    value: 'LAO',
  },
  {
    label: t('countries:country_LAT.message'),
    value: 'LAT',
  },
  {
    label: t('countries:country_LIB.message'),
    value: 'LIB',
  },
  {
    label: t('countries:country_LES.message'),
    value: 'LES',
  },
  {
    label: t('countries:country_LBR.message'),
    value: 'LBR',
  },
  {
    label: t('countries:country_LBA.message'),
    value: 'LBA',
  },
  {
    label: t('countries:country_LIE.message'),
    value: 'LIE',
  },
  {
    label: t('countries:country_LTU.message'),
    value: 'LTU',
  },
  {
    label: t('countries:country_LUX.message'),
    value: 'LUX',
  },
  {
    label: t('countries:country_MAC.message'),
    value: 'MAC',
  },
  {
    label: t('countries:country_MAD.message'),
    value: 'MAD',
  },
  {
    label: t('countries:country_MAW.message'),
    value: 'MAW',
  },
  {
    label: t('countries:country_MAS.message'),
    value: 'MAS',
  },
  {
    label: t('countries:country_MDV.message'),
    value: 'MDV',
  },
  {
    label: t('countries:country_MLI.message'),
    value: 'MLI',
  },
  {
    label: t('countries:country_MLT.message'),
    value: 'MLT',
  },
  {
    label: t('countries:country_MSH.message'),
    value: 'MSH',
  },
  {
    label: t('countries:country_MRT.message'),
    value: 'MRT',
  },
  {
    label: t('countries:country_MTN.message'),
    value: 'MTN',
  },
  {
    label: t('countries:country_MRI.message'),
    value: 'MRI',
  },
  {
    label: t('countries:country_MAY.message'),
    value: 'MAY',
  },
  {
    label: t('countries:country_MEX.message'),
    value: 'MEX',
  },
  {
    label: t('countries:country_FSM.message'),
    value: 'FSM',
  },
  {
    label: t('countries:country_MDA.message'),
    value: 'MDA',
  },
  {
    label: t('countries:country_MON.message'),
    value: 'MON',
  },
  {
    label: t('countries:country_MGL.message'),
    value: 'MGL',
  },
  {
    label: t('countries:country_MGO.message'),
    value: 'MGO',
  },
  {
    label: t('countries:country_MNT.message'),
    value: 'MNT',
  },
  {
    label: t('countries:country_MAR.message'),
    value: 'MAR',
  },
  {
    label: t('countries:country_MOZ.message'),
    value: 'MOZ',
  },
  {
    label: t('countries:country_MYA.message'),
    value: 'MYA',
  },
  {
    label: t('countries:country_NAM.message'),
    value: 'NAM',
  },
  {
    label: t('countries:country_NRU.message'),
    value: 'NRU',
  },
  {
    label: t('countries:country_NEP.message'),
    value: 'NEP',
  },
  {
    label: t('countries:country_NED.message'),
    value: 'NED',
  },
  {
    label: t('countries:country_NCD.message'),
    value: 'NCD',
  },
  {
    label: t('countries:country_NZL.message'),
    value: 'NZL',
  },
  {
    label: t('countries:country_NCA.message'),
    value: 'NCA',
  },
  {
    label: t('countries:country_NIG.message'),
    value: 'NIG',
  },
  {
    label: t('countries:country_NGR.message'),
    value: 'NGR',
  },
  {
    label: t('countries:country_NIU.message'),
    value: 'NIU',
  },
  {
    label: t('countries:country_NFI.message'),
    value: 'NFI',
  },
  {
    label: t('countries:country_PRK.message'),
    value: 'PRK',
  },
  {
    label: t('countries:country_MKD.message'),
    value: 'MKD',
  },
  {
    label: t('countries:country_NMA.message'),
    value: 'NMA',
  },
  {
    label: t('countries:country_NOR.message'),
    value: 'NOR',
  },
  {
    label: t('countries:country_OMA.message'),
    value: 'OMA',
  },
  {
    label: t('countries:country_PAK.message'),
    value: 'PAK',
  },
  {
    label: t('countries:country_PLW.message'),
    value: 'PLW',
  },
  {
    label: t('countries:country_PLE.message'),
    value: 'PLE',
  },
  {
    label: t('countries:country_PAN.message'),
    value: 'PAN',
  },
  {
    label: t('countries:country_PNG.message'),
    value: 'PNG',
  },
  {
    label: t('countries:country_PAR.message'),
    value: 'PAR',
  },
  {
    label: t('countries:country_PER.message'),
    value: 'PER',
  },
  {
    label: t('countries:country_PHI.message'),
    value: 'PHI',
  },
  {
    label: t('countries:country_POL.message'),
    value: 'POL',
  },
  {
    label: t('countries:country_POR.message'),
    value: 'POR',
  },
  {
    label: t('countries:country_PUR.message'),
    value: 'PUR',
  },
  {
    label: t('countries:country_QAT.message'),
    value: 'QAT',
  },
  {
    label: t('countries:country_REU.message'),
    value: 'REU',
  },
  {
    label: t('countries:country_ROU.message'),
    value: 'ROU',
  },
  {
    label: t('countries:country_RUS.message'),
    value: 'RUS',
  },
  {
    label: t('countries:country_RWA.message'),
    value: 'RWA',
  },
  {
    label: t('countries:country_SAM.message'),
    value: 'SAM',
  },
  {
    label: t('countries:country_SMR.message'),
    value: 'SMR',
  },
  {
    label: t('countries:country_STP.message'),
    value: 'STP',
  },
  {
    label: t('countries:country_KSA.message'),
    value: 'KSA',
  },
  {
    label: t('countries:country_SEN.message'),
    value: 'SEN',
  },
  {
    label: t('countries:country_SRB.message'),
    value: 'SRB',
  },
  {
    label: t('countries:country_SEY.message'),
    value: 'SEY',
  },
  {
    label: t('countries:country_SLE.message'),
    value: 'SLE',
  },
  {
    label: t('countries:country_SIN.message'),
    value: 'SIN',
  },
  {
    label: t('countries:country_SVK.message'),
    value: 'SVK',
  },
  {
    label: t('countries:country_SLO.message'),
    value: 'SLO',
  },
  {
    label: t('countries:country_SOL.message'),
    value: 'SOL',
  },
  {
    label: t('countries:country_SOM.message'),
    value: 'SOM',
  },
  {
    label: t('countries:country_RSA.message'),
    value: 'RSA',
  },
  {
    label: t('countries:country_KOR.message'),
    value: 'KOR',
  },
  {
    label: t('countries:country_ESP.message'),
    value: 'ESP',
  },
  {
    label: t('countries:country_SRI.message'),
    value: 'SRI',
  },
  {
    label: t('countries:country_HEL.message'),
    value: 'HEL',
  },
  {
    label: t('countries:country_SKN.message'),
    value: 'SKN',
  },
  {
    label: t('countries:country_LCA.message'),
    value: 'LCA',
  },
  {
    label: t('countries:country_SPM.message'),
    value: 'SPM',
  },
  {
    label: t('countries:country_VIN.message'),
    value: 'VIN',
  },
  {
    label: t('countries:country_SUD.message'),
    value: 'SUD',
  },
  {
    label: t('countries:country_SUR.message'),
    value: 'SUR',
  },
  {
    label: t('countries:country_SWE.message'),
    value: 'SWE',
  },
  {
    label: t('countries:country_SYR.message'),
    value: 'SYR',
  },
  {
    label: t('countries:country_TPE.message'),
    value: 'TPE',
  },
  {
    label: t('countries:country_TJK.message'),
    value: 'TJK',
  },
  {
    label: t('countries:country_TAN.message'),
    value: 'TAN',
  },
  {
    label: t('countries:country_THA.message'),
    value: 'THA',
  },
  {
    label: t('countries:country_TLS.message'),
    value: 'TLS',
  },
  {
    label: t('countries:country_TOG.message'),
    value: 'TOG',
  },
  {
    label: t('countries:country_TGA.message'),
    value: 'TGA',
  },
  {
    label: t('countries:country_TTO.message'),
    value: 'TTO',
  },
  {
    label: t('countries:country_TUN.message'),
    value: 'TUN',
  },
  {
    label: t('countries:country_TUR.message'),
    value: 'TUR',
  },
  {
    label: t('countries:country_TKM.message'),
    value: 'TKM',
  },
  {
    label: t('countries:country_TKS.message'),
    value: 'TKS',
  },
  {
    label: t('countries:country_TUV.message'),
    value: 'TUV',
  },
  {
    label: t('countries:country_ISV.message'),
    value: 'ISV',
  },
  {
    label: t('countries:country_UGA.message'),
    value: 'UGA',
  },
  {
    label: t('countries:country_GBR.message'),
    value: 'GBR',
  },
  {
    label: t('countries:country_UKR.message'),
    value: 'UKR',
  },
  {
    label: t('countries:country_UAE.message'),
    value: 'UAE',
  },
  {
    label: t('countries:country_URU.message'),
    value: 'URU',
  },
  {
    label: t('countries:country_USA.message'),
    value: 'USA',
  },
  {
    label: t('countries:country_UZB.message'),
    value: 'UZB',
  },
  {
    label: t('countries:country_VAN.message'),
    value: 'VAN',
  },
  {
    label: t('countries:country_VEN.message'),
    value: 'VEN',
  },
  {
    label: t('countries:country_VIE.message'),
    value: 'VIE',
  },
  {
    label: t('countries:country_WAF.message'),
    value: 'WAF',
  },
  {
    label: t('countries:country_YEM.message'),
    value: 'YEM',
  },
  {
    label: t('countries:country_ZAM.message'),
    value: 'ZAM',
  },
  {
    label: t('countries:country_ZIM.message'),
    value: 'ZIM',
  },
];
