import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SERVICE_GROUP_TYPE, ServiceGroup } from '@root/src/types';
import { RootState } from '@services/store';
import { eventCategoryServiceGroupFromApiToReducer } from '@features/eventDetail/formatters';

export interface EventDetailClientSliceState {
  preServices: {
    [categoryId: number]: Array<ServiceGroup>;
  };
}

const eventDetailClientSlice = createSlice({
  name: 'eventDetailClient',
  initialState: { preServices: {} },
  reducers: {
    setPreServicesGroup: (state, action: PayloadAction<{ apiPreServices: any; category: number }>) => {
      const { apiPreServices, category } = action.payload;
      state.preServices[category] = apiPreServices
        .map(eventCategoryServiceGroupFromApiToReducer)
        .filter(group => group.groupType === SERVICE_GROUP_TYPE.PRE_SERVICES)
        .sort((a: ServiceGroup, b: ServiceGroup) => a.sortIndex - b.sortIndex)
        .map((group: ServiceGroup) => {
          group.services = group.services.sort((a, b) => a.sortIndex - b.sortIndex);
          return group;
        });
    },
  },
});
export default eventDetailClientSlice.reducer;

export const { setPreServicesGroup } = eventDetailClientSlice.actions;

// Event Categories PreServices
const selectEventCategoriesPreServicesGroups = (state: RootState) => state.eventDetailClient.preServices;
export const selectEventCategoryPreServicesGroups = (categoryId: number) => createSelector(
  selectEventCategoriesPreServicesGroups,
  categories => categories[categoryId]
);
export const selectEventCategoryPreServicesGroupsIds = (categoryId: number) => createSelector(
  selectEventCategoryPreServicesGroups(categoryId),
  preServicesGroup => preServicesGroup.map(group => group._idMso)
);

export const selectEventCategoryPreServiceGroup = (id: number) => createSelector(
  selectEventCategoriesPreServicesGroups,
  categories => Object.values(categories).flat().find(serviceGroup => serviceGroup._idMso === id)
);

export const selectEventCategoryPreService = (id: number) => createSelector(
  selectEventCategoriesPreServicesGroups,
  categories => Object
    .values(categories)
    .flat()
    .flatMap(serviceGroup => serviceGroup.services)
    .find(service => service._idMso === id)
);
