import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';
import { mediaBreakpoint } from '@services/responsiveProvider';
import api from '@services/api';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import getRoute from '@services/routeList';
import { setAuthCookies } from '@services/cookies';
import ModalTemplate from '@features/ui/templates/ModalTemplate';
import { textH1 } from '@features/ui/styles/textStyles';
import ModalRegisterFooter from '@features/users/components/ModalRegisterFooter';
import UseRegisterStepper, { REGISTER_CONTEXT, RegisterCallBack } from '@features/users/components/UseRegisterStepper';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';
import Loader from '@features/ui/components/Loader';

interface ModalRegisterProps {
  closeModal: () => void;
}

const ModalRegister = ({ closeModal }: ModalRegisterProps) => {
  const requestHandler = useRequestHandler();
  const router = useRouter();
  const { t } = useTranslation();

  const authenticateUser = async (username, password) => {
    const { response } = await requestHandler({
      request: api.auth.login({
        body: {
          login: username,
          password,
        },
      }),
      overwrite: { [STATUS_RANGE.ALL]: async (response) => response },
    });

    if (response == null) {
      await router.push(
        getRoute
          .openModal(router)
          .login(), undefined, { scroll: false },
      );
    } else {
      const {
        token,
        user,
      } = await response.json();
      setAuthCookies({
        token,
        userId: user._idMso,
      });
    }
  };

  const onUserCreated = () => {
    toast(<Alert
      variant={ALERT_VARIANTS.SUCCESS}
      title={t('modalRegister_accountCreatedTitle.message')}
      text={t('modalRegister_accountCreatedMessage.message')}
    />);
  };

  const onRegisterCallback = async ({ userAccountContext }: RegisterCallBack) => {
    const {
      username,
      password,
    } = userAccountContext.values;
    await authenticateUser(username, password);
    closeModal();
  };

  const onUserVerificationSelected = async () => {
    await router.push(
      getRoute
        .openModal(router)
        .resetPassword(), null, { scroll: false },
    );
  };

  const {
    registerView,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    currentStep,
    isFirst,
    isLast,
    handleSubmit,
    hasDuplicates,
    steps,
    isSubmitting,
  } = UseRegisterStepper(REGISTER_CONTEXT.NEW_USER,
    onUserVerificationSelected,
    [],
    onUserCreated,
    onRegisterCallback);

  return (
    <ModalTemplate
      footer={isSubmitting ? <></> : (
        <ModalRegisterFooter
          onNext={goToNextStep}
          onPrevious={goToPreviousStep}
          goToStep={goToStep}
          currentStep={currentStep}
          isFirstStep={isFirst}
          isLastStep={isLast}
          closeModal={closeModal}
          onSubmit={handleSubmit}
          hasDuplicates={hasDuplicates}
          nextButtonDisabled={!steps[currentStep - 1].valid}
        />
      )}
      closeModal={closeModal}
    >
      <ModalTitle>{t('modalRegister_title.message')}</ModalTitle>
      <FormWrapper>
        {isSubmitting
          ? <Loader />
          : registerView}
      </FormWrapper>
    </ModalTemplate>
  );
};

export default ModalRegister;

const ModalTitle = styled.div`
  ${textH1}
`;

const FormWrapper = styled.div`
  margin: 32px 0 40px;
  max-width: 682px;
  width: 100%;

  ${mediaBreakpoint.tablet} {
    max-width: unset;
  }
`;
