import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { isEmpty } from 'lodash';
import Fuse from 'fuse.js';
import {
  Category,
  EVENT_LIST_SEARCH_FILTER,
  GenericResultsList,
  ListFilter,
  LiveResultsContext,
  LiveResultsContextFromApi,
  LiveResultsOption,
  LiveResultsRankingsFromApi,
  RANKINGS_LIST_TYPE,
  ResultAvailability,
  RESULTS_TYPE,
  ResultsCount,
  ResultsListOptions,
  SeriesList,
  TEventRankingList,
  TEventResultsList,
  TSeriesResultsList,
} from '@root/src/types';
import { RootState } from '@services/store';
import {
  seriesResultsListDakineOptionsToKeys,
  seriesResultsListOptionsToKeys,
} from '@features/results/components/SeriesResultsList';
import { eventResultsListOptionsToKeys } from '@features/results/components/EventResultsList';
import { liveResultsFromApiToReducer, getResultsFormatter } from '@features/results/formatters';

export interface ResultsSliceState {
  eventResultsAvailability: ResultAvailability;
  resultsType: RESULTS_TYPE;
  seriesList: SeriesList;
  resultsList: GenericResultsList;
  resultsListOptions: ResultsListOptions;
  resultsListType: RANKINGS_LIST_TYPE;
  resultsListFilter: ListFilter;
  resultsListCount: ResultsCount;
  // live results
  liveResultsContext: LiveResultsContext;
}

const resultsSlice = createSlice({
  name: 'results',
  initialState: {
    eventResultsAvailability: null,
    resultsType: null,
    eventResultsTotal: null,
    seriesList: null,
    resultsList: null,
    resultsListOptions: null,
    resultsListType: null,
    resultsListFilter: {
      query: null,
      filterBy: EVENT_LIST_SEARCH_FILTER.ATHLETE,
    },
    resultsListCount: null,
    // live results
    liveResultsContext: null,
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.results,
    }),
  },
  reducers: {
    setEventResultsAvailability: (state, action: PayloadAction<ResultAvailability>) => {
      state.eventResultsAvailability = action.payload;
    },
    setResultType: (state, action: PayloadAction<RESULTS_TYPE>) => {
      state.resultsType = action.payload;
    },
    setSeriesList: (state, action: PayloadAction<SeriesList>) => {
      state.seriesList = action.payload;
    },
    setResultsList: (state, action: PayloadAction<GenericResultsList>) => {
      state.resultsList = action.payload;
    },
    pushResultsPaginated: (
      state,
      action: PayloadAction<{
        resultsApi: any,
        overrideCurrent?: boolean,
        resultsListType: RANKINGS_LIST_TYPE,
      }>
    ) => {
      const formatter = getResultsFormatter(action.payload.resultsListType);
      const results = Array.isArray(action.payload.resultsApi)
        ? [...action.payload.resultsApi.map(result => formatter(result))]
        : [formatter(action.payload.resultsApi)];

      if (action.payload.overrideCurrent) {
        state.resultsList = results;
        return;
      }

      results.forEach(result => {
        const rankingId = result._idMso;
        const idx = state.resultsList.findIndex(rankingList => rankingList._idMso === rankingId);
        if (idx === -1) {
          state.resultsList.push(result);
        } else {
          state.resultsList[idx].rankings = [...state.resultsList[idx].rankings, ...result.rankings];
        }
      });
    },
    setResultsListOptions: (state, action: PayloadAction<ResultsListOptions>) => {
      state.resultsListOptions = action.payload;
    },
    setResultsListType: (state, action: PayloadAction<RANKINGS_LIST_TYPE>) => {
      state.resultsListType = action.payload;
    },
    setResultsListFilter: (state, action: PayloadAction<{ query: string; filterBy: EVENT_LIST_SEARCH_FILTER }>) => {
      state.resultsListFilter = {
        query: action.payload.query,
        filterBy: action.payload.filterBy,
      };
    },
    setResultsCount: (state, action: PayloadAction<GenericResultsList>) => {
      const rankings = Array.isArray(action.payload) ? action.payload : [action.payload];
      state.resultsListCount = Object.assign({}, ...rankings.map(ranking => ({ [ranking._idMso]: ranking.total })));
    },

    // Live results
    setLiveResultsContext: (
      state,
      action: PayloadAction<{
        liveResultsContextApi: LiveResultsContextFromApi,
        categories: Category[],
      }>
    ) => {
      const { liveResultsContextApi, categories } = action.payload;

      const scratchOptions: {[rankingId: number]: LiveResultsOption} = liveResultsContextApi.rankings
        .filter(ranking => ranking.active && !ranking.isCategory)
        .reduce((acc, ranking) => ({
          ...acc,
          [ranking.id]: {
            label: ranking.name,
            wiclaxRankingId: ranking.id,
            categoryId: null,
            typeId: ranking.id,
          },
        }), {});

      let categoryOptions: {[rankingId: number]: LiveResultsOption} = {};
      const liveResultsCategoryRankingsId = liveResultsContextApi.rankings
        .find(ranking => ranking.active && ranking.isCategory)
        ?.id;
      if (liveResultsCategoryRankingsId) {
        categoryOptions = categories.reduce((acc, category) => ({
          ...acc,
          [category._idMso]: {
            label: category.name,
            wiclaxRankingId: liveResultsCategoryRankingsId,
            categoryId: category._idMso,
            typeId: category._idMso,
          },
        }), {});
      }

      const splits = liveResultsContextApi.splits.map(split => split.name);

      state.liveResultsContext = {
        options: {
          [RANKINGS_LIST_TYPE.SCRATCH]: scratchOptions,
          [RANKINGS_LIST_TYPE.CATEGORIES]: categoryOptions,
        },
        splits,
      };
    },
    pushLiveResultsRankings: (
      state,
      action: PayloadAction<{
        liveResultsFromApi: LiveResultsRankingsFromApi,
        currentOption: LiveResultsOption,
        overrideCurrent?: boolean,
        categories: Array<Category>,
      }>
    ) => {
      const { liveResultsFromApi, currentOption, overrideCurrent, categories } = action.payload;
      const { liveResultsContext } = state;

      const resultsList = liveResultsFromApiToReducer(
        liveResultsFromApi,
        liveResultsContext,
        currentOption,
        categories,
      );

      if (overrideCurrent) {
        state.resultsList = resultsList;
        return;
      }

      resultsList.forEach(result => {
        const rankingId = result._idMso;
        const idx = state.resultsList.findIndex(rankingList => rankingList._idMso === rankingId);
        if (idx === -1) {
          state.resultsList.push(result);
        } else {
          state.resultsList[idx].rankings = [...state.resultsList[idx].rankings, ...result.rankings];
        }
      });
    },
  },
});
export const {
  setEventResultsAvailability,
  setResultType,
  setSeriesList,
  setResultsList,
  pushResultsPaginated,
  setResultsListOptions,
  setResultsListType,
  setResultsListFilter,
  setResultsCount,
  setLiveResultsContext,
  pushLiveResultsRankings,
} = resultsSlice.actions;

export default resultsSlice.reducer;

export const selectEventResultsAvailability = (state: RootState) => state.results.eventResultsAvailability;
export const selectResultType = (state: RootState) => state.results.resultsType;
export const selectSeriesList = (state: RootState) => state.results.seriesList;
export const selectEventHasSeries = createSelector(
  selectSeriesList,
  seriesList => (seriesList ? seriesList.length > 0 : false)
);
export const selectResultsList = (state: RootState) => state.results.resultsList || []; // TODO 273 Should by type dynamically

export const selectResultsListOptions = (state: RootState) => state.results.resultsListOptions;
export const selectResultsListType = (state: RootState) => state.results.resultsListType;
export const selectResultsListFilter = (state: RootState) => state.results.resultsListFilter;

export const selectRankingList = (rankingId: number | string) => createSelector(
  selectResultsList,
  selectResultType,
  (resultsList, resultType) => {
    if (resultType === RESULTS_TYPE.SERIES) {
      const resultsListTyped = resultsList as TSeriesResultsList; // TODO 273
      return resultsListTyped.filter(rankingList => rankingList._idMso === rankingId)[0];
    }
    const resultsListTyped = resultsList as TEventResultsList; // TODO 273
    return resultsListTyped.filter(rankingList => rankingList._idMso === rankingId)[0];
  },
);
export const selectRankingListFiltered = (
  rankingId: number | string, // Because the _idMso is a string for results/series/categories
) => createSelector(
  selectResultType,
  selectRankingList(rankingId),
  selectResultsListFilter,
  selectResultsListType,
  (resultType, rankingList, rankingFilter, rankingType) => {
    const { query } = rankingFilter;
    if (query == null || query === '') return rankingList;

    const rankingListTs = rankingList as TEventRankingList; // TODO 273 Totally stupid and wrong because it can also be a SeriesType just because Fuse want a single type

    let keys;
    if (resultType === RESULTS_TYPE.EVENT) {
      keys = eventResultsListOptionsToKeys[rankingFilter.filterBy];
    } else if (rankingType === RANKINGS_LIST_TYPE.DAKINE) {
      keys = seriesResultsListDakineOptionsToKeys[rankingFilter.filterBy];
    } else {
      keys = seriesResultsListOptionsToKeys[rankingFilter.filterBy];
    }
    const fuse = new Fuse(rankingListTs.rankings, {
      keys,
      threshold: 0.3,
    });

    return {
      ...rankingList,
      rankings: fuse.search(query).map(fuseResult => fuseResult.item),
    };
  },
);

const selectResultsListCount = (state: RootState) => state.results.resultsListCount;
export const selectResultsListCountForRanking = (rankingId: number) => createSelector(
  selectResultsListCount,
  resultsListCount => resultsListCount?.[rankingId] ?? null,
);

// Live results
export const selectLiveResultsContext = (state: RootState) => state.results.liveResultsContext;
export const selectLiveResultsEnabled = (state: RootState) => !!state.results.liveResultsContext;
export const selectLiveResultsResultsTypesAvailable = createSelector(
  selectLiveResultsContext,
  liveResultsContext => {
    const resultsTypesAvailable = [];
    if (!isEmpty(liveResultsContext.options[RANKINGS_LIST_TYPE.SCRATCH])) {
      resultsTypesAvailable.push(RANKINGS_LIST_TYPE.SCRATCH);
    }
    if (!isEmpty(liveResultsContext.options[RANKINGS_LIST_TYPE.CATEGORIES])) {
      resultsTypesAvailable.push(RANKINGS_LIST_TYPE.CATEGORIES);
    }
    return resultsTypesAvailable;
  }
);
export const selectLiveResultsOptionsForCurrentResultsListType = createSelector(
  selectResultsListType,
  selectLiveResultsContext,
  (resultsListType, liveResultsContext) => {
    if (resultsListType === RANKINGS_LIST_TYPE.CATEGORIES) {
      return Object.values(liveResultsContext.options[RANKINGS_LIST_TYPE.CATEGORIES])
        .map(({ label, categoryId }) => ({
          label,
          value: categoryId,
        }));
    }

    return Object.values(liveResultsContext.options[RANKINGS_LIST_TYPE.SCRATCH])
      .map(({ label, wiclaxRankingId }) => ({
        label,
        value: wiclaxRankingId,
      }));
  }
);
export const selectLiveResultsOptionFromTypeId = (
  typeId: number,
) => createSelector(
  selectResultsListType,
  selectLiveResultsContext,
  (resultsListType, liveResultsContext): LiveResultsOption | null => {
    if (isEmpty(liveResultsContext.options[resultsListType])) {
      return null;
    }

    if (!typeId) {
      return Object.values(
        liveResultsContext.options[resultsListType]
      )[0] as LiveResultsOption;
    }

    return liveResultsContext.options[resultsListType][typeId];
  }
);
