import { toast } from 'react-toastify';
import Alert, { ALERT_VARIANTS } from '@features/ui/components/Alert';

export const paymentNotification = (t) => toast(<Alert
  variant={ALERT_VARIANTS.ERROR}
  title={t('orderPayment_errorMessageTitle.message')}
  text={t('orderPayment_errorMessageContent.message')}
/>);

export const paymentQuotaExceedNotification = (t) => toast(<Alert
  variant={ALERT_VARIANTS.ERROR}
  title={t('orderPayment_errorMessageTitle.message')}
  text={t('orderPayment_errorQuotaExceededMessageContent.message')}
/>);

export const paymentAmountMismatchNotification = (t) => toast(<Alert
  variant={ALERT_VARIANTS.ERROR}
  title={t('orderPayment_errorMessageTitle.message')}
  text={t('orderPayment_errorTransactionAmountMismatch.message')}
/>);
