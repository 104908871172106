import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { EVENT_LIST_SEARCH_FILTER, RANKINGS_LIST_TYPE, TSeriesResultsList } from '@root/src/types';
import routeList, { QUERY_ENUM, routeRegex, useTestRoute } from '@services/routeList';
import { selectEventSlug } from '@features/eventDetail/slices/eventDetailServerSlice';
import EventListPageTemplate from '@features/eventDetail/templates/EventListPageTemplate';
import {
  selectResultsList,
  selectResultsListFilter,
  selectResultsListOptions,
  selectResultsListType,
  setResultsListFilter,
} from '@features/results/slices/resultsSlice';
import SeriesRankingList from '@features/results/components/SeriesRankingList';
import { useSeriesListInfo } from '@features/results/utils';
import { getQueryEventId } from '@features/eventDetail/utils';
import { selectSerieSlug } from '@features/serieDetail/slices/serieDetailServerSlice';

const DAKINE_TAG_VALUE = -1000;

const SeriesResultsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const routeTester = useTestRoute();

  const isSerieRoute = routeTester(routeRegex.serie);

  const eventId = isSerieRoute
    ? null
    : getQueryEventId(router.query);
  const eventSlug = useSelector(selectEventSlug(eventId));

  const { seriesList, currentSerie } = useSeriesListInfo();
  const serieSlug = useSelector(selectSerieSlug);

  const resultsList = useSelector(selectResultsList) as TSeriesResultsList;
  const resultsListType = useSelector(selectResultsListType);
  const resultsFilter = useSelector(selectResultsListFilter);

  const resultsListOptions = useSelector(selectResultsListOptions);
  const dakineOptions = seriesList
    .filter(({ serieResultsType }) => serieResultsType === RANKINGS_LIST_TYPE.DAKINE)
    .map(({ name, _idMso }) => ({
      label: name,
      value: _idMso,
    }));

  const onFiltersChange = (query) => {
    const route = isSerieRoute
      ? routeList.series(serieSlug).results(query)
      : routeList.events(eventSlug).series(query);

    router.push(route, null, { scroll: false });
  };

  const tagList = seriesList
    .filter(({ serieResultsType }) => serieResultsType !== RANKINGS_LIST_TYPE.DAKINE)
    .map(serie => ({
      label: serie.name,
      value: serie._idMso,
    }));
  if (dakineOptions.length > 0) {
    tagList.push({
      label: t('eventResultsList_tagDAKINE.message'),
      value: DAKINE_TAG_VALUE,
    });
  }

  const tagActiveValue = resultsListType === RANKINGS_LIST_TYPE.DAKINE
    ? DAKINE_TAG_VALUE
    : currentSerie._idMso;

  const onTagChange = (serieId: number) => {
    if (serieId === DAKINE_TAG_VALUE) {
      onFiltersChange({
        type: RANKINGS_LIST_TYPE.DAKINE,
        serieId: null,
      });
      return;
    }

    const serie = seriesList.filter(serie => serie._idMso === serieId)[0];
    if (serie == null) return;
    onFiltersChange({
      type: serie.serieResultsType,
      serieId,
    });
  };

  let groupOptions = null;
  if (resultsListType === RANKINGS_LIST_TYPE.DAKINE) {
    groupOptions = dakineOptions;
  } else if (resultsListOptions != null && resultsListOptions?.length > 0) {
    groupOptions = [
      {
        label: t('eventSeriesList_selectAllCategoriesOptionsLabel.message'),
        value: -1,
      }, ...resultsListOptions,
    ];
  }

  const onGroupChange = (group) => onFiltersChange({
    type: resultsListType,
    typeId: group === -1 ? null : group,
    serieId: resultsListType === RANKINGS_LIST_TYPE.DAKINE ? group : tagActiveValue,
  });

  let searchOptions = getSearchOptions(t);
  if (resultsListType === RANKINGS_LIST_TYPE.DAKINE) {
    searchOptions = searchOptions.slice(0, 1);
  }

  const queryTypeId = router.query[QUERY_ENUM.typeId] as string;
  const querySerieId = router.query[QUERY_ENUM.serieId] as string;

  const groupOptionsValue = resultsListType === RANKINGS_LIST_TYPE.DAKINE
    ? querySerieId || dakineOptions[0].value
    : queryTypeId || -1;

  useEffect(() => {
    if (
      resultsListType === RANKINGS_LIST_TYPE.DAKINE
      && dakineOptions
      && !querySerieId
    ) {
      onFiltersChange({
        type: resultsListType,
        serieId: dakineOptions[0]?.value,
      });
    }
  }, [resultsListType, dakineOptions, querySerieId]);

  return (
    <EventListPageTemplate
      pageTitle={t('seriesResultsList_pageHeaderTitle.message')}
      groupOptions={groupOptions}
      groupOptionsValue={groupOptionsValue}
      onGroupChange={onGroupChange}
      searchOptions={searchOptions}
      onSearchSubmit={(query, filterBy) => dispatch(setResultsListFilter({
        query,
        filterBy,
      }))}
      tagList={tagList}
      tagActiveValue={tagActiveValue}
      onTagChange={onTagChange}
      filter={resultsFilter}
    >
      {resultsList.map(rankingList => (
        <SeriesRankingList rankingId={rankingList._idMso} key={rankingList._idMso} />
      ))}
    </EventListPageTemplate>
  );
};

export default SeriesResultsList;

const getSearchOptions = (t) => [
  {
    label: t('eventList_searchOptionAthlete.message'),
    value: EVENT_LIST_SEARCH_FILTER.ATHLETE,
  },
  {
    label: t('eventList_searchOptionState.message'),
    value: EVENT_LIST_SEARCH_FILTER.DEPARTEMENT,
  },
  {
    label: t('eventList_searchOptionYear.message'),
    value: EVENT_LIST_SEARCH_FILTER.YEAR,
  },
  {
    label: t('eventList_searchOptionLocality.message'),
    value: EVENT_LIST_SEARCH_FILTER.LOCALITY,
  },
  {
    label: t('eventList_searchOptionNationality.message'),
    value: EVENT_LIST_SEARCH_FILTER.NATIONALITY,
  },
];
export const seriesResultsListOptionsToKeys = {
  [EVENT_LIST_SEARCH_FILTER.ATHLETE]: [
    'athlete.firstName',
    'athlete.lastName',
    'athlete.fullName',
    'teamMembers.firstName',
    'teamMembers.lastName',
    'teamMembers.fullName',
  ],
  [EVENT_LIST_SEARCH_FILTER.DEPARTEMENT]: ['athlete.legAddress.department', 'teamMembers.legAddress.department'],
  [EVENT_LIST_SEARCH_FILTER.YEAR]: ['athlete.birthDateObj.year', 'teamMembers.birthDateObj.year'],
  [EVENT_LIST_SEARCH_FILTER.LOCALITY]: ['athlete.legAddress.locality', 'teamMembers.legAddress.locality'],
  [EVENT_LIST_SEARCH_FILTER.NATIONALITY]: ['athlete.nationality', 'teamMembers.nationality'],
};
export const seriesResultsListDakineOptionsToKeys = { [EVENT_LIST_SEARCH_FILTER.ATHLETE]: ['athlete.fullName'] };
