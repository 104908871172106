import { SPORT_CATEGORIES } from '@root/src/types';
import getRoute, { QUERY_ENUM } from '@services/routeList';

export const pushNewEventList = (router, values) => router.push(getRoute.events().list({
  search: values.search ?? router.query[QUERY_ENUM.filterSearch] as string ?? null,
  startDate: values.startDate ?? router.query[QUERY_ENUM.filterStartDate] as string ?? null,
  endDate: values.endDate ?? router.query[QUERY_ENUM.filterEndDate] as string ?? null,
  sportCategory: values.sportCategory ?? router.query[QUERY_ENUM.filterSportCategory] as SPORT_CATEGORIES ?? null,
  site: values.site ?? router.query[QUERY_ENUM.filterSite] as string ?? null,
  eventType: values.eventType ?? router.query[QUERY_ENUM.filterEventType] ?? null,
  filterTag: values.filterTag ?? router.query[QUERY_ENUM.filterTag] as string ?? null,
}));
