import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { ProfileVerification } from '@root/src/types';
import { STATUS_RANGE, useRequestHandler } from '@services/api/handlers';
import api from '@services/api';
import getRoute from '@services/routeList';
import { mediaBreakpoint } from '@services/responsiveProvider';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { textH4, textP } from '@features/ui/styles/textStyles';
import Loader from '@features/ui/components/Loader';
import { REGISTER_CONTEXT } from './UseRegisterStepper';

interface UserVerificationProps {
  user: UserSearch,
  emptyVerificationCallback?: () => void;
  context: REGISTER_CONTEXT;
  onUserSelectedClicked: (user: ProfileVerification) => void;
  usersIDsUnselectable?: Array<string>;
  description: string;
}

export interface UserSearch {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
}

const UserVerification = ({
  user,
  emptyVerificationCallback,
  context,
  onUserSelectedClicked,
  usersIDsUnselectable = [],
  description,
}: UserVerificationProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const requestHandler = useRequestHandler();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<Array<ProfileVerification>>([]);

  const handleVerification = async () => {
    const { response } = await requestHandler({
      request: api.auth.search({ query: user }),
      overwrite: { [STATUS_RANGE.CLIENT_ERROR]: async (res) => res },
    });

    setIsLoading(false);
    if (response && response?.ok) {
      const users = await response.json();
      if (users.length) {
        setUsers(users);
      } else {
        emptyVerificationCallback?.();
      }
    } else {
      emptyVerificationCallback?.();
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleVerification()
      .catch(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <UserVerificationContainer>
      <Loader />
    </UserVerificationContainer>
  ) : (
    <UserVerificationContainer>
      <Trans i18nKey={description}>
        <DescriptionBold />
        <Description />
      </Trans>
      <UserVerificationGroup>
        {users.map((user, index) => (
          <UserVerificationLine isOdd={index % 2 === 0} key={user._id}>
            <FistNameBlock>{user.firstName}</FistNameBlock>
            <LastNameBlock>{user.lastName}</LastNameBlock>
            <BirthdateYearBlock>{user.birthDateObj.year}</BirthdateYearBlock>
            <GenderBlock>{t(`${user.gender.toLowerCase()}.message`)}</GenderBlock>
            <RecoverPasswordBlock>
              {context === REGISTER_CONTEXT.NEW_USER && (
                <Button
                  text={t('userVerification_recoverPassword.message')}
                  leftIcon={ICON_NAME.lock}
                  variant={BUTTON_VARIANT.link}
                  onClick={() => onUserSelectedClicked(user)}
                />
              )}
              {context === REGISTER_CONTEXT.ADD_TEAMMATE && (
                <Button
                  onClick={() => onUserSelectedClicked(user)}
                  text={usersIDsUnselectable.includes(user._id)
                    ? t('userVerification_alreadyTeamMate.message')
                    : t('userVerification_addTeamMate.message')}
                  leftIcon={usersIDsUnselectable.includes(user._id)
                    ? ICON_NAME.check
                    : ICON_NAME.arrowForward}
                  disabled={usersIDsUnselectable.includes(user._id)}
                />
              )}
              {context === REGISTER_CONTEXT.FRIEND && (
                <Button
                  onClick={() => onUserSelectedClicked(user)}
                  text={usersIDsUnselectable.includes(user._id)
                    ? t('userVerification_alreadyFriend.message')
                    : t('userVerification_addFriend.message')}
                  leftIcon={usersIDsUnselectable.includes(user._id)
                    ? ICON_NAME.check
                    : ICON_NAME.arrowForward}
                  disabled={usersIDsUnselectable.includes(user._id)}
                />
              )}
            </RecoverPasswordBlock>
          </UserVerificationLine>
        ))}
      </UserVerificationGroup>
      {context === REGISTER_CONTEXT.NEW_USER && (
        <div>
          <Button
            text={t('userVerification_logToMyAccount.message')}
            variant={BUTTON_VARIANT.link}
            leftIcon={ICON_NAME.accountCircle}
            url={getRoute.openModal(router)
              .login()}
          />
        </div>
      )}
    </UserVerificationContainer>
  );
};

export default UserVerification;

const UserVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 682px;
  ${textP}
  ${mediaBreakpoint.tablet} {
    min-width: unset;
  }
`;

const DescriptionBold = styled.div`
  ${textH4}
`;

const Description = styled.div`
  ${textP}
  margin-top: 4px;
`;

const UserVerificationGroup = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const UserVerificationLine = styled.div<{ isOdd: boolean }>`
  background-color: ${({ isOdd }) => (isOdd ? 'var(--color-grey1)' : 'var(--color-white)')};
  padding: 22px 20px;
  align-items: center;
  justify-content: space-between;
  display: grid;
  row-gap: 20px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "firstname lastname birthdateYear gender recoverPassword";
  ${mediaBreakpoint.tablet} {
    grid-template-areas:
      "firstname lastname"
      "birthdateYear gender"
      "recoverPassword recoverPassword";
  }  
`;

const FistNameBlock = styled.div`
  grid-area: firstname; 
`;

const LastNameBlock = styled.div`
  grid-area: lastname; 
`;

const BirthdateYearBlock = styled.div`
  grid-area: birthdateYear; 
`;

const GenderBlock = styled.div`
  grid-area: gender;
  margin-right: 38px; 
  ${mediaBreakpoint.tablet} {
    margin-right: unset; 
  }
`;

const RecoverPasswordBlock = styled.div`
  grid-area: recoverPassword; 
`;
