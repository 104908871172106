import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { TFunction } from 'next-i18next';
import { EVENT_LIST_SEARCH_FILTER, EventRanking, ListFilter, SelectOptions, SeriesRankingCommon } from '@root/src/types';
import { capitalizeWords } from '@utils/gobal';
import { mediaBreakpoint } from '@services/responsiveProvider';
import SearchInput from '@features/ui/components/SearchInput';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textH1, textH4 } from '@features/ui/styles/textStyles';
import TagSelectable from '@features/ui/components/TagSelectable';
import EventResultsSubscriptionResultsList from '@features/results/components/EventResultsSubscriptionResultsList';

interface EventListOwnProps {
  pageTitle: string;
  pageCount?: string;
  groupOptions?: Array<SelectOptions>;
  groupOptionsValue?: number | string;
  onGroupChange?: (e: number | string) => void;
  searchOptions: Array<SelectOptions>;
  onSearchSubmit: (query: string, filterBy: EVENT_LIST_SEARCH_FILTER) => void;
  tagList?: Array<SelectOptions>;
  tagActiveValue?: number | string;
  onTagChange?: (e: number | string) => void;
  children: ReactNode;
  withResultSubscriptions?: boolean
  disableSearch?: boolean;
  disableFilter?: boolean;
  isLoading?: boolean;
  filter: ListFilter;
}
const EventListPageTemplate = ({
  pageTitle,
  pageCount,
  groupOptions,
  groupOptionsValue,
  onGroupChange,
  searchOptions,
  onSearchSubmit,
  tagList,
  tagActiveValue,
  onTagChange,
  children,
  withResultSubscriptions = false,
  disableSearch = false,
  disableFilter = false,
  isLoading,
  filter,
}: EventListOwnProps) => (
  <Container>
    <Header>
      <h1>{pageTitle}</h1>
      {pageCount && (
        <TextIcon>
          <Icon name={ICON_NAME.iconGroups} />
          <h4>{pageCount}</h4>
        </TextIcon>
      )}
    </Header>
    {withResultSubscriptions && (
      <StyledEventResultsSubscriptionResultsList />
    )}
    <Filter>
      {(tagList && tagList.length > 0)
        ? (
          <TagWrapper>
            {tagList.map(tag => (
              <StyledTagSelectable
                key={tag.value}
                onClick={() => onTagChange(tag.value)}
                selected={tagActiveValue === tag.value}
              >
                {tag.label}
              </StyledTagSelectable>
            ))}
          </TagWrapper>
        )
        : (<div />)}
      <StyledSearchInput
        groupOptions={groupOptions}
        onGroupChange={onGroupChange}
        groupOptionsValue={groupOptionsValue}
        searchFilterBy={searchOptions}
        onSearchSubmit={(query, filterBy) => {
          onSearchSubmit(query, filterBy as EVENT_LIST_SEARCH_FILTER);
        }}
        disableSearch={disableSearch}
        disableFilter={disableFilter}
        isLoading={isLoading}
        filter={filter}
      />
    </Filter>

    {children}
  </Container>
);

export default EventListPageTemplate;

export const getPersonName = (person, t) => {
  if (person?.lastName != null) {
    return `${person.lastName.toUpperCase()} ${capitalizeWords(person.firstName)}`;
  }
  if (person?.fullName != null) {
    return person?.fullName;
  }
  return t('engagedCategoryList_anonymous.message');
};

export const formatLocality = (localities: Array<string> | string | null) => (Array.isArray(localities)
  ? localities.map(locality => capitalizeWords(locality))
  : capitalizeWords(localities));

export const getAthleteAndTeamValue = ({ data, key, getKey, t } : {
  data: EventRanking | SeriesRankingCommon,
  key?: string,
  getKey?: (a?: any, b?: any, c?: any) => any,
  t?: TFunction,
}) => {
  const getValueThenProcessIfNeeded = (data) => {
    if (key) {
      const value = get(data, key, '-');
      return value === '' || value == null ? '-' : value;
    }
    if (getKey) {
      return getKey(data, t);
    }
    return '-';
  };

  try {
    // TODO return object with an id instead of the value directly (can be useful for react key ^^)
    const results = [getValueThenProcessIfNeeded(data.athlete)];
    if (data.teamMembers && data.teamMembers.length > 0) {
      data.teamMembers.forEach(member => {
        results.push(getValueThenProcessIfNeeded(member));
      });
    }
    return results;
  } catch (e) {
    return '';
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    ${textH1};
  }

  ${mediaBreakpoint.mobile} {
    flex-wrap: wrap;
  }
`;

const TextIcon = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    align-self: flex-start;
  }
  h4 {
    ${textH4};
    margin-left: 8px;
  }
`;
const StyledEventResultsSubscriptionResultsList = styled(EventResultsSubscriptionResultsList)`
  >:first-child {
      margin-top: 40px;
  }
`;

const Filter = styled.div`
  display: flex;
  margin-top: 52px;
  justify-content: space-between;
  ${mediaBreakpoint.tablet} {
    flex-direction: column;
    > div {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;
const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledTagSelectable = styled(TagSelectable)`
  margin-bottom: 5px;
`;
const StyledSearchInput = styled(SearchInput)`
  align-self: flex-end;
`;
