import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSubscriptions } from '@features/subscriptions/slices/subscriptionDetailServerSlice';
import SubscriptionResult from '@features/account/components/SubscriptionResult';

interface EventResultsSubscriptionResultsListProps {
  className?: string;
}

const EventResultsSubscriptionResultsList = ({ className }: EventResultsSubscriptionResultsListProps) => {
  const subscriptions = useSelector(selectSubscriptions);

  return subscriptions && subscriptions.total > 0
    ? (
      <Container className={className}>
        {subscriptions.items.map(subscription => (
          <SubscriptionResult key={subscription._idMso} subscription={subscription} />
        ))}
      </Container>
    )
    : null;
};

export default EventResultsSubscriptionResultsList;

const Container = styled.div`
 > *:not(:last-child) {
      margin-bottom: 18px;
    }
`;
