import React from 'react';
import { useTranslation } from 'next-i18next';
import getRoute from '@services/routeList';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button from '@features/ui/components/Button';

const FooterSubmitConfirmed = () => {
  const { t } = useTranslation();

  return (
    <Button
      text={t('modalRequestPasswordResetFooterSend_homeButton.message')}
      leftIcon={ICON_NAME.home}
      url={getRoute.home()}
    />
  );
};

export default FooterSubmitConfirmed;
