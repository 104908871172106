import styled, { css } from 'styled-components';

const TagHoverStyle = css`
  ::before {
    position: absolute;
    content: "";
    left: -1px;
    right: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: transparent;
    pointer-events: none;
    border: 2px solid var(--color-grey2);
    border-radius: 22px;
  }
`;
const TagSelectable = styled.div<{ selected?: boolean }>`
  height: 44px;
  border: 1px solid var(--color-grey2);
  border-radius: 22px;
  background: var(--color-white);
  color: var(--color-grey4);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 20px;
  padding-right: 20px;
  margin-right: 4px;
  text-align: center;

  position: relative;
  :hover {
    ${TagHoverStyle};
  }
  ${({ selected }) => selected && css`
    background: var(--color-blue);
    color: var(--color-white);
    border-color: var(--color-blue);
    :hover {
      background: var(--color-blue-hover);
      border-color: var(--color-blue-hover);

      ::before {
        display: none;
      }
    }
  `}

  >*:last-child {
    width: 6px;
    height: 6px;
    margin-left: 10px;
  }
`;
export default TagSelectable;
