import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import { textH3, textP } from '@features/ui/styles/textStyles';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT, OwnProps } from '@features/ui/components/Button';

const ListHeader = ({
  title,
  count,
  icon,
  onDownloadClick,
} : {
  title: string;
  count: number;
  icon: ICON_NAME;
  onDownloadClick?: (e) => void;
}) => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  return (
    <CategoryTitle>
      <TitleName>
        {title}
      </TitleName>
      {count && count > 0 ? (
        <CategoryCount>
          {count}
          <StyledIcon size="20px" name={icon} color="var(--color-dark)" />
        </CategoryCount>
      ) : null}
      {onDownloadClick && responsive !== RESPONSIVE.MOBILE && (
        <StyledButton
          onClick={onDownloadClick}
          text={t('engagedCategoryList_titleExportButton.message')}
          leftIcon={ICON_NAME.downloadFile}
          variant={BUTTON_VARIANT.secondary}
        />
      )}
    </CategoryTitle>
  );
};

export default ListHeader;

const CategoryTitle = styled.h3`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding-bottom: 10px;
  align-items: center;
  position: relative;
`;

const TitleName = styled.span`
  ${textH3};
  color: var(--color-dark);
`;

const CategoryCount = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: var(--color-grey4);
  ${textP};
  margin-right: 2px;
`;
const StyledButton = styled(Button)<OwnProps>`
  position: absolute;
  right: 0;
`;
const StyledIcon = styled(Icon)`
  margin-left: 5px;
`;
