import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SubscriptionService } from '@root/src/types';
import { selectEventCategoryServiceGroup } from '@features/eventDetail/slices/eventDetailServerSlice';
import ServiceLineTypeService from '@features/subscriptions/components/ServiceLine/ServiceLineTypeService';
import { textH3 } from '@features/ui/styles/textStyles';
import StackableCard from '@features/ui/components/StackableCard';
import { selectServicesGroupById } from '@features/subscriptions/slices/subscriptionDetailServerSlice';

interface ServiceListTypeServiceProps {
  serviceGroupId: number;
  eventId: number;
}

interface ServiceListTypeServiceOrderProps extends ServiceListTypeServiceProps{
  inputDisabled?: boolean;
  disableServiceNotUpdatable?: boolean;
  disableServiceInvoiced?: boolean;
  eventId: number;
}

export const ServicesListTypeServiceOrder = ({
  serviceGroupId,
  disableServiceInvoiced,
  disableServiceNotUpdatable,
  eventId,
}: ServiceListTypeServiceOrderProps) => {
  const serviceGroup = useSelector(selectEventCategoryServiceGroup(eventId, serviceGroupId));

  return (
    <Container>
      <Title>{serviceGroup.title}</Title>
      <StackableCard>
        {serviceGroup.services.filter(service => service).map(service => {
          const isServiceUpdatableDisabled = disableServiceNotUpdatable && !service.updatable;
          const isServiceInvoicedDisabled = disableServiceInvoiced && service.invoiced;

          const inputProps = {
            name: `group_${serviceGroup._idMso}.service_${service._idMso}`,
            disabled: isServiceUpdatableDisabled || isServiceInvoicedDisabled,
            dataQa: `subscriptions-serviceOrder-input-group_${serviceGroup._idMso}.service_${service._idMso}`,
          };

          return (
            <ServiceLineTypeService
              key={service._idMso}
              serviceId={service._idMso}
              eventId={eventId}
              useCard
              {...inputProps}
            />
          );
        })}
      </StackableCard>
    </Container>
  );
};

export const ServicesListTypeServiceSubscription = ({
  serviceGroupId,
  eventId,
}: ServiceListTypeServiceProps) => {
  const serviceGroup = useSelector(selectServicesGroupById(serviceGroupId));

  return (
    <Container>
      <Title>{serviceGroup.title}</Title>
      <StackableCard>
        {serviceGroup.services.filter(service => service).map((service : SubscriptionService) => {
          const canUpdate = service.canBeUpdated && !service.invoiced;

          const inputProps = {
            name: `group_${serviceGroup._idMso}.service_${service._idMso}`,
            disabled: !canUpdate,
          };

          return (
            <ServiceLineTypeService
              key={service._idMso}
              serviceId={service._idMso}
              eventId={eventId}
              useCard
              {...inputProps}
            />
          );
        })}
      </StackableCard>
    </Container>
  );
};

export default ServicesListTypeServiceOrder;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  ${textH3};
  margin: 20px 0;
`;
